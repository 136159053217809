import React, { useRef, useContext, useState, useEffect } from 'react';
import history from '../../../Navigation';

import { Contexto as UsersContext } from '../../../context/UsersContext';
import { Contexto as EstudiosContext } from '../../../context/EstudiosContext';
import { Contexto as DoctoresContext } from '../../../context/DoctoresContext';

import { RestContainer } from '../../../modules/RestContainer';
import MenuEstudio from '../../../modules/MenuEstudio';
import { formatoFechaBDD, formatoFechaFolio, formatoFechaFolioEnd, mesHumanoEsp2 } from '../../../utils/Fecha';
import { handlePDF } from '../../../utils/showPDF';
import { handleEmailSolicitud } from '../../../utils/envioSolicitud';

import FirmaDigital from '../../../modules/FirmaDigital';
import ModalApCi from '../../../modules/ModalApCi';
import FormFacturar from '../../../modules/SolicitudPath/FormFacturar';
import ButtonNavSolicitud from '../../../modules/SolicitudPath/ButtonNavSolicitud';
import CopiarFirma from '../../../modules/SolicitudPath/CopiarFirma';

import BusquedaMedico from './BusquedaMedico';
import ElegirEspecialidad from './ElegirEspecialidad';
import BannerPharma from './BannerPharma';
import InfoColecta from './InfoColecta';
import InfoDeMuestra from './InfoDeMuestra';
import FormPaciente from './FormPaciente';
import ModalApCiBayer from '../../../modules/ModalApCiBayer';

const SolicitarEstudio = props => {
  const seccionesRef = useRef([]);
  const btnCrumbRef = useRef([]);
  const inputAnyFile = useRef();
  const inputFileHistopatologico = useRef();
  const inputFileOtros = useRef();

  const { state: { userUid, nombre, segNombre, apellidoPat, apellidoMat, intereses, cedula, isAdmin, email, telefono, direccion }, updateDireccion } = useContext(UsersContext);
  const { state: { listaEstudios, laboratorios, listadoDiagnosticos }, getListadoDiags, subirEstudio } = useContext(EstudiosContext);
  const { state: { doctores } } = useContext(DoctoresContext);

  const currentYear = new Date().getFullYear('');

  const [tituloGeneral, setTituloGeneral] = useState('Buscar Médico');

  const [cedulaDoc, setCedula] = useState('');
  const [nomDoc, setNomDoc] = useState('');
  const [telDoc, setTelDoc] = useState('');

  const [emailDoc, setEmailDoc] = useState('');
  const [emailDoc2, setEmailDoc2] = useState('');
  const [emailElegido, setEmailElegido] = useState('');

  const [categoriaElegida, setCategoriaElegida] = useState('');
  const [estudioSolNombre, setEstudioSolNombre] = useState('');
  const [estudioElegido, setEstudioElegido] = useState([]);
  const [metodologiaEstudio, setMetodologiaEstudio] = useState('');
  const [claveEstudio, setClaveEstudio] = useState('');
  const [costoEstudio, setCostoEstudio] = useState(0);

  const [date] = useState(new Date());
  const [folio, setFolio] = useState('');

  const [nombrePaciente, setNombrePaciente] = useState('');
  const [apellidoPaciente, setApellidoPaciente] = useState('');

  const [nacDia, setNacDia] = useState(1);
  const [nacMes, setNacMes] = useState(1);
  const [nacAnio, setNacAnio] = useState(currentYear);

  const [fechaNacHumano, setFechaNacHumano] = useState('');
  const [genero, setGenero] = useState('');
  const [emailPaciente, setEmailPaciente] = useState('');
  const [ecog, setEcog] = useState();
  const [telPaciente, setTelPaciente] = useState('');
  const [celular, setCelular] = useState('');

  const [primarioSelect, setPrimarioSelect] = useState('');
  const [metastasicoSelect, setMetastasicoSelect] = useState('');

  const [muestraBloque, setMuestraBloque] = useState(false);
  const [muestraLaminillas, setMuestraLaminillas] = useState(false);

  const [numeroMuestraBloque, setNumeroMuestraBloque] = useState('');
  const [numeroMuestraLaminillas, setNumeroMuestraLaminillas] = useState('');

  const [fechaMuestra, setFechaMuestra] = useState('');
  const [muestraMes, setMuestraMes] = useState(0);
  const [muestraAnio, setMuestraAnio] = useState(currentYear);

  const [selectDiagnostico, setSelectDiagnostico] = useState('');
  const [diagnostico, setDiagnostico] = useState('');

  const [tratamientosPrevios, setTratamientosPrevios] = useState('');

  const [hiddenHistopatologico, setHiddenHistopatologico] = useState('');
  const [hiddenOtrosArchivos, setHiddenOtrosArchivos] = useState('');

  const [labRadio, setLabRadio] = useState('');

  const [listadoDirs, setListadoDirs] = useState([]);
  const [doctorRef, setDoctorRef] = useState('');
  const [dirElegida, setDirElegida] = useState(-2);
  const [inputCalleNumero, setInputCalleNumero] = useState('');
  const [inputCodigoPostal, setInputCodigoPostal] = useState('');
  const [inputColinia, setInputColinia] = useState('');
  const [inputAlcaldiaMun, setInputAlcaldiaMun] = useState('');
  const [inputEstado, setInputEstado] = useState('');
  const [inputNombreHospital, setInputNombreHospital] = useState('');
  const [inputReferencias, setInputReferencias] = useState('');

  const [horarioRecoger, setHorarioRecoger] = useState('');

  const [firmaPaciente, setFirmaPaciente] = useState(null);
  const [firmaMedico, setFirmaMedico] = useState(null);
  const [skipFirmaPaciente, setSkipFirmaPaciente] = useState(false);

  const [metodoPago, setMetodoPago] = useState('Pago en línea');
  const [facturar, setFacturar] = useState(false);
  const [fisicaMoral, setFisicaMoral] = useState('');
  const [RFC, setRFC] = useState('');
  const [emailFactura, setEmailFactura] = useState('');
  const [pago, setPagado] = useState(false);
  const [errorPayPal, setErrorPayPal] = useState(null);
  const payPalRef = useRef();

  const [formListo, setFormListo] = useState(false);
  const [enviadoPress, setEnviadoPress] = useState(false);

  const filtroEstudioCategoria = listaEstudios.filter(estudio => estudio[1]['Categoría'] === categoriaElegida);

  const fechaBDD = formatoFechaBDD(date);

  const referenciasPlaceHolder = 'Por favor indicar el piso, edificio, servicio, contacto que entregará la muestra o cualquier referencia que considere útil para una colecta exitosa.';

  /** SI no hay diagnosticos, obtenerlos */
  useEffect(() => {
    if (!listadoDiagnosticos.length) {
      getListadoDiags();
    }
    // eslint-disable-next-line
  }, []);

  /**
   * Si viene de cotización o de programa, saber qué estudio quiere
   */
  useEffect(() => {
    if (props.location.state && props.location.state.estudioElegido.length > 0) {
      setCategoriaElegida(props.location.state.estudioElegido[1]['Categoría']);
      setMetodologiaEstudio(props.location.state.estudioElegido[1]['Metodología']);
      setClaveEstudio(props.location.state.estudioElegido[1]['Clave del estudio']);
      setCostoEstudio(props.location.state.estudioElegido[1].Costo);
      setEstudioElegido(props.location.state.estudioElegido);
      if (props.location.state.estudioElegido[1].Costo === 0) {
        setPagado(true);
      }

      if (props.location.state.empresaPharma) {
        setSkipFirmaPaciente(true);
      }
    }
  }, [props]);

  /**
   * SI NO es admin, agregar la cédula
   */
  useEffect(() => {
    if (!isAdmin) {
      setDoctorRef(userUid);
      setCedula(cedula);
      setNomDoc(`${nombre} ${segNombre} ${apellidoPat} ${apellidoMat}`);
      setEmailDoc(email);
      setEmailElegido(email);
      setTelDoc(telefono);
      if (direccion[0].calle && direccion[0].colonia && direccion[0].cp && direccion[0].estado && direccion[0].municipio && direccion[0].nombreHospital) {
        setListadoDirs(direccion);
      }
      if (props.location.state && props.location.state.empresaPharma) {
        setTituloGeneral('Detalles del programa');
      } else if (props.location.state && !props.location.state.empresaPharma) {
        setTituloGeneral('Información del paciente');
      } else {
        setTituloGeneral('Estudio a solicitar');
      }
    }
  }, [isAdmin, userUid, cedula, nombre, segNombre, apellidoPat, apellidoMat, email, telefono, direccion, props.location.state]);

  /**
   * Dependiendo la cédula, traer el listado de direcciones
   * Validar si de inicio llenaron los datos del primer hospital
   */
  useEffect(() => {
    if (cedulaDoc && isAdmin) {
      const docElegido = doctores.find(doctor => {
        return doctor.cedula === cedulaDoc;
      });
      const dirDoc = docElegido.direccion[0];
      if (dirDoc.calle && dirDoc.colonia && dirDoc.cp && dirDoc.estado && dirDoc.municipio && dirDoc.nombreHospital) {
        setListadoDirs(docElegido.direccion);
      } else {
        setListadoDirs([]);
      }

      setEmailDoc(docElegido.email);
      if (!!docElegido.email2) {
        setEmailDoc2(docElegido.email2);
        setEmailElegido('');
      } else {
        setEmailElegido(docElegido.email);
      }
      setTelDoc(docElegido.telefono);

      setNomDoc(docElegido.nom)
      setDoctorRef(docElegido.ref);
      setInputCalleNumero('');
      setInputCodigoPostal('');
      setInputColinia('');
      setInputAlcaldiaMun('');
      setInputEstado('');
      setInputNombreHospital('');
      setInputReferencias('');
    }
  }, [cedulaDoc, doctores, isAdmin]);

  useEffect(() => {
    seccionesRef.current[0].style.display = "block";
  }, [seccionesRef]);

  const abandonarSolicitud = () => {
    window.Swal.fire({
      title: '¡Está a punto de cancelar la solicitud!',
      text: '¿Seguro que quiere proceder? Los datos de la solicitud serán vaciados y se tendrá que iniciar una nueva solicitud',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Seguro, abandonar la solicitud',
      confirmButtonText: 'Quedarme en la solicitud'
    }).then(response => {
      if (response.isDismissed && response.dismiss === 'cancel') {
        history.push('/home');
      }
    })
  };

  const cambioApartadoSolicitud = (Btn, seccBuscada, btnMenu = false) => {
    // Que no puedan saltar entre forms sin aprobar el anterior, disables no funcionó
    const btnSig = btnCrumbRef.current.find(btn => btn.className.includes('breadcrumb-item1'));
    if (btnSig && btnSig.id <= Btn && btnMenu) {
      return;
    }

    seccionesRef.current.forEach(seccion => seccion.style.display = "none");
    seccBuscada.style.display = "block";

    btnCrumbRef.current.forEach(btn => {
      // Es el div del btn quien tiene estas clases
      btn.parentElement.classList.add('d-none');
      btn.parentElement.classList.add('d-md-block');

      if (btn.id > Btn) {
        btn.classList.remove('breadcrumb-item2');
        btn.classList.add('breadcrumb-item1');
      } else {
        btn.classList.add('breadcrumb-item2');
        btn.classList.remove('breadcrumb-item1');
      }
    });

    document.getElementById(Btn).parentElement.classList.remove('d-none');
    document.getElementById(Btn).parentElement.classList.remove('d-md-block');
  };

  // Después de que cambie el select Seleccione categoría
  const detectarCategoriaElegida = e => {
    setCategoriaElegida(e.target.value);
    setCostoEstudio(0);
    setMetodologiaEstudio('');
    setClaveEstudio('');
  };

  // Después de que cambie el select Seleccione Estudio
  const setMetoClavePrecio = e => {
    if (e.target.value) {
      const estudioSeleccionado = filtroEstudioCategoria.find(estudio => estudio[0] === e.target.value);
      setMetodologiaEstudio(estudioSeleccionado[1]['Metodología']);
      setClaveEstudio(estudioSeleccionado[1]['Clave del estudio']);
      setCostoEstudio(estudioSeleccionado[1].Costo);
      setEstudioElegido(estudioSeleccionado);
      setEstudioSolNombre(estudioSeleccionado[0]);
    } else {
      setMetodologiaEstudio('');
      setClaveEstudio('');
      setCostoEstudio(0);
      setEstudioElegido([]);
      setEstudioSolNombre('');
    }
  };

  // Para SAFARI, manejo de la fecha de nacimiento
  const handleDateSafari = (value, maxValue, target, tipoFecha = 'nac') => {
    if (tipoFecha === 'nac') {
      if (target === 'dia') {
        setNacDia(value);

        setFechaNacHumano(`${value}-${mesHumanoEsp2(nacMes)}-${nacAnio}`);

      } else if (target === 'mes') {
        setNacMes(value);

        setFechaNacHumano(`${nacDia}-${mesHumanoEsp2(value)}-${nacAnio}`);

      } else {
        if (value > maxValue) {
          setNacAnio(currentYear);
        } else {
          setNacAnio(value);
        }

        setFechaNacHumano(`${nacDia}-${mesHumanoEsp2(nacMes)}-${value}`);
      }

    } else {
      if (target === 'mes') {
        setMuestraMes(value);
      } else {
        if (value > maxValue) {
          setMuestraAnio(currentYear);
        } else {
          setMuestraAnio(value);
        }
      }
    }
  };

  useEffect(() => {
    if (muestraMes && muestraAnio) {
      const mes = mesHumanoEsp2(muestraMes);
      setFechaMuestra(`${mes}/${muestraAnio}`);
    }
  }, [muestraMes, muestraAnio]);

  // Si se llena el correo en el form de paciente, llenar los dos por default
  const setBothEmails = emailPac => {
    setEmailPaciente(emailPac);
    setEmailFactura(emailPac);
  };

  /**
   * El input default de subir archivo está oculto, hay que manejarlo independientemente
   * @param {string} origen - id al que se hizo click
   */
  const handleSignInput = origen => {
    document.getElementById(origen).click();
  };

  /**
   * Cambiar state y mostrar en pantalla subida exitosa
   * @param {string} idInput - id para obtener el resutlado
   */
  const handleInputFileChanged = idInput => {
    if (document.getElementById(idInput).files[0]) {
      const fileName = document.getElementById(idInput).files[0].name;
      window.Swal.fire({
        title: '¡Carga exitosa!',
        text: `El documento ${fileName} se ha subido exitosamente.`,
        icon: 'success',
        confirmButtonText: '¡Listo!'
      });

      switch (idInput) {
        case 'hiddenHistopatologico':
          setHiddenHistopatologico(fileName);
          break;
        case 'hiddenOtrosArchivos':
          setHiddenOtrosArchivos(fileName);
          break;
        default:
          break;
      }
    } else {
      window.Swal.fire({
        title: '¡Cuidado, no hay documento!',
        text: `Cancelaste la carga, por lo que no se tiene ningún documento adjunto.`,
        icon: 'warning',
        confirmButtonText: 'Ok'
      });
      switch (idInput) {
        case 'hiddenHistopatologico':
          setHiddenHistopatologico('');
          break;
        case 'hiddenOtrosArchivos':
          setHiddenOtrosArchivos('');
          break;
        default:
          break;
      }
    }
  };

  /**
   * 
   * @param {string} selectOption - Option del select
   */
  const handleSelectDiagnostico = selectOption => {
    setSelectDiagnostico(selectOption);
    if (selectOption !== 'Otro') {
      setDiagnostico(selectOption);
    } else {
      setDiagnostico('');
    }
  };

  /**
   * Formato del folio:
   * Primera letra del nombre del paciente
   * Primer letra del apellido del paciente
   * date.getTime() dividido por '-'. Ej: 15-9084-0225-571
   */
  useEffect(() => {
    const tiempoFolio = formatoFechaFolio(date);
    const tiempoFolio2 = formatoFechaFolioEnd(date);
    const newFolio = `${tiempoFolio}-${nombrePaciente.slice(0, 1)}${apellidoPaciente.slice(0, 1)}${tiempoFolio2}`;
    setFolio(newFolio.toUpperCase());
  }, [date, nombrePaciente, apellidoPaciente]);

  /**
   * Cargar script de PayPal
   */
  useEffect(() => {
    if (payPalRef.current) {
      window.paypal
        .Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  description: estudioElegido[0],
                  amount: {
                    currency_code: 'MXN',
                    value: costoEstudio,
                  },
                },
              ],
            });
          },
          onApprove: async (data, actions) => {
            const order = await actions.order.capture();
            setPagado(true);
            console.log(order);
          },
          onError: err => {
            setErrorPayPal(err);
            console.error(err);
          },
        })
        .render(payPalRef.current);
    }
  }, [estudioElegido, costoEstudio]);

  const handleDirElegida = selectItem => {
    const itemAsNum = Number(selectItem);
    setDirElegida(itemAsNum);
    if (itemAsNum >= 0) {
      const dirFilter = listadoDirs[itemAsNum];
      setInputCalleNumero(dirFilter.calle);
      setInputCodigoPostal(dirFilter.cp);
      setInputColinia(dirFilter.colonia);
      setInputAlcaldiaMun(dirFilter.municipio);
      setInputEstado(dirFilter.estado);
      setInputNombreHospital(dirFilter.nombreHospital);
      setInputReferencias(dirFilter.referencias);
    } else {
      setInputCalleNumero('');
      setInputCodigoPostal('');
      setInputColinia('');
      setInputAlcaldiaMun('');
      setInputEstado('');
      setInputNombreHospital('');
      setInputReferencias('');
    }
  };

  /**
   * Prender el botón de enviar formulario
   */
  useEffect(() => {
    if (estudioElegido.length > 0 && folio.length > 10 && fechaNacHumano && genero && cedulaDoc
      && primarioSelect && (muestraBloque || muestraLaminillas) && (numeroMuestraBloque || numeroMuestraLaminillas) && fechaMuestra && diagnostico && labRadio
      && (dirElegida > -1 || (inputCalleNumero && inputCodigoPostal && inputColinia && inputAlcaldiaMun && inputEstado && inputNombreHospital)) && inputReferencias && horarioRecoger) {
      setFormListo(true);
    } else {
      setFormListo(false);
    }
  }, [estudioElegido, folio, fechaNacHumano, genero, cedulaDoc, primarioSelect, muestraBloque, muestraLaminillas, numeroMuestraBloque, numeroMuestraLaminillas, fechaMuestra, diagnostico, dirElegida, inputCalleNumero, inputCodigoPostal, inputColinia, inputAlcaldiaMun, inputEstado, inputNombreHospital, inputReferencias, horarioRecoger, labRadio]);

  /**
   * Envío de las firmas del doctor y el paciente
   */
  const enviarFirmas = (firma, fuente) => {
    if (fuente === 'Paciente') {
      setFirmaPaciente(firma);
    }
    if (fuente === 'Médico') {
      setFirmaMedico(firma);
    }
    window.Swal.fire({
      title: '¡Firma exitosa!',
      text: 'La firma se ha guardado correctamente.',
      icon: 'success',
      confirmButtonText: 'OK!'
    });
  };

  /**
   * Ocultar las tarjetas y el menú de la solicitud
   * Mostrar el resumen
   */
  const mostrarResumen = () => {
    document.getElementById('solicitudPasoAPaso').style.display = "none";
    document.getElementById('solicitudResumen').style.display = "flex";
  };

  /**
   * Envio de la info con los valores de todas las secciones
   */
  const envioSolicitud = () => {
    // Parte de alerta para los docs en vivo
    window.Swal.fire({
      title: '¡No se le olvide anexar el folio!',
      text: `Para la recolección de la muestra, se debe colocar el número de la solicitud y la frase FindTrack de forma visible. De otro modo, no procederá.`,
      icon: 'success',
      confirmButtonText: '¡Listo!'
    });

    setEnviadoPress(true);
    const reporteHistopatologico = inputFileHistopatologico.current.files[0];
    const otroArchivo = inputFileOtros.current.files[0];
    const direccion = {
      calle: inputCalleNumero,
      cp: inputCodigoPostal,
      colonia: inputColinia,
      municipio: inputAlcaldiaMun,
      estado: inputEstado,
      nombreHospital: inputNombreHospital,
      referencias: inputReferencias
    };
    const empresaPharma = props.location.state && props.location.state.empresaPharma ? props.location.state.empresaPharma : '';

    /** Envío al API para generar el PDF y enviar el correo con el mismo */
    handleEmailSolicitud(folio, nombrePaciente, apellidoPaciente, nacDia, nacMes, nacAnio, genero,
      categoriaElegida, estudioElegido, metodologiaEstudio, claveEstudio,
      nomDoc, cedula, emailElegido, telDoc,
      primarioSelect, metastasicoSelect, muestraBloque, numeroMuestraBloque, muestraLaminillas, numeroMuestraLaminillas, muestraMes, muestraAnio, diagnostico, tratamientosPrevios, labRadio,
      inputNombreHospital, inputCalleNumero, inputCodigoPostal, inputColinia, inputAlcaldiaMun, inputEstado, inputReferencias, horarioRecoger,
      costoEstudio, firmaPaciente, firmaMedico);

    if (inputAnyFile.current.files.length > 0) {
      const etapa = pago ? 'Programar Colecta' : 'Pago Pendiente';
      const archivoASubir = inputAnyFile.current.files[0];
      const solicitudCompletada = {
        destino: doctorRef,
        folio,
        cedulaDoc,
        emailDoc: emailElegido,
        nomDoc,
        categoriaElegida,
        metodologiaEstudio,
        claveEstudio,
        nombrePaciente,
        ecog,
        estudio: estudioElegido[0],
        apellidoPaciente,
        fechaNac: fechaNacHumano,
        genero,
        email: emailPaciente,
        telefono: telDoc,
        telPaciente,
        celular,
        file: archivoASubir.name,
        reporteHistopatologico: hiddenHistopatologico,
        otroArchivo: hiddenOtrosArchivos,
        pago,
        fecha_solcitada: fechaBDD,
        etapa,
        firmaPaciente,
        firmaMedico,
        denominacionSocial: fisicaMoral,
        RFC,
        costo: costoEstudio,
        emailFactura,
        primarioSelect,
        metastasicoSelect,
        muestraBloque,
        numeroMuestraBloque,
        muestraLaminillas,
        numeroMuestraLaminillas,
        fechaMuestra,
        diagnostico,
        tratamientosPrevios,
        direccion,
        empresaPharma,
        horarioRecoger,
        laboratorioSeleccionado: labRadio
      };

      console.log(' El estudio se envió CON ARCHIVO');
      subirEstudio(doctorRef, solicitudCompletada, folio, archivoASubir, reporteHistopatologico, otroArchivo);
    } else if ((firmaPaciente || skipFirmaPaciente) && firmaMedico) {
      const etapa = pago ? 'Programar Colecta' : 'Pago Pendiente';
      const solicitudCompletada = {
        destino: doctorRef,
        folio,
        cedulaDoc,
        emailDoc: emailElegido,
        nomDoc,
        nombrePaciente,
        ecog,
        categoriaElegida,
        metodologiaEstudio,
        claveEstudio,
        estudio: estudioElegido[0],
        apellidoPaciente,
        fechaNac: fechaNacHumano,
        genero,
        email: emailPaciente,
        telefono: telDoc,
        telPaciente,
        celular,
        reporteHistopatologico: hiddenHistopatologico,
        otroArchivo: hiddenOtrosArchivos,
        pago,
        fecha_solcitada: fechaBDD,
        etapa,
        firmaPaciente,
        firmaMedico,
        denominacionSocial: fisicaMoral,
        RFC,
        costo: costoEstudio,
        emailFactura,
        primarioSelect,
        metastasicoSelect,
        muestraBloque,
        numeroMuestraBloque,
        muestraLaminillas,
        numeroMuestraLaminillas,
        fechaMuestra,
        diagnostico,
        tratamientosPrevios,
        direccion,
        empresaPharma,
        horarioRecoger,
        laboratorioSeleccionado: labRadio
      };

      console.log(' El estudio se envió CON FIRMAS');
      subirEstudio(doctorRef, solicitudCompletada, folio, null, reporteHistopatologico, otroArchivo);
    } else {
      const etapa = 'AP_CI Pendiente';
      const solicitudCompletada = {
        destino: doctorRef,
        folio,
        cedulaDoc,
        emailDoc: emailElegido,
        nomDoc,
        nombrePaciente,
        ecog,
        categoriaElegida,
        metodologiaEstudio,
        claveEstudio,
        estudio: estudioElegido[0],
        apellidoPaciente,
        fechaNac: fechaNacHumano,
        genero,
        email: emailPaciente,
        telefono: telDoc,
        telPaciente,
        reporteHistopatologico: hiddenHistopatologico,
        otroArchivo: hiddenOtrosArchivos,
        celular,
        file: null,
        pago,
        fecha_solcitada: fechaBDD,
        etapa,
        firmaPaciente,
        firmaMedico,
        denominacionSocial: fisicaMoral,
        RFC,
        costo: costoEstudio,
        emailFactura,
        primarioSelect,
        metastasicoSelect,
        muestraBloque,
        numeroMuestraBloque,
        muestraLaminillas,
        numeroMuestraLaminillas,
        fechaMuestra,
        diagnostico,
        tratamientosPrevios,
        direccion,
        empresaPharma,
        horarioRecoger,
        laboratorioSeleccionado: labRadio
      };

      console.log(' El estudio se envió SIN ARCHIVO');
      subirEstudio(doctorRef, solicitudCompletada, folio, null, reporteHistopatologico, otroArchivo);
    }
    if (dirElegida < 0 && listadoDirs.length === 0) {
      updateDireccion(doctorRef, direccion, 0);
    } else if (dirElegida < 0 && listadoDirs.length > 0) {
      updateDireccion(doctorRef, direccion, listadoDirs.length);
    } else {
      updateDireccion(doctorRef, direccion, dirElegida);
    }
  };

  // Pruebas:
  // isAdmin,
  // De programa o cotización --> props.location.state && props.location.state.saltarElegirEspecialidad,
  // props.location.state && props.location.state.empresaPharma === 'Bayer'
  const generarButtonAtras = origen => {
    let tituloGeneralCambio = '';
    switch (origen) {
      // info paciente es la 3ra
      case 'infoPaciente':
        if (props.location.state) {
          // 1 sí, 2 sí, 3 esta [2], 4 sí, 5 sí, 6 no -- listo
          if (props.location.state.empresaPharma && isAdmin) {
            tituloGeneralCambio = 'Detalles del programa';
            return (
              <ButtonNavSolicitud
                navegacion="Atrás" destino={1} tituloGeneral={tituloGeneralCambio}
                cambioApartadoSolicitud={cambioApartadoSolicitud} seccionesRef={seccionesRef} setTituloGeneral={setTituloGeneral}
              />
            );
          }
          // 1 no, 2 sí, 3 esta [1], 4 sí, 5 sí, 6 no -- listo - empresa pharma
          // 1 sí, 2 no, 3 esta [1], 4 sí, 5 sí, 6 sí -- listo - Buscar Médico
          else if (props.location.state.empresaPharma || (props.location.state.saltarElegirEspecialidad && isAdmin)) {
            tituloGeneralCambio = props.location.state.empresaPharma ? 'Detalles del programa' : 'Buscar Médico';
            return (
              <ButtonNavSolicitud
                navegacion="Atrás" destino={0} tituloGeneral={tituloGeneralCambio}
                cambioApartadoSolicitud={cambioApartadoSolicitud} seccionesRef={seccionesRef} setTituloGeneral={setTituloGeneral}
              />
            );
          }
        } else {
          tituloGeneralCambio = 'Estudio a solicitar';
          // 1 sí, 2 sí, 3 esta [2], 4 sí, 5 sí, 6 sí -- listo
          if (isAdmin) {
            return (
              <ButtonNavSolicitud
                navegacion="Atrás" destino={1} tituloGeneral={tituloGeneralCambio}
                cambioApartadoSolicitud={cambioApartadoSolicitud} seccionesRef={seccionesRef} setTituloGeneral={setTituloGeneral}
              />
            );
          }
          // 1 no, 2 sí, 3 esta [1], 4 sí, 5 sí, 6 sí -- listo
          else {
            return (
              <ButtonNavSolicitud
                navegacion="Atrás" destino={0} tituloGeneral={tituloGeneralCambio}
                cambioApartadoSolicitud={cambioApartadoSolicitud} seccionesRef={seccionesRef} setTituloGeneral={setTituloGeneral}
              />
            );
          }
        }
        break;

      case 'infoMuestra':
        tituloGeneralCambio = 'Información del paciente';
        if (props.location.state) {
          // 1 sí, 2 sí, 3 sí, 4 esta [3], 5 sí, 6 sí, 7 no -- listo
          if (props.location.state.empresaPharma && isAdmin) {
            return (
              <ButtonNavSolicitud
                navegacion="Atrás" destino={2} tituloGeneral={tituloGeneralCambio}
                cambioApartadoSolicitud={cambioApartadoSolicitud} seccionesRef={seccionesRef} setTituloGeneral={setTituloGeneral}
              />
            );
          }
          // 1 no, 2 sí, 3 sí, 4 esta [2], 5 sí, 6 sí, 7 no -- listo
          // 1 sí, 2 no, 3 sí, 4 esta [2], 5 sí, 6 sí, 7 sí -- listo
          else if ((props.location.state.saltarElegirEspecialidad && isAdmin) || (props.location.state.empresaPharma && !isAdmin)) {
            return (
              <ButtonNavSolicitud
                navegacion="Atrás" destino={1} tituloGeneral={tituloGeneralCambio}
                cambioApartadoSolicitud={cambioApartadoSolicitud} seccionesRef={seccionesRef} setTituloGeneral={setTituloGeneral}
              />
            );
          }
          // 1 no, 2 no, 3 sí, 4 esta [1], 5 sí, 6 sí, 7 sí -- listo
          else if (props.location.state.saltarElegirEspecialidad && !isAdmin) {
            return (
              <ButtonNavSolicitud
                navegacion="Atrás" destino={0} tituloGeneral={tituloGeneralCambio}
                cambioApartadoSolicitud={cambioApartadoSolicitud} seccionesRef={seccionesRef} setTituloGeneral={setTituloGeneral}
              />
            );
          }
        } else {
          // 1 sí, 2 sí, 3 sí, 4 esta [3], 5 sí, 6 sí, 7 sí -- listo
          if (isAdmin) {
            return (
              <ButtonNavSolicitud
                navegacion="Atrás" destino={2} tituloGeneral={tituloGeneralCambio}
                cambioApartadoSolicitud={cambioApartadoSolicitud} seccionesRef={seccionesRef} setTituloGeneral={setTituloGeneral}
              />
            );
          }
          // 1 no, 2 sí, 3 sí, 4 esta [2], 5 sí, 6 sí, 7 sí -- listo
          else {
            return (
              <ButtonNavSolicitud
                navegacion="Atrás" destino={1} tituloGeneral={tituloGeneralCambio}
                cambioApartadoSolicitud={cambioApartadoSolicitud} seccionesRef={seccionesRef} setTituloGeneral={setTituloGeneral}
              />
            );
          }
        }
        break;

      case 'dirColecta':
        tituloGeneralCambio = 'Información sobre la muestra';
        if (props.location.state) {
          // 1 sí, 2 sí, 3 sí, 4 sí, 5 esta [4], 6 sí, 7 no -- listo
          if (props.location.state.empresaPharma && isAdmin) {
            return (
              <ButtonNavSolicitud
                navegacion="Atrás" destino={3} tituloGeneral={tituloGeneralCambio}
                cambioApartadoSolicitud={cambioApartadoSolicitud} seccionesRef={seccionesRef} setTituloGeneral={setTituloGeneral}
              />
            );
          }
          // 1 no, 2 sí, 3 sí, 4 sí, 5 esta [3], 6 sí, 7 no -- listo
          // 1 sí, 2 no, 3 sí, 4 sí, 5 esta [3], 6 sí, 7 sí -- listo
          else if ((props.location.state.saltarElegirEspecialidad && isAdmin) || (props.location.state.empresaPharma && !isAdmin)) {
            return (
              <ButtonNavSolicitud
                navegacion="Atrás" destino={2} tituloGeneral={tituloGeneralCambio}
                cambioApartadoSolicitud={cambioApartadoSolicitud} seccionesRef={seccionesRef} setTituloGeneral={setTituloGeneral}
              />
            );
          }
          // 1 no, 2 no, 3 sí, 4 sí, 5 esta [2], 6 sí -- listo
          else if (props.location.state.saltarElegirEspecialidad && !isAdmin) {
            return (
              <ButtonNavSolicitud
                navegacion="Atrás" destino={1} tituloGeneral={tituloGeneralCambio}
                cambioApartadoSolicitud={cambioApartadoSolicitud} seccionesRef={seccionesRef} setTituloGeneral={setTituloGeneral}
              />
            );
          }
        } else {
          // 1 sí, 2 sí, 3 sí, 4 sí, 5 esta [4], 6 sí, 7 sí -- listo
          if (isAdmin) {
            return (
              <ButtonNavSolicitud
                navegacion="Atrás" destino={3} tituloGeneral={tituloGeneralCambio}
                cambioApartadoSolicitud={cambioApartadoSolicitud} seccionesRef={seccionesRef} setTituloGeneral={setTituloGeneral}
              />
            );
          }
          // 1 no, 2 sí, 3 sí, 4 sí, 5 esta [3], 6 sí, 7 sí -- listo
          else {
            return (
              <ButtonNavSolicitud
                navegacion="Atrás" destino={2} tituloGeneral={tituloGeneralCambio}
                cambioApartadoSolicitud={cambioApartadoSolicitud} seccionesRef={seccionesRef} setTituloGeneral={setTituloGeneral}
              />
            );
          }
        }

        break;

      case 'APCI':
        tituloGeneralCambio = 'Información de colecta y envío de la muestra';
        if (props.location.state) {
          // 1 sí, 2 sí, 3 sí, 4 sí, 5 sí, 6 [5], 7 no -- listo
          if (props.location.state.empresaPharma && isAdmin) {
            return (
              <ButtonNavSolicitud
                navegacion="Atrás" destino={4} tituloGeneral={tituloGeneralCambio}
                cambioApartadoSolicitud={cambioApartadoSolicitud} seccionesRef={seccionesRef} setTituloGeneral={setTituloGeneral}
              />
            );
          }
          // 1 sí, 2 no, 3 sí, 4 sí, 5 sí, 6 [4], 7 sí -- listo
          // 1 sí, 2 no, 3 sí, 4 sí, 5 sí, 6 [4], 7 no -- listo
          if ((props.location.state.saltarElegirEspecialidad && isAdmin) || (props.location.state.empresaPharma && !isAdmin)) {
            return (
              <ButtonNavSolicitud
                navegacion="Atrás" destino={3} tituloGeneral={tituloGeneralCambio}
                cambioApartadoSolicitud={cambioApartadoSolicitud} seccionesRef={seccionesRef} setTituloGeneral={setTituloGeneral}
              />
            );
          }
          // 1 no, 2 no, 3 sí, 4 sí, 5 sí, 6 [3], 7 sí -- listo
          // 1 no, 2 no, 3 sí, 4 sí, 5 sí, 6 [3], 7 no -- listo
          else if (props.location.state.saltarElegirEspecialidad && !isAdmin) {
            return (
              <ButtonNavSolicitud
                navegacion="Atrás" destino={2} tituloGeneral={tituloGeneralCambio}
                cambioApartadoSolicitud={cambioApartadoSolicitud} seccionesRef={seccionesRef} setTituloGeneral={setTituloGeneral}
              />
            );
          }
        } else {
          // 1 sí, 2 sí, 3 sí, 4 sí, 5 sí, 6 [5], 7 sí -- listo
          if (isAdmin) {
            return (
              <ButtonNavSolicitud
                navegacion="Atrás" destino={4} tituloGeneral={tituloGeneralCambio}
                cambioApartadoSolicitud={cambioApartadoSolicitud} seccionesRef={seccionesRef} setTituloGeneral={setTituloGeneral}
              />
            );
          }
          // 1 no, 2 sí, 3 sí, 4 sí, 5 sí, 6 [4], 7 sí -- listo
          else {
            return (
              <ButtonNavSolicitud
                navegacion="Atrás" destino={3} tituloGeneral={tituloGeneralCambio}
                cambioApartadoSolicitud={cambioApartadoSolicitud} seccionesRef={seccionesRef} setTituloGeneral={setTituloGeneral}
              />
            );
          }
        }
        break;

      default:
        break;
    }
  };
  const generarButtonSiguiente = origen => {
    let tituloGeneralCambio = '';
    switch (origen) {
      // info paciente es la 3ra
      case 'infoPaciente':
        tituloGeneralCambio = 'Información sobre la muestra';
        if (nombrePaciente && apellidoPaciente && fechaNacHumano && genero && ecog) {
          if (props.location.state) {
            // 1 sí, 2 sí, 3 esta [2], 4 sí, 5 sí, 6 no -- listo
            if (props.location.state.empresaPharma && isAdmin) {
              return (
                <ButtonNavSolicitud
                  navegacion="Siguiente" destino={3} tituloGeneral={tituloGeneralCambio}
                  cambioApartadoSolicitud={cambioApartadoSolicitud} seccionesRef={seccionesRef} setTituloGeneral={setTituloGeneral}
                />
              );
            }
            // 1 no, 2 sí, 3 esta [1], 4 sí, 5 sí, 6 no -- listo
            // 1 sí, 2 no, 3 esta [1], 4 sí, 5 sí, 6 sí -- listo
            else if ((props.location.state.saltarElegirEspecialidad && isAdmin) || (props.location.state.empresaPharma && !isAdmin)) {
              return (
                <ButtonNavSolicitud
                  navegacion="Siguiente" destino={2} tituloGeneral={tituloGeneralCambio}
                  cambioApartadoSolicitud={cambioApartadoSolicitud} seccionesRef={seccionesRef} setTituloGeneral={setTituloGeneral}
                />
              );
            }
            // 1 no, 2 no, 3 esta [0], 4 sí, 5 sí -- listo
            else if (props.location.state.saltarElegirEspecialidad && !isAdmin) {
              return <ButtonNavSolicitud
                navegacion="Siguiente" destino={1} tituloGeneral={tituloGeneralCambio}
                cambioApartadoSolicitud={cambioApartadoSolicitud} seccionesRef={seccionesRef} setTituloGeneral={setTituloGeneral}
              />
            }
          } else {
            if (isAdmin) {
              return (
                <ButtonNavSolicitud
                  navegacion="Siguiente" destino={3} tituloGeneral={tituloGeneralCambio}
                  cambioApartadoSolicitud={cambioApartadoSolicitud} seccionesRef={seccionesRef} setTituloGeneral={setTituloGeneral}
                />
              );
            }
            // 1 no, 2 sí, 3 esta, 4 sí, 5 sí, 6 sí -- listo
            else {
              return (
                <ButtonNavSolicitud
                  navegacion="Siguiente" destino={2} tituloGeneral={tituloGeneralCambio}
                  cambioApartadoSolicitud={cambioApartadoSolicitud} seccionesRef={seccionesRef} setTituloGeneral={setTituloGeneral}
                />
              );
            }
          }
        }
        break;

      case 'infoMuestra':
        tituloGeneralCambio = 'Información de colecta y envío de la muestra';
        if (primarioSelect && (muestraBloque || muestraLaminillas) && fechaMuestra && diagnostico && (numeroMuestraBloque || numeroMuestraLaminillas) && labRadio) {
          if (props.location.state) {
            // 1 sí, 2 sí, 3 sí, 4 esta [3], 5 sí, 6 sí, 7 no -- listo
            if (props.location.state.empresaPharma && isAdmin) {
              return (
                <ButtonNavSolicitud
                  navegacion="Siguiente" destino={4} tituloGeneral={tituloGeneralCambio}
                  cambioApartadoSolicitud={cambioApartadoSolicitud} seccionesRef={seccionesRef} setTituloGeneral={setTituloGeneral}
                />
              );
            }
            // 1 no, 2 sí, 3 sí, 4 esta [2], 5 sí, 6 sí, 7 no -- listo
            // 1 sí, 2 no, 3 sí, 4 esta [2], 5 sí, 6 sí, 7 sí -- listo
            else if ((props.location.state.saltarElegirEspecialidad && isAdmin) || (props.location.state.empresaPharma && !isAdmin)) {
              return (
                <ButtonNavSolicitud
                  navegacion="Siguiente" destino={3} tituloGeneral={tituloGeneralCambio}
                  cambioApartadoSolicitud={cambioApartadoSolicitud} seccionesRef={seccionesRef} setTituloGeneral={setTituloGeneral}
                />
              );
            }
            // 1 no, 2 no, 3 sí, 4 esta [1], 5 sí, 6 sí, 7 sí -- listo
            else if (props.location.state.saltarElegirEspecialidad && !isAdmin) {
              return (
                <ButtonNavSolicitud
                  navegacion="Siguiente" destino={2} tituloGeneral={tituloGeneralCambio}
                  cambioApartadoSolicitud={cambioApartadoSolicitud} seccionesRef={seccionesRef} setTituloGeneral={setTituloGeneral}
                />
              );
            }
          } else {
            // 1 sí, 2 sí, 3 sí, 4 esta [3], 5 sí, 6 sí, 7 sí -- listo
            if (isAdmin) {
              return (
                <ButtonNavSolicitud
                  navegacion="Siguiente" destino={4} tituloGeneral={tituloGeneralCambio}
                  cambioApartadoSolicitud={cambioApartadoSolicitud} seccionesRef={seccionesRef} setTituloGeneral={setTituloGeneral}
                />
              );
            }
            // 1 no, 2 sí, 3 sí, 4 esta [2], 5 sí, 6 sí, 7 sí -- listo
            else {
              return (
                <ButtonNavSolicitud
                  navegacion="Siguiente" destino={3} tituloGeneral={tituloGeneralCambio}
                  cambioApartadoSolicitud={cambioApartadoSolicitud} seccionesRef={seccionesRef} setTituloGeneral={setTituloGeneral}
                />
              );
            }
          }
        }
        break;

      case 'dirColecta':
        tituloGeneralCambio = 'Aviso de Privacidad y Consentimiento Informado';
        if ((dirElegida > -1 || (inputCalleNumero && inputCodigoPostal && inputColinia && inputAlcaldiaMun && inputEstado && inputNombreHospital)) && inputReferencias && horarioRecoger) {
          if (props.location.state) {
            // 1 sí, 2 sí, 3 sí, 4 sí, 5 esta [4], 6 sí, 7 no -- listo
            if (props.location.state.empresaPharma && isAdmin) {
              return (
                <ButtonNavSolicitud
                  navegacion="Siguiente" destino={5} tituloGeneral={tituloGeneralCambio}
                  cambioApartadoSolicitud={cambioApartadoSolicitud} seccionesRef={seccionesRef} setTituloGeneral={setTituloGeneral}
                />
              );
            }
            // 1 no, 2 sí, 3 sí, 4 sí, 5 esta [3], 6 sí, 7 no -- listo
            // 1 sí, 2 no, 3 sí, 4 sí, 5 esta [3], 6 sí, 7 sí -- listo
            else if ((props.location.state.saltarElegirEspecialidad && isAdmin) || (props.location.state.empresaPharma && !isAdmin)) {
              return (
                <ButtonNavSolicitud
                  navegacion="Siguiente" destino={4} tituloGeneral={tituloGeneralCambio}
                  cambioApartadoSolicitud={cambioApartadoSolicitud} seccionesRef={seccionesRef} setTituloGeneral={setTituloGeneral}
                />
              );
            }
            // 1 no, 2 no, 3 sí, 4 sí, 5 esta [2], 6 sí -- listo
            else if (props.location.state.saltarElegirEspecialidad && !isAdmin) {
              return (
                <ButtonNavSolicitud
                  navegacion="Siguiente" destino={3} tituloGeneral={tituloGeneralCambio}
                  cambioApartadoSolicitud={cambioApartadoSolicitud} seccionesRef={seccionesRef} setTituloGeneral={setTituloGeneral}
                />
              );
            }
          } else {
            // 1 sí, 2 sí, 3 sí, 4 sí, 5 esta [4], 6 sí, 7 sí -- listo
            if (isAdmin) {
              return (
                <ButtonNavSolicitud
                  navegacion="Siguiente" destino={5} tituloGeneral={tituloGeneralCambio}
                  cambioApartadoSolicitud={cambioApartadoSolicitud} seccionesRef={seccionesRef} setTituloGeneral={setTituloGeneral}
                />
              );
            }
            // 1 no, 2 sí, 3 sí, 4 sí, 5 esta [3], 6 sí, 7 sí -- listo
            else {
              return (
                <ButtonNavSolicitud
                  navegacion="Siguiente" destino={4} tituloGeneral={tituloGeneralCambio}
                  cambioApartadoSolicitud={cambioApartadoSolicitud} seccionesRef={seccionesRef} setTituloGeneral={setTituloGeneral}
                />
              );
            }
          }
        }

        break;

      case 'APCI':
        tituloGeneralCambio = 'Información del pago';
        if (props.location.state) {
          // 1 sí, 2 no, 3 sí, 4 sí, 5 sí, 6 [4], 7 sí -- listo
          if (props.location.state.saltarElegirEspecialidad && !props.location.state.empresaPharma && isAdmin) {
            return (
              <ButtonNavSolicitud
                navegacion="Siguiente" destino={5} tituloGeneral={tituloGeneralCambio}
                cambioApartadoSolicitud={cambioApartadoSolicitud} seccionesRef={seccionesRef} setTituloGeneral={setTituloGeneral}
              />
            );
          }
          // 1 no, 2 no, 3 sí, 4 sí, 5 sí, 6 [3], 7 sí -- listo
          else if (props.location.state.saltarElegirEspecialidad && !props.location.state.empresaPharma && !isAdmin) {
            return (
              <ButtonNavSolicitud
                navegacion="Siguiente" destino={4} tituloGeneral={tituloGeneralCambio}
                cambioApartadoSolicitud={cambioApartadoSolicitud} seccionesRef={seccionesRef} setTituloGeneral={setTituloGeneral}
              />
            );
          }
          // 1 sí, 2 no, 3 sí, 4 sí, 5 sí, 6, 7 no -- listo
          // 1 no, 2 no, 3 sí, 4 sí, 5 sí, 6, 7 no -- listo
          else {
            return (
              <button
                className="btn btn-primary btn-md mx-2 btn-avance-retroceso" type="button"
                onClick={mostrarResumen}
              >
                Siguiente <i className="fas fa-arrow-circle-right"></i>
              </button>
            );
          }
        } else {
          // 1 sí, 2 sí, 3 sí, 4 sí, 5 sí, 6 [5], 7 sí -- listo
          if (isAdmin) {
            return (
              <ButtonNavSolicitud
                navegacion="Siguiente" destino={6} tituloGeneral={tituloGeneralCambio}
                cambioApartadoSolicitud={cambioApartadoSolicitud} seccionesRef={seccionesRef} setTituloGeneral={setTituloGeneral}
              />
            );
          }
          // 1 no, 2 sí, 3 sí, 4 sí, 5 sí, 6 [4], 7 sí -- listo
          else if (!isAdmin) {
            return (
              <ButtonNavSolicitud
                navegacion="Siguiente" destino={5} tituloGeneral={tituloGeneralCambio}
                cambioApartadoSolicitud={cambioApartadoSolicitud} seccionesRef={seccionesRef} setTituloGeneral={setTituloGeneral}
              />
            );
          }
          // 1 sí, 2 sí, 3 sí, 4 sí, 5 sí, 6, 7 no -- listo
          // 1 no, 2 sí, 3 sí, 4 sí, 5 sí, 6, 7 no -- listo
          else {
            return (
              <button
                className="btn btn-primary btn-md mx-2 btn-avance-retroceso" type="button"
                onClick={mostrarResumen}
              >
                Siguiente <i className="fas fa-arrow-circle-right"></i>
              </button>
            );
          }
        }
        break;

      default:
        break;
    }
  };

  return (
    <RestContainer>
      <div id="Seleccion" className="col-sm-12 col-md-12 col-lg-8">
        <div className="row">
          <div className="col-6">
            <h3>Solicitar Estudio</h3>
          </div>
        </div>

        {/* Menu Superior de navegación */}
        <MenuEstudio
          cambioApartadoSolicitud={cambioApartadoSolicitud}
          secciones={seccionesRef.current}
          btnCrumbRef={btnCrumbRef}
        />
        <div className="card mt-3" id="solicitudPasoAPaso">
          <div className="card-header">
            <h4 id="tituloGeneral">{tituloGeneral}</h4>
          </div>
          <div className="card-body">
            <ul className="list-group">

              {/* BUSCAR MÉDICO */}
              {
                isAdmin && (
                  <form id="Médico" className="scale-up-center apartadoSolcitud"
                    ref={ref => {
                      if (ref && !seccionesRef.current.includes(ref)) {
                        seccionesRef.current.push(ref);
                      }
                    }}
                  >
                    <BusquedaMedico
                      setCedula={setCedula}
                      cedulaDoc={cedulaDoc}
                      emailDoc={emailDoc}
                      emailDoc2={emailDoc2}
                      emailElegido={emailElegido}
                      setEmailElegido={setEmailElegido}
                    >
                      {
                        cedulaDoc && emailElegido && !props.location.state ?
                          <div className="col-12 mb-3 text-center">
                            <ButtonNavSolicitud
                              navegacion="Siguiente" destino={1} tituloGeneral='Estudio a solicitar'
                              cambioApartadoSolicitud={cambioApartadoSolicitud} seccionesRef={seccionesRef} setTituloGeneral={setTituloGeneral}
                            />
                          </div>
                          : null
                      }
                      {/* Pharma */}
                      {
                        cedulaDoc && emailElegido && props.location.state && props.location.state.empresaPharma ?
                          <div className="col-12 mb-3 text-center">
                            <ButtonNavSolicitud
                              navegacion="Siguiente" destino={1} tituloGeneral='Detalles del programa'
                              cambioApartadoSolicitud={cambioApartadoSolicitud} seccionesRef={seccionesRef} setTituloGeneral={setTituloGeneral}
                            />
                          </div>
                          : null
                      }
                      {/* Programa */}
                      {
                        cedulaDoc && emailElegido && props.location.state && !props.location.state.empresaPharma ?
                          <div className="col-12 mb-3 text-center">
                            <ButtonNavSolicitud
                              navegacion="Siguiente" destino={1} tituloGeneral='Información del paciente'
                              cambioApartadoSolicitud={cambioApartadoSolicitud} seccionesRef={seccionesRef} setTituloGeneral={setTituloGeneral}
                            />
                          </div>
                          : null
                      }
                    </BusquedaMedico>
                  </form>
                )
              }


              {/* ELEGIR LA ESPECIALIDAD */}
              {
                props.location.state && props.location.state.empresaPharma && (
                  <div id="Estudio" className="col-12 my-3 scale-up-center apartadoSolcitud"
                    ref={ref => {
                      if (ref && !seccionesRef.current.includes(ref)) {
                        seccionesRef.current.push(ref);
                      }
                    }}
                  >
                    <BannerPharma
                      state={props.location.state}
                      seccionesRef={seccionesRef}
                      isAdmin={isAdmin}
                      metodologiaEstudio={metodologiaEstudio}
                      claveEstudio={claveEstudio}
                      cambioApartadoSolicitud={cambioApartadoSolicitud}
                      setTituloGeneral={setTituloGeneral}
                    />
                  </div>
                )
              }
              {
                !props.location.state && (
                  <form id="Estudio" className="col-12 my-3 scale-up-center apartadoSolcitud"
                    ref={ref => {
                      if (ref && !seccionesRef.current.includes(ref)) {
                        seccionesRef.current.push(ref);
                      }
                    }}
                  >
                    <ElegirEspecialidad
                      state={props.location.state}
                      metodologiaEstudio={metodologiaEstudio}
                      claveEstudio={claveEstudio}
                      detectarCategoriaElegida={detectarCategoriaElegida}
                      intereses={intereses}
                      setMetoClavePrecio={setMetoClavePrecio}
                      categoriaElegida={categoriaElegida}
                      filtroEstudioCategoria={filtroEstudioCategoria}
                      costoEstudio={costoEstudio}
                      estudioSolNombre={estudioSolNombre}
                    >
                      <div className="col-12 mb-3 text-center">
                        {
                          isAdmin && metodologiaEstudio && claveEstudio ?
                            <>
                              <ButtonNavSolicitud
                                navegacion="Atrás" destino={0} tituloGeneral='Buscar Médico'
                                cambioApartadoSolicitud={cambioApartadoSolicitud} seccionesRef={seccionesRef} setTituloGeneral={setTituloGeneral}
                              />
                              <ButtonNavSolicitud
                                navegacion="Siguiente" destino={2} tituloGeneral='Información del paciente'
                                cambioApartadoSolicitud={cambioApartadoSolicitud} seccionesRef={seccionesRef} setTituloGeneral={setTituloGeneral}
                              />
                            </>
                            :
                            null
                        }
                        {
                          isAdmin && !(metodologiaEstudio && claveEstudio) ?
                            <ButtonNavSolicitud
                              navegacion="Atrás" destino={0} tituloGeneral='Buscar Médico'
                              cambioApartadoSolicitud={cambioApartadoSolicitud} seccionesRef={seccionesRef} setTituloGeneral={setTituloGeneral}
                            />
                            :
                            null
                        }
                        {
                          !isAdmin && metodologiaEstudio && claveEstudio ?
                            <ButtonNavSolicitud
                              navegacion="Siguiente" destino={1} tituloGeneral='Información del paciente'
                              cambioApartadoSolicitud={cambioApartadoSolicitud} seccionesRef={seccionesRef} setTituloGeneral={setTituloGeneral}
                            />
                            : null
                        }
                      </div>
                    </ElegirEspecialidad>
                  </form>
                )
              }


              {/* INGRESAR DATOS DEL PACIENTE */}
              <form id="Paciente" className="my-3 scale-up-center apartadoSolcitud"
                ref={ref => {
                  if (ref && !seccionesRef.current.includes(ref)) {
                    seccionesRef.current.push(ref);
                  }
                }}
              >
                <FormPaciente
                  folio={folio}
                  setNombrePaciente={setNombrePaciente}
                  nombrePaciente={nombrePaciente}
                  setApellidoPaciente={setApellidoPaciente}
                  apellidoPaciente={apellidoPaciente}
                  fechaNacHumano={fechaNacHumano}
                  handleDateSafari={handleDateSafari}
                  nacDia={nacDia}
                  nacMes={nacMes}
                  nacAnio={nacAnio}
                  currentYear={currentYear}
                  genero={genero}
                  setGenero={setGenero}
                  setBothEmails={setBothEmails}
                  emailPaciente={emailPaciente}
                  ecog={ecog}
                  setEcog={setEcog}
                  telPaciente={telPaciente}
                  setTelPaciente={setTelPaciente}
                  celular={celular}
                  setCelular={setCelular}
                >
                  <div className="col-12 mb-3 text-center">
                    {generarButtonAtras('infoPaciente')}
                    {generarButtonSiguiente('infoPaciente')}
                  </div>
                </FormPaciente>
              </form>


              {/* INFO DE LA MUESTRA */}
              <form id="Muestra" className="scale-up-center apartadoSolcitud"
                ref={ref => {
                  if (ref && !seccionesRef.current.includes(ref)) {
                    seccionesRef.current.push(ref);
                  }
                }}
              >
                <InfoDeMuestra
                  primarioSelect={primarioSelect}
                  setPrimarioSelect={setPrimarioSelect}
                  metastasicoSelect={metastasicoSelect}
                  setMetastasicoSelect={setMetastasicoSelect}
                  muestraBloque={muestraBloque}
                  setMuestraBloque={setMuestraBloque}
                  muestraLaminillas={muestraLaminillas}
                  setMuestraLaminillas={setMuestraLaminillas}
                  numeroMuestraBloque={numeroMuestraBloque}
                  setNumeroMuestraBloque={setNumeroMuestraBloque}
                  numeroMuestraLaminillas={numeroMuestraLaminillas}
                  setNumeroMuestraLaminillas={setNumeroMuestraLaminillas}
                  fechaMuestra={fechaMuestra}
                  handleDateSafari={handleDateSafari}
                  muestraMes={muestraMes}
                  muestraAnio={muestraAnio}
                  currentYear={currentYear}
                  selectDiagnostico={selectDiagnostico}
                  handleSelectDiagnostico={handleSelectDiagnostico}
                  setDiagnostico={setDiagnostico}
                  diagnostico={diagnostico}
                  tratamientosPrevios={tratamientosPrevios}
                  setTratamientosPrevios={setTratamientosPrevios}
                  handleInputFileChanged={handleInputFileChanged}
                  inputFileHistopatologico={inputFileHistopatologico}
                  inputFileOtros={inputFileOtros}
                  laboratorios={laboratorios}
                  labRadio={labRadio}
                  setLabRadio={setLabRadio}
                  idTodo="Uno"
                  listadoDiagnosticos={listadoDiagnosticos}
                >
                  <div className="col-12 my-3 text-center">
                    {generarButtonAtras('infoMuestra')}
                    {generarButtonSiguiente('infoMuestra')}
                  </div>
                </InfoDeMuestra>
              </form>

              {/* INFO DE LA COLECTA */}
              <form id="Colecta" className="scale-up-center apartadoSolcitud"
                ref={ref => {
                  if (ref && !seccionesRef.current.includes(ref)) {
                    seccionesRef.current.push(ref);
                  }
                }}
              >
                <InfoColecta
                  handleDirElegida={handleDirElegida}
                  listadoDirs={listadoDirs}
                  dirElegida={dirElegida}
                  setInputCalleNumero={setInputCalleNumero}
                  inputCalleNumero={inputCalleNumero}
                  setInputCodigoPostal={setInputCodigoPostal}
                  inputCodigoPostal={inputCodigoPostal}
                  setInputColinia={setInputColinia}
                  inputColinia={inputColinia}
                  setInputAlcaldiaMun={setInputAlcaldiaMun}
                  inputAlcaldiaMun={inputAlcaldiaMun}
                  setInputEstado={setInputEstado}
                  inputEstado={inputEstado}
                  setInputNombreHospital={setInputNombreHospital}
                  inputNombreHospital={inputNombreHospital}
                  setInputReferencias={setInputReferencias}
                  inputReferencias={inputReferencias}
                  referenciasPlaceHolder={referenciasPlaceHolder}
                  horarioRecoger={horarioRecoger}
                  setHorarioRecoger={setHorarioRecoger}
                  generarButtonAtras={generarButtonAtras}
                  generarButtonSiguiente={generarButtonSiguiente}
                />
                <div className="col-12 mb-3 text-center">
                  {generarButtonAtras('dirColecta')}
                  {generarButtonSiguiente('dirColecta')}
                </div>
              </form>

              {/* SUBIR O FIRMAR EL ACUERDO DE CONFIDENCIALIDAD Y EL AVISO DE PRIVACIDAD */}
              <form id="AP/CI" className="my-3 scale-up-center apartadoSolcitud"
                ref={ref => {
                  if (ref && !seccionesRef.current.includes(ref)) {
                    seccionesRef.current.push(ref);
                  }
                }}
              >
                <h5 className="text-center">Usted puede consultar el Aviso de Privacidad y Consentimiento Informado en el siguiente enlance:</h5>
                <br />
                <h6 className="text-center">Al firmar el formato de solicitud de estudios certifico la información asentada y acepto los término del presente Consentimiento Informado y Aviso de Privacidad.</h6>
                <div className="form-row mt-3 mb-3">
                  <div style={{ justifyContent: 'center' }} className="col mb-3 d-flex align-items-center">
                    <button type="button" className="btn btn-APCi" data-toggle="modal" data-target="#ModalApCi">Ver AP & CI sobre la solicitud</button>
                  </div>
                  {
                    skipFirmaPaciente && (
                      <div style={{ justifyContent: 'center' }} className="col mb-3 d-flex align-items-center">
                        <button type="button" className="btn btn-APCi" data-toggle="modal" data-target="#ModalApCiBayer">Ver Aviso de Privacidad de Bayer</button>
                      </div>
                    )
                  }
                </div>

                <hr />
                <h5 className="mb-3">Firma del médico solicitante</h5>
                <p className="h6-especial">Para que su muestra sea procesada, es necesario que firme la solicitud con una de las siguientes opciones:</p>
                <div className="form-row d-flex">
                  <div className="col-6 mb-3 px-3 d-flex align-items-center">
                    <button type="button" className="btn btn-info btn-block" data-toggle="modal" data-target="#ModalFirmaMedico">Firmar digitalmente médico <i className="fas fa-file-signature"></i></button>
                  </div>
                  <div className="col-6 px-3 mb-3 d-flex align-items-center">
                    <button type="button" className="btn btn-info btn-block"
                      onClick={() => {
                        handlePDF(folio, nombrePaciente, apellidoPaciente, nacDia, nacMes, nacAnio, genero,
                          categoriaElegida, estudioElegido, metodologiaEstudio, claveEstudio,
                          nomDoc, cedula, emailElegido, telDoc,
                          primarioSelect, metastasicoSelect, muestraBloque, numeroMuestraBloque, muestraLaminillas, numeroMuestraLaminillas, muestraMes, muestraAnio, diagnostico, tratamientosPrevios, labRadio,
                          inputNombreHospital, inputCalleNumero, inputCodigoPostal, inputColinia, inputAlcaldiaMun, inputEstado, inputReferencias, horarioRecoger,
                          costoEstudio, firmaPaciente, firmaMedico)
                      }}
                    >
                      Imprimir solicitud para firmar <i className="fas fa-print"></i>
                    </button>
                  </div>

                  {
                    skipFirmaPaciente ?
                      null
                      :
                      <div className="col-6 px-3 mb-3 d-flex align-items-center">
                        <button type="button" className="btn btn-info btn-block" data-toggle="modal" data-target="#ModalFirmaPaciente">Firmar digitalmente paciente <i className="fas fa-file-signature"></i></button>
                      </div>
                  }
                  <div className="col-sm-12 col-xs-12 col-md-6 px-3 ml-auto mb-3">
                    {/* <label>Subir documento firmado</label> */}
                    <input type="file" className="btn btn-info btn-block"
                      onChange={() => handleInputFileChanged('hiddenSubirFirma')}
                      name="SubirFirma"
                      accept="image/*,.pdf"
                      ref={inputAnyFile}
                      multiple
                      id="hiddenSubirFirma"
                      style={{ display: 'none' }}
                    />
                    <label className="custom-file-label" htmlFor="SubirFirma"
                      onClick={() => handleSignInput('hiddenSubirFirma')}
                    >
                      Subir documento firmado <i className="fas fa-file-upload"></i>
                    </label>
                    <br />
                    <br />
                    <small>Sólo archivos PDF e imágenes</small>
                  </div>
                  {
                    isAdmin && (
                      <CopiarFirma asunto="firmar" folio={folio} />
                    )
                  }
                  <div className="col-12 mb-3 text-center">
                    <hr />
                    {generarButtonAtras('APCI')}
                    {generarButtonSiguiente('APCI')}
                  </div>
                </div>
              </form>

              {/* REALIZAR EL PAGO */}
              {
                props.location.state && props.location.state.empresaPharma === 'Bayer' ?
                  null
                  :
                  <form id="Pago" className="my-3 scale-up-center apartadoSolcitud"
                    ref={ref => {
                      if (ref && !seccionesRef.current.includes(ref)) {
                        seccionesRef.current.push(ref);
                      }
                    }}
                  >
                    {errorPayPal && <div>¡Uh oh!, ha ocurrido un error: {errorPayPal.message}</div>}
                    <div className="form-row">
                      {
                        costoEstudio > 0 ?
                          pago ?
                            <div className="col-6 mb-3">
                              <div className="form-control mr-sm-2">¡El estudio ha sido pagado con éxito!</div>
                            </div>
                            :
                            <>
                              <div className="col-12 mb-3">
                                {
                                  costoEstudio > 0 ?
                                    <div className="mr-sm-2 text-center">Monto a pagar: ${costoEstudio}.00 MXN</div>
                                    :
                                    <div className="mr-sm-2 text-center">Este estudio no tiene ningún costo para paciente</div>
                                }
                              </div>
                              {
                                costoEstudio > 0 ?
                                  <>
                                    <div className="col-12 mb-3">
                                      <CopiarFirma asunto="pagar" folio={folio} />
                                    </div>
                                    <div className="col-12 mb-3">
                                      <div className="mr-sm-2 text-center">
                                        ¿Necesita factura?: <input type="checkbox"
                                          value="¿Se requiere factura?"
                                          name="checkFactura"
                                          checked={facturar}
                                          onChange={e => setFacturar(e.target.checked)}
                                        />
                                      </div>
                                    </div>
                                  </>
                                  : null
                              }
                              {
                                facturar ?
                                  <FormFacturar
                                    fisicaMoral={fisicaMoral}
                                    setFisicaMoral={setFisicaMoral}
                                    RFC={RFC}
                                    setRFC={setRFC}
                                    correoFactura={emailFactura}
                                    setEmailFactura={setEmailFactura}
                                    metodoPago={metodoPago}
                                  />
                                  : null
                              }
                              <div id="NavsPagos" className="col-12 mb-3">
                                <ul className="nav nav-pills mb-3 d-flex justify-content-center" id="pills-tab" role="tablist">
                                  <li className="nav-item" role="presentation">
                                    <a className="nav-link active" id="PagoPlataforma-tab" data-toggle="pill" href="#PagoPlataforma" role="tab" aria-controls="PagoPlataforma" aria-selected="true"
                                      onClick={() => setMetodoPago('Pago en línea')}
                                    >PayPal</a>
                                  </li>
                                  <li className="nav-item" role="presentation">
                                    <a className="nav-link" id="FichaBancaria-tab" data-toggle="pill" href="#FichaBancaria" role="tab" aria-controls="FichaBancaria" aria-selected="false"
                                      onClick={() => setMetodoPago('Transferencia')}
                                    >Bancario</a>
                                  </li>
                                </ul>
                                <div className="tab-content" id="myTabContent">
                                  <div className="tab-pane fade show active" id="PagoPlataforma" role="tabpanel" aria-labelledby="home-tab">
                                    <div id="infoPago" className="my-3">
                                      <div className="division2"></div>
                                      <h5 className="mb-3">Información del pago</h5>
                                      <div className="form-row">
                                        <div className="col-12 mb-3 text-center">
                                          <div ref={payPalRef} />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="tab-pane fade" id="FichaBancaria" role="tabpanel" aria-labelledby="profile-tab">
                                    <div className="my-3">
                                      <div className="division2"></div>
                                      <h5 className="mb-3">Información del pago</h5>
                                      <div className="form-row">
                                        <div className="col-12 mb-3 text-center">
                                          Para continuar con el pago directamente en el banco de click en el siguiente botón.
                                        </div>
                                        <div className="col-12 mb-3 text-center">
                                          <button className="btn btn-danger btn-md" type="button"
                                            data-toggle="modal" data-target="#VisualizarPDF"
                                          >
                                            Imprima su ficha de pago <i className="fi-cwluxl-check"></i>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          : <div className="col-12 mb-3">
                            <div className="jumbotron alert-success">
                              <h4 className="display-4">No se preocupe, recuerde que este programa no tiene costo alguno para el paciente.</h4>
                            </div>
                          </div>
                      }
                    </div>
                  </form>
              }

            </ul>
            <div className="col-12 mb-3 text-center">
              <hr />
              {
                formListo ?
                  <>
                    {/* <button className="btn btn-info mx-2">Generar borrador <i className="fas fa-save"></i></button> */}
                    <button onClick={() => abandonarSolicitud()} className="btn btn-danger mx-2">Cancelar solicitud <i className="fas fa-trash"></i></button>
                  </>
                  :
                  <button onClick={() => abandonarSolicitud()} className="btn btn-danger mx-2">Cancelar solicitud <i className="fas fa-trash"></i></button>
              }
            </div>
          </div>
        </div>

        {/* RESUMEN */}
        <div className="card mt-3" id="solicitudResumen">
          <div className="card-header">
            <h4>Resumen de la solicitud</h4>
            <h5>Revisar la información para confimar de correcto y enviar</h5>
          </div>
          {formListo && (
            <div className="col-12 mb-3 text-center">
              <hr />
              <button className="btn btn-primary btn-md" type="button" disabled={enviadoPress}
                onClick={envioSolicitud}
              >
                <b>CONFIRMAR Y ENVIAR</b> <i className="fas fa-arrow-circle-right"></i>
              </button>
            </div>
          )}
          <div className="card-body">
            {
              isAdmin && (
                <>
                  <BusquedaMedico
                    setCedula={setCedula}
                    cedulaDoc={cedulaDoc}
                  />
                  <hr className="mb-4" />
                </>
              )
            }
            {
              !props.location.state && (
                <>
                  <ElegirEspecialidad
                    state={props.location.state}
                    metodologiaEstudio={metodologiaEstudio}
                    claveEstudio={claveEstudio}
                    detectarCategoriaElegida={detectarCategoriaElegida}
                    intereses={intereses}
                    setMetoClavePrecio={setMetoClavePrecio}
                    categoriaElegida={categoriaElegida}
                    filtroEstudioCategoria={filtroEstudioCategoria}
                    costoEstudio={costoEstudio}
                    estudioSolNombre={estudioSolNombre}
                  />
                  <hr className="mb-4" />
                </>
              )
            }
            <FormPaciente
              folio={folio}
              setNombrePaciente={setNombrePaciente}
              nombrePaciente={nombrePaciente}
              setApellidoPaciente={setApellidoPaciente}
              apellidoPaciente={apellidoPaciente}
              fechaNacHumano={fechaNacHumano}
              handleDateSafari={handleDateSafari}
              nacDia={nacDia}
              nacMes={nacMes}
              nacAnio={nacAnio}
              currentYear={currentYear}
              genero={genero}
              setGenero={setGenero}
              setBothEmails={setBothEmails}
              emailPaciente={emailPaciente}
              ecog={ecog}
              setEcog={setEcog}
              telPaciente={telPaciente}
              setTelPaciente={setTelPaciente}
              celular={celular}
              setCelular={setCelular}
            />
            <hr className="mb-4" />

            <InfoDeMuestra
              primarioSelect={primarioSelect}
              setPrimarioSelect={setPrimarioSelect}
              metastasicoSelect={metastasicoSelect}
              setMetastasicoSelect={setMetastasicoSelect}
              muestraBloque={muestraBloque}
              setMuestraBloque={setMuestraBloque}
              muestraLaminillas={muestraLaminillas}
              setMuestraLaminillas={setMuestraLaminillas}
              numeroMuestraBloque={numeroMuestraBloque}
              setNumeroMuestraBloque={setNumeroMuestraBloque}
              numeroMuestraLaminillas={numeroMuestraLaminillas}
              setNumeroMuestraLaminillas={setNumeroMuestraLaminillas}
              fechaMuestra={fechaMuestra}
              handleDateSafari={handleDateSafari}
              muestraMes={muestraMes}
              muestraAnio={muestraAnio}
              currentYear={currentYear}
              selectDiagnostico={selectDiagnostico}
              handleSelectDiagnostico={handleSelectDiagnostico}
              setDiagnostico={setDiagnostico}
              diagnostico={diagnostico}
              tratamientosPrevios={tratamientosPrevios}
              setTratamientosPrevios={setTratamientosPrevios}
              handleInputFileChanged={handleInputFileChanged}
              inputFileHistopatologico={null}
              inputFileOtros={null}
              laboratorios={laboratorios}
              labRadio={labRadio}
              setLabRadio={setLabRadio}
              idTodo="Dos"
              listadoDiagnosticos={listadoDiagnosticos}
            />
            <hr className="mb-4" />

            <InfoColecta
              handleDirElegida={handleDirElegida}
              listadoDirs={listadoDirs}
              dirElegida={dirElegida}
              setInputCalleNumero={setInputCalleNumero}
              inputCalleNumero={inputCalleNumero}
              setInputCodigoPostal={setInputCodigoPostal}
              inputCodigoPostal={inputCodigoPostal}
              setInputColinia={setInputColinia}
              inputColinia={inputColinia}
              setInputAlcaldiaMun={setInputAlcaldiaMun}
              inputAlcaldiaMun={inputAlcaldiaMun}
              setInputEstado={setInputEstado}
              inputEstado={inputEstado}
              setInputNombreHospital={setInputNombreHospital}
              inputNombreHospital={inputNombreHospital}
              setInputReferencias={setInputReferencias}
              inputReferencias={inputReferencias}
              referenciasPlaceHolder={referenciasPlaceHolder}
              horarioRecoger={horarioRecoger}
              setHorarioRecoger={setHorarioRecoger}
            />

            {formListo && (
              <div className="col-12 mb-3 text-center">
                <hr />
                <button className="btn btn-primary btn-md" type="button" disabled={enviadoPress}
                  onClick={envioSolicitud}
                >
                  <b>Confirmar y Enviar</b> <i className="fas fa-arrow-circle-right"></i>
                </button>
              </div>
            )}
            <div className="col-12 mb-3 text-center">
              <hr />
              {
                formListo ?
                  <>
                    {/* <button className="btn btn-info mx-2">Generar borrador <i className="fas fa-save"></i></button> */}
                    <button onClick={() => abandonarSolicitud()} className="btn btn-danger mx-2">Cancelar solicitud <i className="fas fa-trash"></i></button>
                  </>
                  :
                  <button onClick={() => abandonarSolicitud()} className="btn btn-danger mx-2">Cancelar solicitud <i className="fas fa-trash"></i></button>
              }
            </div>
          </div>
        </div>
      </div>

      {/* FIRMA */}
      <FirmaDigital
        idModal='ModalFirmaPaciente'
        del='Paciente'
        enviarFirmas={enviarFirmas}
        skipFirmaPaciente={skipFirmaPaciente}
      />
      <FirmaDigital
        idModal='ModalFirmaMedico'
        del='Médico'
        enviarFirmas={enviarFirmas}
        skipFirmaPaciente={skipFirmaPaciente}
      />

      <ModalApCi
        skipFirmaPaciente={skipFirmaPaciente}
      />

      {skipFirmaPaciente && <ModalApCiBayer />}

    </RestContainer>
  );
};

export default SolicitarEstudio;
