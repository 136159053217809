import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Contexto as UsersContext } from '../context/UsersContext';
import { Contexto as AdminContext } from '../context/AdminContext';

import { home, Admin } from '../screens/screenNames';
import DatLogo from '../../img/DAT-logo.svg';

const Header = () => {
  const { state: { userUid, nombre, apellidoPat, isAdmin, operador, intereses, email, telefono }, logoutUsuario, resetPassUsuario, setInteresesUsuario } = useContext(UsersContext);
  const { state: { titulosCategorias }, getAllCategorias } = useContext(AdminContext);

  const [interesesElegidos, setInteresesElegidos] = useState([]);
  const [resetExitoso, setRegistroExitoso] = useState(false);

  useEffect(() => {
    getAllCategorias();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setInteresesElegidos(intereses);
  }, [intereses]);

  /**
   * Cada que cambie, se acutualicen los intereses del usuario
   */
  useEffect(() => {
    if (interesesElegidos.length > 0) {
      setInteresesUsuario(userUid, interesesElegidos);
    }
    // eslint-disable-next-line
  }, [interesesElegidos.length, userUid]);

  const signOut = () => {
    logoutUsuario();
  };

  const cerrarModal = () => {
    window.hideModal("#prefCuentaDoc");
  };

  /**
   * Al hacer click en el botón del modal
   */
  const cambioContrasenia = () => {
    const response = resetPassUsuario(email);
    setRegistroExitoso(response);
  };

  /* Función para modificar los estilos de las preferencias de correo */
  const changeColorTextNotificacion = () => {
    let text = document.getElementById('colorText');
    let statusText = document.getElementById('statusText');
    let alertMessage = document.getElementById('textNotification');
    if (text.classList.contains('text-success')) {
      alertMessage.classList.remove('d-none');
      text.classList.replace('text-success', 'text-danger');
      statusText.innerText = 'desactivadas'
    } else {
      alertMessage.classList.add('d-none');
      text.classList.replace('text-danger', 'text-success');
      statusText.innerText = 'activadas'
    }
  };

  const categoriasButtons = () => {
    if (titulosCategorias) {
      return Object.keys(titulosCategorias).map(function (key) {

        if (intereses.includes(titulosCategorias[key])) {
          return <button
            key={key}
            className={`btn btn-lg btn-outline-primary text-uppercase m-2 col-5 active btnPreferencias`}
            onClick={e => {
              e.target.classList.toggle('active');

              e.target.className.includes('active') ?
                setInteresesElegidos([...interesesElegidos, titulosCategorias[key]])
                : setInteresesElegidos(interesesElegidos.filter(interes => titulosCategorias[key] !== interes))
            }}
            type="button" >
            {titulosCategorias[key]}
          </button>;
        }

        return <button
          key={key}
          className={`btn btn-lg btn-outline-primary text-uppercase m-2 col-5 btnPreferencias`}
          onClick={e => {
            e.target.classList.toggle('active');

            e.target.className.includes('active') ?
              setInteresesElegidos([...interesesElegidos, titulosCategorias[key]])
              : setInteresesElegidos(interesesElegidos.filter(interes => titulosCategorias[key] !== interes))
          }}
          type="button" >
          {titulosCategorias[key]}
        </button>;
      });
    }
  };


  // FB, Google, Twitter, etc.
  const activarAuthOtroMetiodo = () => {
    // const auth = getAuth();
    // linkWithPopup(auth.currentUser, provider).then((result) => {
    //   // Accounts successfully linked.
    //   const credential = GoogleAuthProvider.credentialFromResult(result);
    //   const user = result.user;
    //   // ...
    // }).catch((error) => {
    //   // Handle Errors here.
    //   // ...
    // });
  };

  return (
    <>
      <header className="row">
        <div id="navegacion" className="col-sm-12 bg-nav">
          <nav className="navbar navbar-dark">
            <Link className="navbar-brand" to={`/${home}`}>
              <img src={DatLogo} className="img-fluid" width="94" alt="logo DAT" />
            </Link>
            <h1 className="d-none d-md-block">Bienvenid@ <span className="d-none d-lg-inline">{`${nombre} ${apellidoPat}`}</span></h1>
            <div className="navbar-expand-lg" id="navbarSupportedContent">
              {
                isAdmin && !operador && (
                  <Link className="btn btn-secondary mr-1 mr-md-3 botonMenu" to={`/${Admin}`}>
                    <i className="fas fa-cog"></i> <span className="d-none d-md-inline-flex">Administración</span>
                  </Link>
                )
              }
              {
                isAdmin ?
                  <button className="btn btn-info mr-1 mr-md-3 botonMenu"
                    data-toggle="modal" data-target="#prefCuentaDoc"
                  >
                    <i className="fas fa-user"></i> <span className="d-none d-md-inline-flex">Preferencias</span>
                  </button>
                  :
                  <button className="btn btn-secondary mr-1 mr-md-3 botonMenu" type="button"
                    data-toggle="modal" data-target="#prefCuentaDoc"
                  >
                    <i className="fas fa-cog"></i> <span className="d-none d-md-inline-flex">Preferencias</span>
                  </button>
              }
              <button className="btn btn-danger mr-1 botonMenu" onClick={signOut} tabIndex="-1" aria-disabled="true">
                <i className="fas fa-sign-out-alt"></i>
              </button>
            </div>
          </nav>
        </div>
      </header>

      <div id="prefCuentaDoc" className="modal fade">
        <div className="modal-dialog card">
          <div className="modal-content">
            <div className="modal-header">
              <h2>Modifique su cuenta</h2>
              <button type="button" className="close" onClick={cerrarModal} aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="container-fluid">

                {/* TELEFONO DE RECUPERACION */}
                {/* <div className="row">
                  <div className="col text-center">
                    <h4>¿Ya se activó el inicio de sesión por teléfono?</h4>
                    {
                      true ?
                        <>
                          <h5>
                            Vemos que todavía no se activa el inicio de sesión con el número {telefono}
                            <br />
                            De <button
                              style={{ fontSize: '1.25rem' }}
                              type="button"
                              className="btn btnTypeAnchor"
                              onClick={cambioContrasenia}
                            >
                              click aquí.
                            </button>
                          </h5>
                        </>
                        :
                        <h5>
                          El número {telefono} ha sido activado para iniciar sesión.
                        </h5>
                    }
                    <hr />
                  </div>
                </div> */}

                {/* CAMBIAR CONTRASEÑA */}
                <div className="row">
                  <div className="col text-center">
                    <h4>¿Quiere cambiar su contraseña?</h4>
                    {
                      resetExitoso ?
                        <h5>Se ha enviado un enlace de verificación a su correo electrónico. Puede llegar a correo no deseado. En caso de o recibirlo, por favor contáctenos.</h5>
                        :
                        <h5>
                          De <button
                            style={{ fontSize: '1.25rem' }}
                            type="button"
                            className="btn btnTypeAnchor"
                            onClick={cambioContrasenia}
                          >
                            click aquí.
                          </button>
                        </h5>
                    }
                    <hr />
                  </div>
                </div>

                {/* NOTIFICACIONES EMAIL */}
                <div className="row">
                  <div className="col text-center">
                    <h4 className="mb-3">¿Quiere modificar sus notificaciones de email?</h4>
                    <h5 id="textNotification" className="mb-3 alert alert-warning d-none">Al desactivar las notificaciones no recibirá correos acerca de sus casos.</h5>
                    <div className="form-row mb-3 d-flex justify-content-center align-items-center">
                      <label className="switch" htmlFor="checkbox" onChange={changeColorTextNotificacion}>
                        <input type="checkbox" id="checkbox" defaultChecked />
                        <div className="slider round"></div>
                      </label>
                      <h4 id="colorText" className="mx-2 text-success">Notificaciones <span id="statusText">activadas</span></h4>
                    </div>
                    <hr />
                  </div>
                </div>

                {/* MODIFICAR INTERESES */}
                <div className="row">
                  <div className="col text-center">
                    <h4>¿Quiere modificar sus intereses?</h4>
                    <h5 className="mb-3">Haga click en los intereses que quiera activar o desactivar para su cuenta</h5>
                    <div className="form-row mb-3 d-flex justify-content-center">
                      {categoriasButtons()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-danger" onClick={cerrarModal}><i className="fas fa-times" aria-hidden="true"></i> Cerrar</button>
            </div>
          </div>
        </div>
      </div >
    </>
  );
};

export default Header;
