import React from 'react';
import AdminEstudioForm from '../../modules/AdminPath/AdminEstudioForm';

const AgregarEstudio = ({ titulo, titulosCategorias, titulosEspecialidades, enviarEstudio }) => {
  return (
    <div className="tab-pane fade show active" id="agregarEstudio" role="tabpanel" aria-labelledby="agregarEstudio-tab">
      <AdminEstudioForm
        titulo={titulo}
        titulosCategorias={titulosCategorias}
        titulosEspecialidades={titulosEspecialidades}
        enviarEstudio={enviarEstudio}
      />
    </div>
  );
};

export default AgregarEstudio;