import axios from 'axios';
// baseURL: 'http://localhost:5000',
// baseURL: 'http://54.234.216.178:5000',
// baseURL: 'http://dxatiempo.com:5000',
// baseURL: 'https://dxatiempo.com',
export default axios.create({
  baseURL: 'https://dxatiempo.com',
  headers: {
    'Content-Type': 'application/json',
    Accept: '*/*',
    "Access-Control-Allow-Origin": "*"
  }
});