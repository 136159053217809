import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { Registros, Cotizacion, Estudio, Programas, Doctores } from '../screens/screenNames';
import { Contexto as UsersContext } from '../context/UsersContext';

const MenuLateral = () => {
  const { state: { isAdmin } } = useContext(UsersContext);

  const location = window.location.pathname;

  return (
    <div className="col-sm-12 col-md-12 col-lg-4 d-none d-md-none d-lg-block">
      <div className="position-sticky py-3">
        {
          location !== `/${Registros}` ?
            <Link to={`/${Registros}`} className="btn btn-primary btn-block btn-lg">
              <div className="txtBtnMenu">
                <i className="fas fa-notes-medical"></i> Consultar mis registros
              </div>
            </Link>
            : null
        }

        {
          location !== `/${Cotizacion}` ?
            <Link to={`/${Cotizacion}`} className="btn btn-primary btn-block btn-lg">
              <div className="txtBtnMenu">
                <i className="fas fa-dollar-sign"></i> Solicitar cotización
              </div>
            </Link>
            : null
        }

        {
          location !== `/${Estudio}` ?
            <Link to={`/${Estudio}`} className="btn btn-primary btn-block btn-lg">
              <div className="txtBtnMenu">
                <i className="fas fa-laptop-medical"></i> Solicitar estudio
              </div>
            </Link>
            : null
        }

        {
          location !== `/${Programas}` ?
            <Link to={`/${Programas}`} className="btn btn-primary btn-block btn-lg">
              <div className="txtBtnMenu">
                <i className="fas fa-certificate"></i> Programas
              </div>
            </Link>
            : null
        }

        {
          isAdmin && location !== `/${Doctores}` ?
            <Link to={`/${Doctores}`} className="btn btn-primary btn-block btn-lg">
              <div className="txtBtnMenu">
                <i className="fas fa-search"></i> Buscar médicos en el sistema
            </div>
            </Link>
            : null
        }

        {/* <Link to="/registrosTotales" className="btn btn-primary btn-block btn-lg"><i className="fas fa-search" aria-hidden="true"></i> Consulta general</Link> */}

         <div className="my-3">
            <h6 className="h6-especial">¿Necesita asistencia? Contáctenos vía: <br/></h6>
            <i className="fas fa-envelope"></i> Correo a <a className="menu-lateral-a-azul" href="mailto:contacto@diagnostiqueatiempo.com">contacto@diagnostiqueatiempo.com</a><br/>
            <i className="fab fa-whatsapp"></i> Whatsapp® haciendo click <a className="menu-lateral-a-azul" target="blank" href="https://wa.me/525588807871">aquí.</a><br/>
            <i className="fas fa-mobile-alt"></i> Teléfono al número: <a className="menu-lateral-a-azul" href="tel:+525588807871">+52 55 8880 7871</a>.
         </div>
      </div>
    </div>
  );
};

export default MenuLateral;
