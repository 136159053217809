import React from 'react';

const InfoDeMuestra = ({ children, primarioSelect, setPrimarioSelect, metastasicoSelect, setMetastasicoSelect, muestraBloque, setMuestraBloque, muestraLaminillas, setMuestraLaminillas, numeroMuestraBloque, setNumeroMuestraBloque, numeroMuestraLaminillas, setNumeroMuestraLaminillas, fechaMuestra, handleDateSafari, muestraMes, muestraAnio, currentYear, selectDiagnostico, handleSelectDiagnostico, setDiagnostico, diagnostico, tratamientosPrevios, setTratamientosPrevios, handleInputFileChanged, inputFileHistopatologico, inputFileOtros, laboratorios, labRadio, setLabRadio, idTodo, listadoDiagnosticos }) => {

  return (
    <>
      <h5 className="mb-3">Información de la muestra</h5>
      <div className="form-row">
        <div className="col-6 mb-3 px-3">
          <label className="alert-warning"><b>*</b> Primario o metastásico:</label>
          <div className="form-check">
            <input className="form-check-input" type="radio" name="tipoMuestra" id={`tipoMuestra1${idTodo}`} value="primario"
              checked={primarioSelect === 'primario'}
              onChange={e => setPrimarioSelect(e.target.value)}
            />
            <label className="form-check-label" htmlFor={`tipoMuestra1${idTodo}`}>
              Primario
            </label>
          </div>
          <div className="form-check">
            <input className="form-check-input" type="radio" name="tipoMuestra" id={`tipoMuestra2${idTodo}`} value="metastásico"
              checked={primarioSelect === 'metastásico'}
              onChange={e => setPrimarioSelect(e.target.value)}
            />
            <label className="form-check-label" htmlFor={`tipoMuestra2${idTodo}`}>
              Metastásico
            </label>
            {
              primarioSelect === "metastásico" && (
                <>
                  <input className="form-control alert-warning" type="text"
                    placeholder="Especificar zona de la muestra"
                    value={metastasicoSelect}
                    onChange={e => setMetastasicoSelect(e.target.value)}
                  />
                  {
                    metastasicoSelect && (
                      <small>Especificar zona de la muestra</small>
                    )
                  }
                </>
              )
            }
          </div>
          <hr />
        </div>

        <div className="col-6 mb-3 px-3">
          <label className="alert-warning"><b>*</b> Muestra enviada (bloque / laminillas):</label>
          <div className="form-check">
            <input className="form-check-input" type="checkbox" name="muestraBloque" id={`muestraBloque1${idTodo}`} value="bloque"
              checked={muestraBloque}
              onChange={e => setMuestraBloque(e.target.checked)}
            />
            <label className="form-check-label" htmlFor={`muestraBloque1${idTodo}`}>
              Bloque
            </label>
            {
              !!muestraBloque ?
                <>
                  <input className={`form-control alert-warning${numeroMuestraBloque}`} type="text"
                    placeholder="Ingresa el identificador de la muestra"
                    value={numeroMuestraBloque}
                    onChange={e => setNumeroMuestraBloque(e.target.value)}
                  />
                  <small>Si no cuenta con identificador ingrese: “No Aplica Bloque”</small>
                </>
                : null
            }
          </div>
          <div className="form-check">
            <input className="form-check-input" type="checkbox" name="muestraBloque" id="muestraBloque2" value="laminillas"
              checked={muestraLaminillas}
              onChange={e => setMuestraLaminillas(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="muestraBloque2">
              Laminillas
            </label>
            {
              !!muestraLaminillas ?
                <>
                  <input className={`form-control alert-warning${numeroMuestraLaminillas}`} type="text"
                    placeholder="Ingresa el identificador de la muestra"
                    value={numeroMuestraLaminillas}
                    onChange={e => setNumeroMuestraLaminillas(e.target.value)}
                  />
                  <small>Si no cuenta con identificador ingrese: “No Aplica Laminillas”</small>
                </>
                : null
            }
          </div>
          <hr />
        </div>

        <div className="col-12 mb-3 px-3">
          <label className="alert-warning"><b>*</b> Fecha de obtención de la muestra: {fechaMuestra}</label>
          <div className="row">
            <div className="col-6">
              <label>Mes</label>
              <input className={`form-control mr-sm-2 alert-warning${fechaMuestra}`} type="number" placeholder="mes" name="muestraMes"
                onChange={e => handleDateSafari(e.target.value, 12, 'mes', 'obtencionMuestra')}
                value={muestraMes}
                max="12"
                min="1"
                maxLength="2"
              />
            </div>
            <div className="col-6">
              <label>Año</label>
              <input className={`form-control mr-sm-2 alert-warning${fechaMuestra}`} type="number" placeholder="año" name="muestraAnio"
                onChange={e => handleDateSafari(e.target.value, currentYear, 'anio', 'obtencionMuestra')}
                value={muestraAnio}
                max={`${currentYear}`}
                min="2010"
                maxLength="4"
              />
            </div>
          </div>
          <hr />
        </div>

        <div className="col-6 mb-3 px-3">
          <label className="alert-warning"><b>*</b> Diagnóstico:</label>
          <select id="selectDiag" className={`form-control mr-sm-2 alert-warning${selectDiagnostico}`} name="dirMedico"
            onChange={e => handleSelectDiagnostico(e.target.value)}
            value={selectDiagnostico}
          >
            <option>Elija un diagnóstico</option>
            {listadoDiagnosticos.map(itemDiag => {
              return <option key={itemDiag} value={itemDiag}>{itemDiag}</option>
            })}
            <option value="Otro">Otro</option>
          </select>
          {
            selectDiagnostico === 'Otro' && (
              <>
                <label>Ingresa el diagnóstico:</label>
                <input className="form-control mr-sm-2" type="text" placeholder="Descripción del diagnóstico" name="diagnostico"
                  onChange={e => setDiagnostico(e.target.value)}
                  value={diagnostico}
                />
                {
                  !!diagnostico && <small>Descripción del diagnóstico</small>
                }
              </>
            )
          }
          <hr />
        </div>

        <div className="col-6 mb-3 px-3">
          <label>Tratamientos previos:</label>
          <div className="form-check">
            <input className="form-check-input" type="radio" name="tratamientosPrevios" id="tratamientosPrevios1" value="Sin tratamiento previo"
              checked={tratamientosPrevios === 'Sin tratamiento previo'}
              onChange={e => setTratamientosPrevios(e.target.value)}
            />
            <label className="form-check-label" htmlFor="tratamientosPrevios1">
              Sin tratamiento previo
            </label>
          </div>
          <div className="form-check">
            <input className="form-check-input" type="radio" name="tratamientosPrevios" id="tratamientosPrevios2" value="2L"
              checked={tratamientosPrevios === '2L'}
              onChange={e => setTratamientosPrevios(e.target.value)}
            />
            <label className="form-check-label" htmlFor="tratamientosPrevios2">
              2L
            </label>
          </div>
          <div className="form-check">
            <input className="form-check-input" type="radio" name="tratamientosPrevios" id="tratamientosPrevios3" value="3L ó subsecuentes"
              checked={tratamientosPrevios === '3L ó subsecuentes'}
              onChange={e => setTratamientosPrevios(e.target.value)}
            />
            <label className="form-check-label" htmlFor="tratamientosPrevios3">
              3L ó subsecuentes
            </label>
          </div>
          <hr />
        </div>
        {/* Adjuntar reporte histopatológico */}
        {/* Otros archivos */}
        {
          inputFileHistopatologico && inputFileOtros && (
            <>

              <div className="col-sm-12 col-xs-12 col-md-6">
                <label htmlFor="hiddenHistopatologico">
                  Adjuntar reporte histopatológico <i className="fas fa-file-upload"></i>
                </label>
                <input type="file" className={`btn btn-info btn-block`}
                  onChange={() => handleInputFileChanged('hiddenHistopatologico')}
                  name="SubirHistopatologico"
                  accept="image/*,.pdf"
                  ref={inputFileHistopatologico}
                  multiple
                  id="hiddenHistopatologico"
                />
                <small>Sólo archivos PDF e imágenes</small>
                <hr className="mt-5" />
              </div>
              <div className="col-sm-12 col-xs-12 col-md-6">
                <label htmlFor="hiddenOtrosArchivos">
                  ¿Desea subir otros archivos? <i className="fas fa-file-upload"></i>
                </label>
                <input type="file" className="btn btn-info btn-block"
                  onChange={() => handleInputFileChanged('hiddenOtrosArchivos')}
                  name="SubirOtros"
                  accept="image/*,.pdf"
                  ref={inputFileOtros}
                  multiple
                  id="hiddenOtrosArchivos"
                />
                <small>Sólo archivos PDF e imágenes</small>
                <hr className="mt-5" />
              </div>
            </>
          )
        }

        <div className="col-12 mt-3 mb-3"><h5>Laboratorios disponibles para el programa</h5></div>
        <div className="col-6 mb-3 px-3">
          <label className="alert-warning"><b>*</b> Seleccione el laboratorio donde se procesará su muestra:</label>
          {
            laboratorios.map((lab, index) => {
              return (
                <div className="form-check" key={lab}>
                  <input className="form-check-input" type="radio" name={lab} id={`${lab}${index}`} value={lab}
                    checked={labRadio === lab}
                    onChange={e => setLabRadio(e.target.value)}
                  />
                  <label className="form-check-label" htmlFor={`${lab}${index}`}>
                    {lab}
                  </label>
                </div>
              );
            })
          }
        </div>

        {children}
      </div>
    </>
  );
};

export default InfoDeMuestra;
