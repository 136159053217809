export const registro = 'registro';
export const completarregistro = 'completarregistro';
export const Verificar = 'Verificacion';

export const home = 'home';
export const Registros = 'Registros';
export const Cotizacion = 'Cotizacion';
export const Estudio = 'Estudio';
export const Programas = 'Programas';
export const Doctores = 'Doctores';
export const DetailDoctor = 'DetalleDoctor';
export const DetalleRegistro = 'DetalleRegistro';
export const Admin = 'administracion';

export const FirmaMedico = 'firmamedico';
export const FirmaPaciente = 'firmapaciente';

export const AvisoPrivacidad = 'avisodeprivacidad';
export const TerminosCondiciones = 'terminosycondiciones';
export const Cookies = 'cookies';