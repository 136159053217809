import { getAge } from "./Fecha";

const convertToCSV = objArray => {
  const array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
  let str = '';

  for (let i = 0; i < array.length; i++) {
    let line = '';
    // eslint-disable-next-line
    for (let index in array[i]) {
      if (line !== '') line += ','

      line += array[i][index];
    }

    str += line + '\r\n';
  }

  return str;
};

const replaceComas = (text) => {
  if (!text) return 'N/A';
  const newLine = text.replace(/\n/g, '');
  return newLine.replace(/,/g, '');
};

const formatoDireccion = (calle, colonia, cp) => {
  const calleClean = replaceComas(calle);
  const coloniaClean = replaceComas(colonia);
  const cpClean = replaceComas(cp);
  return `${calleClean} - ·${coloniaClean} - ${cpClean}`;
};

export const formatItems = itemsNotFormatted => {
  const itemsFormatted = [];

  // format the data
  itemsNotFormatted.forEach((item) => {
    let firmaPac = item.firmaPaciente ? 'El paciente firmó' : 'No hay firma de paciente';
    let firmaMed = item.firmaMedico ? 'El médico firmó' : 'El médico no ha firmado';
    let isFile = item.file ? item.file : 'No se cargó impresión o foto';
    let isPago = item.pago ? 'Ya se pagó' : 'No se ha pagado';
    let colProg = item.colecta_programada ? item.colecta_programada.fecha : 'No se ha programado la colecta';
    let resEstatus = 'Todavía no hay resultado del estudio disponible.';
    let resFecha = 'Todavía no hay resultado del estudio disponible.';
    let resCom = 'Todavía no hay resultado del estudio disponible.';
    let resFile = 'Todavía no hay resultado del estudio disponible.';
    if (item.resultado_emitido) {
      resEstatus = item.resultado_emitido.estatus;
      resFecha = item.resultado_emitido.fecha;
      resCom = item.resultado_emitido.comentarios;
      resFile = item.resultado_emitido.filename;
    }
    let regMuestra = item.regreso_muestra ? item.regreso_muestra.fecha : 'No se ha programado el regreso de la colecta.';
    let colRel = item.colecta_realizada ? item.colecta_realizada : `No se ha recolectado la muestra.`;
    let enLab = item.entregada_lab ? item.entregada_lab : `No se ha entregado la colecta al laboratorio ${item.laboratorioSeleccionado}.`;

    itemsFormatted.push({
      folio: replaceComas(item.folio),
      estudio: replaceComas(item.estudio),
      nomDoc: replaceComas(item.nomDoc),
      nombrePaciente: replaceComas(item.nombrePaciente),
      apellidoPaciente: replaceComas(item.apellidoPaciente),
      fechaNac: replaceComas(item.fechaNac),
      genero: replaceComas(item.genero),
      email: replaceComas(item.email),
      telefono: replaceComas(item.telefono),
      celular: replaceComas(item.celular),
      denominacionSocial: replaceComas(item.denominacionSocial),
      RFC: replaceComas(item.RFC),
      emailFactura: replaceComas(item.emailFactura),
      file: replaceComas(isFile),
      reporteHistopatologico: replaceComas(item.reporteHistopatologico),
      otroArchivo: replaceComas(item.otroArchivo),
      pago: replaceComas(isPago),
      costo: replaceComas(`$${item.costo}.00MXN`),
      fecha_solcitada: replaceComas(item.fecha_solcitada),
      etapa: replaceComas(item.etapa),
      firmaPaciente: firmaPac,
      firmaMedico: replaceComas(firmaMed),
      muestraBloque: typeof (item.muestraBloque) === 'string' ? replaceComas(item.muestraBloque) : item.muestraBloque,
      numeroMuestraBloque: item.numeroMuestraBloque.replace(/,/g, ''),
      muestraLaminillas: item.muestraLaminillas,
      numeroMuestraLaminillas: replaceComas(item.numeroMuestraLaminillas),
      fechaMuestra: replaceComas(item.fechaMuestra),
      diagnostico: replaceComas(item.diagnostico),
      tratamientosPrevios: replaceComas(item.tratamientosPrevios),
      direccion: formatoDireccion(item.direccion.calle, item.direccion.colonia, item.direccion.cp),
      direccion_estado: replaceComas(item.direccion.estado),
      direccion_municipio: replaceComas(item.direccion.municipio),
      direccion_hospital: replaceComas(item.direccion.nombreHospital),
      direccion_referencias: replaceComas(item.direccion.referencias),
      horarioRecoger: replaceComas(item.horarioRecoger),
      laboratorioSeleccionado: replaceComas(item.laboratorioSeleccionado),
      empresaPharma: replaceComas(item.empresaPharma),
      colecta_programada: replaceComas(colProg),
      colecta_realizada: replaceComas(colRel),
      entregada_lab: replaceComas(enLab),
      resultado_emitido_estatus: replaceComas(resEstatus),
      resultado_emitido_fecha: replaceComas(resFecha),
      resultado_emitido_comentarios: replaceComas(resCom),
      resultado_emitido_filename: replaceComas(resFile),
      regreso_muestra: replaceComas(regMuestra)
    });
  });

  return itemsFormatted;
}

export const exportCSVFile = (headers, itemsNotFormatted, fileTitle = `misEstudiosCSV-fecha-${new Date().getFullYear()}-${new Date().getMonth()}-${new Date().getDate()}`) => {
  const items = formatItems(itemsNotFormatted);

  if (headers) {
    items.unshift(headers);
  }

  // Convert Object to JSON
  var jsonObject = JSON.stringify(items);

  var csv = convertToCSV(jsonObject);

  var exportedFilenmae = fileTitle + '.csv' || 'export.csv';

  var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) { // IE 10+
    navigator.msSaveBlob(blob, exportedFilenmae);
  } else {
    var link = document.createElement("a");
    if (link.download !== undefined) { // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", exportedFilenmae);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

export const headers = {
  folio: 'Folio'.replace(/,/g, ''), // remove commas to avoid errors
  estudio: 'Estudio solicitado'.replace(/,/g, ''),
  nomDoc: "Nombre doctor".replace(/,/g, ''),
  nombrePaciente: "Nomnres paciente".replace(/,/g, ''),
  apellidoPaciente: "Apellidos paciente".replace(/,/g, ''),
  fechaNac: "Fecha nacimiento paciente".replace(/,/g, ''),
  genero: "Género paciente".replace(/,/g, ''),
  email: 'Email paciente'.replace(/,/g, ''),
  telefono: "Teléfono paciente".replace(/,/g, ''),
  celular: "Celular paciente".replace(/,/g, ''),
  denominacionSocial: "Denominación Social".replace(/,/g, ''),
  RFC: 'RFC'.replace(/,/g, ''),
  emailFactura: "Email Factura".replace(/,/g, ''),
  file: "Firma en impresión".replace(/,/g, ''),
  reporteHistopatologico: 'Reporte Histopatológico'.replace(/,/g, ''),
  otroArchivo: "Otros Archivos".replace(/,/g, ''),
  pago: "Pago del paciente".replace(/,/g, ''),
  costo: "Costo del estudio".replace(/,/g, ''),
  fecha_solcitada: "Fecha de envío solicitud".replace(/,/g, ''),
  etapa: 'Etapa de la solicitud'.replace(/,/g, ''),
  firmaPaciente: "Se subió firma paciente".replace(/,/g, ''),
  firmaMedico: "Se subió firma doctor".replace(/,/g, ''),
  muestraBloque: "Se envió muestra de bloque".replace(/,/g, ''),
  numeroMuestraBloque: 'Identificador de la muestra'.replace(/,/g, ''),
  muestraLaminillas: "Se envió muestra de laminillas".replace(/,/g, ''),
  numeroMuestraLaminillas: 'Identificador de la muestra'.replace(/,/g, ''),
  fechaMuestra: "Fecha de toma de la muestra".replace(/,/g, ''),
  diagnostico: "Diagnóstico de la muestra".replace(/,/g, ''),
  tratamientosPrevios: "Tratamientos previos al estudio".replace(/,/g, ''),
  direccion_calle: 'Dirección calle de colecta'.replace(/,/g, ''),
  direccion_colonia: 'Dirección colonia de colecta'.replace(/,/g, ''),
  direccion_cp: 'Dirección C.P. de colecta'.replace(/,/g, ''),
  direccion_estado: 'Dirección estado de colecta'.replace(/,/g, ''),
  direccion_municipio: 'Dirección alcaldía o municipio de colecta'.replace(/,/g, ''),
  direccion_hospital: 'Dirección hospital de colecta'.replace(/,/g, ''),
  direccion_referencias: 'Dirección referencias de colecta'.replace(/,/g, ''),
  horarioRecoger: "Horario de recolección de la muestra".replace(/,/g, ''),
  laboratorioSeleccionado: "Laboratorio para análisis de la muestra".replace(/,/g, ''),
  empresaPharma: "Empresa pharma".replace(/,/g, ''),
  colecta_programada: "Fecha de programación colecta".replace(/,/g, ''),
  colecta_realizada: "Fecha de recolección colecta".replace(/,/g, ''),
  entregada_lab: "Fecha de entrega a laboratorio".replace(/,/g, ''),
  resultado_emitido_estatus: "Estatus del resultado emitido".replace(/,/g, ''),
  resultado_emitido_fecha: "Fecha del resultado emitido".replace(/,/g, ''),
  resultado_emitido_comentarios: "Comentarios del resultado emitido".replace(/,/g, ''),
  resultado_emitido_filename: "Archivo del resultado emitido".replace(/,/g, ''),
  regreso_muestra: "Fecha de regreso colecta".replace(/,/g, '')
};