export const USER_REGISTER = 'USER_REGISTER';
export const USER_REGISTER_INFO = 'USER_REGISTER_INFO';
export const USER_COMPLETANDO_REGISTRO = 'USER_COMPLETANDO_REGISTRO';
export const SET_VERIF_LINK = 'SET_VERIF_LINK';
export const USER_SIGNIN = 'USER_SIGNIN';
export const USER_SIGNOUT = 'USER_SIGNOUT';
export const INTERESES_USUARIO = 'INTERESES_USUARIO';

export const ALL_SOLICITUDES_DAT = 'ALL_SOLICITUDES_DAT';
export const LISTADO_ESTUDIOS = 'LISTADO_ESTUDIOS';
export const ESTATUS_ESTUDIOS = 'ESTATUS_ESTUDIOS';
export const GET_PROMOCIONES = 'GET_PROMOCIONES';
export const SEND_COTIZACION = 'SEND_COTIZACION';
export const DOCTOR_ESTUDIOS = 'DOCTOR_ESTUDIOS';

export const REPORTE_HISTOPATOLOGICO = 'REPORTE_HISTOPATOLOGICO';
export const ESTUDIO_APCI = 'ESTUDIO_APCI';
export const ESTUDIO_PAGO = 'ESTUDIO_PAGO';
export const RES_EMITIDO = 'RES_EMITIDO';
export const RES_EMITIDO_AGAIN = 'RES_EMITIDO_AGAIN';
export const LIMPIAR_ARCHIVOS = 'LIMPIAR_ARCHIVOS';
export const ALL_LABS = 'ALL_LABS';
export const ALL_DIAGS = 'ALL_DIAGS';

export const LISTADO_DOCTORES = 'LISTADO_DOCTORES';
export const UN_DOCTOR = 'UN_DOCTOR';

export const ALL_TITULOS_CATEGORIAS = 'ALL_TITULOS_CATEGORIAS';
export const ALL_TITULOS_ESPECIALIDADES = 'ALL_TITULOS_ESPECIALIDADES';
export const ALL_TITULOS_ESTUDIOS = 'ALL_TITULOS_ESTUDIOS';
export const APPEND_ESTUDIO = 'APPEND_ESTUDIO';
export const INFO_ESTUDIO = 'INFO_ESTUDIO';
export const ESTUDIO_DUPLCIADO = 'ESTUDIO_DUPLCIADO';
export const ALL_PHARMAS = 'ALL_PHARMAS';

export const ALL_TITULOS_PROGRAS = 'ALL_TITULOS_PROGRAS';
export const APPEND_PROGRA = 'APPEND_PROGRA';
export const INFO_PROGRA = 'INFO_PROGRA';
export const PROGRA_DUPLCIADO = 'PROGRA_DUPLCIADO';

export const BUSQUEDA_FOLIO_SOLICITUD = 'BUSQUEDA_FOLIO_SOLICITUD';