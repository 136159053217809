import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';

import { Contexto as UsersContext } from '../../context/UsersContext';

import PromocionContainer from './PromocionContainer';
import Promocion from './Promocion';
import { formatoFechaHumano } from '../../utils/Fecha';
import { Estudio } from '../../screens/screenNames';

import LogoDat from '../../../img/DAT-logo.svg';

const Promociones = ({ children, programas, limit }) => {
  const { state: { intereses } } = useContext(UsersContext);
  const [todasTodas, setTodasTodas] = useState(false);

  if (programas.length === 0) {
    return (
      <PromocionContainer>
        <h5 className="card-title">Por el momento no hay programas vigentes.</h5>
      </PromocionContainer>
    );
  }

  // FILTRAR DE PHARMA
  // En home y en la pantalla de programas
  const programasSinPharma = programas.filter(programa => programa[1].empresaPharma === 'N/A');

  if (programasSinPharma.length === 0) {
    return (
      <PromocionContainer>
        <h5 className="card-title">Por el momento no hay programas vigentes.</h5>
      </PromocionContainer>
    );
  }

  let programasRender = programasSinPharma;
  // Mostrar nada más las promos de sus intereses o todos los intereses
  if (!todasTodas && programasSinPharma && intereses) {
    programasRender = programasSinPharma.filter(programa => intereses.includes(programa[1]['Categoría']));
    if (programasRender.length === 0) {
      return (
        <PromocionContainer>
          <h5 className="card-title">Por el momento no hay programas vigentes.</h5>
          {/* <h5 className="card-title">No tienes programas visibles en tus intereses.</h5>
          {
            limit ?
              null
              :
              todasTodas ?
                <button className="btn btn-primary btn-block mt-5"
                  onClick={() => setTodasTodas(false)}
                >Sólo mostrar programas de mi interés <i className="fas fa-search-minus"></i></button>
                :
                <button className="btn btn-outline-primary btn-block mt-5"
                  onClick={() => setTodasTodas(true)}
                >Ver todas sin importar categoría <i className="fas fa-search-plus"></i></button>
          } */}
        </PromocionContainer>
      );
    }
  }

  return (
    <PromocionContainer>

      {/* Listado de programas */}
      {
        programasRender.map((promocion, index) => {
          if (limit && index > 2) {
            return null;
          }

          const promoInicio = formatoFechaHumano(promocion[1].fecha_promo_inicio);
          const promoFin = formatoFechaHumano(promocion[1].fecha_promo_fin);
          const estudioElegido = [promocion[1].estudio, { 'Categoría': promocion[1]['Categoría'], Costo: promocion[1].costo, 'Metodología': promocion[1].prueba, 'Clave del estudio': 'N/A' }];
          return (
            <div className="card mb-3" key={promocion[0]}>
              <div className="card-header card-promos-header">
                <h4>{promocion[0]}</h4>
              </div>
              <div className="card-body row imgProgram mx-0">
                <div className="col-12 mb-4">
                  <img src={LogoDat} className="img-fluid" alt="Logo Dat en Programa" />
                </div>
                <div className="col-12">
                  <h5 className="card-title">{promocion[1].desc_general}</h5>
                </div>
                <Promocion promocion={promocion} origen="" />
                <div className="col-12">
                  <p className="card-text">Programa válido del {promoInicio} al {promoFin}.</p>
                  <Link className="btn btn-primary btn-block mt-3"
                    to={{
                      pathname: `/${Estudio}`,
                      state: { estudioElegido, saltarElegirEspecialidad: true }
                    }}
                  >
                    Solicitar Estudio <i className="fas fa-arrow-circle-right"></i>
                  </Link>
                </div>
              </div>
            </div>
          );
        })
      }
      {children}

      {
        limit || programasRender.length <= programasSinPharma.length ?
          null
          :
          todasTodas ?
            <button className="btn btn-primary btn-block mt-5"
              onClick={() => setTodasTodas(false)}
            >Sólo mostrar programas de mi interés <i className="fas fa-search-minus"></i></button>
            :
            <button className="btn btn-outline-primary btn-block mt-5"
              onClick={() => setTodasTodas(true)}
            >Ver todas sin importar categoría <i className="fas fa-search-plus"></i></button>
      }

    </PromocionContainer>
  );
};

export default Promociones;
