import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import history from '../../Navigation';

import { Contexto as DoctoresContext } from '../../context/DoctoresContext';
import { Contexto as EstudiosContext } from '../../context/EstudiosContext';
import { Contexto as UsersContext } from '../../context/UsersContext';

import { RestContainer } from '../../modules/RestContainer';
import EstudiosActivosContainer from '../../modules/EstudiosActivosContainer';

import { ToggleCampos } from '../../utils/displayFields';
import Input from '../../modules/DoctorPath/Input';

const DetalleDoctor = () => {
  const { state: { doctor, listaEstudiosDoc }, getOneDoctor, estudiosDoctorDisplay } = useContext(DoctoresContext);
  const { state: { listadoEstatus } } = useContext(EstudiosContext);
  const { updateUsuario, resetPassUsuario } = useContext(UsersContext);

  const [primerNombre, setPrimerNombre] = useState('');
  const [segundoNombre, setSegundoNombre] = useState('');
  const [apellidoPat, setApellidoPat] = useState('');
  const [apellidoMat, setApellidoMat] = useState('');
  const [inputTelefono, setInputTelefono] = useState('');
  const [inputCalleNumero, setInputCalleNumero] = useState('');
  const [inputCodigoPostal, setInputCodigoPostal] = useState('');
  const [inputColinia, setInputColinia] = useState('');
  const [inputAlcaldiaMun, setInputAlcaldiaMun] = useState('');
  const [inputEstado, setInputEstado] = useState('');
  const [referencias, setReferencias] = useState('');
  const [email, setEmail] = useState('');
  const [email2, setEmail2] = useState('');
  const [inputNombreHospital, setInputNombreHospital] = useState('');

  const [cedula, setCedula] = useState('');
  const [cedulaEsp, setCedulaEsp] = useState('');
  const [especialidad, setEspecialidad] = useState('');

  const [resetExitoso, setResetExitoso] = useState(false);

  const [error, setError] = useState(false);

  const location = useLocation();
  const ubicacionActual = location.pathname.split('/');

  useEffect(() => {
    getOneDoctor(ubicacionActual[ubicacionActual.length - 1]);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (doctor) {
      setPrimerNombre(doctor[1].primerNombre);
      setSegundoNombre(doctor[1].segundoNombre);
      setApellidoPat(doctor[1].apellidoPat);
      setApellidoMat(doctor[1].apellidoMat);
      setInputTelefono(doctor[1].telefono);
      setInputCalleNumero(doctor[1].direccion[0].calle);
      setInputCodigoPostal(doctor[1].direccion[0].cp);
      setInputColinia(doctor[1].direccion[0].colonia);
      setInputAlcaldiaMun(doctor[1].direccion[0].municipio);
      setInputEstado(doctor[1].direccion[0].estado);
      setInputNombreHospital(doctor[1].direccion[0].nombreHospital);
      setReferencias(doctor[1].referencias);
      setEmail(doctor[1].email);
      setEmail2(doctor[1].email2);
      setCedula(doctor[1].cedula);
      setCedulaEsp(doctor[1].cedulaEspecialidad);
      setEspecialidad(doctor[1].especialidad);

      estudiosDoctorDisplay(doctor[0]);
    }
    // eslint-disable-next-line
  }, [doctor]);

  if (!doctor) {
    return null;
  }

  const updateInfoUsuario = () => {
    if (primerNombre && apellidoPat && inputTelefono && inputCalleNumero && inputCodigoPostal && inputColinia && inputAlcaldiaMun && inputEstado && inputNombreHospital && referencias) {
      const infoUsuario = {
        primerNombre,
        segundoNombre,
        apellidoPat,
        apellidoMat,
        telefono: inputTelefono,
        email2,
        direccion: {
          0: {
            calle: inputCalleNumero,
            cp: inputCodigoPostal,
            colonia: inputColinia,
            municipio: inputAlcaldiaMun,
            estado: inputEstado,
            nombreHospital: inputNombreHospital,
            referencias
          }
        },
        cedula,
        cedulaEspecialidad: cedulaEsp
      };

      // userUid, infoUsuario
      updateUsuario(doctor[0], infoUsuario);
      ToggleCampos(3, true);
      setError(false);
    } else {
      setError(true);
    }
  };

  /**
   * Al hacer click en el botón del modal
   */
  const cambioContrasenia = () => {
    const response = resetPassUsuario(email);
    if (response) {
      window.Swal.fire({
        title: '¡Recuperación de contraseña enviada!',
        text: `Se ha enviado un correo de cambio de contraseña a ${email}`,
        icon: 'success',
        confirmButtonText: 'OK!'
      });
    }
    setResetExitoso(response);
  };

  return (
    <RestContainer>
      <div id="Seleccion" className="col-sm-12 col-md-12 col-lg-8">
        <h3 onClick={() => history.goBack()}><i className="fas fa-arrow-circle-left"></i> Doctor {`${doctor[1].primerNombre} ${doctor[1].segundoNombre} ${doctor[1].apellidoPat} ${doctor[1].apellidoMat}`}</h3>
        <div className="card mt-3">
          <div className="card-header">
            <h4>Datos generales</h4>
          </div>
          <div className="card-body">
            <div className="form-row">
              <Input id="primerNombre" label="Primer nombre *" placeholder="Nombre del doctor"
                claseExtra="alert-warning"
                value={primerNombre}
                onChange={setPrimerNombre}
              />
              <Input id="segundoNombre" placeholder="Segundo nombre"
                value={segundoNombre}
                onChange={setSegundoNombre}
              />
              <Input id="apellidoPat" label="Apellido paterno *" placeholder="Apellido paterno"
                claseExtra="alert-warning"
                value={apellidoPat}
                onChange={setApellidoPat}
              />
              <Input id="apellidoMat" placeholder="Apellido materno"
                value={apellidoMat}
                onChange={setApellidoMat}
              />
              <Input id="telefono" label="Teléfono *" placeholder="Teléfono" type="number"
                claseExtra="alert-warning"
                value={inputTelefono}
                onChange={setInputTelefono}
              />

              <Input id="calleNum" label="Calle y número *" placeholder="Calle ejemplo #4566"
                claseExtra="alert-warning"
                value={inputCalleNumero}
                onChange={setInputCalleNumero}
              />
              <Input id="codigoP" label="Código Postal *" placeholder="ZIP"
                claseExtra="alert-warning"
                value={inputCodigoPostal}
                onChange={setInputCodigoPostal}
              />
              <Input id="colonia" label="Colonia *" placeholder="Ingresar la colonia"
                claseExtra="alert-warning"
                value={inputColinia}
                onChange={setInputColinia}
              />
              <Input id="direccionDoctor" label="Alcaldía/Municipio *" placeholder="Ingresar alcaldía o municipio"
                claseExtra="alert-warning"
                value={inputAlcaldiaMun}
                onChange={setInputAlcaldiaMun}
              />
              <div className="col-6 mb-3">
                <label htmlFor="inputEstado">Estado *</label>
                <select id="inputEstado" className="form-control deshabilitado3" name="inputEstado"
                  onChange={e => setInputEstado(e.target.value)}
                  value={inputEstado}
                  disabled
                >
                  <option value=''>Seleccione su estado</option>
                  <option value='Aguascalientes'>Aguascalientes</option>
                  <option value='Baja California'>Baja California</option>
                  <option value='Baja California Sur'>Baja California Sur</option>
                  <option value='Campeche'>Campeche</option>
                  <option value='CDMX'>CDMX</option>
                  <option value='Chiapas'>Chiapas</option>
                  <option value='Chihuahua'>Chihuahua</option>
                  <option value='Coahuila'>Coahuila</option>
                  <option value='Colima'>Colima</option>
                  <option value='Durango'>Durango</option>
                  <option value='Estado de México'>Estado de México</option>
                  <option value='Guanajuato'>Guanajuato</option>
                  <option value='Guerrero'>Guerrero</option>
                  <option value='Hidalgo'>Hidalgo</option>
                  <option value='Jalisco'>Jalisco</option>
                  <option value='Michoacán'>Michoacán</option>
                  <option value='Morelos'>Morelos</option>
                  <option value='Nayarit'>Nayarit</option>
                  <option value='Nuevo León'>Nuevo León</option>
                  <option value='Oaxaca'>Oaxaca</option>
                  <option value='Puebla'>Puebla</option>
                  <option value='Querétaro'>Querétaro</option>
                  <option value='Quintana Roo'>Quintana Roo</option>
                  <option value='San Luis Potosí'>San Luis Potosí</option>
                  <option value='Sinaloa'>Sinaloa</option>
                  <option value='Sonora'>Sonora</option>
                  <option value='Tabasco'>Tabasco</option>
                  <option value='Tamaulipas'>Tamaulipas</option>
                  <option value='Tlaxcala'>Tlaxcala</option>
                  <option value='Veracruz'>Veracruz</option>
                  <option value='Yucatán'>Yucatán</option>
                  <option value='Zacatecas'>Zacatecas</option>
                </select>
              </div>
              <Input id="hospital" label="Nombre del hospital *" placeholder="Hospital"
                claseExtra="alert-warning"
                value={inputNombreHospital}
                onChange={setInputNombreHospital}
              />
              <Input id="referencias" label="Referencias *" placeholder="Referencias"
                claseExtra="alert-warning"
                value={referencias}
                onChange={setReferencias}
              />
              <Input id="CorreoAlternativo" label="Correo Alternativo" placeholder="Segundo correo, para envío alterno de correos"
                value={email2}
                onChange={setEmail2}
              />
              <Input id="Cedula" label="Cédula" placeholder="Ingresar la cédula"
                value={cedula}
                onChange={setCedula}
              />
              <Input id="CedulaEsp" label="Cédula especialidad" placeholder="Ingresar la cédula de la especialidad"
                value={cedulaEsp}
                onChange={setCedulaEsp}
              />
              <div className="col-6 mb-3">
                <label>Especialidad</label>
                <p className="form-control mr-sm-2 deshabilitado3">{especialidad}</p>
              </div>

              <div className="col-6 mb-3">
                <label htmlFor="hospital">Enviar el correo de recuperación de contraseña:</label>
                <br />
                <small>Esto es al correo principal: <b>{email}</b></small>
                {
                  resetExitoso ?
                    <p>Se ha enviado un correo de cambio de contraseña al email ${email}</p>
                    :
                    <p>
                      Click <button type="button" className="btn btnTypeAnchor" onClick={cambioContrasenia}>haciendo click aquí.</button>
                    </p>
                }
              </div>

              {/* TO DO: Esperar a tener el nuevo estilo de LUIS */}
              {/* <div className="col-6 mb-3">
                <label htmlFor="especialidad">Intereses</label>
                <select className="form-control mr-sm-2 deshabilitado3" name="especialidad" disabled>
                  <option value="">Seleccione sus Intereses</option>
                  {
                    doctor[1].intereses.map(interes => {
                      return <option key={interes} value={interes}>{interes}</option>;
                    })
                  }
                </select>
              </div> */}
              {
                error ?
                  <div className="col-12 mb-3 text-center">
                    <h5 className="anuncioNegativo">Falta un campo obligatorio</h5>
                  </div>
                  : null
              }
              <div className="col-12 mb-3 text-center">
                <button className="btn btn-secondary btn-md mx-2" type="submit" onClick={() => ToggleCampos(3, false)}>Editar <i className="fi-xnsuxl-edit-solid"></i></button>
                <button className="btn btn-primary btn-md deshabilitado3 mx-2" type="submit" onClick={() => updateInfoUsuario()}>Guardar <i className="fi-xnsuxl-lock-solid"></i></button>
              </div>
            </div>
          </div>
        </div>
        <div className="card mt-3">
          <div className="card-header">
            <h4>Últimos registros</h4>
          </div>
          <div className="card-body">
            <EstudiosActivosContainer
              listaEstudiosDoc={listaEstudiosDoc}
              limit={false}
              listadoEstatus={listadoEstatus}
            />
          </div>
        </div>
      </div>
    </RestContainer>
  );
};

export default DetalleDoctor;