import React, { useState, useEffect, useRef } from 'react';
import { compararDosFechas } from '../../utils/Fecha';

const AdminProgForm = ({ titulo, titulosEstudios, getDetalleEstudio, infoEstudioPart, infoPrograPart, enviarPrograma, progSelect, eliminarPrograDuplicado, pharmas }) => {
  const form = useRef(null);

  const [nombreProg, setNombreProg] = useState('');
  const [estudioSelect, setEstudioSelect] = useState('');
  const [pharmaSelect, setPharmaSelect] = useState('');

  const [categoriaProg, setCategoriaProg] = useState('');
  const [costoProg, setCostoProg] = useState(0);
  const [fechaFin, setFechaFin] = useState('');
  const [fechaInicio, setFechaInicio] = useState('');
  const [descGeneral, setDescGeneral] = useState('');

  const [muestraProg, setMuestraProg] = useState('');
  const [perfilPaciente, setPerfilPaciente] = useState('');
  const [pruebaProg, setPruebaProg] = useState('');
  const [tiempoEntrega, setTiempoEntrega] = useState(0);

  const [metodologia, setMetodologia] = useState('');

  const [fechasMal, setFechasMal] = useState(false);
  const [faltanCampos, setFaltanCampos] = useState(false);
  const [formEnviado, setFormEnviado] = useState(false);

  useEffect(() => {
    if (estudioSelect) {
      getDetalleEstudio(estudioSelect);
    }
    // eslint-disable-next-line
  }, [estudioSelect]);

  useEffect(() => {
    if (infoEstudioPart) {
      setCategoriaProg(infoEstudioPart['Categoría']);
    }
  }, [infoEstudioPart]);

  useEffect(() => {
    if (fechaInicio && fechaFin) {
      if (!compararDosFechas(fechaInicio, fechaFin)) {
        setFechasMal(true);
      } else {
        setFechasMal(false);
      }
    }
  }, [fechaInicio, fechaFin]);

  const enviarNuevoPrograma = () => {
    if (!compararDosFechas(fechaInicio, fechaFin)) {
      return;
    }
    if (nombreProg && categoriaProg && descGeneral && pharmaSelect && estudioSelect && fechaFin && fechaInicio && muestraProg && perfilPaciente && pruebaProg && tiempoEntrega > 0) {
      setFechasMal(false);
      setFaltanCampos(false);
      
      const nuevoPrograma = {
        'Categoría': categoriaProg,
        costo: Number(costoProg),
        desc_general: descGeneral,
        empresaPharma: pharmaSelect,
        estudio: estudioSelect,
        fecha_promo_fin: fechaFin,
        fecha_promo_inicio: fechaInicio,
        muestra: muestraProg,
        perfil_paciente: perfilPaciente,
        prueba: pruebaProg,
        tiempo_entrega_dias: Number(tiempoEntrega),
        'Metodología': metodologia
      };

      enviarPrograma(nombreProg, nuevoPrograma);
      setFormEnviado(true);
      // Si se está editando un programa y se cambió el nombre
      // Eliminar el viejo, agregar el nuevo
      if (progSelect) {
        if (progSelect !== nombreProg) {
          eliminarPrograDuplicado(progSelect);
        }
      }
    } else {
      setFaltanCampos(true);
      setFormEnviado(false);
      window.Swal.fire({
        title: '¡Faltan algunos campos!',
        text: 'Revise bien el formularulario para que esté listo para enviarse.',
        icon: 'warning',
        confirmButtonText: 'OK!'
      });
    }
  };

  /**
   * Autocompletar info en caso de estar EDITANDO.
   */
  useEffect(() => {
    if (infoPrograPart) {
      setNombreProg(progSelect);
      setEstudioSelect(infoPrograPart.estudio);
      setCategoriaProg(infoPrograPart['Categoría']);
      setPharmaSelect(infoPrograPart.empresaPharma);
      setCostoProg(infoPrograPart.costo);
      setDescGeneral(infoPrograPart.desc_general);
      setFechaFin(infoPrograPart.fecha_promo_fin);
      setFechaInicio(infoPrograPart.fecha_promo_inicio);
      setMuestraProg(infoPrograPart.muestra);
      setPerfilPaciente(infoPrograPart.perfil_paciente);
      setPruebaProg(infoPrograPart.prueba);
      setTiempoEntrega(infoPrograPart.tiempo_entrega_dias);
      setMetodologia(infoPrograPart['Metodología']);
    }
  }, [infoPrograPart, progSelect]);

  /**
   * Limpiar Form después de enviar
   */
  useEffect(() => {
    if (formEnviado) {
      form.current.reset();
      setNombreProg('');
      setEstudioSelect('');
      setCategoriaProg('');
      setPharmaSelect('');
      setCostoProg(0);
      setDescGeneral('');
      setFechaFin('');
      setFechaInicio('');
      setMuestraProg('');
      setPerfilPaciente('');
      setPruebaProg('');
      setTiempoEntrega(0);
      setFormEnviado(false);
      setMetodologia('');
    }
  }, [formEnviado]);

  return (
    <div className="card mt-3">
      <div className="card-header">
        <h5><i className="fi-cwsuxl-plus-solid"></i> {titulo}</h5>
      </div>
      <div className="card-body">
        <form ref={form}>
          <div className="form-row">
            <div className="col-4 mb-3">
              <label htmlFor="NombrePrograma">Nombre del Programa</label>
              <input className="form-control mr-sm-2" type="text" placeholder="Nombre del Programa" name="NombrePrograma"
                onChange={e => setNombreProg(e.target.value)}
                value={nombreProg}
              />
            </div>
            <div className="col-4 mb-3">
              <label htmlFor="especialidad">Seleccione Especialidad</label>
              <select className="form-control mr-sm-2" name="especialidad"
                onChange={e => setEstudioSelect(e.target.value)}
                value={estudioSelect}
              >
                <option value="">Seleccione una especialidad</option>
                {
                  titulosEstudios.map(titulo => {
                    return <option key={titulo} value={titulo}>{titulo}</option>;
                  })
                }
              </select>
            </div>
            <div className="col-4 mb-3">
              <label htmlFor="especialidad">Indique si el programa es parte de una pharma</label>
              <select className="form-control mr-sm-2" name="especialidad"
                onChange={e => setPharmaSelect(e.target.value)}
                value={pharmaSelect}
              >
                <option value="">Seleccione una pharma</option>
                {
                  pharmas.map(titulo => {
                    return <option key={titulo} value={titulo}>{titulo}</option>;
                  })
                }
              </select>
            </div>

            <div className="col-4 mb-3">
              <label htmlFor="PrecioDescuento">Precio del Programa</label>
              <input className="form-control mr-sm-2" type="number" placeholder="Precio"
                onChange={e => setCostoProg(e.target.value)}
                value={costoProg}
              />
            </div>
            <div className="col-4 mb-3">
              <label htmlFor="fechaInicio">Fecha de inicio</label>
              <input className="form-control mr-sm-2" type="date"
                onChange={e => setFechaInicio(e.target.value)}
                value={fechaInicio}
              />
            </div>
            <div className="col-4 mb-3">
              <label htmlFor="fechaTermino">Fecha de termino</label>
              <input className="form-control mr-sm-2" type="date"
                onChange={e => setFechaFin(e.target.value)}
                value={fechaFin}
              />
            </div>
            <div className="col-12 mb-3">
              <label htmlFor="Descripcion">Descripción general del Programa</label>
              <textarea className="form-control mr-sm-2" cols="30" rows="10"
                onChange={e => setDescGeneral(e.target.value)}
                value={descGeneral}
              ></textarea>
            </div>
            <div className="col-6 mb-3">
              <label htmlFor="NombrePrograma">Perfil del paciente</label>
              <input className="form-control mr-sm-2" type="text" placeholder="Perfil del paciente"
                onChange={e => setPerfilPaciente(e.target.value)}
                value={perfilPaciente}
              />
            </div>
            <div className="col-6 mb-3">
              <label htmlFor="NombrePrograma">Detalles de la prueba</label>
              <input className="form-control mr-sm-2" type="text" placeholder="Info de la prueba"
                onChange={e => setPruebaProg(e.target.value)}
                value={pruebaProg}
              />
            </div>
            <div className="col-6 mb-3">
              <label htmlFor="NombrePrograma">Detalles de la muestra</label>
              <input className="form-control mr-sm-2" type="text" placeholder="Info de la muestra"
                onChange={e => setMuestraProg(e.target.value)}
                value={muestraProg}
              />
            </div>
            <div className="col-6 mb-3">
              <label htmlFor="NombrePrograma">Tiempo de entrega (días hábiles)</label>
              <input className="form-control mr-sm-2" type="number" placeholder="Tiempo de entrega en días"
                onChange={e => setTiempoEntrega(e.target.value)}
                value={tiempoEntrega}
              />
            </div>
            <div className="col-6 mb-3">
              <label htmlFor="NombrePrograma">Metodología (opcional)</label>
              <input className="form-control mr-sm-2" type="text" placeholder="Metodología del programa"
                onChange={e => setMetodologia(e.target.value)}
                value={metodologia}
              />
            </div>
            {
              formEnviado ?
                <div className="col-12 mb-3 text-center">
                  <h3 className="anuncio">¡Registro exitoso!</h3>
                </div>
                : null
            }
            {
              faltanCampos ?
                <div className="col-12 mb-3 text-center">
                  <h4 className="anuncioNegativo">¡Se deben llenar todos los campos!</h4>
                </div>
                : null
            }
            {
              fechasMal ?
                <div className="col-12 mb-3 text-center">
                  <h4 className="anuncioNegativo">¡Revisa tus fechas!</h4>
                </div>
                : null
            }
            <div className="col-12 mb-3 text-center">
              <button className="btn btn-primary btn-md" type="button"
                onClick={enviarNuevoPrograma}
              >
                Enviar <i className="fi-cwsuxl-plus-solid"></i>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AdminProgForm;