export const formatoFecha = (dia, mes, anio) => {
  let newDia = dia;
  let newMes = mes + 1;
  if (newDia < 10) {
    newDia = `0${newDia}`;
  }
  if (newMes < 10) {
    newMes = `0${newMes}`;
  }

  return { newDia, newMes, anio };
};

export const formatoFechaBDD = (date) => {
  const { newDia, newMes, anio } = formatoFecha(date.getDate(), date.getMonth(), date.getFullYear());

  return `${anio}-${newMes}-${newDia}`;
};

/**
 * Transformar la fecha en algo legible como 31 de Diciembre de 2019
 * @param {string} fechaBDD - Como viene el dato desde firebase
 */
export const formatoFechaHumano = fechaBDD => {
  const partesFecha = fechaBDD.split('-');
  const mes = mesHumanoEsp(partesFecha[1]);
  return `${partesFecha[2]} de ${mes} de ${partesFecha[0]}`;
};

export const mesHumanoEsp = mesNumeroString => {
  let mes = 'enero';
  switch (mesNumeroString) {
    case 2:
    case '2':
    case '02':
      mes = 'febrero';
      break;
    case 3:
    case '3':
    case '03':
      mes = 'marzo';
      break;
    case 4:
    case '4':
    case '04':
      mes = 'abril';
      break;
    case 5:
    case '5':
    case '05':
      mes = 'mayo';
      break;
    case 6:
    case '6':
    case '06':
      mes = 'junio';
      break;
    case 7:
    case '7':
    case '07':
      mes = 'julio';
      break;
    case 8:
    case '8':
    case '08':
      mes = 'agosto';
      break;
    case 9:
    case '9':
    case '09':
      mes = 'septiembre';
      break;
    case 10:
    case '010':
    case '10':
      mes = 'octubre';
      break;
    case 11:
    case '011':
    case '11':
      mes = 'noviembre';
      break;
    case 12:
    case '012':
    case '12':
      mes = 'diciembre';
      break;
    default:
      break;
  }
  return mes;
};
export const mesHumanoEsp2 = mesNumeroString => {
  const mes = mesHumanoEsp(mesNumeroString);
  return mes;
};

export const mesHumanoANumero = mesString => {
  let mes = 1;
  switch (mesString) {
    case 'febrero':
      mes = 2;
      break;
    case 'marzo':
      mes = 3;
      break;
    case 'abril':
      mes = 4;
      break;
    case 'mayo':
      mes = 5;
      break;
    case 'junio':
      mes = 6;
      break;
    case 'julio':
      mes = 7;
      break;
    case 'agosto':
      mes = 8;
      break;
    case 'septiembre':
      mes = 9;
      break;
    case 'octubre':
      mes = 10;
      break;
    case 'noviembre':
      mes = 11;
      break;
    case 'diciembre':
      mes = 12;
      break;
    default:
      mes = 1;
      break;
  }
  return mes;
};

export const inputDateToDate = valorInput => {
  // return `${valorInput}T00:00`;
  return `${valorInput} GMT-5`;
};

export const formatoFechaInput = date => {
  const { newDia, newMes, anio } = formatoFecha(date.getDate(), date.getMonth(), date.getFullYear());
  return `${newDia}/${newMes}/${anio}`;
};

export const formatoFechaInputHumano = date => {
  const { newDia, newMes, anio } = formatoFecha(date.getDate(), date.getMonth(), date.getFullYear());
  if (isNaN(newDia)) {
    return;
  }
  const mes = mesHumanoEsp(newMes);

  return `${newDia}/${mes}/${anio}`;
};
export const formatoFechaInputHumanoDos = fechaInputDate => {
  const partesFecha = fechaInputDate.split('-');
  const mes = mesHumanoEsp(partesFecha[1]);
  return `${partesFecha[2]}/${mes}/${partesFecha[0]}`;
};

export const formatoFechaDeBddAHumano = date => {
  const fecha = date.split('-');
  return `${fecha[2]}/${fecha[1]}/${fecha[0]}`;
};

export const formatoFechaDeHumanoABDD = date => {
  const fecha = date.split('/');
  return `${fecha[2]}-${fecha[1]}-${fecha[0]}`;
};

export const formatoFechaFolio = date => {
  const { newDia, newMes, anio } = formatoFecha(date.getDate(), date.getMonth(), date.getFullYear());
  const newAnio = `${anio}`.slice(2);
  return `${newDia}${newMes}${newAnio}`
};
export const formatoFechaFolioEnd = date => {
  const tiempo = date.getTime().toString();
  return tiempo.slice(10);
};

export const compararDosFechasEstricto = (fechaMenor, fechaMayor) => {
  if (fechaMenor < fechaMayor) {
    return true;
  }
  return false;
};
export const compararDosFechas = (fechaMenor, fechaMayor) => {
  if (fechaMenor <= fechaMayor) {
    return true;
  }
  return false;
};

export const compararFechas = (fechaUno, fechaBDD, fechaDos) => {
  if (fechaUno <= fechaBDD) {
    if (fechaBDD <= fechaDos) {
      return true;
    }
  }
  return false;
};

export const sumarDias = (fecha, numDias) => {
  const date = new Date(`${fecha}T00:00`);
  date.setDate(date.getDate() + numDias);

  return formatoFechaInput(date);
};

export const setDateSafariMode = fecha => {
  const formatoSafari = fecha.replace(/[^0-9-]+/g, "");
  return formatoSafari;
};

export const getAge = fecha => {
  if (!fecha) return '';
  return '';
};