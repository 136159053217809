import React, { useState } from 'react';
import { storageRef } from '../firebase/Firebase';
import { formatoFechaDeBddAHumano } from '../utils/Fecha';

const EstudioURL = ({ solicitudActiva }) => {
  const [url, setUrl] = useState('');
  const [fecha, setFecha] = useState('');

  if (solicitudActiva.resultado_emitido && !solicitudActiva.resultado_emitido_again_subido) {
    const refArchivo = storageRef.ref(`${solicitudActiva.destino}/${solicitudActiva.folio}/${solicitudActiva.resultado_emitido.filename}`);
    refArchivo.getDownloadURL()
      .then(url => {
        setUrl(url);
        setFecha(formatoFechaDeBddAHumano(solicitudActiva.resultado_emitido.fecha));
      }).catch(e => {
        console.log('Error bajando primer resultado emitido', e);
      });
  } else if (solicitudActiva.resultado_emitido_again_subido) {
    const refArchivo = storageRef.ref(`${solicitudActiva.destino}/${solicitudActiva.folio}/${solicitudActiva.resultado_emitido_again_subido.filename}`);
    refArchivo.getDownloadURL()
      .then(url => {
        setUrl(url);
        setFecha(formatoFechaDeBddAHumano(solicitudActiva.resultado_emitido_again_subido.fecha));
      }).catch(e => {
        console.log('Error bajando segundo resultado emitido', e);
      });
  }

  return (
    <td>{solicitudActiva.etapa === 'Regreso Muestra' || solicitudActiva.etapa === 'Resultado Listo' ? <a href={url} className="btn btnTypeLink" target="blank" download={`Resultado de ${solicitudActiva.folio}`}>{fecha}</a> : 'Falta resultado'}</td>
  );
};

export default EstudioURL;