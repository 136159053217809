import React from 'react';
import AvisosContainer from '../../modules/AvisosPath/AvisosContainer';

const Terminos = () => {
  return (
    <AvisosContainer>
      <h3 className="textoFormal mb-2">Términos y Condiciones del Servicio</h3>
      <p className="text-justify mb-2">
        Bienvenido a nuestra plataforma digital. Aquí están descritos los términos y condiciones para el uso del servicio al que pueden acceder, pero no estar limitada a la dirección de internet www.diagnostiqueatiempo.com. En estos términos y condiciones, cuando nos referimos a “Plataforma DAT” nos referimos a las páginas operadas por o en nombre de Dx A Tiempo SAS de CV (de ahora en adelante DAT), sin importar a como acceda a la plataforma. Sin embargo, al acceder y usar la Plataforma DAT, usted acepta estos términos y condiciones.
      </p>

      <h4 className="textoFormal text-justify mb-2">Registro</h4>
      <p className="text-justify mb-2">
        Puede ser que usted ingrese a áreas de la Plataforma DAT que requieran registro para tener un perfil  de usuario y crear una cuenta con nosotros. Usted acepta ser responsable para mantener confidencialidad en sus contraseñas u otros identificadores de su cuenta elegidos por usted.
        <br />
        Al registrarse en la plataforma DAT usted acepta que:
      </p>
      <ul className="text-justify mb-2 dashed">
        <li>Su cuenta y su contraseña son personales y no serán usadas por otra persona para acceder al sitio.</li>
        <li>No brindará apoyo a otros usuarios no registrados para ganar acceso a otras áreas de la plataforma DAT.</li>
        <li>No creará cuentas de usuarios con el propósito de abusar de la funcionalidad del sitio o de otros usuarios ni se hará pasar por otro usuario.</li>
        <li>Accede a notificarnos inmediatamente si toma consciencia del uso de su contraseña o cuenta por otros usuarios.</li>
        <li>Es un profesional de la salud capacitado para solicitar, analizar e interpretar estudios de diagnóstico, pronósticos o decidir tratamiento.</li>
        <li>Tiene un título, certificado o cédula que lo faculta para ejercer en el territorio nacional.</li>
        <li>Tiene al menos 18 años de edad.</li>
        <li>Está usando su identidad real.</li>
        <li>Ha proporcionado solo i formación real, certera y actual acerca de usted.</li>
        <li>Mantendrá actualizada esta información.</li>
        <li>Obtendrá acceso a través de su registro solo para los usos permitidos por tal registro.</li>
      </ul>

      <h4 className="textoFormal text-justify mb-2">Sobre el consejo médico</h4>
      <p className="text-justify mb-2">
        Usted entiende y reconoce ser responsable de sus solicitudes de pruebas diagnósticas, pronósticas o tratamientos ofrecidos a usted o a sus pacientes. Todo el contenido de nuestros sitios, incluyendo texto, tratamientos, dosis, resultados, gráficos, perfiles, fotografías, imágenes son para propósitos informativos únicamente y no pretenden sustituir el criterio médico, decisiones terapéuticas o diagnósticas.
      </p>
      <p className="text-justify mb-2">
        Nuestros sitios pueden contener información relacionada a ruebas diagnósticas, estudios de investigación, ensayos clínicos y tratamientos. Esta información no constituye una promoción o apoyo de dichas intervenciones por DAT. DAT no se responsabiliza por informar a los usuarios sobre tales estudios, ensayos, diagnósticos o tratamientos. DAT tampoco asume responsabilidad por conectar al usuario con el proveedor de un estudio de investigación, ensayo clínico, diagnóstico o tratamiento y tampoco asume responsabilidad para asegurar la inclusión de un participante o usuario en cualquier estudio de investigación, ensayo clínico, programa diagnóstico o tratamiento. Es responsabilidad de cada usuario contactar a los investigadores relevantes o emisores directos de la información.
      </p>

      <h4 className="textoFormal text-justify mb-2">Terminación del registro</h4>
      <p className="text-justify mb-2">
        Si usted no desea continuar con su cuenta registrada, puede finalizar su cuenta al enviar un correo electrónico a <a href="mailto:contacto@diagnostiqueatiempo.com">contacto@diagnostiqueatiempo.com</a>. Si usted ya no acepta estos términos y condiciones o cualquier modificación futura de estos términos y condiciones, usted debe descontinuar el uso de la plataforma. El uso continuado de la Plataforma DAT indica que continúa aceptando estos términos y condiciones.
      </p>
      <p className="text-justify mb-2">
        Si por cualquier razón encontramos que no ha cumplido con estos términos y condiciones, podemos, a nuestra discreción, cancelar su acceso a áreas de la Plataforma DAT que requieren registro sin mediar notificación. Podemos terminar con su cuenta registrada, a discreción nuestra y será notificado por correo electrónico a la dirección registrada, estableciendo que el acuerdo ha terminado.
      </p>

      <h4 className="textoFormal text-justify mb-2">Uso del contenido de la Plataforma DAT</h4>
      <p className="text-justify mb-2">
        El uso de la Plataforma DAT es para su uso personal y uso no comercial únicamente. Usted acepta que, entre Dx A Tiempo SAS de CV, la Plataforma DAT y usted, con la excepción del contenido del usuario y publicidad de terceros, DAT es el único dueño de todo el contenido del sitio, incluyendo, sin limitarse a, todos los registros de marca aplicables, patentes, marcas registradas, acuerdos comerciales, nombres comerciales, logos y otra propiedad intelectual como texto, imagen, video, gráficos, logos, audio, video y otro material que aparezca en el sitio, es decir el contenido de la Plataforma DAT. La Plataforma DAT y el contenido de la plataforma DAT están protegidos por las leyes de derechos de autor y protegidas globalmente por las leyes aplicables de derechos de autor y propiedad intelectual.
      </p>
      <p className="text-justify mb-2">
        Donde esté especificado, usted puede descargar contenido para su uso personal y no comercial únicamente, en acuerdo que siempre mantendrá reconocerá la autoría, derechos de autor, aviso de registro de marca y mantendrá la privacidad de datos sensibles y no sensibles de acuerdo a la ley aplicable.
      </p>
      <p className="text-justify mb-2">
        Si usted desea usar contenido diferente al permitido en estos términos y condiciones, por favor escríbanos a <a href="mailto:contacto@diagnostiqueatiempo.com">contacto@diagnostiqueatiempo.com</a>.
      </p>

      <h4 className="textoFormal text-justify mb-2">Publicidad o información de terceros en la Plataforma DAT</h4>
      <p className="text-justify mb-2">
        Usted podrá ver material publicitario de terceras partes en la Plataforma DAT. Cada anunciante individual es responsable de su contenido.
        <br />
        Si existe un enlace dentro de la Plataforma DAT que dirija a otros sitios o servicios que no son parte de DAT, estos sitios tienen sus propias políticas de privacidad y términos de uso y no son responsabilidad de DAT, aconsejamos leer la política de privacidad y términos de servicio de dichos sitios.
      </p>

      <h4 className="textoFormal text-justify mb-2">Contenido del usuario</h4>
      <p className="text-justify mb-2">
        Los usuarios registrados tienen permitido subir contenido a la Plataforma DAT para los propósitos indicados. Cuando usted cargue contenido a la Plataforma DAT, usted consiente que ha creado o compartido ese contenido con nosotros y ha recibido el permiso o consentimiento para cargar dicho contenido o está autorizado por el dueño de esa información para compartirla con DAT y cargarla en el sitio.
      </p>
      <ul className="text-justify mb-2 dashed">
        <li>La información proporcionada por el usuario, que permita identificar a un paciente será proporcionada únicamente si usted tiene los consentimientos y/o autorizaciones necesarias para ello.</li>
        <li>Solo proporcionará información que cumple con la ley aplicable, no compartirá información que sea abusiva, difamatoria, amenazadora o inapropiada.</li>
      </ul>
      <p className="text-justify mb-2">
        Al compartir dicha información, usted afirma que la información proporcionada a DAT por cualquier vía es información veraz y el responsable de dicha información es el emisor de la información o documentación compartida. Usted asegura que el contenido no infringe patentes, registros, secretos comerciales, propiedad intelectual o privacidad de cualquier tercero o individuo. También se obliga a no compartir contenido que transmita software de virus o cualquier otro código de computadora, archivos o programas diseñados para interrumpir, destruir o limitar la funcionalidad de la Plataforma DAT o cualquier equipo físico “software”, “hardware” o de telecomunicaciones.
      </p>
      <p className="text-justify mb-2">
        Usted o el dueño del contenido cargado mantienen propiedad sobre el mismo, pero al compartir este contenido con nosotros, nos ha proporcionado un permiso incondicional, irrevocable, no exclusivo, libre de regalías, transferible, con licencia indefinida a nivel global para usarla, transferirla, publicarla o transmitirla y para autorizar a terceros a usarla, publicarla o transmitir dicho contenido en cualquier formato o plataforma.
      </p>

      <h4 className="textoFormal text-justify mb-2">Protección de Datos</h4>
      <p className="text-justify mb-2">
        Para consultar que información colectamos y como la usamos, por favor visite nuestro Aviso de Privacidad.
      </p>

      <h4 className="textoFormal text-justify mb-2">Cambios a estos términos y condiciones de uso</h4>
      <p className="text-justify mb-2">
        Es probable que cambiemos estos términos y condiciones de uso cada cierto tiempo a discreción de DAT y nos reservamos el derecho de hacerlo sin su consentimiento. Por favor asegúrese de revisar estos términos y condiciones con regularidad ya que consideramos su aceptación si continúa usando la Plataforma DAT una vez se han actualizado los términos y condiciones.
      </p>

      <h4 className="textoFormal text-justify mb-2">Indemnizaciones</h4>
      <p className="text-justify mb-2">
        Usted acepta defender, indemnizar y sacar en paz y a salvo a DAT, a sus afiliados y a cada uno de sus respectivos oficiales, directores y empleados de cualquier reclamo, costo y gastos, incluyendo pero sin limitarse a, honorarios de abogados y gastos legales, por la presentación de una violación por usted u otro usuario de su cuenta de los términos y condiciones o la política de privacidad o una violación de sus obligaciones de acuerdo a estos términos y condiciones.
      </p>
    </AvisosContainer>
  );
};

export default Terminos;