import React, { useState, useEffect, useRef } from 'react';

const AdminEstudioForm = ({ titulo, titulosCategorias, titulosEspecialidades, enviarEstudio, infoEstudioPart, estudioSelect, eliminarEstudioDuplicado }) => {
  const form = useRef(null);
  const [nombreEstudio, setNombreEstudio] = useState('');
  const [categoriaEstudio, setCategoriaEstudio] = useState('');
  const [refrigeracion, setRefrigeracion] = useState('');
  const [sangre, setSangre] = useState('');
  const [temperatura, setTemperatura] = useState('');
  const [costo, setCosto] = useState(0);
  const [especialidadesEstudio, setEspecialidadesEstudio] = useState([]);
  const [metodoEstudio, setMetodoEstudio] = useState('');
  const [claveEstudio, setClaveEstudio] = useState('');

  const [faltanCampos, setFaltanCampos] = useState(false);
  const [formEnviado, setFormEnviado] = useState(false);

  const handleMultipleSelect = options => {
    const selectedOptions = Array.from(options).filter(option => option.selected);
    const selectedValues = selectedOptions.map(selection => {
      return selection.value;
    });
    setEspecialidadesEstudio(selectedValues)
  };

  const enviarNuevoEstudio = () => {
    if (nombreEstudio && categoriaEstudio && claveEstudio && refrigeracion && sangre && temperatura && costo && especialidadesEstudio.length > 0 && metodoEstudio) {
      setFaltanCampos(false);

      const estudioNuevo = {
        'Categoría': categoriaEstudio,
        'Clave del estudio': claveEstudio,
        'Condiciones de la muestra': {
          'Refrigeración': refrigeracion,
          Sangre: sangre,
          Temperatura: temperatura
        },
        Costo: Number(costo),
        Especialidad: especialidadesEstudio,
        'Metodología': metodoEstudio
      };
      enviarEstudio(nombreEstudio, estudioNuevo);
      setFormEnviado(true);

      // Si se está editando un estudio y se cambió el nombre
      // Eliminar el viejo, agregar el nuevo
      if (estudioSelect) {
        if (estudioSelect !== nombreEstudio) {
          eliminarEstudioDuplicado(estudioSelect);
        }
      }
    } else {
      setFaltanCampos(true);
      setFormEnviado(false);
      window.Swal.fire({
        title: '¡Faltan algunos campos!',
        text: 'Revise bien el formularulario para que esté listo para enviarse.',
        icon: 'warning',
        confirmButtonText: 'OK!'
      });
    }
  };

  useEffect(() => {
    if (infoEstudioPart) {
      setNombreEstudio(estudioSelect);
      setCategoriaEstudio(infoEstudioPart['Categoría']);
      setRefrigeracion(infoEstudioPart['Condiciones de la muestra']['Refrigeración']);
      setSangre(infoEstudioPart['Condiciones de la muestra'].Sangre);
      setTemperatura(infoEstudioPart['Condiciones de la muestra'].Temperatura);
      setCosto(infoEstudioPart.Costo);
      setEspecialidadesEstudio(infoEstudioPart.Especialidad);
      setMetodoEstudio(infoEstudioPart['Metodología']);
      setClaveEstudio(infoEstudioPart['Clave del estudio']);
    }
  }, [infoEstudioPart, estudioSelect]);

  useEffect(() => {
    if (formEnviado) {
      form.current.reset();
      setNombreEstudio('');
      setCategoriaEstudio('');
      setRefrigeracion('');
      setSangre('');
      setTemperatura('');
      setCosto(0);
      setEspecialidadesEstudio([]);
      setMetodoEstudio('');
      setClaveEstudio('');
      setFormEnviado(false);
    }
  }, [formEnviado]);

  return (
    <div className="card mt-3">
      <div className="card-header">
        <h5><i className="fi-cwsuxl-plus-solid"></i> {titulo}</h5>
      </div>
      <div className="card-body">
        <form ref={form}>
          <div className="form-row">
            <div className="col-6 mb-3">
              <label htmlFor="nombreEstudio">Nombre del Estudio</label>
              <input className="form-control mr-sm-2" type="text" name="nombreEstudio" placeholder="Ingresar el nombre"
                onChange={e => setNombreEstudio(e.target.value)}
                value={nombreEstudio}
              />
            </div>
            <div className="col-6 mb-3">
              <label htmlFor="categoria">Seleccione la categoría</label>
              <select className="form-control mr-sm-2"
                onChange={e => setCategoriaEstudio(e.target.value)}
                value={categoriaEstudio}
              >
                <option value=''>Seleccione una categoría</option>
                {
                  titulosCategorias.map(titulo => {
                    return <option key={titulo} value={titulo}>{titulo}</option>;
                  })
                }
              </select>
            </div>
            <div className="col-6 mb-3">
              <label htmlFor="metodologia">Metodología</label>
              <input className="form-control mr-sm-2" type="text" placeholder="Detalles de la metodología"
                onChange={e => setMetodoEstudio(e.target.value)}
                value={metodoEstudio}
              />
            </div>
            <div className="col-6 mb-3">
              <label htmlFor="estudio">Clave de estudio</label>
              <input className="form-control mr-sm-2" type="text" placeholder="Clave del estudio"
                onChange={e => setClaveEstudio(e.target.value)}
                value={claveEstudio}
              />
            </div>

            <div className="col-6 mb-3">
              <label htmlFor="refrigeracion">Condiciones de Refrigeración</label>
              <input className="form-control mr-sm-2" type="text" placeholder="Condiciones de la refrigeración"
                onChange={e => setRefrigeracion(e.target.value)}
                value={refrigeracion}
              />
            </div>
            <div className="col-6 mb-3">
              <label htmlFor="refrigeracion">Condiciones de Sangre</label>
              <input className="form-control mr-sm-2" type="text" placeholder="Condiciones de la sangre"
                onChange={e => setSangre(e.target.value)}
                value={sangre}
              />
            </div>
            <div className="col-6 mb-3">
              <label htmlFor="refrigeracion">Condiciones de Temperatura</label>
              <input className="form-control mr-sm-2" type="text" placeholder="Condiciones de la temperatura"
                onChange={e => setTemperatura(e.target.value)}
                value={temperatura}
              />
            </div>

            <div className="col-6 mb-3">
              <label htmlFor="CostoEstudio">Costo del estudio</label>
              <input className="form-control mr-sm-2" type="number" placeholder="Costo del estudio"
                onChange={e => setCosto(e.target.value)}
                value={costo}
              />
            </div>
            <div className="col-6 mb-3">
              <label htmlFor="especialidad">Seleccione las Especialidades</label>
              <select className="form-control mr-sm-2" multiple
                onChange={e => handleMultipleSelect(e.target.options)}
                value={especialidadesEstudio}
              >
                {
                  titulosEspecialidades.map(titulo => {
                    return <option key={titulo} value={titulo}>{titulo}</option>;
                  })
                }
              </select>
            </div>
            {
              formEnviado ?
                <div className="col-12 mb-3 text-center">
                  <h3 className="anuncio">¡Registro exitoso!</h3>
                </div>
                : null
            }
            {
              faltanCampos ?
                <div className="col-12 mb-3 text-center">
                  <h4 className="anuncioNegativo">¡Se deben llenar todos los campos!</h4>
                </div>
                : null
            }
            <div className="col-12 mb-3 text-center">
              <button className="btn btn-primary btn-md" type="button"
                onClick={enviarNuevoEstudio}
              >
                Enviar <i className="fi-cwsuxl-plus-solid"></i>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AdminEstudioForm;