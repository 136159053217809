import React from 'react';

import Program1Img from '../../../img/Program1.svg';
import Program2Img from '../../../img/Program2.svg';
import Program3Img from '../../../img/Program3.svg';
import Program4Img from '../../../img/Program4.svg';
import Program5Img from '../../../img/Program5.svg';
import Program6Img from '../../../img/Program6.svg';

const Promocion = ({ promocion, origen }) => {

  const dividirParrafo = texto => {
    return texto.split('. ').map(parrafo => <p className="text-left" key={parrafo}>• {parrafo}</p>);
  };

  return (
    <>
      <div className="col-6 col-sm-6 col-md-4 mb-5 text-center">
        <img width="25%" className="mb-3" src={Program1Img} alt="" />
        <h5 className="mb-2 programas text-center"> Perfil de paciente</h5>
        {dividirParrafo(promocion[1].perfil_paciente)}
      </div>
      <div className="col-6 col-sm-6 col-md-4 mb-5 text-center">
        <img width="25%" className="mb-3" src={Program2Img} alt="" />
        <h5 className="mb-2 programas text-center">Prueba</h5>
        {dividirParrafo(promocion[1].prueba)}
      </div>
      <div className="col-6 col-sm-6 col-md-4 mb-5 text-center">
        <img width="25%" className="mb-3" src={Program3Img} alt="" />
        <h5 className="mb-2 programas text-center">Metodología</h5>
        {dividirParrafo(promocion[1]['Metodología'])}
      </div>
      <div className="col-6 col-sm-6 col-md-4 mb-5 text-center">
        <img width="25%" className="mb-3" src={Program4Img} alt="" />
        <h5 className="mb-2 programas text-center">Muestra</h5>
        {dividirParrafo(promocion[1].muestra)}
      </div>
      <div className="col-6 col-sm-6 col-md-4 mb-5 text-center">
        <img width="25%" className="mb-3" src={Program5Img} alt="" />
        <h5 className="mb-2 programas text-center">Tiempo de entrega</h5>
        {
          origen === 'bayer' ?
            <>
              <p className="text-left">• IHQ: {promocion[1].tiempo_entrega_dias} día(s) hábil(es)</p>
              <p className="text-left">• NGS: 30 día(s) hábil(es)</p>
            </>
            :
            <p className="text-left">• {promocion[1].tiempo_entrega_dias} día(s) hábil(es)</p>
        }
      </div>
      <div className="col-6 col-sm-6 col-md-4 mb-5 text-center">
        <img width="25%" className="mb-3" src={Program6Img} alt="" />
        <h5 className="mb-2 programas text-center">Costo</h5>
        <p className="text-left">${promocion[1].costo}.00 MXN</p>
      </div>
    </>
  );
};

export default Promocion;
