import React, { useState, useContext, useEffect } from 'react';

import { Contexto as UsersContext } from '../../context/UsersContext';

import LoginContainer from '../../modules/LoginPath/LoginContainer';
import LoginCard from './LoginCard';
import Register from './Register';

const Login = () => {
  const { resetPassUsuario } = useContext(UsersContext);

  const [isLogin, setIsLogin] = useState(true);

  const [resetExitoso, setRegistroExitoso] = useState(false);
  const [correoReset, setCorreoReset] = useState('');
  const [correoResetValido, setCorreoResetValido] = useState(false);

  const cambioContrasenia = () => {
    const response = resetPassUsuario(correoReset);
    setRegistroExitoso(response);
  };

  useEffect(() => {
    // Cambiar fondo body
    window.document.body.classList.add('bodyLogin');
  }, []);

  useEffect(() => {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(correoReset)) {
      // if (correoReset.includes('gmail') || correoReset.includes('hotmail') || correoReset.includes('live') || correoReset.includes('yahoo') || correoReset.includes('aol') || correoReset.includes('icloud')) {
      //   setCorreoResetValido(false);
      // } else {
      //   setCorreoResetValido(true);
      // }
      setCorreoResetValido(true);
    } else {
      setCorreoResetValido(false);
    }
  }, [correoReset]);

  return (
    <>
      <LoginContainer>
        {isLogin ?
          <LoginCard setIsLogin={setIsLogin} />
          : <Register setIsLogin={setIsLogin} />
        }
      </LoginContainer>


      {/* MODAL OLVIDO CONTRASEÑA */}
      <div className="modal fade" id="OlvidoContrasenia" tabIndex="-1" role="dialog" aria-labelledby="OlvidoContrasenia" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="OlvidoContraseniaTitle">Ingrese su correo para enviarle un enlace de verificación</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={e => e.preventDefault()} className="form-signin">
                <div className="form-label-group text-center">
                  <input type="text" id="correoReset" className="form-control text-center" placeholder="Correo" required
                    value={correoReset}
                    onChange={e => setCorreoReset(e.target.value)}
                  />
                  <label htmlFor="correoReset">Correo</label>
                </div>
                {
                  correoResetValido ?
                    <div className="form-group">
                      <div className="row">
                        <div className="col-12 mb-3">
                          <button type="button" className="btn btn-primary btn-block" onClick={() => cambioContrasenia()}>
                            Solicitar cambio de contraseña
                          </button>
                        </div>
                      </div>
                    </div>
                    : null
                }
                {
                  resetExitoso ?
                    <h5>Se ha enviado un enlace de verificación a su correo electrónico. Puede llegar a correo no deseado. En caso de o recibirlo, por favor contáctenos.</h5>
                    : null
                }
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
