import React, { useEffect, useState, useRef, useContext } from 'react';
import history from '../../../Navigation';
import './DetalleCaso.css';

import { Contexto as UsersContext } from '../../../context/UsersContext';
import { Contexto as EstudiosContext } from '../../../context/EstudiosContext';

import { ESTUDIO_PAGO, REPORTE_HISTOPATOLOGICO, RES_EMITIDO, RES_EMITIDO_AGAIN } from '../../../actions/actionNames';

import { RestContainer } from '../../../modules/RestContainer';
import AnuncioPositivoNegativo from '../../../modules/AnuncioPositivoNegativo';
import { formatoFechaDeBddAHumano, formatoFechaDeHumanoABDD, formatoFechaBDD, sumarDias, compararDosFechas, setDateSafariMode } from '../../../utils/Fecha';
import { handleGeneratedPDF, handleGeneratedPDFBayer } from '../../../utils/showPDF';
import { handleEmailResListo } from '../../../utils/envioSolicitud';
import CopiarFirma from '../../../modules/SolicitudPath/CopiarFirma';
import ModalModifDatos from './ModalModifDatos';

const DetalleCaso = props => {
  const { solicitudActiva } = props.location.state;

  const reporteHistopatologicoFile = useRef();
  const [repHistoFileName, setRepHistoFileName] = useState('');

  const [correoDoc, setCorreoDoc] = useState('');

  const [fechaSolicitud, setFechaSolicitud] = useState('');
  const [idAEmplear, setIdAEmplear] = useState('');
  const apciAnyFile = useRef();
  const [isApciDoc, setIsApciDoc] = useState('');
  const resultadoEmitidoFile = useRef();
  const resultadoEmitidoAgainFile = useRef();

  const [widthBarra, setWidthBarra] = useState('0%');
  const [etapaActual, setEtapaActual] = useState('');

  const [estudioPagado, setEstudioPagado] = useState(false);
  const [isPagoDoc, setIsPagoDoc] = useState('');
  const pagoAnyFile = useRef();

  const [fechaColectaProgramada, setFechaColectaProgramada] = useState('');
  const [trackingId, setTrackingId] = useState('');
  const [isColectaProgramada, setIsColectaProgramada] = useState(false);


  const [fechaColectaRealizada, setFechaColectaRealizada] = useState('');
  const [fechaCRValida, setFechaCRValida] = useState(false);
  const [isColectaRealizada, setIsColectaRealizada] = useState(false);


  const [fechaLaboratorio, setFechaLaboratorio] = useState('');
  const [fechaLabValida, setFechaLabValida] = useState(false);
  const [isEntregadaLab, setIsEntregadaLab] = useState(false);

  const [resultadoEmitidoCarga, setResultadoEmitidoCarga] = useState('');
  const [emitidoEstatusSelect, setEmitidoEstatusSelect] = useState('');
  const [resultadoEmitidoFecha, setResultadoEmitidoFecha] = useState('');
  const [resultadoEmitidoComentarios, setResultadoEmitidoComentarios] = useState('');

  const [nuevoProceso, setNuevoProceso] = useState(false);
  const [nuevoProcesoSoloResultado, setNuevoProcesoSoloResultado] = useState(false);

  const [fechaColectaProgramadaAgain, setFechaColectaProgramadaAgain] = useState('');
  const [trackingIdAgain, setTrackingIdAgain] = useState('');
  const [isColectaProgramadaAgain, setIsColectaProgramadaAgain] = useState('');
  const [fechaColectaRealizadaAgain, setFechaColectaRealizadaAgain] = useState('');
  const [fechaCRValidaAgain, setFechaCRValidaAgain] = useState(false);
  const [isColectaRealizadaAgain, setIsColectaRealizadaAgain] = useState('');
  const [fechaLaboratorioAgain, setFechaLaboratorioAgain] = useState('');
  const [fechaLabValidaAgain, setFechaLabValidaAgain] = useState(false);
  const [isEntregadaLabAgain, setIsEntregadaLabAgain] = useState('');

  const [resultadoModificado, setResultadoModificado] = useState(false);
  const [resultadoEmitidoTerminado, setResultadoEmitidoTerminado] = useState(false);

  const [fechaRegreso, setFechaRegreso] = useState('');
  const [trackingIdRegreso, setTrackingIdRegreso] = useState('');
  const [isRegresoMuestra, setIsRegresoMuestra] = useState(false);

  const [isEmitidoDoc, setIsEmitidoDoc] = useState('');
  const [emitidoListo, setEmitidoListo] = useState(false);
  const [emitidoListoAgain, setEmitidoListoAgain] = useState(false);
  const [resultadoEmitidoCargaAgain, setResultadoEmitidoCargaAgain] = useState('');
  const [emitidoEstatusSelectAgain, setEmitidoEstatusSelectAgain] = useState('');
  const [resultadoEmitidoFechaAgain, setResultadoEmitidoFechaAgain] = useState('');
  const [resultadoEmitidoComentariosAgain, setResultadoEmitidoComentariosAgain] = useState('');
  const [resultadoModificadoAgain, setResultadoModificadoAgain] = useState(false);

  const { state: { isAdmin } } = useContext(UsersContext);
  const { state: { docApCi, docPago, listadoEstatus, reporteHistoDoc, resEmitidoBD, resEmitidoDosBD }, subirApCiEstudio, bajarApCiEstudio, updateEtapaEstudio, updateInfoEstudio, subirDocumentosDB, bajarDocumentos, regresarYLimpiar } = useContext(EstudiosContext);

  const [AP_CI_Pendiente, ColectaProgramada, ColectaRealizada, EntregadaLab, PagoPendiente, PagoRealizado, ProgramarColecta, RegresoMuestra, ResultadoEmitido, ResultadoListo] = listadoEstatus;

  const advertenciaDoc = '¡No se ha cargado ningún documento!';
  const docCargaExitosa = '¡Subido correctamente!';

  /** VER TODA LA INFO EN BD DE LA SOLICITUD - fines de debug
   * 
   */
  /*useEffect(() => {
    console.log('solicitudActiva');
    console.log(solicitudActiva);
  }, [solicitudActiva]);*/

  /**
   * Actualizar barra de progreso
  */
  useEffect(() => {
    switch (etapaActual) {
      case AP_CI_Pendiente[1]:
        if (solicitudActiva.pago || docPago) {
          setWidthBarra('20%');
        } else {
          setWidthBarra('10%');
        }
        break;
      case PagoPendiente[1]:
        if (docApCi || (solicitudActiva.firmaMedico && solicitudActiva.firmaPaciente) || !solicitudActiva.empresaPharma) {
          setWidthBarra('20%');
        } else {
          setWidthBarra('10%');
        }
        break;
      case PagoRealizado[1]:
        if (docApCi || (solicitudActiva.firmaMedico && solicitudActiva.firmaPaciente) || !solicitudActiva.empresaPharma) {
          setWidthBarra('30%');
        } else {
          setWidthBarra('20%');
        }
        break;
      case ProgramarColecta[1]:
        setWidthBarra('35%');
        break;
      case ColectaProgramada[1]:
        setWidthBarra('45%');
        break;
      case ColectaRealizada[1]:
        setWidthBarra('55%');
        break;
      case EntregadaLab[1]:
        setWidthBarra('65%');
        break;
      case ResultadoEmitido[1]:
        setWidthBarra('75%');
        break;
      case RegresoMuestra[1]:
        setWidthBarra('85%');
        break;
      case ResultadoListo[1]:
        setWidthBarra('100%');
        break;
      default:
        setWidthBarra('5%');
        break;
    }
    // eslint-disable-next-line
  }, [etapaActual, docApCi, docPago]);

  /**
   * Poner IdAEmplear
   * Poner las etapas como true/verde de inicio
   */
  useEffect(() => {
    setFechaSolicitud(formatoFechaDeBddAHumano(solicitudActiva.fecha_solcitada));

    if (solicitudActiva.destino) {
      setIdAEmplear(solicitudActiva.destino);
    }

    if (solicitudActiva.emailDoc) {
      setCorreoDoc(solicitudActiva.emailDoc);
    }

    if (solicitudActiva.etapa) {
      setEtapaActual(solicitudActiva.etapa);
    }

    if (solicitudActiva.reporteHistopatologico) {
      bajarDocumentos(idAEmplear, solicitudActiva.folio, solicitudActiva.reporteHistopatologico, REPORTE_HISTOPATOLOGICO);
    }

    if (solicitudActiva.file && idAEmplear) {
      bajarApCiEstudio(idAEmplear, solicitudActiva.folio, solicitudActiva.file);
    }
    if (solicitudActiva.pago.filename && idAEmplear) {
      setEstudioPagado(solicitudActiva.pago.estatus);
      bajarDocumentos(idAEmplear, solicitudActiva.folio, solicitudActiva.pago.filename, ESTUDIO_PAGO);
    } else {
      setEstudioPagado(solicitudActiva.pago);
    }
    if (solicitudActiva.colecta_programada) {
      setFechaColectaProgramada(solicitudActiva.colecta_programada.fecha);
      setTrackingId(solicitudActiva.colecta_programada.tracking);
      setIsColectaProgramada(true);
    }
    if (solicitudActiva.colecta_realizada) {
      setFechaColectaRealizada(solicitudActiva.colecta_realizada);
      setIsColectaRealizada(true);
    }
    if (solicitudActiva.entregada_lab) {
      setIsEntregadaLab(true);
      setFechaLaboratorio(solicitudActiva.entregada_lab);
    }
    if (solicitudActiva.resultado_emitido && idAEmplear) {
      setEmitidoEstatusSelect(solicitudActiva.resultado_emitido.estatus);
      setResultadoEmitidoComentarios(solicitudActiva.resultado_emitido.comentarios);
      setEmitidoListo(true);

      setResultadoEmitidoFecha(formatoFechaDeBddAHumano(solicitudActiva.resultado_emitido.fecha));
      setResultadoEmitidoCarga(solicitudActiva.resultado_emitido.filename);
      bajarDocumentos(idAEmplear, solicitudActiva.folio, solicitudActiva.resultado_emitido.filename, RES_EMITIDO);
      // if (!solicitudActiva.resultado_emitido_again && !solicitudActiva.resultado_emitido_again_parcial) {
      // }
    }
    if (solicitudActiva.colecta_programada_again) {
      setFechaColectaProgramadaAgain(formatoFechaDeBddAHumano(solicitudActiva.colecta_programada_again.fecha));
      setTrackingIdAgain(solicitudActiva.colecta_programada_again.tracking);
      setIsColectaProgramadaAgain(true);
    }
    if (solicitudActiva.colecta_realizada_again) {
      setFechaColectaRealizadaAgain(formatoFechaDeBddAHumano(solicitudActiva.colecta_realizada_again));
      setIsColectaRealizadaAgain(true);
    }
    if (solicitudActiva.entregada_lab_again) {
      setIsEntregadaLabAgain(true);
      setFechaLaboratorioAgain(formatoFechaDeBddAHumano(solicitudActiva.entregada_lab_again));
    }

    if (solicitudActiva.resultado_emitido_again) {
      setNuevoProceso(true);
    }
    if (solicitudActiva.resultado_emitido_again_parcial) {
      setNuevoProcesoSoloResultado(true);
    }
    if (solicitudActiva.resultado_emitido_again_subido && idAEmplear) {
      setEmitidoListoAgain(true);
      setEmitidoEstatusSelectAgain(solicitudActiva.resultado_emitido_again_subido.estatus);
      setResultadoEmitidoComentariosAgain(solicitudActiva.resultado_emitido_again_subido.comentarios);

      setResultadoEmitidoCargaAgain(solicitudActiva.resultado_emitido_again_subido.filename);
      setResultadoEmitidoFechaAgain(formatoFechaDeBddAHumano(solicitudActiva.resultado_emitido_again_subido.fecha));
      bajarDocumentos(idAEmplear, solicitudActiva.folio, solicitudActiva.resultado_emitido_again_subido.filename, RES_EMITIDO_AGAIN);
    }

    if (solicitudActiva.etapa === ResultadoListo[1]) {
      setResultadoEmitidoTerminado(true);
      setEmitidoListoAgain(true);
    }
    if (solicitudActiva.regreso_muestra) {
      setFechaRegreso(formatoFechaDeBddAHumano(solicitudActiva.regreso_muestra.fecha));
      setTrackingIdRegreso(solicitudActiva.regreso_muestra.tracking);
      setResultadoEmitidoTerminado(true);
      setEmitidoListoAgain(true);
    }
    // eslint-disable-next-line
  }, [solicitudActiva, idAEmplear]);

  /** Revisar que las fechas estén acorde y poner validaciones para el usuario */
  useEffect(() => {
    if (fechaColectaProgramada && fechaColectaRealizada) {
      const validarRealizada = compararDosFechas(formatoFechaDeHumanoABDD(fechaColectaProgramada), formatoFechaDeHumanoABDD(fechaColectaRealizada));
      setFechaCRValida(validarRealizada);
      if (!validarRealizada) {
        window.Swal.fire({
          title: '¡La fecha de colecta no puede ser menor a la programada!',
          text: 'Hay que corregir la fecha de colecta',
          icon: 'error',
          confirmButtonText: 'OK!'
        });
      }
    }

    if (fechaCRValida && fechaLaboratorio) {
      const validarRealizada = compararDosFechas(formatoFechaDeHumanoABDD(fechaColectaRealizada), formatoFechaDeHumanoABDD(fechaLaboratorio));
      setFechaLabValida(validarRealizada);
      if (!validarRealizada) {
        window.Swal.fire({
          title: '¡La fecha de entrega no puede ser menor a la de colecta!',
          text: 'Hay que corregir la fecha de entrega.',
          icon: 'error',
          confirmButtonText: 'OK!'
        });
      }
    }

    if (fechaColectaProgramadaAgain && fechaColectaRealizadaAgain) {
      const validarRealizada = compararDosFechas(formatoFechaDeHumanoABDD(fechaColectaProgramadaAgain), formatoFechaDeHumanoABDD(fechaColectaRealizadaAgain));
      setFechaCRValidaAgain(validarRealizada);
      if (!validarRealizada) {
        window.Swal.fire({
          title: '¡La fecha de colecta no puede ser menor a la programada!',
          text: 'Hay que corregir la fecha de colecta',
          icon: 'error',
          confirmButtonText: 'OK!'
        });
      }
    }

    if (fechaCRValidaAgain && fechaLaboratorioAgain) {
      const validarRealizada = compararDosFechas(formatoFechaDeHumanoABDD(fechaColectaRealizadaAgain), formatoFechaDeHumanoABDD(fechaLaboratorioAgain));
      setFechaLabValidaAgain(validarRealizada);
      if (!validarRealizada) {
        window.Swal.fire({
          title: '¡La fecha de entrega no puede ser menor a la de colecta!',
          text: 'Hay que corregir la fecha de entrega.',
          icon: 'error',
          confirmButtonText: 'OK!'
        });
      }
    }

  }, [fechaColectaRealizada, fechaColectaProgramada, fechaLaboratorio, fechaCRValida, fechaColectaProgramadaAgain, fechaColectaRealizadaAgain, fechaCRValidaAgain, fechaLaboratorioAgain]);

  const subirApCi = () => {
    if (apciAnyFile.current.files.length > 0) {
      if (docPago || solicitudActiva.pago) {
        setEtapaActual(PagoRealizado[1]);
        updateEtapaEstudio(idAEmplear, solicitudActiva.folio, PagoRealizado[1]);
      } else {
        setEtapaActual(PagoPendiente[1]);
        updateEtapaEstudio(idAEmplear, solicitudActiva.folio, PagoPendiente[1]);
      }
      subirApCiEstudio(idAEmplear, solicitudActiva.folio, apciAnyFile.current.files[0], 'file');
      setIsApciDoc(docCargaExitosa);
    } else {
      setIsApciDoc(advertenciaDoc);
    }
  };

  const subirPago = () => {
    if (pagoAnyFile.current.files.length > 0) {
      if (docApCi || (solicitudActiva.firmaMedico && solicitudActiva.firmaPaciente)) {
        guardarDatosSeccion(PagoRealizado[1], 'pago', { fecha: formatoFechaBDD(new Date()), estatus: true, filename: pagoAnyFile.current.files[0].name });
      } else {
        guardarDatosSeccion(AP_CI_Pendiente[1], 'pago', { fecha: formatoFechaBDD(new Date()), estatus: true, filename: pagoAnyFile.current.files[0].name });
      }
      subirDocumentosDB(idAEmplear, solicitudActiva.folio, pagoAnyFile.current.files[0], ESTUDIO_PAGO);
      setIsPagoDoc(docCargaExitosa);
    } else {
      setIsPagoDoc(advertenciaDoc);
    }
  };

  const mostrarFirmas = () => {
    if (solicitudActiva.firmaMedico && solicitudActiva.firmaPaciente) {
      return (
        <div className="alert alert-success mt-3" role="alert">
          Puedes descargar las firmas aquí.
          <br />
          <a href={solicitudActiva.firmaMedico} target="blank" download={`Firma del doctor ${solicitudActiva.folio}`}>Firma del doctor</a> & <a href={solicitudActiva.firmaPaciente} target="blank" download={`Firma del paciente ${solicitudActiva.folio}`}>Firma del paciente</a>
        </div>
      );
    }
    if (solicitudActiva.firmaMedico && !solicitudActiva.firmaPaciente) {
      return (
        <div className="alert alert-success mt-3" role="alert">
          Puedes descargar el archivo con la firma del médico en la sección de arriba.
          {/* <br />
          <a href={solicitudActiva.firmaMedico} target="blank" download={`Firma del doctor ${solicitudActiva.folio}`}>Firma del doctor</a> */}
        </div>
      );
    }
    if (!solicitudActiva.firmaMedico && solicitudActiva.firmaPaciente) {
      return (
        <div className="alert alert-success mt-3" role="alert">
          Puedes descargar la firma del paciente aquí.
          <br />
          <a href={solicitudActiva.firmaPaciente} target="blank" download={`Firma del paciente ${solicitudActiva.folio}`}>Firma del paciente</a>
        </div>
      );
    }

    return null;
  };

  /**
   * Para mayor fácil organización de esos temas del subproceso
   */
  const todosLosProcesosNuevosAFalse = () => {
    setNuevoProceso(false);
    setNuevoProcesoSoloResultado(false);
    guardarDatosSeccionParte3('resultado_emitido_again', false);
    guardarDatosSeccionParte3('resultado_emitido_again_parcial', false);
  };

  /**
   * Si el resultado no es el esperado, hay que iniciar un nuevo proceso.
   */
  const mostrarQuieresNuevoProceso = () => {
    if (!resultadoModificado && !resultadoEmitidoTerminado) {
      if ((solicitudActiva.empresaPharma && emitidoEstatusSelect !== 'Negativo') || (!solicitudActiva.empresaPharma && emitidoEstatusSelect !== 'Negativo' && emitidoEstatusSelect !== 'Positivo')) {

        if (nuevoProceso) {
          return (
            <div className="my-4">
              <label>Se ha iniciado un nuevo proceso:</label>
              <br />
              <button onClick={() => {
                todosLosProcesosNuevosAFalse();
              }} type="button" className="btn btn-info">¿Se desea cancelar el nuevo proceso?</button>
            </div>
          );

        } else if (nuevoProcesoSoloResultado) {
          return (
            <div className="my-4">
              <label>Se ha continuado con el proceso porque la muestra es suficiente</label>
              <br />
              <button onClick={() => {
                todosLosProcesosNuevosAFalse();
              }} type="button" className="btn btn-info">¿Acaso se cometió algún error?</button>
            </div>
          );
        } else if (!nuevoProceso && !nuevoProcesoSoloResultado) {
          return (
            <div className="my-4">
              <label>El resultado fue {emitidoEstatusSelect}, con base en ello:</label>
              <br />
              <button onClick={() => {
                setNuevoProceso(true);
                setNuevoProcesoSoloResultado(false);
                setEmitidoListoAgain(false);
                guardarDatosSeccionParte3('resultado_emitido_again', true);
                guardarDatosSeccionParte3('resultado_emitido_again_parcial', false);
              }} type="button" className="btn btn-info btn-md mx-2 btn-avance-retroceso">¿Se enviará una nueva muestra?</button>
              <button onClick={() => {
                setNuevoProceso(false);
                setNuevoProcesoSoloResultado(true);
                setEmitidoListoAgain(false);
                guardarDatosSeccionParte3('resultado_emitido_again', false);
                guardarDatosSeccionParte3('resultado_emitido_again_parcial', true);
              }} type="button" className="btn btn-warning btn-md mx-2 btn-avance-retroceso">¿Se cuenta con muestra suficiente?</button>

              <br />

              <button onClick={() => {
                confirmacionFinal();
              }} type="button" className="btn btn-secondary btn-md mx-2 mt-3">¿Se terminará el proceso?</button>
            </div >
          );
        }
      }
    }
  };

  /**
   * Continuación de mostrarQuieresNuevoProceso().
   * Confiramción y calidación final para terminar el proceso humano
   */
  const confirmacionFinal = () => {
    window.Swal.fire({
      title: 'Confirmación de finalización del caso',
      text: 'Se está a punto de terminar el proceso para esta solicitud',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Fue un error, continuar con la solicitud',
      confirmButtonText: '¡Adelante!'
    }).then(response => {
      if (response.value) {
        resultadoEmitidoFinishProceso(true);
      }
    });
  };

  /**
   * Guradar los datos de la sección corresponidiente para base de datos y barra de progreso
   * @param {string} etapaUpdate - Etapa para actualizar la barra de progreso.
   * @param {string} tipo - Id de etapa de origen para hacer el update en base de datos.
   * @param {*} datosUpdate - Datos agregados como hijos del tipo (id) { fecha, trackingId }.
   */
  const guardarDatosSeccion = (etapaUpdate, tipo, datosUpdate) => {
    if (tipo === 'pago') {
      setEstudioPagado(true);
    }
    if (tipo === 'colecta_realizada' && !fechaCRValida) {
      return;
    }
    if (tipo === 'entregada_lab' && !fechaLabValida) {
      return;
    }
    if (tipo === 'colecta_realizada_again' && !fechaCRValidaAgain) {
      return;
    }
    if (tipo === 'entregada_lab_again' && !fechaLabValidaAgain) {
      return;
    }
    if (tipo === 'resultado_emitido') {
      // { filename, estatus, fecha, comentarios }
      subirDocumentosDB(idAEmplear, solicitudActiva.folio, resultadoEmitidoFile.current.files[0], RES_EMITIDO);
      setResultadoEmitidoFecha(formatoFechaDeBddAHumano(datosUpdate.fecha));
      setEmitidoListo(true);
    }
    if (tipo === 'resultado_emitido_again_subido') {
      subirDocumentosDB(idAEmplear, solicitudActiva.folio, resultadoEmitidoAgainFile.current.files[0], RES_EMITIDO_AGAIN);
      setResultadoEmitidoFechaAgain(formatoFechaDeBddAHumano(datosUpdate.fecha));
      setEmitidoListoAgain(true);
      setIsEmitidoDoc(docCargaExitosa);
    }

    guardarDatosSeccionParte2(etapaUpdate);
    guardarDatosSeccionParte3(tipo, datosUpdate);
  };

  /**
   * Actulización solamente de la etapa
   * @param {string} etapaUpdate - Etapa para actualizar la barra de progreso.
   */
  const guardarDatosSeccionParte2 = etapaUpdate => {
    setEtapaActual(etapaUpdate);
    updateEtapaEstudio(idAEmplear, solicitudActiva.folio, etapaUpdate);
  };

  /**
   * Actualización solamente de los datos en BDD para tener registros de los progresos.
   * @param {string} tipo - Id de etapa de origen para hacer el update en base de datos.
   * @param {*} datosUpdate - Datos agregados como hijos del tipo (id), objetos { fecha, trackingId } o strings.
   */
  const guardarDatosSeccionParte3 = (tipo, datosUpdate) => {
    updateInfoEstudio(idAEmplear, solicitudActiva.folio, tipo, datosUpdate);
  };

  /**
   * Se quedaban los archivos seteados en el context
   * Eliminarlos del context - evitar bugs
   */
  const eliminarReferenciasRegreso = () => {
    regresarYLimpiar();
    history.goBack();
  };

  /**
   * Cambiar state y mostrar en pantalla subida exitosa
   * @param {string} idInput - id para obtener el resutlado
   */
  const handleInputFileChanged = idInput => {
    if (document.getElementById(idInput).files[0]) {
      const fileName = document.getElementById(idInput).files[0].name;
      window.Swal.fire({
        title: '¡Carga exitosa!',
        text: `El documento ${fileName} se ha subido exitosamente.`,
        icon: 'success',
        confirmButtonText: '¡Listo!'
      });

      switch (idInput) {
        case 'resultadoEmitidoCarga':
          setResultadoEmitidoCarga(fileName);
          break;
        case 'resultadoEmitidoCargaAgain':
          setResultadoEmitidoCargaAgain(fileName);
          break;
        default:
          break;
      }
    } else {
      window.Swal.fire({
        title: '¡Cuidado, no hay documento!',
        text: `Cancelaste la carga, por lo que no se tiene ningún documento adjunto.`,
        icon: 'warning',
        confirmButtonText: 'Ok'
      });
      switch (idInput) {
        case 'resultadoEmitidoCarga':
          setResultadoEmitidoCarga('');
          break;
        case 'resultadoEmitidoCargaAgain':
          setResultadoEmitidoCargaAgain('');
          break;
        default:
          break;
      }
    }
  };

  /**
   * Función para decir que el resultado emitido es el final y proceder con el sig paso.
   * 
   * Además de envío de correo con handleEmailResListo.
   * @param {boolean} terminarProceso - condiciones cumplidas por cliente para terminar proceso
   */
  const resultadoEmitidoFinishProceso = (terminarProceso = false) => {
    if ((solicitudActiva.empresaPharma && emitidoEstatusSelect === 'Negativo') || (!solicitudActiva.empresaPharma && (emitidoEstatusSelect === 'Negativo' || emitidoEstatusSelect === 'Positivo'))) {
      resultadoEmitidoFinishProcesoParte2(terminarProceso);
    }
    if (terminarProceso) {
      resultadoEmitidoFinishProcesoParte2(terminarProceso);
      handleEmailResListo(solicitudActiva.folio, solicitudActiva.nomDoc, correoDoc);
    }
  };
  const resultadoEmitidoFinishProcesoParte2 = procesoEmitidoAgain => {
    setEmitidoListoAgain(procesoEmitidoAgain);
    setResultadoEmitidoTerminado(true);
    setEmitidoListo(true);
    setIsEmitidoDoc(docCargaExitosa);
    guardarDatosSeccionParte2(ResultadoListo[1]);
  };

  /**
   * Positivo y negativo es completado
   * No concluyente y el otro es INCIDENCIA
   */
  const resultadoEmitidoDisplay = () => {
    if (emitidoListo) {
      // if () {
      //   // más bien en proceso, en vez de INICIDENCIA
      //   return <td className="EstatusIncidencia progress-bar-striped progress-bar-animated text-center"><i className="fas fa-exclamation-circle"></i> <strong>INICIDENCIA</strong></td>;
      // }
      return <td className="EstatusCompleto progress-bar-striped progress-bar-animated text-center"><i className="fas fa-check-circle"></i> <strong>COMPLETADO</strong></td>;
    }
    return <td className="EstatusEspera progress-bar-striped progress-bar-animated text-center"><i className="fas fa-ban"></i> <strong>EN ESPERA</strong></td>;
  };

  const resultadoEmitidoAgainDisplay = () => {
    if (emitidoListoAgain) {
      return <td className="EstatusCompleto progress-bar-striped progress-bar-animated text-center"><i className="fas fa-check-circle"></i> <strong>COMPLETADO</strong></td>;
    }
    return <td className="EstatusEspera progress-bar-striped progress-bar-animated text-center"><i className="fas fa-ban"></i> <strong>EN ESPERA</strong></td>;
  };

  /**
   * Renderar el banner de resultado listo
   */
  const resultadoListoEstatusDisplay = () => {
    if (emitidoListo && resultadoEmitidoTerminado) {
      return <td className="EstatusCompleto progress-bar-striped progress-bar-animated text-center"><i className="fas fa-check-circle"></i> <strong>COMPLETADO</strong></td>;
    }
    return <td className="EstatusEspera progress-bar-striped progress-bar-animated text-center"><i className="fas fa-ban"></i> <strong>EN ESPERA</strong></td>;
  };

  const setDateSafari = inputFecha => {
    const response = setDateSafariMode(inputFecha);
    setFechaColectaProgramada(response);
  };

  return (
    <RestContainer>
      <div id="Seleccion" className="col-sm-12 col-md-12 col-lg-8">
        <div className="row">
          <div className="col-12">
            <h3 onClick={eliminarReferenciasRegreso}><i className="fas fa-arrow-circle-left"></i> Detalle del proceso No. {solicitudActiva.folio}</h3>
          </div>
        </div>
        {
          !!isAdmin &&
          <>
            <div className="division2 my-3"></div>
            <div className="row">
              <div className="col-12 text-center">
                <button className="btn btn-lg btn-primary btn-block" data-toggle="modal" data-target="#editSolicitudModal">
                  Modificar Solicitud
                </button>
              </div>
            </div>
          </>
        }
        <div className="division2 my-3"></div>
        <div className="card mt-3 tarjetas tableWarp">
          <div className="card-header">
            <h4>Progreso del caso</h4>
          </div>
          <div className="card-body">
            <ul className="list-group">

              <div className="progress">
                <div className="progress-bar progress-bar-striped progress-bar-animated bg-info" role="progressbar" style={{ width: widthBarra }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
              <div className="division3 my-3"></div>

              <table id="accordionTable" className="table table-hover accordion">
                <thead>
                  <tr>
                    <th scope="col">Etapa</th>
                    <th scope="col">Estatus</th>
                  </tr>
                </thead>

                <tbody>


                  <tr data-toggle="collapse" data-target="#collapSolicitudEstudio" aria-expanded="false" aria-controls="collapSolicitudEstudio">
                    <th scope="row"><h5>1. Solicitud de estudio</h5></th>
                    <td className="EstatusCompleto progress-bar-striped progress-bar-animated text-center"><i className="fas fa-check-circle"></i> <strong>COMPLETADO</strong></td>
                  </tr>
                  {/* <!-- Colapsable Solicitud Estudio --> */}
                  <tr>
                    <td colSpan="2" className="ColSpan2">
                      <div className="row">
                        <div id="collapSolicitudEstudio" className="collapse show col-sm-12 text-center" aria-labelledby="headingOne" data-parent="#accordionTable">
                          <div className="alert alert-success mt-3 onClickDivs" role="alert"
                            onClick={() => handleGeneratedPDF(solicitudActiva)}
                          >
                            Estudio solicitado el <strong>{fechaSolicitud}</strong><br />
                            {/* TODO */}
                            <div style={{ color: 'black' }}>
                              Puedes descargar el PDF <b className="btnTypeLink">haciendo click aquí.</b>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>

                  {
                    solicitudActiva.empresaPharma && (
                      <>
                        <tr data-toggle="collapse" data-target="#collapAPCIBayer" aria-expanded="false" aria-controls="collapAPCIBayer">
                          <th scope="row"><h5>1.1. Avi. Priv./Cons. Inf. de Bayer</h5></th>
                          {
                            docApCi || (solicitudActiva.firmaMedico && solicitudActiva.firmaPaciente) || (solicitudActiva.firmaMedico && solicitudActiva.empresaPharma) ?
                              <td className="EstatusCompleto progress-bar-striped progress-bar-animated text-center"><i className="fas fa-check-circle"></i> <strong>COMPLETADO</strong></td>
                              :
                              <td className="EstatusEspera progress-bar-striped progress-bar-animated text-center"><i className="fas fa-ban"></i> <strong>EN ESPERA</strong></td>
                          }
                        </tr>
                        {/* <!-- Colapsable Firma AV/CI --> */}
                        <tr>
                          <td colSpan="2" className="ColSpan2">
                            <div className="row">
                              <div id="collapAPCIBayer" className="collapse col-sm-12 text-center" aria-labelledby="headingOne" data-parent="#accordionTable">
                                {
                                  !!solicitudActiva.firmaMedico ?
                                    <div className="alert alert-success mt-3 onClickDivs" role="alert"
                                      onClick={() => handleGeneratedPDFBayer(solicitudActiva)}
                                    >
                                      {/* TODO */}
                                      <div style={{ color: 'black' }}>
                                        Puedes descargar el PDF de Bayer <b className="btnTypeLink">haciendo click aquí.</b>
                                      </div>
                                    </div>
                                    :
                                    <div className="alert alert-info mt-3" role="alert">
                                      {/* TODO */}
                                      <div style={{ color: 'black' }}>
                                        Todavía no se cuenta con la firma en la plataforma.
                                    </div>
                                    </div>
                                }
                              </div>
                            </div>
                          </td>
                        </tr>
                      </>
                    )
                  }

                  <tr data-toggle="collapse" data-target="#collapReporteHispa" aria-expanded="false" aria-controls="collapReporteHispa">
                    <th scope="row"><h5>1.2 {!!reporteHistoDoc ? 'Actualizar' : 'Adjuntar'} reporte histopatológico</h5></th>
                    {
                      solicitudActiva.reporteHistopatologico ?
                        <td className="EstatusCompleto progress-bar-striped progress-bar-animated text-center"><i className="fas fa-check-circle"></i> <strong>COMPLETADO</strong></td>
                        :
                        <td className="EstatusEspera progress-bar-striped progress-bar-animated text-center"><i className="fas fa-ban"></i> <strong>EN ESPERA</strong></td>
                    }
                  </tr>
                  {/* <!-- ACTUALIZAR REPORTE HISTOPATOÓGICO --> */}
                  <tr>
                    <td colSpan="2" className="ColSpan2">
                      <div className="row">
                        <div id="collapReporteHispa" className="collapse col-sm-12 text-center" aria-labelledby="headingOne" data-parent="#accordionTable">
                          {
                            reporteHistoDoc
                              ?
                              typeof (reporteHistoDoc) === 'object' ?
                                <div className="alert alert-success mt-3" role="alert">
                                  ¡El archivo {reporteHistoDoc.name} se ha subido con éxito!
                                </div>
                                : <div className="alert alert-success mt-3" role="alert">
                                  Puedes descargar el archivo <a href={reporteHistoDoc} target="blank" download={`AP_CI de ${solicitudActiva.folio}`}>aquí.</a>
                                </div>
                              : null
                          }
                          <div className="row align-items-center">
                            <div className="col-12 mt-3 mb-3">
                              <input type="file" className="btn btn-info"
                                onChange={() => {
                                  if (reporteHistopatologicoFile.current.files[0]) {
                                    setRepHistoFileName(reporteHistopatologicoFile.current.files[0].name);
                                  } else {
                                    setRepHistoFileName('')
                                  }
                                }}
                                accept="image/*,.pdf"
                                ref={reporteHistopatologicoFile}
                              />
                            </div>
                            <div className="col-12 mb-3">
                              <button type="button" className="btn btn-info"
                                disabled={!repHistoFileName}
                                onClick={() => {
                                  subirDocumentosDB(idAEmplear, solicitudActiva.folio, reporteHistopatologicoFile.current.files[0], REPORTE_HISTOPATOLOGICO);
                                  guardarDatosSeccionParte3('reporteHistopatologico', repHistoFileName);
                                }}
                              >
                                {!!reporteHistoDoc ? 'Actualizar reporte' : 'Subir reporte'} <i className="fas fa-file-upload"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>


                  <tr data-toggle="collapse" data-target="#collapFirmaAPCI" aria-expanded="false" aria-controls="collapFirmaAPCI">
                    <th scope="row"><h5>2. Firma Avi. Priv./Cons. Inf.</h5></th>
                    {
                      docApCi || (solicitudActiva.firmaMedico && solicitudActiva.firmaPaciente) || (solicitudActiva.firmaMedico && solicitudActiva.empresaPharma) ?
                        <td className="EstatusCompleto progress-bar-striped progress-bar-animated text-center"><i className="fas fa-check-circle"></i> <strong>COMPLETADO</strong></td>
                        :
                        <td className="EstatusEspera progress-bar-striped progress-bar-animated text-center"><i className="fas fa-ban"></i> <strong>EN ESPERA</strong></td>
                    }
                  </tr>
                  {/* <!-- Colapsable Firma AV/CI --> */}
                  <tr>
                    <td colSpan="2" className="ColSpan2">
                      <div className="row">
                        <div id="collapFirmaAPCI" className="collapse col-sm-12 text-center" aria-labelledby="headingOne" data-parent="#accordionTable">
                          {
                            docApCi
                              ?
                              typeof (docApCi) === 'object' ?
                                <div className="alert alert-success mt-3" role="alert">
                                  ¡El archivo {docApCi.name} se ha subido con éxito!
                                </div>
                                : <div className="alert alert-success mt-3" role="alert">
                                  Puedes descargar el archivo <a href={docApCi} target="blank" download={`AP_CI de ${solicitudActiva.folio}`}>aquí.</a>
                                </div>
                              : null
                          }
                          {mostrarFirmas()}
                          {
                            !docApCi && (!solicitudActiva.firmaMedico || !solicitudActiva.empresaPharma) ?
                              <div className="row align-items-center">
                                <div className="col-12 mt-3 mb-3">
                                  <CopiarFirma
                                    asunto='firmar'
                                    folio={solicitudActiva.folio}
                                    desdeDetalle={true}
                                  />
                                </div>
                                <div className="col-12 mt-3 mb-3">
                                  <input type="file" className="btn btn-info"
                                    accept="image/*,.pdf"
                                    ref={apciAnyFile}
                                  />
                                </div>
                                <div className="col-12 mb-3">
                                  <AnuncioPositivoNegativo
                                    mensaje={isApciDoc}
                                    comprobacion={advertenciaDoc}
                                  />
                                  <button type="button" className="btn btn-info"
                                    onClick={subirApCi}
                                  >
                                    Subir documentos <i className="fas fa-file-upload"></i>
                                  </button>
                                </div>
                              </div>
                              : null
                          }
                        </div>
                      </div>
                    </td>
                  </tr>


                  {
                    solicitudActiva.empresaPharma ?
                      null
                      :
                      <>
                        <tr data-toggle="collapse" data-target="#collapPago" aria-expanded="false" aria-controls="collapPago">
                          <th scope="row"><h5>3. Pago</h5></th>
                          {
                            estudioPagado ?
                              <td className="EstatusCompleto progress-bar-striped progress-bar-animated text-center"><i className="fas fa-check-circle"></i> <strong>COMPLETADO</strong></td>
                              :
                              <td className="EstatusEspera progress-bar-striped progress-bar-animated text-center"><i className="fas fa-ban"></i> <strong>EN ESPERA</strong></td>
                          }
                        </tr>
                        {/* <!-- Colapsable Pago --> */}
                        <tr>
                          <td colSpan="2" className="ColSpan2">
                            <div className="row">
                              <div id="collapPago" className="collapse col-sm-12 text-center mt-3" aria-labelledby="headingOne" data-parent="#accordionTable">
                                {
                                  docPago
                                    ?
                                    typeof (docPago) === 'object' ?
                                      <div className="alert alert-success mt-3" role="alert">
                                        ¡El archivo {docPago.name} se ha subido con éxito!
                                </div>
                                      : <div className="alert alert-success mt-3" role="alert">
                                        Puedes descargar el archivo <a href={docPago} target="blank" download={`Pago de ${solicitudActiva.folio}`}>aquí.</a>
                                      </div>
                                    : null
                                }
                                {
                                  !docPago ?
                                    estudioPagado ?
                                      < div className="alert alert-success mt-3" role="alert">
                                        Pago comprobado el día {solicitudActiva.pago.estatus ? solicitudActiva.pago.fecha : fechaSolicitud}
                                      </div>
                                      :
                                      <div className="row">
                                        <div className="col-12">
                                          <div className="custom-file">
                                            <input type="file" className="btn btn-info"
                                              accept="image/*,.pdf"
                                              ref={pagoAnyFile}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-12 col-md-12 mt-3 text-center">
                                          <AnuncioPositivoNegativo
                                            mensaje={isPagoDoc}
                                            comprobacion={advertenciaDoc}
                                          />
                                          <button type="button" className="btn btn-info"
                                            onClick={subirPago}
                                          >
                                            Guardar comprobante <i className="fas fa-file-upload"></i>
                                          </button>
                                        </div>
                                      </div>
                                    : null
                                }
                                {
                                  solicitudActiva.RFC && solicitudActiva.denominacionSocial ?
                                    <div className="form-row mt-3">
                                      <div className="col-12 mt-3 mb-3">
                                        <h5>Este paciente solicitó factura, estos son sus datos:</h5>
                                      </div>
                                      <div className="col-6 mb-3">
                                        <label>Denominación Social:</label>
                                        <div className="form-control mr-sm-2" type="input" placeholder="Denominacion Social">
                                          {solicitudActiva.denominacionSocial}
                                        </div>
                                      </div>
                                      <div className="col-6 mb-3">
                                        <label>RFC:</label>
                                        <div className="form-control mr-sm-2" type="input" placeholder="RFC">
                                          {solicitudActiva.RFC}
                                        </div>
                                      </div>
                                    </div>
                                    : null
                                }
                              </div>
                            </div>
                          </td>
                        </tr>
                      </>
                  }


                  <tr data-toggle="collapse" data-target="#collapColectaProgramada" aria-expanded="false" aria-controls="collapColectaProgramada">
                    <th scope="row"><h5>{solicitudActiva.empresaPharma ? '3.' : '4.'} Colecta programada</h5></th>
                    {
                      isColectaProgramada ?
                        <td className="EstatusCompleto progress-bar-striped progress-bar-animated text-center"><i className="fas fa-check-circle"></i> <strong>COMPLETADO</strong></td>
                        :
                        <td className="EstatusEspera progress-bar-striped progress-bar-animated text-center"><i className="fas fa-ban"></i> <strong>EN ESPERA</strong></td>
                    }
                  </tr>
                  {/* <!-- Colapsable Colecta Programada --> */}
                  <tr>
                    <td colSpan="2" className="ColSpan2">
                      <div className="row">
                        <div id="collapColectaProgramada" className="collapse col-sm-12 text-center" aria-labelledby="headingOne" data-parent="#accordionTable">
                          {
                            isAdmin ?
                              <form className="mt-3">
                                <div className="row">
                                  <div className="col-12 col-md-6">
                                    <label className="" htmlFor="FechaColecta">Fecha Programada</label>
                                    {
                                      window.userAgent === 'safari' ?
                                        <>
                                          <input type="date" className="form-control" name="FechaColecta"
                                            value={fechaColectaProgramada}
                                            onChange={e => setDateSafari(e.target.value)}
                                            placeholder="AAAA-MM-DD"
                                            maxLength={10}
                                            minLength={10}
                                          />
                                          {!!fechaColectaProgramada && <small>Formato: AAAA-MM-DD</small>}
                                        </>
                                        :
                                        <input type="date" className="form-control" name="FechaColecta"
                                          value={fechaColectaProgramada}
                                          onChange={e => setFechaColectaProgramada(e.target.value)}
                                        />
                                    }
                                  </div>
                                  <div className="col-12 col-md-6">
                                    <label htmlFor="NumeroTracking">Numero de Tracking</label>
                                    <input type="text" className="form-control" placeholder="# Tracking" name="NumeroTracking" id="NumeroTracking"
                                      value={trackingId}
                                      onChange={e => setTrackingId(e.target.value)}
                                    />
                                  </div>
                                  <div className="col-12 col-md-12 mt-3 text-center">
                                    {
                                      fechaColectaProgramada && trackingId ?
                                        <button type="button" className="btn btn-primary mb-3"
                                          disabled={fechaColectaProgramada.length < 10 && fechaColectaProgramada.length > 0}
                                          onClick={() => {
                                            setIsColectaProgramada(true);
                                            guardarDatosSeccion(ColectaProgramada[1], 'colecta_programada', { fecha: fechaColectaProgramada, tracking: trackingId });
                                          }}
                                        >
                                          {
                                            isColectaProgramada ?
                                              "Modificar"
                                              : "Guardar"
                                          }
                                        </button>
                                        : null
                                    }
                                  </div>
                                </div>
                              </form>
                              : null
                          }
                          {
                            isColectaProgramada ?
                              <div className="alert alert-success mt-3" role="alert">
                                La muestra será recogida el día <strong>{formatoFechaDeBddAHumano(fechaColectaProgramada)}</strong><br />
                                Su tracking es <strong>{trackingId}</strong>
                              </div>
                              : null
                          }
                        </div>
                      </div>
                    </td>
                  </tr>


                  <tr data-toggle="collapse" data-target="#collapColectaRealizada" aria-expanded="false" aria-controls="collapColectaRealizada">
                    <th scope="row"><h5>{solicitudActiva.empresaPharma ? '4.' : '5.'} Colecta realizada</h5></th>
                    {
                      isColectaRealizada ?
                        <td className="EstatusCompleto progress-bar-striped progress-bar-animated text-center"><i className="fas fa-check-circle"></i> <strong>COMPLETADO</strong></td>
                        : <td className="EstatusEspera progress-bar-striped progress-bar-animated text-center"><i className="fas fa-ban"></i> <strong>EN ESPERA</strong></td>
                    }
                  </tr>
                  {/* <!-- Colapsable Colecta Realizada --> */}
                  <tr>
                    <td colSpan="2" className="ColSpan2">
                      <div className="row">
                        <div id="collapColectaRealizada" className="collapse col-sm-12 text-center" aria-labelledby="headingOne" data-parent="#accordionTable">
                          {
                            isAdmin ?
                              <form className="mt-3">
                                <div className="row">
                                  <div className="col-12">
                                    <label className="" htmlFor="FechaRealizada">Fecha Recolectada</label>
                                    {
                                      window.userAgent === 'safari' ?
                                        <>
                                          <input type="date" className="form-control" name="FechaRealizada"
                                            value={fechaColectaRealizada}
                                            onChange={e => setFechaColectaRealizada(e.target.value)}
                                            placeholder="AAAA-MM-DD"
                                            maxLength={10}
                                            minLength={10}
                                          />
                                          {!!fechaColectaRealizada && <small>Formato: AAAA-MM-DD</small>}
                                        </>
                                        :
                                        <input type="date" className="form-control" name="FechaRealizada"
                                          value={formatoFechaDeHumanoABDD(fechaColectaRealizada)}
                                          onChange={e => setFechaColectaRealizada(e.target.value)}
                                        />
                                    }
                                  </div>
                                  <div className="col-12 col-md-12 mt-3 text-center">
                                    {
                                      fechaColectaRealizada && fechaCRValida ?
                                        <button type="button" className="btn btn-primary mb-3"
                                          disabled={fechaColectaRealizada.length < 10 && fechaColectaRealizada.length > 0}
                                          onClick={() => {
                                            setIsColectaRealizada(true);
                                            guardarDatosSeccion(ColectaRealizada[1], 'colecta_realizada', fechaColectaRealizada)
                                          }}
                                        >
                                          {
                                            isColectaRealizada ?
                                              "Modificar"
                                              : "Guardar"
                                          }
                                        </button>
                                        : null
                                    }
                                  </div>
                                </div>
                              </form>
                              : null
                          }
                          {
                            isColectaRealizada ?
                              <div className="alert alert-success mt-3" role="alert">
                                La muestra se recolectó el día <strong>{formatoFechaDeBddAHumano(fechaColectaRealizada)}</strong>
                              </div>
                              : null
                          }
                        </div>
                      </div>
                    </td>
                  </tr>


                  {/* Entregada a LAB en progreso si ha sido colectada */}
                  <tr data-toggle="collapse" data-target="#collapColectaEntregaLaboratorio" aria-expanded="false" aria-controls="collapColectaEntregaLaboratorio">
                    <th scope="row"><h5>{solicitudActiva.empresaPharma ? '5.' : '6.'} Entregada al laboratorio</h5></th>
                    {
                      isEntregadaLab ?
                        <td className="EstatusCompleto progress-bar-striped progress-bar-animated text-center"><i className="fas fa-check-circle"></i> <strong>COMPLETADO</strong></td>
                        : fechaColectaRealizada ?
                          <td className="EstatusTrabajando progress-bar-striped progress-bar-animated text-center"><i className="fas fa-spinner"></i> <strong>EN PROCESO</strong></td>
                          : <td className="EstatusEspera progress-bar-striped progress-bar-animated text-center"><i className="fas fa-ban"></i> <strong>EN ESPERA</strong></td>
                    }
                  </tr>
                  {/* <!-- Colapsable Entregada al laboratorio --> */}
                  <tr>
                    <td colSpan="2" className="ColSpan2">
                      <div className="row">
                        <div id="collapColectaEntregaLaboratorio" className="collapse col-sm-12 text-center" aria-labelledby="headingOne" data-parent="#accordionTable">
                          {
                            isAdmin ?
                              <form className="mt-3">
                                <div className="row">
                                  <div className="col-12">
                                    <label className="" htmlFor="FechaLab">Fecha Programada</label>
                                    {
                                      window.userAgent === 'safari' ?
                                        <>
                                          <input type="date" className="form-control" name="FechaLab"
                                            value={fechaLaboratorio}
                                            onChange={e => setFechaLaboratorio(e.target.value)}
                                            placeholder="AAAA-MM-DD"
                                            maxLength={10}
                                            minLength={10}
                                          />
                                          {!!fechaLaboratorio && <small>Formato: AAAA-MM-DD</small>}
                                        </>
                                        :
                                        <input type="date" className="form-control" name="FechaLab"
                                          value={formatoFechaDeHumanoABDD(fechaLaboratorio)}
                                          onChange={e => setFechaLaboratorio(e.target.value)}
                                        />
                                    }
                                  </div>
                                  <div className="col-12 col-md-12 mt-3 text-center">
                                    {
                                      fechaLaboratorio && fechaLabValida ?
                                        <button type="button" className="btn btn-primary mb-3"
                                          disabled={fechaLaboratorio.length < 10 && fechaLaboratorio.length > 0}
                                          onClick={() => {
                                            setIsEntregadaLab(true);
                                            guardarDatosSeccion(EntregadaLab[1], 'entregada_lab', fechaLaboratorio)
                                          }}
                                        >
                                          {
                                            isEntregadaLab && fechaLabValida ?
                                              "Modificar"
                                              : "Guardar"
                                          }
                                        </button>
                                        : null
                                    }
                                  </div>
                                </div>
                              </form>
                              : null
                          }
                          {
                            isEntregadaLab ?
                              <div className="alert alert-success mt-3" role="alert">
                                La muestra se entregó al laboratorio el día <strong>{formatoFechaDeBddAHumano(fechaLaboratorio)}</strong>
                                <br />
                                El resultado se estima el día <strong>{sumarDias(fechaLaboratorio, 3)}</strong>
                              </div>
                              : null
                          }
                        </div>
                      </div>
                    </td>
                  </tr>



                  <tr data-toggle="collapse" data-target="#collapResultadoEmitido" aria-expanded="false" aria-controls="collapResultadoEmitido">
                    <th scope="row"><h5>{solicitudActiva.empresaPharma ? '6.' : '7.'} Resultado emitido Inmunohistoquímica</h5></th>
                    {resultadoEmitidoDisplay()}
                  </tr>
                  {/* <!-- Colapsable Resultado Emitido --> */}
                  <tr>
                    <td colSpan="2" className="ColSpan2">
                      <div className="row">
                        <div id="collapResultadoEmitido" className="collapse col-sm-12 text-center" aria-labelledby="headingOne" data-parent="#accordionTable">
                          {
                            isAdmin ?
                              <form className="mt-3 mb-3">
                                <div className="row">
                                  <div className="col-12 col-md-6">
                                    {
                                      solicitudActiva.resultado_emitido ?
                                        <>
                                          <label>¿Desea reemplazarlo por otro resultado?</label>
                                          <br />
                                          <input type="file" className="btn btn-info"
                                            onChange={() => {
                                              handleInputFileChanged('resultadoEmitidoCarga');
                                              setResultadoModificado(true);
                                            }}
                                            accept="image/*,.pdf"
                                            ref={resultadoEmitidoFile}
                                            multiple
                                            id="resultadoEmitidoCarga"
                                          />
                                          <br />
                                          <label>Se había subido el archivo {solicitudActiva.resultado_emitido.filename}</label>
                                        </>
                                        :
                                        <>
                                          <label>Carga el resultado del laboratorio</label>
                                          <br />
                                          <input type="file" className="btn btn-info"
                                            onChange={() => {
                                              handleInputFileChanged('resultadoEmitidoCarga');
                                              setResultadoModificado(true);
                                            }}
                                            accept="image/*,.pdf"
                                            ref={resultadoEmitidoFile}
                                            multiple
                                            id="resultadoEmitidoCarga"
                                          />
                                        </>
                                    }
                                  </div>
                                  <div className="col-12 col-md-6">
                                    {/* "Positivo", "Negativo", "No concluyente" "Se requiere otra muestra" */}
                                    <label>Elija el estatus</label>
                                    <select className="form-control mr-sm-2" name="estudio"
                                      onChange={e => {
                                        setEmitidoEstatusSelect(e.target.value);
                                        setResultadoModificado(true);
                                      }}
                                      value={emitidoEstatusSelect}
                                    >
                                      <option value=''>Seleccione el estatus del resultado</option>
                                      <option value='Positivo'>Positivo</option>
                                      <option value='Negativo'>Negativo</option>
                                      <option value='No concluyente'>No concluyente</option>
                                      <option value='Se requiere otra muestra'>Se requiere otra muestra</option>
                                    </select>
                                  </div>
                                  <div className="col-12 mt-3">
                                    <label htmlFor="comentarios">Comentarios:</label>
                                    <textarea name="comentarios" className="form-control" rows="8"
                                      value={resultadoEmitidoComentarios}
                                      onChange={e => {
                                        setResultadoEmitidoComentarios(e.target.value);
                                        setResultadoModificado(true);
                                      }}
                                    ></textarea>
                                  </div>
                                  <div className="col-12 col-md-12 mt-3 text-center">
                                    {
                                      emitidoEstatusSelect && resultadoEmitidoCarga && resultadoEmitidoComentarios ?
                                        <>
                                          <AnuncioPositivoNegativo
                                            mensaje={isEmitidoDoc}
                                            comprobacion={advertenciaDoc}
                                          />
                                          <button type="button" className="btn btn-primary"
                                            onClick={() => {
                                              if (resultadoEmitidoFile.current.files.length > 0) {
                                                guardarDatosSeccion(ResultadoEmitido[1], 'resultado_emitido', { fecha: formatoFechaBDD(new Date()), estatus: emitidoEstatusSelect, filename: resultadoEmitidoFile.current.files[0].name, comentarios: resultadoEmitidoComentarios });
                                                setResultadoModificado(false);
                                                setResultadoEmitidoTerminado(false);
                                                resultadoEmitidoFinishProceso();
                                                handleEmailResListo(solicitudActiva.folio, solicitudActiva.nomDoc, correoDoc);
                                              } else {
                                                setIsEmitidoDoc(advertenciaDoc);
                                              }
                                            }}
                                          >
                                            {
                                              emitidoListoAgain ?
                                                'Modificar'
                                                : 'Guardar'
                                            }
                                          </button>
                                          <br />
                                          {emitidoListo && mostrarQuieresNuevoProceso()}
                                        </>
                                        : null
                                    }
                                  </div>
                                </div>
                              </form>
                              : null
                          }
                          {
                            !resultadoModificado && emitidoListo && (
                              <div className="alert alert-success mt-3" role="alert">
                                El estatus del resultado fue <strong>{emitidoEstatusSelect}</strong>
                                <br />
                                Los comentarios anexados son: <strong>{resultadoEmitidoComentarios}</strong>
                              </div>
                            )
                          }
                        </div>
                      </div>
                    </td>
                  </tr>

                  {
                    nuevoProceso ?
                      <>
                        <tr data-toggle="collapse" data-target="#collapColectaAgain" aria-expanded="false" aria-controls="collapColectaAgain">
                          <th scope="row"><h5>{solicitudActiva.empresaPharma ? '6.1' : '7.1'} Nueva Colecta programada</h5></th>
                          {
                            isColectaProgramadaAgain ?
                              <td className="EstatusCompleto progress-bar-striped progress-bar-animated text-center"><i className="fas fa-check-circle"></i> <strong>COMPLETADO</strong></td>
                              : <td className="EstatusEspera progress-bar-striped progress-bar-animated text-center"><i className="fas fa-ban"></i> <strong>EN ESPERA</strong></td>
                          }
                        </tr>
                        <tr>
                          <td colSpan="2" className="ColSpan2">
                            <div className="row">
                              <div id="collapColectaAgain" className="collapse col-sm-12 text-center" aria-labelledby="headingOne" data-parent="#accordionTable">
                                <form className="mt-3">
                                  <div className="row">
                                    <div className="col-12 col-md-6">
                                      <label className="" htmlFor="FechaColectaAgain">Fecha Programada Nuevo Proceso</label>
                                      <input type="date" className="form-control" name="FechaColectaAgain"
                                        value={formatoFechaDeHumanoABDD(fechaColectaProgramadaAgain)}
                                        onChange={e => setFechaColectaProgramadaAgain(formatoFechaDeBddAHumano(e.target.value))}
                                      />
                                    </div>
                                    <div className="col-12 col-md-6">
                                      <label htmlFor="NumeroTracking">Numero de Tracking</label>
                                      <input type="text" className="form-control" placeholder="# Tracking" name="NumeroTracking" id="NumeroTracking"
                                        value={trackingIdAgain}
                                        onChange={e => setTrackingIdAgain(e.target.value)}
                                      />
                                    </div>
                                    <div className="col-12 col-md-12 mt-3 text-center">
                                      {
                                        fechaColectaProgramadaAgain && trackingIdAgain ?
                                          <button type="button" className="btn btn-primary mb-3"
                                            onClick={() => {
                                              setIsColectaProgramadaAgain(true);
                                              guardarDatosSeccion(ResultadoEmitido[1], 'colecta_programada_again', { fecha: formatoFechaDeHumanoABDD(fechaColectaProgramadaAgain), tracking: trackingIdAgain });
                                            }}
                                          >
                                            {
                                              isColectaProgramadaAgain ?
                                                "Modificar"
                                                : "Guardar"
                                            }
                                          </button>
                                          : null
                                      }
                                    </div>
                                  </div>
                                </form>
                                {
                                  isColectaProgramadaAgain ?
                                    <div className="alert alert-success mt-3" role="alert">
                                      La muestra será recogida el día <strong>{fechaColectaProgramadaAgain}</strong><br />
                                      Su tracking es <strong>{trackingIdAgain}</strong>
                                    </div>
                                    : null
                                }
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr data-toggle="collapse" data-target="#collapRealizadaAgain" aria-expanded="false" aria-controls="collapRealizadaAgain">
                          <th scope="row"><h5>{solicitudActiva.empresaPharma ? '6.2' : '7.2'} Nueva Colecta realizada</h5></th>
                          {
                            isColectaRealizadaAgain ?
                              <td className="EstatusCompleto progress-bar-striped progress-bar-animated text-center"><i className="fas fa-check-circle"></i> <strong>COMPLETADO</strong></td>
                              : <td className="EstatusEspera progress-bar-striped progress-bar-animated text-center"><i className="fas fa-ban"></i> <strong>EN ESPERA</strong></td>
                          }
                        </tr>
                        <tr>
                          <td colSpan="2" className="ColSpan2">
                            <div className="row">
                              <div id="collapRealizadaAgain" className="collapse col-sm-12 text-center" aria-labelledby="headingOne" data-parent="#accordionTable">
                                <form className="mt-3">
                                  <div className="row">
                                    <div className="col-12">
                                      <label className="" htmlFor="FechaRealizada">Fecha Recolectada Nuevo Proceso</label>
                                      <input type="date" className="form-control" name="FechaRealizada"
                                        value={formatoFechaDeHumanoABDD(fechaColectaRealizadaAgain)}
                                        onChange={e => setFechaColectaRealizadaAgain(formatoFechaDeBddAHumano(e.target.value))}
                                      />
                                    </div>
                                    <div className="col-12 col-md-12 mt-3 text-center">
                                      {
                                        fechaColectaRealizadaAgain && fechaCRValidaAgain ?
                                          <button type="button" className="btn btn-primary mb-3"
                                            onClick={() => {
                                              setIsColectaRealizadaAgain(true);
                                              guardarDatosSeccion(ResultadoEmitido[1], 'colecta_realizada_again', formatoFechaDeHumanoABDD(fechaColectaRealizadaAgain))
                                            }}
                                          >
                                            {
                                              isColectaRealizadaAgain ?
                                                "Modificar"
                                                : "Guardar"
                                            }
                                          </button>
                                          : null
                                      }
                                    </div>
                                  </div>
                                </form>
                                {
                                  isColectaRealizadaAgain ?
                                    <div className="alert alert-success mt-3" role="alert">
                                      La muestra se recolectó el día <strong>{fechaColectaRealizadaAgain}</strong>
                                    </div>
                                    : null
                                }
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr data-toggle="collapse" data-target="#collapLabAgain" aria-expanded="false" aria-controls="collapLabAgain">
                          <th scope="row"><h5>{solicitudActiva.empresaPharma ? '6.3' : '7.3'} Nueva Entregada al laboratorio</h5></th>
                          {
                            isEntregadaLabAgain ?
                              <td className="EstatusCompleto progress-bar-striped progress-bar-animated text-center"><i className="fas fa-check-circle"></i> <strong>COMPLETADO</strong></td>
                              : <td className="EstatusEspera progress-bar-striped progress-bar-animated text-center"><i className="fas fa-ban"></i> <strong>EN ESPERA</strong></td>
                          }
                        </tr>
                        <tr>
                          <td colSpan="2" className="ColSpan2">
                            <div className="row">
                              <div id="collapLabAgain" className="collapse col-sm-12 text-center" aria-labelledby="headingOne" data-parent="#accordionTable">
                                <form className="mt-3">
                                  <div className="row">
                                    <div className="col-12">
                                      <label className="" htmlFor="FechaLab">Fecha Programada Nuevo Proceso</label>
                                      <input type="date" className="form-control" name="FechaLab"
                                        value={formatoFechaDeHumanoABDD(fechaLaboratorioAgain)}
                                        onChange={e => setFechaLaboratorioAgain(formatoFechaDeBddAHumano(e.target.value))}
                                      />
                                    </div>
                                    <div className="col-12 col-md-12 mt-3 text-center">
                                      {
                                        fechaLaboratorioAgain && fechaLabValidaAgain ?
                                          <button type="button" className="btn btn-primary mb-3"
                                            onClick={() => {
                                              setIsEntregadaLabAgain(true);
                                              guardarDatosSeccion(ResultadoEmitido[1], 'entregada_lab_again', formatoFechaDeHumanoABDD(fechaLaboratorioAgain))
                                            }}
                                          >
                                            {
                                              isEntregadaLabAgain && fechaLabValidaAgain ?
                                                "Modificar"
                                                : "Guardar"
                                            }
                                          </button>
                                          : null
                                      }
                                    </div>
                                  </div>
                                </form>
                                {
                                  isEntregadaLabAgain ?
                                    <div className="alert alert-success mt-3" role="alert">
                                      La muestra se entregó al laboratorio el día <strong>{fechaLaboratorioAgain}</strong>
                                      <br />
                                      El resultado se estima el día <strong>{sumarDias(formatoFechaDeHumanoABDD(fechaLaboratorioAgain), 3)}</strong>
                                    </div>
                                    : null
                                }
                              </div>
                            </div>
                          </td>
                        </tr>
                      </>
                      : null
                  }

                  {
                    (nuevoProceso || nuevoProcesoSoloResultado) && (
                      <>

                        <tr data-toggle="collapse" data-target="#collapResultadoEmitidoAgain" aria-expanded="false" aria-controls="collapResultadoEmitidoAgain">
                          <th scope="row"><h5>{nuevoProceso ? '6.4' : '6.1'} Resultado emitido NGS</h5></th>
                          {resultadoEmitidoAgainDisplay()}
                        </tr>
                        <tr>
                          <td colSpan="2" className="ColSpan2">
                            <div className="row">
                              <div id="collapResultadoEmitidoAgain" className="collapse col-sm-12 text-center" aria-labelledby="headingOne" data-parent="#accordionTable">
                                {
                                  isAdmin ?
                                    <form className="mt-3 mb-3">
                                      <div className="row">
                                        <div className="col-12 col-md-6">
                                          {
                                            solicitudActiva.resultado_emitido_again_subido ?
                                              <>
                                                <label>¿Desea reemplazarlo por otro resultado?</label>
                                                <br />
                                                <input type="file" className="btn btn-info"
                                                  onChange={() => {
                                                    handleInputFileChanged('resultadoEmitidoCargaAgain');
                                                    setResultadoModificadoAgain(true);
                                                  }}
                                                  accept="image/*,.pdf"
                                                  ref={resultadoEmitidoAgainFile}
                                                  multiple
                                                  id="resultadoEmitidoCargaAgain"
                                                />
                                                <br />
                                                <label>Se había subido el archivo {solicitudActiva.resultado_emitido_again_subido.filename}</label>
                                              </>
                                              :
                                              <>
                                                <label>Carga el resultado del laboratorio</label>
                                                <br />
                                                <input type="file" className="btn btn-info"
                                                  onChange={() => {
                                                    handleInputFileChanged('resultadoEmitidoCargaAgain');
                                                    setResultadoModificadoAgain(true);
                                                  }}
                                                  accept="image/*,.pdf"
                                                  ref={resultadoEmitidoAgainFile}
                                                  multiple
                                                  id="resultadoEmitidoCargaAgain"
                                                />
                                              </>
                                          }
                                        </div>
                                        <div className="col-12 col-md-6">
                                          {/* "Positivo", "Negativo", "No concluyente" "Se requiere otra muestra" */}
                                          <label>Elija el estatus</label>
                                          <select className="form-control mr-sm-2" name="estudio"
                                            onChange={e => {
                                              setEmitidoEstatusSelectAgain(e.target.value);
                                              setResultadoModificadoAgain(true);
                                            }}
                                            value={emitidoEstatusSelectAgain}
                                          >
                                            <option value=''>Seleccione el estatus del resultado</option>
                                            <option value='Positivo'>Positivo</option>
                                            <option value='Negativo'>Negativo</option>
                                            <option value='No concluyente'>No concluyente</option>
                                            <option value='Se requiere otra muestra'>Se requiere otra muestra</option>
                                          </select>
                                        </div>
                                        <div className="col-12 mt-3">
                                          <label htmlFor="comentarios">Comentarios:</label>
                                          <textarea name="comentarios" className="form-control" rows="8"
                                            value={resultadoEmitidoComentariosAgain}
                                            onChange={e => {
                                              setResultadoEmitidoComentariosAgain(e.target.value);
                                              setResultadoModificadoAgain(true);
                                            }}
                                          ></textarea>
                                        </div>
                                        <div className="col-12 col-md-12 mt-3 text-center">
                                          {
                                            emitidoEstatusSelectAgain && resultadoEmitidoCargaAgain && resultadoEmitidoComentariosAgain ?
                                              <>
                                                <AnuncioPositivoNegativo
                                                  mensaje={isEmitidoDoc}
                                                  comprobacion={advertenciaDoc}
                                                />
                                                <button type="button" className="btn btn-primary"
                                                  onClick={() => {
                                                    if (resultadoEmitidoAgainFile.current.files.length > 0) {
                                                      guardarDatosSeccion(ResultadoListo[1], 'resultado_emitido_again_subido', { fecha: formatoFechaBDD(new Date()), estatus: emitidoEstatusSelectAgain, filename: resultadoEmitidoAgainFile.current.files[0].name, comentarios: resultadoEmitidoComentariosAgain });
                                                      setResultadoModificadoAgain(false);
                                                      resultadoEmitidoFinishProceso(true);
                                                    } else {
                                                      setIsEmitidoDoc(advertenciaDoc);
                                                    }
                                                  }}
                                                >
                                                  {
                                                    emitidoListoAgain ?
                                                      'Modificar'
                                                      : 'Guardar'
                                                  }
                                                </button>
                                                <br />
                                              </>
                                              : null
                                          }
                                        </div>
                                      </div>
                                    </form>
                                    : null
                                }
                                {
                                  !resultadoModificadoAgain && emitidoListoAgain && (
                                    <div className="alert alert-success mt-3" role="alert">
                                      El estatus del resultado fue <strong>{emitidoEstatusSelectAgain}</strong>
                                      <br />
                                      Los comentarios anexados son: <strong>{resultadoEmitidoComentariosAgain}</strong>
                                    </div>
                                  )
                                }
                              </div>
                            </div>
                          </td>
                        </tr>

                      </>
                    )
                  }

                  <tr data-toggle="collapse" data-target="#collapResultadoListo" aria-expanded="false" aria-controls="collapResultadoListo">
                    <th scope="row"><h5>{solicitudActiva.empresaPharma ? '7.' : '8.'}  Resultado listo</h5></th>
                    {resultadoListoEstatusDisplay()}
                  </tr>

                  {/* <!-- Colapsable Resultado listo --> */}
                  <tr>
                    <td colSpan="2" className="ColSpan2">
                      <div className="row">
                        <div id="collapResultadoListo" className="collapse col-sm-12 text-center" aria-labelledby="headingOne" data-parent="#accordionTable">
                          {
                            emitidoListo && typeof (resEmitidoBD) === 'string' ?
                              <div className="alert alert-success mt-3" role="alert">
                                Descargar resultado subido en etapa 6 <a href={resEmitidoBD} target="blank" download={`Resultado subido de ${solicitudActiva.folio}`}>aquí.</a>
                              </div>
                              : null
                          }
                          {
                            emitidoListoAgain && typeof (resEmitidoDosBD) === 'string' ?
                              <div className="alert alert-success mt-3" role="alert">
                                Descargar segundo resultado subido en etapa {nuevoProceso ? '6.4' : '6.1'} <a href={resEmitidoDosBD} target="blank" download={`Resultado subproceso de ${solicitudActiva.folio}`}>aquí.</a>
                              </div>
                              : null
                          }
                          {
                            emitidoListo && typeof (resEmitidoBD) === 'object' ?
                              <div className="alert alert-success mt-3" role="alert">
                                ¡El resultado se subió de forma exitosa el {resultadoEmitidoFecha}!
                                </div>
                              : null
                          }
                          {
                            emitidoListoAgain && typeof (resEmitidoDosBD) === 'object' ?
                              <div className="alert alert-success mt-3" role="alert">
                                ¡El segundo resultado se subió de forma exitosa el {resultadoEmitidoFechaAgain}!
                                </div>
                              : null
                          }
                        </div>
                      </div>
                    </td>
                  </tr>


                  <tr data-toggle="collapse" data-target="#collapRetornoMuestra" aria-expanded="false" aria-controls="collapRetornoMuestra">
                    <th scope="row"><h5>{solicitudActiva.empresaPharma ? '8.' : '9.'} Regreso muestra</h5></th>
                    {
                      fechaRegreso && trackingIdRegreso ?
                        <td className="EstatusCompleto progress-bar-striped progress-bar-animated text-center"><i className="fas fa-check-circle"></i> <strong>COMPLETADO</strong></td>
                        :
                        nuevoProceso ?
                          <td className="EstatusTrabajando progress-bar-striped progress-bar-animated text-center"><i className="fas fa-spinner"></i> <strong>EN PROCESO</strong></td>
                          :
                          <td className="EstatusEspera progress-bar-striped progress-bar-animated text-center"><i className="fas fa-ban"></i> <strong>EN ESPERA</strong></td>
                    }
                  </tr>
                  {/* <!-- Colapsable Retorno Colecta --> */}
                  <tr>
                    <td colSpan="2" className="ColSpan2">
                      <div className="row">
                        <div id="collapRetornoMuestra" className="collapse col-sm-12 text-center" aria-labelledby="headingOne" data-parent="#accordionTable">
                          {
                            isAdmin ?
                              <form className="mt-3">
                                <div className="row">
                                  <div className="col-12 col-md-6">
                                    <label className="" htmlFor="FechaColecta">Fecha Programada</label>
                                    <input type="date" className="form-control" name="FechaRetornoColecta" id="FechaRetornoColecta"
                                      value={formatoFechaDeHumanoABDD(fechaRegreso)}
                                      onChange={e => setFechaRegreso(formatoFechaDeBddAHumano(e.target.value))}
                                    />
                                  </div>
                                  <div className="col-12 col-md-6">
                                    <label htmlFor="NumeroTracking">Numero de Tracking</label>
                                    <input type="text" className="form-control" placeholder="# Tracking" name="NumeroTrackingRetorno" id="NumeroTrackingRetorno"
                                      value={trackingIdRegreso}
                                      onChange={e => setTrackingIdRegreso(e.target.value)}
                                    />
                                  </div>
                                  <div className="col-12 col-md-12 mt-3 text-center">
                                    {
                                      fechaRegreso && trackingIdRegreso ?
                                        <button type="button" className="btn btn-primary mb-3"
                                          onClick={() => {
                                            guardarDatosSeccion(ResultadoListo[1], 'regreso_muestra', { fecha: formatoFechaDeHumanoABDD(fechaRegreso), tracking: trackingIdRegreso });
                                            setIsRegresoMuestra(true);
                                          }}
                                        >
                                          {
                                            isRegresoMuestra ?
                                              "Modificar"
                                              : "Guardar"
                                          }
                                        </button>
                                        : null
                                    }
                                  </div>
                                </div>
                              </form>
                              : null
                          }
                          {
                            isRegresoMuestra ?
                              <div className="alert alert-success mt-3" role="alert">
                                La muestra será recogida el día <strong>{fechaRegreso}</strong><br />
                                Su tracking es <strong>{trackingIdRegreso}</strong>
                              </div>
                              : null
                          }
                        </div>
                      </div>
                    </td>
                  </tr>

                </tbody>
              </table>
            </ul>
          </div>
        </div>
      </div>

      <ModalModifDatos solicitudActiva={solicitudActiva} />

    </RestContainer >
  );
};

export default DetalleCaso;
