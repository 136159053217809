import database from '../firebase/Firebase';
import crearContextoDatos from './crearContextoDatos';

import { LISTADO_DOCTORES, UN_DOCTOR, DOCTOR_ESTUDIOS } from '../actions/actionNames';

const doctoresReducer = (state, action) => {
  switch (action.type) {
    case LISTADO_DOCTORES:
      return { ...state, doctores: action.payload };
    case UN_DOCTOR:
      return { ...state, doctor: action.payload };
    case DOCTOR_ESTUDIOS:
      return { ...state, listaEstudiosDoc: action.payload };
    default:
      return state;
  };
};

const compare = (textA, textB) => {
  const doctorA = textA.nom.toUpperCase();
  const doctorB = textB.nom.toUpperCase();

  let comparison = 0;
  if (doctorA > doctorB) {
    comparison = 1;
  } else if (doctorA < doctorB) {
    comparison = -1;
  }
  return comparison;
};

const getAllDoctores = dispatch => {
  return async () => {
    await database.ref('usuarios')
    .once('value')
    .then(snapshot => {
      const doctores = Object.entries(snapshot.val());
      const doctoresInfoEspecifica = doctores.map(doctor => ({ nom: `${doctor[1].primerNombre} ${doctor[1].segundoNombre} ${doctor[1].apellidoPat} ${doctor[1].apellidoMat}`, cedula: doctor[1].cedula, skip: doctor[1].isAdmin, email: doctor[1].email, email2: doctor[1].email2, direccion: doctor[1].direccion, ref: doctor[0], telefono: doctor[1].telefono, especialidad: doctor[1].especialidad }) );

      // Sort doctoresInfoEspecifica[] por nombre
      doctoresInfoEspecifica.sort(compare);

      dispatch({ type: LISTADO_DOCTORES, payload: doctoresInfoEspecifica });
    }).catch(e => {
      console.log('Error viendo nombres de médico', e);
    });
  };
};

const getOneDoctor = dispatch => {
  return async cedula => {
    await database.ref('usuarios')
    .once('value')
    .then(snapshot => {
      const doctores = Object.entries(snapshot.val());
      const unDoctor = doctores.filter(doctor => doctor[1].cedula === cedula);
      dispatch({ type: UN_DOCTOR, payload: unDoctor[0] });
    }).catch(e => {
      console.log('Problemas obteniendo un doctor', e);
    });
  };
};

const estudiosDoctorDisplay = dispatch => {
  return uid => {
    database.ref(`estudios_solicitados/${uid}`)
      .once('value')
      .then(snapshot => {
        if (snapshot.val()) {
          const estudios = Object.values(snapshot.val());
          dispatch({ type: DOCTOR_ESTUDIOS, payload: estudios });
        } else {
          dispatch({ type: DOCTOR_ESTUDIOS, payload: [] });
        }
      })
      .catch(e => {
        console.log('Error obteniendo estudios de este doctor', e);
      });
  };
};

export const { Contexto, Proveedor } = crearContextoDatos(
  doctoresReducer,
  { getAllDoctores, getOneDoctor, estudiosDoctorDisplay },
  { doctores: [], doctor: null, listaEstudiosDoc: [] }
)