import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import history from '../../Navigation';

import { Contexto as DoctoresContext } from '../../context/DoctoresContext';

import { RestContainer } from '../../modules/RestContainer';
import { DetailDoctor } from '../screenNames';

const BuscarMedico = () => {
  const { state: { doctores } } = useContext(DoctoresContext);

  const [doctoresFiltrados, setDoctoresFiltrados] = useState([]);
  const [nomDoc, setNomDoc] = useState('');

  useEffect(() => {
    const nuevaLista = doctores.filter(doctor => {
      return doctor.nom.toUpperCase().includes(nomDoc.toUpperCase()) && !doctor.skip;
    });
    setDoctoresFiltrados(nuevaLista);
  }, [doctores, nomDoc]);

  if (doctoresFiltrados.length < 1) {
    return (
      <RestContainer>
        <div id="Seleccion" className="col-sm-12 col-md-12 col-lg-8">
          <h3 onClick={() => history.push('/home')} className="mb-3"><i className="fas fa-arrow-circle-left"></i> Buscar Médicos</h3>
          <div className="card mt-3 tarjetas">
            <div className="card-header">
              <h4>Resultados: {doctoresFiltrados.length} doctores</h4>
            </div>
            <div className="card-body">
              <ul className="list-group">
                <form className="mb-3">
                  <div className="form-row">
                    <div className="col-12">
                      <input className="form-control mr-sm-2" type="search" placeholder="Buscar por nombre" aria-label="Buscar"
                        onChange={e => setNomDoc(e.target.value)}
                        value={nomDoc}
                      />
                    </div>
                  </div>
                </form>
                <ul className="list-group">
                  <div className="list-group-item list-group-item-action">No hay doctores registrados en el sistema.</div>
                </ul>
              </ul>
            </div>
          </div>
        </div>
      </RestContainer>
    );
  }

  return (
    <RestContainer>
      <div id="Seleccion" className="col-sm-12 col-md-12 col-lg-8">
        <h3 onClick={() => history.push('/home')} className="mb-3"><i className="fas fa-arrow-circle-left"></i> Buscar Médicos</h3>
        <div className="card mt-3 tarjetas">
          <div className="card-header">
            <h4>Resultados: {doctoresFiltrados.length} doctores</h4>
          </div>
          <div className="card-body">
            <ul className="list-group">
              <form className="mb-3">
                <div className="form-row">
                  <div className="col-12">
                    <input className="form-control mr-sm-2" type="search" placeholder="Buscar por nombre" aria-label="Buscar"
                      onChange={e => setNomDoc(e.target.value)}
                      value={nomDoc}
                    />
                  </div>
                </div>
              </form>

              <ul className="list-group">
                {
                  doctoresFiltrados.map(doctor => {
                    if (doctor.cedula) {
                      return <Link key={doctor.cedula} to={`/${DetailDoctor}/${doctor.cedula}`} className="list-group-item list-group-item-action">Doctor {doctor.nom}</Link>;
                    }
                    return null;
                  })
                }
              </ul>
            </ul>
          </div>
        </div>
      </div>
    </RestContainer>
  );
};

export default BuscarMedico;