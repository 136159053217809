import React, { useContext } from 'react';
import history from '../../Navigation';

import { Contexto as EstudiosContext } from '../../context/EstudiosContext';
import { Contexto as UsersContext } from '../../context/UsersContext';
import { Contexto as AdminContext } from '../../context/AdminContext';

import Header from '../../modules/Header';
import Footer from '../../modules/Footer';
import EstudiosActivosContainer from '../../modules/EstudiosActivosContainer';

const ConsultarRegistros = () => {
  const { state: { listaEstudiosDoc, listadoEstatus } } = useContext(EstudiosContext);
  const { state: { nombre, apellidoPat, userUid, isAdmin } } = useContext(UsersContext);
  const { state: { allEstudiosDAT } } = useContext(AdminContext);

  return (
    <div className="container-fluid container-rest">
      <Header />
      <section className="row p-2 mt-2">
        <div className="col-sm-12 col-md-10">
          <h1 onClick={() => history.push('/home')}><i className="fas fa-arrow-circle-left"></i> Bienvenid@ <span className="d-none d-md-inline">{`${nombre} ${apellidoPat}`}</span></h1>
        </div>
        <div className="col-sm-12">
          <div className="division my-3"></div>
        </div>
        <div className="col-12">
          {
            isAdmin ?
              <EstudiosActivosContainer
                listaEstudiosDoc={allEstudiosDAT}
                limit={false}
                listadoEstatus={listadoEstatus}
                idDocBuscado={userUid}
              />
              :
              <EstudiosActivosContainer
                listaEstudiosDoc={listaEstudiosDoc}
                limit={false}
                listadoEstatus={listadoEstatus}
                idDocBuscado={userUid}
              />
          }
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default ConsultarRegistros;