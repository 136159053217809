import React from 'react';
import FormFacturar from './FormFacturar';

const FormPago = ({ payPalRef, costoEstudio, pago, facturar, fisicaMoral, RFC, errorPayPal, setFacturar, setFisicaMoral, setRFC, emailFactura, setEmailFactura, metodoPago }) => {
  return (
    <>
      <h5 className="mb-3 card-h5">Información del pago</h5>
      {errorPayPal && <div>¡Uh oh!, ha ocurrido un error: {errorPayPal.message}</div>}
      <div className="form-row">
        {
          costoEstudio > 0 ?
            pago ?
              <div className="col-6 mb-3">
                <div className="form-control mr-sm-2">¡El estudio ha sido pagado con éxito!</div>
              </div>
              :
              <>
                <div className="col-12 mb-3">
                  <div className="mr-sm-2 text-center">Monto a pagar: ${costoEstudio}.00 MXN</div>
                </div>
                {
                  costoEstudio > 0 ?
                    <div className="col-12 mb-3">
                      <div className="mr-sm-2 text-center">
                        ¿Necesita factura?: <input type="checkbox"
                          value="¿Se requiere factura?"
                          name="checkFactura"
                          checked={facturar}
                          onChange={e => setFacturar(e.target.checked)}
                        />
                      </div>
                    </div>
                    : null
                }
                {
                  facturar ?
                    <FormFacturar
                      fisicaMoral={fisicaMoral}
                      setFisicaMoral={setFisicaMoral}
                      RFC={RFC}
                      setRFC={setRFC}
                      correoFactura={emailFactura}
                      setEmailFactura={setEmailFactura}
                      metodoPago={metodoPago}
                    />
                    : null
                }
                <div className="col-12 mb-3 text-center">
                  <div ref={payPalRef} />
                </div>
              </>
            : null
        }
      </div>
    </>
  );
};

export default FormPago;