import axios from '../axios/axios';

export const handleEmailSolicitud = async (folio, nombrePaciente, apellidoPaciente, nacDia, nacMes, nacAnio, genero,
  categoriaElegida, estudioElegido, metodologiaEstudio, claveEstudio,
  nomDoc, cedula, email, telefono,
  primarioSelect, metastasicoSelect, muestraBloque, numeroMuestraBloque, muestraLaminillas, numeroMuestraLaminillas, muestraMes, muestraAnio, diagnostico, tratamientosPrevios, labRadio,
  inputNombreHospital, inputCalleNumero, inputCodigoPostal, inputColinia, inputAlcaldiaMun, inputEstado, inputReferencias, horarioRecoger,
  costoEstudio, firmaPaciente, firmaMedico) => {

  firmaPaciente = firmaPaciente ? String(firmaPaciente) : '';
  firmaMedico = firmaMedico ? String(firmaMedico) : '';
  const response = await axios.post('/emailestudio',
    {
      folio,
      nombrePaciente,
      apellidoPaciente,
      nacDia: Number(nacDia),
      nacMes: Number(nacMes),
      nacAnio: Number(nacAnio),
      genero,
      categoriaElegida,
      estudioElegido: estudioElegido[0],
      metodologiaEstudio,
      claveEstudio,
      nomDoc,
      cedula,
      email: email.toLocaleLowerCase(),
      telefono,
      primarioSelect,
      metastasicoSelect,
      muestraBloque,
      numeroMuestraBloque,
      muestraLaminillas,
      numeroMuestraLaminillas,
      muestraMes: Number(muestraMes),
      muestraAnio: Number(muestraAnio),
      diagnostico,
      tratamientosPrevios,
      labRadio,
      inputNombreHospital,
      inputCalleNumero,
      inputCodigoPostal,
      inputColinia,
      inputAlcaldiaMun,
      inputEstado,
      inputReferencias,
      horarioRecoger,
      costoEstudio: Number(costoEstudio),
      firmaPaciente,
      firmaMedico,
      docHistopato:'',
      docOtros:''
    }
  );

  console.log(response.status);
};

/**
 * 
 * @param {string} folio 
 * @param {string} nomDoc 
 * @param {string} correoDoc 
 */
export const handleEmailResListo = async (folio, nomDoc, correoDoc) => {
  const response = await axios.post('/emailresultado', {
    folio,
    nomDoc,
    correoDoc
  });

  console.log(response.status);
};