import React, { useRef, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import history from '../../Navigation';

import { Contexto as EstudiosContext } from '../../context/EstudiosContext';
import { Contexto as UsersContext } from '../../context/UsersContext';

import { RestContainer } from '../../modules/RestContainer';
import { formatoFechaInputHumano, formatoFechaBDD } from '../../utils/Fecha';
import { Estudio } from '../screenNames';

const SolicitarCotizacion = () => {
  const InputAgregarEstudio = useRef();

  const { state: { listaEstudios }, envioGuardarCotizacion } = useContext(EstudiosContext);
  const { state: { userUid, nombre, segNombre, apellidoPat, apellidoMat, intereses } } = useContext(UsersContext);

  const [categoriaElegida, setCategoriaElegida] = useState('');
  const [estudioElegido, setEstudioElegido] = useState([]);
  const [costoEstudio, setCostoEstudio] = useState(0);

  const nombreDoctor = `${nombre} ${segNombre} ${apellidoPat} ${apellidoMat}`;
  const fechaHoy = formatoFechaInputHumano(new Date());
  const fechaBDD = formatoFechaBDD(new Date());

  const filtroEstudioCategoria = listaEstudios.filter(estudio => estudio[1]['Categoría'] === categoriaElegida);

  // Habilitar el campo de otro estudio (todavía falta afinar esa lógica)
  const habilitarCampo = value => {
    if (value === 'Otro') {
      InputAgregarEstudio.current.disabled = false;
      InputAgregarEstudio.current.style.display = 'flex';
    } else {
      InputAgregarEstudio.current.disabled = true;
      InputAgregarEstudio.current.value = '';
      InputAgregarEstudio.current.style.display = 'none';
    }
  };

  // Después de que cambie el select Seleccione categoría
  const detectarCategoriaElegida = e => {
    setCategoriaElegida(e.target.value);
    setCostoEstudio(0);
  };

  // Después de que cambie el select Seleccione Estudio
  const setPrecioEstudio = e => {
    if (e.target.value) {
      habilitarCampo(e.target.value);
      const estudioSeleccionado = filtroEstudioCategoria.find(estudio => estudio[0] === e.target.value);
      setCostoEstudio(estudioSeleccionado[1].Costo);
      setEstudioElegido(estudioSeleccionado);
    } else {
      setCostoEstudio(0);
      setEstudioElegido([]);
    }
  }

  // Enviar info a base de datos
  const canelarCotizacion = () => {
    envioGuardarCotizacion(userUid, fechaBDD, estudioElegido[0]);
  }

  return (
    <RestContainer>
      <div id="Seleccion" className="col-sm-12 col-md-12 col-lg-8">
        <div className="row">
          <div className="col-12">
            <h3 onClick={() => history.push('/home')}><i className="fas fa-arrow-circle-left"></i> Solicitar cotización</h3>
          </div>
        </div>
        <div className="card mt-3">
          <div className="card-header">
            <h4>Nueva Cotización</h4>
          </div>
          <div className="card-body">
            <ul className="list-group">
              <form onSubmit={e => e.preventDefault()} className="my-3">
                <div className="form-row">
                  <div className="col-6 mb-3">
                    <div className="form-control mr-sm-2 text-center" type="input" placeholder="Nombre del Doctor" name="doctor">{nombreDoctor}</div>
                  </div>
                  <div className="col-6 mb-3">
                    <div className="form-control mr-sm-2 text-center" type="input" placeholder="Fecha de hoy" name="doctor">{fechaHoy}</div>
                  </div>
                  <div className="col-6 mb-3">
                    <select id="SelectCategoriaCoti" className="form-control mr-sm-2" name="estudio"
                      onChange={detectarCategoriaElegida}
                    >
                      <option value=''>Seleccione Categoría</option>
                      {
                        intereses.map(interes => {
                          return <option key={interes} value={interes}>{interes}</option>;
                        })
                      }
                    </select>
                  </div>
                  <div className="col-6 mb-3">
                    <select id="SelectEstudioCoti" className="form-control mr-sm-2" name="estudio" onChange={setPrecioEstudio}>
                      {
                        categoriaElegida ?
                          <>
                            <option value=''>Seleccione Estudio</option>
                            {
                              filtroEstudioCategoria.map(estudio => {
                                return <option key={estudio[0]} value={estudio[0]}>{estudio[0]}</option>;
                              })
                            }
                            {/* <option value="otro">Otro</option>; */}
                          </>
                          :
                          <option>No se ha elegido una categoría</option>
                      }
                    </select>
                  </div>
                  <div className="col-6 mb-3">
                    <input id="InputAgregarEstudio" ref={InputAgregarEstudio} className="form-control mr-sm-2" type="text" placeholder="Escriba el estudio" disabled name="otroEstudio" style={{ display: 'none' }} />
                  </div>
                  <div className="col-12 mb-3 text-center">
                    <h5 style={{ color: 'green', fontWeight: 'bold' }}>Costo de estudio: ${costoEstudio}.00 MXN</h5>
                  </div>
                  <div className="col-12 mb-3 text-center">
                    <button className="btn btn-danger btn-md mx-2"
                      type="submit"
                      onClick={canelarCotizacion}
                    >
                      Cancelar la cotización
                    </button>
                    {
                      costoEstudio > 0
                        ? <Link className="btn btn-primary btn-md"
                          type="submit"
                          to={{
                            pathname: `/${Estudio}`,
                            state: { estudioElegido, saltarElegirEspecialidad: true }
                          }}
                        >
                          Solicitar el Estudio
                        </Link>
                        : null
                    }
                  </div>
                </div>
              </form>
            </ul>
          </div>
        </div>
      </div>
    </RestContainer>
  );
};

export default SolicitarCotizacion;