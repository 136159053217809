import React, { useContext, useEffect, useState } from 'react';
import { Contexto as EstudiosContext } from '../../../context/EstudiosContext';
import { mesHumanoANumero, mesHumanoEsp2 } from '../../../utils/Fecha';
import FormPaciente from '../SolicitudPath/FormPaciente';
import InfoColecta from '../SolicitudPath/InfoColecta';
import InfoDeMuestra from '../SolicitudPath/InfoDeMuestra';

// <!-- staticBackdrop Modal -->
const ModalModifDatos = ({ solicitudActiva }) => {

  const referenciasPlaceHolder = 'Por favor indicar el piso, edificio, servicio, contacto que entregará la muestra o cualquier referencia que considere útil para una colecta exitosa.';

  const { state: { laboratorios, listadoDiagnosticos }, getListadoDiags } = useContext(EstudiosContext);

  useEffect(() => {
    console.log('solicitudActiva');
    console.log(solicitudActiva);
  }, [solicitudActiva]);

  /** SI no hay diagnosticos, obtenerlos */
  useEffect(() => {
    if (!listadoDiagnosticos.length) {
      getListadoDiags();
    }
    // eslint-disable-next-line
  }, []);

  const { apellidoPaciente, diagnostico, ecog, email, fechaMuestra, fechaNac, folio, muestraBloque, muestraLaminillas, numeroMuestraBloque, numeroMuestraLaminillas, nombrePaciente } = solicitudActiva;

  const currentYear = new Date().getFullYear('');

  const [nomPac, setNombrePaciente] = useState(nombrePaciente);
  const [apellidoPac, setApellidoPaciente] = useState(apellidoPaciente);

  const fechaNacSplit = fechaNac.split('-');
  const [nacDia, setNacDia] = useState(Number(fechaNacSplit[0]));
  const [nacMes, setNacMes] = useState(mesHumanoANumero(fechaNacSplit[1]));
  const [nacAnio, setNacAnio] = useState(Number(fechaNacSplit[2]));

  const [fechaNacHumano, setFechaNacHumano] = useState('');
  const [genero, setGenero] = useState('');
  const [emailPaciente, setEmailPaciente] = useState(email);
  const [ecog2, setEcog] = useState(ecog);
  const [telPaciente, setTelPaciente] = useState('');
  const [celular, setCelular] = useState('');

  const [primarioSelect, setPrimarioSelect] = useState('');
  const [metastasicoSelect, setMetastasicoSelect] = useState('');

  const [muestraB, setMuestraBloque] = useState(muestraBloque);
  const [muestraL, setMuestraLaminillas] = useState(muestraLaminillas);

  const [numeroMuestraB, setNumeroMuestraBloque] = useState(numeroMuestraBloque);
  const [numeroMuestraL, setNumeroMuestraLaminillas] = useState(numeroMuestraLaminillas);

  const fechaMuestraSplit = fechaMuestra.split('/');
  const [muestraMes, setMuestraMes] = useState(mesHumanoANumero(fechaMuestraSplit[0]));
  const [muestraAnio, setMuestraAnio] = useState(Number(fechaMuestraSplit[1]));

  const [selectDiagnostico, setSelectDiagnostico] = useState(listadoDiagnosticos.includes(diagnostico) ? diagnostico : 'Otro');
  const [diag, setDiagnostico] = useState(diagnostico);

  const [tratamientosPrevios, setTratamientosPrevios] = useState('');

  const [hiddenHistopatologico, setHiddenHistopatologico] = useState('');
  const [hiddenOtrosArchivos, setHiddenOtrosArchivos] = useState('');

  const [labRadio, setLabRadio] = useState('');

  const [listadoDirs, setListadoDirs] = useState([]);
  const [doctorRef, setDoctorRef] = useState('');
  const [dirElegida, setDirElegida] = useState(-2);
  const [inputCalleNumero, setInputCalleNumero] = useState('');
  const [inputCodigoPostal, setInputCodigoPostal] = useState('');
  const [inputColinia, setInputColinia] = useState('');
  const [inputAlcaldiaMun, setInputAlcaldiaMun] = useState('');
  const [inputEstado, setInputEstado] = useState('');
  const [inputNombreHospital, setInputNombreHospital] = useState('');
  const [inputReferencias, setInputReferencias] = useState('');

  const [horarioRecoger, setHorarioRecoger] = useState('');

  // Para SAFARI, manejo de la fecha de nacimiento
  const handleDateSafari = (value, maxValue, target, tipoFecha = 'nac') => {
    if (tipoFecha === 'nac') {
      if (target === 'dia') {
        setNacDia(value);

        setFechaNacHumano(`${value}-${mesHumanoEsp2(nacMes)}-${nacAnio}`);

      } else if (target === 'mes') {
        setNacMes(value);

        setFechaNacHumano(`${nacDia}-${mesHumanoEsp2(value)}-${nacAnio}`);

      } else {
        if (value > maxValue) {
          setNacAnio(currentYear);
        } else {
          setNacAnio(value);
        }

        setFechaNacHumano(`${nacDia}-${mesHumanoEsp2(nacMes)}-${value}`);
      }

    } else {
      if (target === 'mes') {
        setMuestraMes(value);
      } else {
        if (value > maxValue) {
          setMuestraAnio(currentYear);
        } else {
          setMuestraAnio(value);
        }
      }
    }
  };

  /**
   * Cambiar state y mostrar en pantalla subida exitosa
   * @param {string} idInput - id para obtener el resutlado
   */
  const handleInputFileChanged = idInput => {
    if (document.getElementById(idInput).files[0]) {
      const fileName = document.getElementById(idInput).files[0].name;
      window.Swal.fire({
        title: '¡Carga exitosa!',
        text: `El documento ${fileName} se ha subido exitosamente.`,
        icon: 'success',
        confirmButtonText: '¡Listo!'
      });

      switch (idInput) {
        case 'hiddenHistopatologico':
          setHiddenHistopatologico(fileName);
          break;
        case 'hiddenOtrosArchivos':
          setHiddenOtrosArchivos(fileName);
          break;
        default:
          break;
      }
    } else {
      window.Swal.fire({
        title: '¡Cuidado, no hay documento!',
        text: `Cancelaste la carga, por lo que no se tiene ningún documento adjunto.`,
        icon: 'warning',
        confirmButtonText: 'Ok'
      });
      switch (idInput) {
        case 'hiddenHistopatologico':
          setHiddenHistopatologico('');
          break;
        case 'hiddenOtrosArchivos':
          setHiddenOtrosArchivos('');
          break;
        default:
          break;
      }
    }
  };

  /**
   * 
   * @param {string} selectOption - Option del select
   */
  const handleSelectDiagnostico = selectOption => {
    setSelectDiagnostico(selectOption);
    if (selectOption !== 'Otro') {
      setDiagnostico(selectOption);
    } else {
      setDiagnostico('');
    }
  };

  const handleDirElegida = selectItem => {
    const itemAsNum = Number(selectItem);
    setDirElegida(itemAsNum);
    if (itemAsNum >= 0) {
      const dirFilter = listadoDirs[itemAsNum];
      setInputCalleNumero(dirFilter.calle);
      setInputCodigoPostal(dirFilter.cp);
      setInputColinia(dirFilter.colonia);
      setInputAlcaldiaMun(dirFilter.municipio);
      setInputEstado(dirFilter.estado);
      setInputNombreHospital(dirFilter.nombreHospital);
      setInputReferencias(dirFilter.referencias);
    } else {
      setInputCalleNumero('');
      setInputCodigoPostal('');
      setInputColinia('');
      setInputAlcaldiaMun('');
      setInputEstado('');
      setInputNombreHospital('');
      setInputReferencias('');
    }
  };

  return (
    <div className="modal fade" id="editSolicitudModal" data-backdrop="static" data-keyboard="false" tabIndex="-1" aria-labelledby="editSolicitudModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-xl modal-dialog-scrollable modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="editSolicitudModalLabel">Modal title</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Cerrar">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <FormPaciente
              folio={folio}
              setNombrePaciente={setNombrePaciente}
              nombrePaciente={nombrePaciente}
              setApellidoPaciente={setApellidoPaciente}
              apellidoPaciente={apellidoPaciente}
              fechaNacHumano={fechaNacHumano}
              handleDateSafari={handleDateSafari}
              nacDia={nacDia}
              nacMes={nacMes}
              nacAnio={nacAnio}
              currentYear={currentYear}
              genero={genero}
              setGenero={setGenero}
              setBothEmails={setEmailPaciente}
              emailPaciente={emailPaciente}
              ecog={ecog}
              setEcog={setEcog}
              telPaciente={telPaciente}
              setTelPaciente={setTelPaciente}
              celular={celular}
              setCelular={setCelular}
            />
            <hr className="mb-4" />

            <InfoDeMuestra
              primarioSelect={primarioSelect}
              setPrimarioSelect={setPrimarioSelect}
              metastasicoSelect={metastasicoSelect}
              setMetastasicoSelect={setMetastasicoSelect}
              muestraBloque={muestraBloque}
              setMuestraBloque={setMuestraBloque}
              muestraLaminillas={muestraLaminillas}
              setMuestraLaminillas={setMuestraLaminillas}
              numeroMuestraBloque={numeroMuestraBloque}
              setNumeroMuestraBloque={setNumeroMuestraBloque}
              numeroMuestraLaminillas={numeroMuestraLaminillas}
              setNumeroMuestraLaminillas={setNumeroMuestraLaminillas}
              fechaMuestra={fechaMuestra}
              handleDateSafari={handleDateSafari}
              muestraMes={muestraMes}
              muestraAnio={muestraAnio}
              currentYear={currentYear}
              selectDiagnostico={selectDiagnostico}
              handleSelectDiagnostico={handleSelectDiagnostico}
              setDiagnostico={setDiagnostico}
              diagnostico={diagnostico}
              tratamientosPrevios={tratamientosPrevios}
              setTratamientosPrevios={setTratamientosPrevios}
              handleInputFileChanged={handleInputFileChanged}
              inputFileHistopatologico={null}
              inputFileOtros={null}
              laboratorios={laboratorios}
              labRadio={labRadio}
              setLabRadio={setLabRadio}
              idTodo="Dos"
              listadoDiagnosticos={listadoDiagnosticos}
            />
            <hr className="mb-4" />

            <InfoColecta
              handleDirElegida={handleDirElegida}
              listadoDirs={listadoDirs}
              dirElegida={dirElegida}
              setInputCalleNumero={setInputCalleNumero}
              inputCalleNumero={inputCalleNumero}
              setInputCodigoPostal={setInputCodigoPostal}
              inputCodigoPostal={inputCodigoPostal}
              setInputColinia={setInputColinia}
              inputColinia={inputColinia}
              setInputAlcaldiaMun={setInputAlcaldiaMun}
              inputAlcaldiaMun={inputAlcaldiaMun}
              setInputEstado={setInputEstado}
              inputEstado={inputEstado}
              setInputNombreHospital={setInputNombreHospital}
              inputNombreHospital={inputNombreHospital}
              setInputReferencias={setInputReferencias}
              inputReferencias={inputReferencias}
              referenciasPlaceHolder={referenciasPlaceHolder}
              horarioRecoger={horarioRecoger}
              setHorarioRecoger={setHorarioRecoger}
            />

          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            <button type="button" className="btn btn-primary">Listo</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalModifDatos;