import React, { useContext } from 'react';
import history from '../../Navigation';

import { Contexto as EstudiosContext } from '../../context/EstudiosContext';

import { RestContainer } from '../../modules/RestContainer';
import Promociones from '../../modules/PromocionesPath/Promociones';

const ProgramasScreen = () => {
  const { state: { programas } } = useContext(EstudiosContext);

  return (
    <RestContainer>
      <div id="Seleccion" className="col-sm-12 col-md-12 col-lg-8">
        <div className="row">
          <div className="col-12">
            <h3 onClick={() => history.push('/home')}>
              <i className="fas fa-arrow-circle-left"></i> Listado de programas</h3>
          </div>
        </div>
        <Promociones
          programas={programas}
        />
      </div>
    </RestContainer >
  );
};

export default ProgramasScreen;