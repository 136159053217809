import * as firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/analytics';
import 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyCcokObW13CzANInkizqtpaWdbsZjuPmo8",
  authDomain: "diagnostique-a-tiempo.firebaseapp.com",
  databaseURL: "https://diagnostique-a-tiempo.firebaseio.com",
  projectId: "diagnostique-a-tiempo",
  storageBucket: "diagnostique-a-tiempo.appspot.com",
  messagingSenderId: "810241057481",
  appId: "1:810241057481:web:e35f10fbf487ef6cd5e318",
  measurementId: "G-NQCQP01RJZ"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

const database = firebase.database();;
const storageRef = firebase.storage();

export { firebase, database as default, storageRef } ;

// // .on('value', callback, error)
// categorias.on('value', snap => {
//   console.log(snap.val());
// }, errData);

// // Eliminar suscripción
// categorias.off();
