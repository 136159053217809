import React from 'react';

const ButtonNavSolicitud = ({ navegacion, cambioApartadoSolicitud, seccionesRef, destino, tituloGeneral, setTituloGeneral }) => {
  if (navegacion === 'Atrás') {
    return <button
      className="btn btn-primary btn-md mx-2 btn-avance-retroceso" type="button"
      onClick={() => {
        cambioApartadoSolicitud(`btn-crumb-${destino}`, seccionesRef.current[destino]);
        setTituloGeneral(tituloGeneral);
      }} >
      Atrás <i className="fas fa-arrow-circle-left"></i>
    </button>
  }

  return (
    <button
      className="btn btn-primary btn-md mx-2 btn-avance-retroceso" type="button"
      onClick={() => {
        cambioApartadoSolicitud(`btn-crumb-${destino}`, seccionesRef.current[destino]);
        setTituloGeneral(tituloGeneral);
      }} >
      {navegacion} <i className="fas fa-arrow-circle-right"></i>
    </button>
  );
};

export default ButtonNavSolicitud;