import React, { useEffect, useState } from 'react';
import AdminProgForm from '../../modules/AdminPath/AdminProgForm';

const EditarPrograma = ({ titulo, titulosEstudios, getDetalleEstudio, infoEstudioPart, enviarPrograma, titulosProgs, infoPrograPart, getDetalleProgra, eliminarPrograDuplicado, pharmas }) => {
  const [progSelect, setProgSelect] = useState('');

  useEffect(() => {
    if (progSelect) {
      getDetalleProgra(progSelect);
    }
    // eslint-disable-next-line
  }, [progSelect]);

  return (
    <div className="tab-pane fade" id="editarPrograma" role="tabpanel" aria-labelledby="editarPrograma-tab">
      <div className="card mt-3">
        <div className="card-header">
          <h5><i className="fi-cwsuxl-check"></i> Selecione el estudio que desea editar</h5>
        </div>
        <div className="card-body">
          <div className="form-row">
            <div className="col-12 mb-3">
              <label htmlFor="especialidad">Seleccione un programa</label>
              <select className="form-control mr-sm-2" name="especialidad"
                onChange={e => setProgSelect(e.target.value)}
              >
                <option value="">Seleccione uno de los programas</option>
                {
                  titulosProgs.map(prog => {
                    return <option key={prog} value={prog}>{prog}</option>;
                  })
                }
              </select>
            </div>
          </div>
        </div>
      </div>
      <AdminProgForm
        titulo={titulo}
        titulosEstudios={titulosEstudios}
        getDetalleEstudio={getDetalleEstudio}
        infoEstudioPart={infoEstudioPart}
        enviarPrograma={enviarPrograma}
        infoPrograPart={infoPrograPart}
        progSelect={progSelect}
        eliminarPrograDuplicado={eliminarPrograDuplicado}
        pharmas={pharmas}
      />
    </div>
  );
};

export default EditarPrograma;