import React from 'react';
import ReactDOM from 'react-dom';

import { Proveedor as UserProveedor } from './components/context/UsersContext';
import { Proveedor as EstudiosProveedor } from './components/context/EstudiosContext';
import { Proveedor as DoctoresProveedor } from './components/context/DoctoresContext';
import { Proveedor as AdminProveedor } from './components/context/AdminContext';
import { Proveedor as PacientesProveedor } from './components/context/PacientesContext';

import App from './components/App';

ReactDOM.render(
  <AdminProveedor>
    <UserProveedor>
      <EstudiosProveedor>
        <DoctoresProveedor>
          <PacientesProveedor>
            <App />
          </PacientesProveedor>
        </DoctoresProveedor>
      </EstudiosProveedor>
    </UserProveedor>
  </AdminProveedor>,
  document.getElementById('root')
);