import React from 'react';

const ApiCiFindtrack = () => {
  return (
    <div className="row printApCI" id="printSection">
      <p className="mb-2">Al completar el formato de solicitud de estudios para el programa FINDTRAK (el programa) certifico la información asentada y acepto los término del presente Consentimiento Informado y Aviso de Privacidad.</p>
      <h3 className="textoFormal">Consentimiento informado</h3>
      <ol className="text-justify mb-2">
        <li>Soy un médico o profesional de la salud con un título, cédula profesional y/o certificado para ejercer en México.</li>
        <li>Estoy capacitado para solicitar, analizar e interpretar el estudio solicitado.</li>
        <li>He informado a mi paciente o a su representante legal sobre la naturaleza del estudio, su utilidad, alcances y limitaciones.</li>
        <li>He obtenido el consentimiento de mi paciente o su representante legal para realizar la prueba solicitada.</li>
        <li>He obtenido el consentimiento de mi paciente o su representante legal para transferir su información personal que permita realizar la prueba solicitada.</li>
        <li>He proporcionado información veraz, correcta y completa para la realización de este estudio.</li>
        <li>Una muestra de tejido tumoral es requerida para realizar el estudio.</li>
        <li>Existe la posibilidad de falla en el estudio por considerarse la muestra proporcionada insuficiente o no adecuada.</li>
        <li>Una muestra adicional puede ser necesaria para realizar la prueba solicitada.</li>
        <li>En caso de existir material restante al finalizar el estudio, quedará en resguardo temporal del laboratorio y será retornado con el médico solicitante.</li>
        <li>El análisis de DNA es específico para ciertas condiciones. La exactitud de los análisis puede verse limitada por la técnica empleada, el conocimiento o tecnología existente al momento de realizar el estudio.</li>
        <li>Los resultados del paciente serán confidenciales y entregados únicamente al médico solicitante.</li>
        <li>El análisis y transporte de la muestra no tiene costo para el paciente, médico solicitante o institución.</li>
        <li>Puedo retirar mi consentimiento para el Programa en cualquier momento.</li>
        <li>El que suscribe, sus familiares, pacientes o representantes legales no han recibido propuestas de beneficios adicionales de ningún tipo por la realización de esta prueba y no reclamaremos reembolso alguno por estos servicios.</li>
      </ol>
      <p className="text-justify mb-1">
        Como médico solicitante, he informado sobre el programa diagnóstico denominado FINDTRACK (el programa) a mi paciente o su representante legal. Se me ha informado que el Programa es patrocinado por Diagnostique A Tiempo SAS de CV (DAT) en colaboración con BAYER de México SA de CV (Bayer) y tiene como objetivo permitir la realización de la prueba diagnóstica denominada “Análisis de fusiones del gen NTRK”.
        <br />
        Al entregar una muestra de tejido tumoral ésta será entregada a un laboratorio facultado para realizar dicha prueba con quién DAT tenga un convenio vigente. Los resultados de la prueba o información relacionada a la misma no será proporcionada a BAYER, únicamente será proporcionada al médico solicitante mediante distribución digital en la plataforma web de DAT disponible en www.diagnostiqueatiempo.com. Al hacer uso de la plataforma, como médico solicitante, entiendo y acepto los términos y condiciones y políticas de uso de la plataforma DAT.
      </p>
      <p className="text-justify mb-1">El programa no es una entidad prestadora de servicios de salud ni tiene como finalidad reemplazar el consejo médico ni condiciona el uso de tratamientos o medicamentos.</p>
      <p className="text-justify mb-1">
        Entiendo y reconozco que DAT no es responsable de los resultados derivados de la prueba solicitada y que éstos son responsabilidad exclusiva del laboratorio emisor del resultado y/o del laboratorio que realiza la prueba. Entiendo también que entiendo la existencia del riesgo de daño, robo, hurto o consumo total del material biológico que pudiera ocurrir durante el tránsito de la muestra hacia el laboratorio o durante el proceso y análisis del material y que tales circunstancias se encuentran fuera de control del médico solicitante y del Programa.
      </p>
      <p className="text-justify mb-2">
        Al firmar la solicitud de estudios que acompaña el presente documento reconozco plenamente las condiciones aquí descritas y entiendo y acepto perfectamente su contenido. He recibido oportunidad de realizar preguntas y estas han sido respondidas o explicadas a mi entera satisfacción.
      </p>
      <h3 className="textoFormal">Aviso de privacidad</h3>
      <p className="text-justify">
        Comprendo y acepto libre, expresa y voluntariamente suscribirme al programa denominado “FINDTRACK” en patrocinio por DX a Tiempo SAS de CV (Diagnostique A Tiempo, de ahora en adelante “DAT”) para que sea realizada la prueba “Análisis de fusiones del gen NTRK” mediante inmunohistoquímica y en caso de ser necesario, realizar la prueba mediante Secuenciación de Siguiente Generación a mi paciente. Al aceptar ingresar al programa también consiento que mis datos personales y confidenciales al igual que los de mi paciente sean usados para éste fin, y sean guardados y transferidos con terceros quienes proporcionen servicios a DAT para que accedan a esta información y hagan uso de ella. Esta transferencia de información se hará con socios comerciales, proveedores de servicios y/o laboratorios en convenio con DAT para apoyar en el traslado y procesamiento de las pruebas, en territorio nacional o en el extranjero.
        <br />
        DAT ha implementado controles técnicos y organizacionales para proteger mi información personal y la de mi paciente contra accesos no autorizados, daño o pérdida accidental. Si deseo ejercer mis derechos de Transparencia, Acceso, Rectificación, Supresión, Limitación, Portabilidad, Cancelación u Oposición en términos del capítulo IV de la Ley Federal de Protección de Datos Personales en Posesión de Particulares (LFPDPPP) o excluirse de comunicaciones o programas conforme a la ley aplicable, podré solicitarlo por escrito a Dx A Tiempo SAS de CV a la siguiente dirección de correo electrónico contacto@diagnostiqueatiempo.com o a la siguiente dirección: Avenida General Emiliano Zapata 360 E201, Colonia Santa Cruz Atoyac, C.P. 03310, alcaldía Benito Juárez, Ciudad de México, México.
      </p>
    </div>
  );
};

export default ApiCiFindtrack;