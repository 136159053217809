import React from 'react';

const ModalLogin = ({ children }) => {

  const guardarModal = () => {
    window.hideModal("#ModalLogin");
  };

  return (
    <div id="ModalLogin" className="modal fade">
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Ingresar al sistema</h5>
            <button type="button" className="close" onClick={guardarModal} aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="container-fluid">
              {children}
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={guardarModal}>Cerrar</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalLogin;