import React from 'react';

const ModalApCiBayer = () => {

  const guardarModal = () => {
    window.hideModal("#ModalApCiBayer");
  };

  return (
    <div id="ModalApCiBayer" className="modal fade">
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Aviso de Provacidad de Bayer</h5>
            <button type="button" className="close" onClick={guardarModal} aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="container-fluid">
              <div className="row printApCI" id="printSection">
                {/* Aviso de Privacidad  */}
                <h3 className="textoFormal">Aviso de Privacidad </h3>
                <p className="text-justify mb-3">
                  Bayer de México S.A. de C.V. (en lo sucesivo “Bayer”) pone a su disposición el presente Aviso de Privacidad con la finalidad de darle a conocer el tratamiento que le dará a sus datos personales de conformidad con las disposiciones de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares y su Reglamento (en lo sucesivo “la Ley”).
                </p>

                <h4 className="textoFormal">Identidad y domicilio del responsable del tratamiento de sus datos personales.</h4>
                <p className="text-justify">Bayer, con domicilio en Boulevard Miguel de Cervantes Saavedra 259, Col. Granada, Delegación Miguel Hidalgo, Ciudad de México, C.P. 11520 es el responsable en términos de la Ley.</p>

                <h4 className="textoFormal">Datos Personales.</h4>
                <p className="text-justify mb-2">Los datos personales que serán sujetos a tratamiento por parte de Bayer pertenecen a las siguientes categorías:</p>
                <ul className="text-justify mb-3">
                  <li>
                    Datos de identificación y contacto: Nombre, domicilio, teléfono, correo electrónico, CURP, fecha de nacimiento, edad, nacionalidad, fotografía y firma.
                  </li>
                  <li>
                    Datos laborales: Puesto o cargo, domicilio laboral, correo electrónico, teléfono y fax del trabajo, institución de salud a la que pertenece.
                  </li>
                  <li>
                    Datos académicos: Trayectoria educativa, título, número de cédula profesional, especialidad, tipos de práctica, certificados de estudios, información sobre sus intereses científicos y/o médicos, actividad profesional como investigador.
                  </li>
                  <li>
                    Datos financieros o patrimoniales. RFC, CLABE y Domicilio de facturación.
                  </li>
                  <li>
                    Datos de sus hábitos de consumo.
                  </li>
                </ul>

                <h4 className="textoFormal">Almacenamiento de sus datos personales.</h4>
                <p className="text-justify mb-3">Sólo conservamos sus datos durante el periodo de tiempo que resulta relevante para dar cumplimiento a las finalidades más adelante descritas; mientras mantengamos una relación comercial activa con usted; o para dar cumplimiento a un contrato o un litigio pendiente. En aquellos casos en los que tengamos que cumplir con obligaciones legales derivadas del tratamiento de sus datos, éstos serán conservados por los plazos indicados en la ley aplicable. Ej. Datos personales de personal que participa en ensayos clínicos.</p>

                <h4 className="textoFormal">Datos personales de menores de edad.</h4>
                <p className="text-justify mb-3">En Bayer conocemos la importancia que tiene la protección de la privacidad y los datos personales de los menores de edad, por lo que Bayer no obtiene, usa, divulga o almacena información relacionada con menores de edad, sin el consentimiento previo de sus padres o tutores.</p>
                <p className="text-justify mb-3">Si usted es padre/madre o tutor de algún menor de edad que haya proporcionado sus datos personales sin su consentimiento, puede solicitar que dichos datos sean cancelados contactando al Departamento de Protección de Datos de Bayer.</p>

                <h4 className="textoFormal">Finalidades y/o usos de los datos personales:</h4>
                <p className="text-justify mb-2">Bayer dará tratamiento a sus datos personales para las siguientes finalidades necesarias:</p>
                <ul className="text-justify mb-3">
                  <li>Visitarlo y contactarlo para promocionar los productos y servicios de Bayer.</li>
                  <li>Enviarle información relevante respecto de nuestros productos.</li>
                  <li>Entender mejor sus intereses y preferencias y hacerle llega información que podría interesarle acerca de nuestros productos y servicios.</li>
                  <li>Mejorar la colaboración e interacción con nuestros clientes.</li>
                  <li>Realizar estudios de mercado, análisis de potencialidad, y conocer su posición frente a medicamentos y patologías.</li>
                  <li>Realizar entrega de material científico, promocional y muestras médicas de productos de Bayer.</li>
                  <li>Cumplir con obligaciones legales derivadas de la relación que con usted se tenga o se llegase a tener.</li>
                  <li>Solicitarle información sobre el impacto que han tenido nuestros productos en sus pacientes con fines estadísticos y científicos.</li>
                  <li>Hacer reportes a las autoridades sanitarias nacionales y extranjeras respecto de posibles reacciones adversas y/o quejas técnicas acerca de nuestros productos.</li>
                  <li>Gestionar los detalles de su participación en eventos de educación continua, o congresos, ya sea como participante o ponente.</li>
                  <li>Registrar su participación en estudios y/o ensayos clínicos, cuando decida participar en los mismos.</li>
                  <li>Cuando así lo decida, grabar, transmitir y almacenar su testimonio y anécdotas.</li>
                  <li>Analizar el uso que hace de nuestras comunicaciones electrónicas para poder ajustar nuestros contenidos a sus preferencias.</li>
                </ul>

                <h4 className="textoFormal">Transferencias de datos personales</h4>
                <p className="text-justify mb-3">Bayer únicamente transferirá sus datos personales a terceros, nacionales o extranjeros, que estén relacionados jurídica o comercialmente con Bayer para cumplir con las finalidades descritas en el presente Aviso de Privacidad. Asimismo, Bayer podrá transferir sin su consentimiento sus datos personales en los casos previstos y autorizados por la Ley.</p>
                <p className="text-justify mb-3">En el caso que Bayer venda una unidad comercial a otra compañía y sus datos personales sean usados por dicha unidad comercial, los mismos podrán transferirse al comprador junto con el negocio a fin de que el comprador pueda utilizarlos de la misma manera en que Bayer los utilizaba.</p>
                <p className="text-justify mb-3">El Titular otorga su autorización para que Bayer transfiera sus Datos Personales a terceros, ya sean mexicanos o extranjeros sin que para ello se requiera recabar nuevamente su consentimiento, cuando la transferencia sea efectuada a sociedades subsidiarias o afiliadas bajo el control común del Responsable o a una sociedad matriz o a cualquier sociedad del mismo grupo de Bayer que opere bajo los mismos procesos y políticas internas.</p>

                <h4 className="textoFormal">Medidas de seguridad</h4>
                <p className="text-justify mb-3">Bayer ha implementado y mantiene las medidas de seguridad, técnicas, administrativas y físicas, necesarias para proteger sus datos personales y evitar su daño, pérdida, alteración, destrucción o el uso, acceso o tratamiento no autorizado.</p>

                <h4 className="textoFormal">Derechos del titular de los datos personales</h4>
                <p className="text-justify mb-3">Usted o su representante legal debidamente acreditado, podrán ejercer los derechos de Acceso, Rectificación, Cancelación y Oposición (derechos “ARCO”) respecto de sus datos personales. Asimismo, usted puede limitar el uso o divulgación de sus datos personales y revocar el consentimiento que haya otorgado para el tratamiento de sus datos personales, siempre y cuando el tratamiento no resulte necesario o derive de una relación jurídica.</p>
                <p className="text-justify mb-3">El ejercicio de los derechos previstos en la Ley se podrá llevar a cabo a través del envío de su solicitud en los términos establecidos por la Ley y su Reglamento, o el envío del formato sugerido que encontrará disponible en el sitio de internet www.bayer.mx, al correo electrónico <a href="mailto:data.protectionmx@bayer.com">data.protectionmx@bayer.com</a> o en nuestras instalaciones ubicadas en Boulevard Miguel de Cervantes Saavedra 259, Col. Granada. México, Ciudad de México, C.P. 11520.</p>

                <h4 className="textoFormal">Modificaciones al Aviso de Privacidad.</h4>
                <p className="text-justify mb-3">Bayer se reserva el derecho de enmendar o modificar el presente Aviso de Privacidad según estime conveniente, por ejemplo, para cumplir con cambios a la legislación o cumplir con disposiciones internas de Bayer.</p>
                <p className="text-justify mb-3">De requerir notificarle de los cambios en el tratamiento de datos personales o recabar nuevamente su consentimiento, Bayer pondrá a su disposición el Aviso de Privacidad actualizado al correo electrónico que nos haya proporcionado o en el sitio web <a href="www.bayer.mx" target="_blank">www.bayer.mx</a></p>

                <h4 className="textoFormal">Contacto</h4>
                <p className="text-justify mb-3">Podrá dirigir preguntas o comentarios respecto al presente Aviso de Privacidad y otras cuestiones de protección de datos personales al Departamento de Protección de Datos Personales a través de los siguientes canales: correo electrónico: data.protectionmx@bayer.com; teléfono: (01 55) 5713 2288; o directamente en nuestras oficinas en: Boulevard Miguel de Cervantes Saavedra 259, Col. Granada, Delegación Miguel Hidalgo, Ciudad de México, C.P. 11520</p>

                <h4 className="textoFormal">Fecha de última actualización: mayo 2019</h4>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={guardarModal}>Cerrar</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalApCiBayer;