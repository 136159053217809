import database, { firebase } from '../firebase/Firebase';
import crearContextoDatos from './crearContextoDatos';
import {
  USER_SIGNIN, USER_SIGNOUT,
  USER_REGISTER,
  INTERESES_USUARIO, USER_COMPLETANDO_REGISTRO, USER_REGISTER_INFO
} from '../actions/actionNames';

import history from '../Navigation';

const reducerSignIn = (state = null, action) => {
  switch (action.type) {
    case USER_REGISTER:
      return { ...state, userUid: action.payload, login: 'register' };
    case USER_REGISTER_INFO:
      return {
        ...state,
        nombre: action.payload.nombre,
        segNombre: action.payload.segNombre,
        apellidoPat: action.payload.apellidoPat,
        apellidoMat: action.payload.apellidoMat,
        cedula: action.payload.cedula,
        intereses: action.payload.intereses,
        isAdmin: action.payload.isAdmin,
        operador: action.payload.operador,
        email: action.payload.email,
        telefono: action.payload.telefono,
        direccion: action.payload.direccion
      };
    case USER_COMPLETANDO_REGISTRO:
      return { ...state, login: 'signin', intereses: action.payload };
    case USER_SIGNIN:
      return {
        userUid: action.payload.userUid,
        login: 'signin',
        nombre: action.payload.nombre,
        segNombre: action.payload.segNombre,
        apellidoPat: action.payload.apellidoPat,
        apellidoMat: action.payload.apellidoMat,
        cedula: action.payload.cedula,
        intereses: action.payload.intereses,
        isAdmin: action.payload.isAdmin,
        operador: action.payload.operador,
        email: action.payload.email,
        telefono: action.payload.telefono,
        direccion: action.payload.direccion,
        emailVerified: action.payload.emailVerified
      };
    case USER_SIGNOUT:
      return { userUid: null, login: null, intereses: [] };
    case INTERESES_USUARIO:
      return { ...state, intereses: action.payload };
    default:
      return state;
  }
};

// Que un usuario entre con sus credenciales
const loginUsuario = dispatch => {
  return async (usuario, contrasenia) => {
    var errorLogin = false;

    await firebase.auth().signInWithEmailAndPassword(usuario, contrasenia)
      .then(async response => {
        window.localStorage.setItem('logueado', true);

        const { uid, emailVerified } = response.user;

        await database.ref(`usuarios/${uid}`)
          .once('value')
          .then(snapshot => {
            const nombre = snapshot.val().primerNombre;
            const segNombre = snapshot.val().segundoNombre;
            const apellidoPat = snapshot.val().apellidoPat;
            const apellidoMat = snapshot.val().apellidoMat;
            const intereses = snapshot.val().intereses;
            const cedula = snapshot.val().cedula;
            const isAdmin = snapshot.val().isAdmin;
            const operador = snapshot.val().operador;
            const email = snapshot.val().email;
            const direccion = snapshot.val().direccion;

            const telefono = snapshot.val().telefono;
            dispatch({ type: USER_SIGNIN, payload: { userUid: uid, nombre, segNombre, apellidoPat, apellidoMat, intereses, cedula, isAdmin, operador, email, telefono, direccion, emailVerified } });

            errorLogin = false;
            if (response.user.emailVerified) {
              history.push('/home');
            }
          })
          .catch(e => {
            console.clear();
            // console.log('Error buscando intereses', e);
          });
      })
      .catch(err => {
        console.clear();
        errorLogin = err.code;
        // console.log(err);
      });

    return errorLogin;
  };
};

/**
 * // Cuando ya está logueado un usuario pero entran a home
 * @param {*} dispatch - idUsuario, nombre, apellido e intereses
 * @param {string} userId 
 */
const enterUsuario = dispatch => {
  return async (userUid, emailVerified) => {
    database.ref(`usuarios/${userUid}`)
      .once('value')
      .then(snapshot => {
        const nombre = snapshot.val().primerNombre;
        const segNombre = snapshot.val().segundoNombre;
        const apellidoPat = snapshot.val().apellidoPat;
        const apellidoMat = snapshot.val().apellidoMat;
        const intereses = snapshot.val().intereses;
        const cedula = snapshot.val().cedula;
        const isAdmin = snapshot.val().isAdmin;
        const operador = snapshot.val().operador;
        const email = snapshot.val().email;
        const direccion = snapshot.val().direccion;

        const telefono = snapshot.val().telefono;
        dispatch({ type: USER_SIGNIN, payload: { userUid, nombre, segNombre, apellidoPat, apellidoMat, intereses, cedula, isAdmin, operador, email, telefono, direccion, emailVerified } });
      })
      .catch(e => {
        console.clear();
        console.log('Error buscando intereses', e);
      });
  };
};

// Una nueva persona está intentando registrarse
const registrarNuevoUsuario = dispatch => {
  return async (usuario, contrasenia) => {
    var error = '';

    await firebase.auth().createUserWithEmailAndPassword(usuario, contrasenia)
      .then(response => {
        dispatch({ type: USER_REGISTER, payload: response.user.uid });
      }).catch(err => {
        console.clear();
        console.log('Error al registrar', err);
        error = err.code;
      });

    return error;
  };
};

// Se registró una persona y ahora hay que agregar su info a la base de datos
const registrarInfoNuevoUsuario = dispatch => {
  return async (uid, infoPersona) => {
    await database.ref(`usuarios/${uid}`).set(infoPersona);

    const nombre = infoPersona.primerNombre;
    const segNombre = infoPersona.segundoNombre;
    const apellidoPat = infoPersona.apellidoPat;
    const apellidoMat = infoPersona.apellidoMat;
    const cedula = infoPersona.cedula;
    const isAdmin = infoPersona.isAdmin;
    const operador = infoPersona.operador;
    const email = infoPersona.email;
    const telefono = infoPersona.telefono;
    const direccion = [infoPersona.direccion[0]];

    dispatch({ type: USER_REGISTER_INFO, payload: { nombre, segNombre, apellidoPat, apellidoMat, cedula, isAdmin, operador, email, telefono, direccion } });
  };
};

// Se actualizará la info de una persona dentro del sistema
const updateUsuario = () => {
  return async (uid, infoPersona) => {
    await database.ref(`usuarios/${uid}`).update(infoPersona);
  };
};

const updateDireccion = () => {
  return async (uid, direccion, posicionDir) => {
    await database.ref(`usuarios/${uid}/direccion`).update({ [posicionDir]: direccion })
      .catch(e => {
        console.log('Error al actualizar la dirección del usuario', e);
      });;
  }
}

// Ya tenemos registrada la info de la nueva cuenta, ahora que elija sus intereses
const setInteresesUsuario = dispatch => {
  return async (uid, intereses) => {
    var setInteresesExitoso = false;
    if (uid) {
      dispatch({ type: USER_COMPLETANDO_REGISTRO, payload: intereses });
      await database.ref(`usuarios/${uid}/intereses`).set(intereses)
        .then(() => {
          setInteresesExitoso = true;
        }).catch(e => {
          console.log('Error al cambiar intereses del usuario', e);
        });

      window.localStorage.setItem('logueado', true);
    }
    return setInteresesExitoso;
  };
};

// Que el usuario cierre sesión
const logoutUsuario = dispatch => {
  return async () => {
    window.localStorage.clear();
    dispatch({ type: USER_SIGNOUT });

    await firebase.auth().signOut();
    history.push('/');
  };
};

const resetPassUsuario = () => {
  return async emailAddress => {
    var resetExitoso = false;
    await firebase.auth().sendPasswordResetEmail(emailAddress)
      .then(() => {
        // Email sent.
        resetExitoso = true;
      }).catch(e => {
        console.log('Ocurrió un error al mandar el correo de cambio de contraseña', e);
      });
    return resetExitoso;
  };
};

export const { Contexto, Proveedor } = crearContextoDatos(
  reducerSignIn,
  {
    loginUsuario, enterUsuario, registrarNuevoUsuario,
    registrarInfoNuevoUsuario, logoutUsuario, setInteresesUsuario,
    updateUsuario, resetPassUsuario, updateDireccion
  },
  {
    userUid: null, login: null,
    nombre: '', segNombre: '', apellidoPat: '', apellidoMat: '',
    intereses: [], cedula: '', email: '',
    telefono: '', direccion: [],
    isAdmin: false, operador: false, emailVerified: false
  }
);