import database from '../firebase/Firebase';
import crearContextoDatos from './crearContextoDatos';
import { BUSQUEDA_FOLIO_SOLICITUD } from '../actions/actionNames';

const reducerPacientes = (state = null, action) => {
  switch (action.type) {
    case BUSQUEDA_FOLIO_SOLICITUD:
      return {
        ...state,
        folioEncontrado: action.payload.folioEncontrado,
        idEmplear: action.payload.idEmplear,
        nombrePaciente: action.payload.nombrePaciente,
        estudioElegido: action.payload.estudio,
        pago: action.payload.pago,
        costoEstudio: action.payload.costo,
        empresaPharma: action.payload.empresaPharma,
        firmaMed: action.payload.firmaMedico
      };
    default:
      return state;
  }
};

/**
 * 
 * @param {number} numFolio
 * Guardar el id del médico para mandarlo al guardado de los datos.
 * Guardar el path para hacerlo más sencillo.
 * Decir si se encontró el folio.
 * Guardar los datos del paciente para que visualmente lo comprueben. 
 */
const buscarSolicitudFolio = dispatch => {
  return async numFolio => {
    await database.ref('estudios_solicitados')
    .once('value')
    .then(snapshot => {
      // const listadoEstudios = Object.keys(snapshot.val());
      const listadoEstudios = Object.entries(snapshot.val());

      for (let i = 0; i < listadoEstudios.length; i++) {
        const idMedico = listadoEstudios[i][0];
        let nombrePaciente = '';
        let estudio = '';
        let pago = false;
        let costo = 0;
        let empresaPharma = '';
        let firmaMedico = '';

        const idEncontrado = Object.entries(listadoEstudios[i][1]).find(estudioId => {
          console.log(estudioId);
          estudio = estudioId[1].estudio;
          pago = estudioId[1].pago;
          costo = estudioId[1].costo;
          empresaPharma = estudioId[1].empresaPharma;
          firmaMedico = estudioId[1].firmaMedico;
          
          nombrePaciente = `${estudioId[1].nombrePaciente} ${estudioId[1].apellidoPaciente}`;
          return estudioId[0] === numFolio;
        });
        if (idEncontrado) {
          dispatch({
            type: BUSQUEDA_FOLIO_SOLICITUD, payload: {
              idEmplear: idMedico,
              folioEncontrado: true,
              nombrePaciente,
              estudio,
              pago,
              costo,
              empresaPharma,
              firmaMedico
            }
          });
          break;
        } else {
          dispatch({
            type: BUSQUEDA_FOLIO_SOLICITUD, payload: {
              idEmplear: null,
              folioEncontrado: false,
              nombrePaciente: null,
              estudio: null,
              pago: null,
              costo,
              empresaPharma: null,
              firmaMedico: null
            }
          });
        }
      }
    }).catch(e => {
      console.log('Error buscando folio', e);
    });
  };
};

export const { Contexto, Proveedor } = crearContextoDatos(
  reducerPacientes,
  { buscarSolicitudFolio },
  { folioEncontrado: false, nombrePaciente: null, idEmplear: null, estudioElegido: null, pago: null, costoEstudio: 0, empresaPharma: null, firmaMed: null }
);