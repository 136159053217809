import React, { useState, useEffect, useContext } from 'react';

import { Contexto as UsersContext } from '../../context/UsersContext';
import { Contexto as AdminContext } from '../../context/AdminContext';

import LoginContainer from '../../modules/LoginPath/LoginContainer';
import mostrarSaludo from '../../utils/Saludo';
import history from '../../Navigation';

const SegundaFase = () => {
  const { state, setInteresesUsuario } = useContext(UsersContext);
  const { state: { titulosCategorias }, getAllCategorias } = useContext(AdminContext);
  const [interesesElegidos, setInteresesElegidos] = useState([]);

  const categoriasButtons = [];
  const { texto } = mostrarSaludo();

  useEffect(() => {
    getAllCategorias();
    // eslint-disable-next-line
  }, []);

  // Definir render para listado de botones
  if (titulosCategorias) {
    Object.keys(titulosCategorias).map(function (key, index) {
      return categoriasButtons.push(<button
        key={key}
        className={`btn btn-lg btn-outline-primary text-uppercase m-2 col-5 ${index}`}
        onClick={e => {
          e.target.classList.toggle('active');

          e.target.className.includes('active') ?
            setInteresesElegidos([...interesesElegidos, titulosCategorias[key]])
            : setInteresesElegidos(interesesElegidos.filter(interes => titulosCategorias[key] !== interes))
        }}
        type="button" >
        {titulosCategorias[key]}
      </button>);
    });
  }

  const enviarIntereses = () => {
    const response = setInteresesUsuario(state.userUid, interesesElegidos);
    if (response) {
      history.push('/home');
    }
  }

  return (
    <LoginContainer>
      <div className="form-group">
        <p>
          <b>{texto}<span id="saludo"></span> doctor@,</b>
          <br />
          {/* seleccione su especialidad */}
          seleccione sus intereses
        </p>
      </div>
      <div className="form-row mb-3 d-flex justify-content-center">
        {categoriasButtons}
      </div>
      {
        interesesElegidos.length > 0 ?
          <button
            style={{ color: 'white' }} className="btn btn-lg btn-primary btn-block text-uppercase" type="button"
            onClick={enviarIntereses}
          >
            Continuar
          </button>
          : null
      }
    </LoginContainer>
  );
};

export default SegundaFase;
