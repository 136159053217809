import React, { useEffect, useContext, useState } from 'react';
import { Route, Switch, Redirect, Router } from 'react-router-dom';

import { firebase } from '../components/firebase/Firebase';
import history from './Navigation';
import { Contexto as UsersContext } from './context/UsersContext';

import {
  registro, completarregistro,
  home, Verificar,
  Registros, Cotizacion, Estudio,
  Programas, Doctores, DetailDoctor,
  DetalleRegistro, Admin,
  AvisoPrivacidad, TerminosCondiciones, Cookies,
  FirmaMedico, FirmaPaciente
} from './screens/screenNames';
import Login from './screens/LoginPath/Login';
import Register from './screens/LoginPath/Register';
import SegundaFase from './screens/LoginPath/SegundaFase';
import Home from './screens/HomePath/Home';

import Verificacion from './screens/VeriticacionPath/Verificacion';

import ConsultarRegistros from './screens/HomePath/ConsultarRegistros';
import SolicitarCotizacion from './screens/HomePath/SolicitarCotizacion';
import SolicitarEstudio from './screens/HomePath/SolicitudPath/SolicitarEstudio';
import ProgramasScreen from './screens/HomePath/ProgramasScreen';
import DetalleCaso from './screens/HomePath/DetalleCaso/DetalleCaso';

import BuscarMedico from './screens/HomePath/BuscarMedico';
import DetalleDoctor from './screens/HomePath/DetalleDoctor';

import Administracion from './screens/AdminPath/Administracion';

import Privacidad from './screens/AvisosPath/Privacidad';
import Terminos from './screens/AvisosPath/Terminos';
import PoliticaCookies from './screens/AvisosPath/PoliticaCookies';

import VisorFirma from './screens/FolioPath/VisorFirma';

import NotFound from './screens/NotFoundPath/NotFound';
import mostrarSaludo from './utils/Saludo';

import './App.css';

const App = () => {
  const { state: { isAdmin, operador, userUid, emailVerified, login }, enterUsuario } = useContext(UsersContext);
  const [redirigir, setRedirigir] = useState(false);

  useEffect(() => {
    // Saber si hay un usuario adentro o no
    // No importa si se detiene/refresca la página, firebase mantiene la sesión.
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        if (user.emailVerified) {
          if (localStorage.getItem('logueado')) {
            enterUsuario(user.uid, user.emailVerified);
          }
          if (history.location.pathname === '/') {
            history.push(`/${home}`);
          }
        } else {
          history.push(`/${Verificar}`);
        }
      } else {
        // registro FirmaMedico FirmaPaciente AvisoPrivacidad TerminosCondiciones Cookies
        if (history.location.pathname !== `/${registro}` && !history.location.pathname.includes(FirmaMedico) && !history.location.pathname.includes(FirmaPaciente) && history.location.pathname !== `/${AvisoPrivacidad}` && history.location.pathname !== `/${TerminosCondiciones}` && history.location.pathname !== `/${Cookies}` ) {
          history.push('/');
        }
      }
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const { fondo } = mostrarSaludo();
    document.body.classList.add(fondo);
  }, []);

  useEffect(() => {
    if (userUid && emailVerified && login !== 'register') {
      setRedirigir(true);
    }
    setRedirigir(false)
  }, [userUid, login, emailVerified]);

  return (
    <Router history={history}>
      <Switch>
        <Route exact path='/' component={Login}>
          {userUid && emailVerified ? <Redirect to={`/${home}`} /> : null}
        </Route>
        <Route exact path={`/${FirmaMedico}/:id`} component={VisorFirma} />
        <Route exact path={`/${FirmaPaciente}/:id`} component={VisorFirma} />

        <Route exact path={`/${AvisoPrivacidad}`} component={Privacidad} />
        <Route exact path={`/${TerminosCondiciones}`} component={Terminos} />
        <Route exact path={`/${Cookies}`} component={PoliticaCookies} />

        <Route path={`/${Verificar}`} exact component={Verificacion}>
          {userUid && emailVerified ? <Redirect to={`/${home}`} /> : null}
        </Route>

        <Route path={`/${registro}`} exact component={Register}>
          {redirigir && emailVerified ? <Redirect to={`/${home}`} /> : null}
        </Route>
        <Route path={`/${completarregistro}`} exact component={SegundaFase}>
          {redirigir && emailVerified ? <Redirect to={`/${home}`} /> : null}
        </Route>

        <Route path={`/${home}`} exact component={Home}>
          {userUid && !emailVerified ? <Redirect to={`/${Verificar}`} /> : null}
        </Route>

        <Route path={`/${Registros}`} exact component={ConsultarRegistros}>
          {userUid ? null : <Redirect to='/' />}
        </Route>
        <Route path={`/${Registros}`} exact component={ConsultarRegistros}>
          {userUid ? null : <Redirect to='/' />}
        </Route>
        <Route path={`/${Cotizacion}`} exact component={SolicitarCotizacion}>
          {userUid ? null : <Redirect to='/' />}
        </Route>
        <Route path={`/${Estudio}`} exact component={SolicitarEstudio}>
          {userUid ? null : <Redirect to='/' />}
        </Route>
        <Route path={`/${Programas}`} exact component={ProgramasScreen}>
          {userUid ? null : <Redirect to='/' />}
        </Route>
        <Route path={`/${DetalleRegistro}/:id`} exact component={DetalleCaso}>
          {userUid ? null : <Redirect to='/' />}
        </Route>

        {
          isAdmin && (
            <>
              <Route path={`/${Doctores}`} exact component={BuscarMedico}>
                {userUid ? null : <Redirect to='/' />}
              </Route>
              <Route path={`/${DetailDoctor}/:id`} exact component={DetalleDoctor}>
                {userUid ? null : <Redirect to='/' />}
              </Route>
              {!operador && < Route path={`/${Admin}`} exact component={Administracion} />}
            </>
          )
        }
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
}

export default App;