import database, { storageRef } from '../firebase/Firebase';
import crearContextoDatos from './crearContextoDatos';
import {
  LISTADO_ESTUDIOS, ESTATUS_ESTUDIOS, GET_PROMOCIONES,
  SEND_COTIZACION, DOCTOR_ESTUDIOS, REPORTE_HISTOPATOLOGICO,
  ESTUDIO_APCI, ESTUDIO_PAGO, RES_EMITIDO,
  RES_EMITIDO_AGAIN, LIMPIAR_ARCHIVOS, ALL_LABS,
  ALL_DIAGS
} from '../actions/actionNames';

import history from '../Navigation';

const reducerDisplayEstudio = (state = null, action) => {
  switch (action.type) {
    case LISTADO_ESTUDIOS:
      return { ...state, listaEstudios: action.payload };
    case ESTATUS_ESTUDIOS:
      return { ...state, listadoEstatus: action.payload };
    case GET_PROMOCIONES:
      return { ...state, programas: action.payload };
    case SEND_COTIZACION:
      return { ...state, cotizacion: action.payload };
    case DOCTOR_ESTUDIOS:
      return { ...state, listaEstudiosDoc: action.payload };
    case ESTUDIO_APCI:
      return { ...state, docApCi: action.payload };
    case ESTUDIO_PAGO:
      return { ...state, docPago: action.payload };
    case RES_EMITIDO:
      return { ...state, resEmitidoBD: action.payload };
    case RES_EMITIDO_AGAIN:
      return { ...state, resEmitidoDosBD: action.payload };
    case REPORTE_HISTOPATOLOGICO:
      return { ...state, reporteHistoDoc: action.payload };
    case LIMPIAR_ARCHIVOS:
      return { ...state, docApCi: null, docPago: null, resEmitidoBD: null, reporteHistoDoc: null };
    case ALL_LABS:
      return { ...state, laboratorios: action.payload };
    case ALL_DIAGS:
      return { ...state, listadoDiagnosticos: action.payload };
    default:
      return state;
  };
};

const getAllEstudios = dispatch => {
  return async intereses => {
    await database.ref('estudios')
      .once('value')
      .then(snapshot => {
        if (snapshot.val()) {
          const estudios = Object.entries(snapshot.val());
          const estudiosPorIntereses = estudios.filter(estudio => intereses.includes(estudio[1]['Categoría']));

          dispatch({ type: LISTADO_ESTUDIOS, payload: estudiosPorIntereses });
        }
      })
      .catch(e => {
        console.log('Error obteniendo estudios', e);
      });
  };
};

const getAllEstatus = dispatch => {
  return async () => {
    await database.ref('estatus_estudios')
      .once('value')
      .then(snapshot => {
        const estatus_estudios = Object.entries(snapshot.val());
        dispatch({ type: ESTATUS_ESTUDIOS, payload: estatus_estudios });
      }).catch(e => {
        console.log('Error obteniendo clases de estatus', e);
      })
  };
};

const displayProgramas = dispatch => {
  return async fechaActual => {
    await database.ref('programas')
      .once('value')
      .then(snapshot => {
        // Saber si están en promoción o no
        const listaProgramas = Object.entries(snapshot.val());
        // Saber si están dentro del rango de fechas
        const programasEsFecha = listaProgramas.filter(promo => {
          return promo[1].fecha_promo_inicio <= fechaActual && fechaActual <= promo[1].fecha_promo_fin;
        });

        dispatch({ type: GET_PROMOCIONES, payload: programasEsFecha });
      })
      .catch(e => {
        console.log('Error obteniendo listado de promociones', e);
      });
  };
};

const envioGuardarCotizacion = () => {
  return (uid, fecha, cotizacion) => {
    database.ref(`cotizaciones/${uid}/${fecha}`).push(cotizacion);
    history.push('/home');
  };
};

const cotizacionASolicitud = dispatch => {
  return cotizacion => {
    dispatch({ type: SEND_COTIZACION, payload: cotizacion });
    history.push('/SolicitarEstudio');
  };
};

const subirEstudio = () => {
  return async (uid, nuevaSolicitud, folioId, archivoFirmado = null, reporteHistopatologico = null, otroArchivo = null) => {
    await database.ref(`estudios_solicitados/${uid}/${folioId}`).set(nuevaSolicitud);
    if (archivoFirmado) {
      const refArchivo = storageRef.ref(`${uid}/${folioId}/${archivoFirmado.name}`);
      await refArchivo.put(archivoFirmado)
        .catch(e => console.log('Hubo un error subiendo el archivo', e));
    }

    if (reporteHistopatologico) {
      const refReporteHistopatologico = storageRef.ref(`${uid}/${folioId}/${reporteHistopatologico.name}`);
      await refReporteHistopatologico.put(reporteHistopatologico)
        .catch(e => console.log('Hubo un error subiendo el reporte histopatológico', e));
    }

    if (otroArchivo) {
      const refOtro = storageRef.ref(`${uid}/${folioId}/${otroArchivo.name}`);
      await refOtro.put(otroArchivo)
        .catch(e => console.log('Hubo un error subiendo el archivo OTRO', e));
    }
    history.push('/home');
  };
};

const subirApCiEstudio = dispatch => {
  return (uid, folioId, archivoFirmado) => {
    database.ref(`estudios_solicitados/${uid}/${folioId}`).update({ file: archivoFirmado.name });
    const refArchivo = storageRef.ref(`${uid}/${folioId}/${archivoFirmado.name}`);
    refArchivo.put(archivoFirmado)
      .catch(e => console.log('Error subiendo el archivo desde Detalle', e));
    dispatch({ type: ESTUDIO_APCI, payload: archivoFirmado });
  };
};

const bajarApCiEstudio = dispatch => {
  return (uid, folioId, nombreArchivo) => {
    const refArchivo = storageRef.ref(`${uid}/${folioId}/${nombreArchivo}`);
    refArchivo.getDownloadURL()
      .then(url => {
        dispatch({ type: ESTUDIO_APCI, payload: url });
      }).catch(e => {
        console.log('Error bajando doc', e);
      });
  };
};

const updateEtapaEstudio = () => {
  return (uid, folioId, etapaActual) => {
    database.ref(`estudios_solicitados/${uid}/${folioId}`).update({ etapa: etapaActual })
      .catch(e => {
        console.log('No se pudo actualizar la etapa', e);
      });
  };
};

const updateInfoEstudio = () => {
  return (uid, folioId, tipo, infoNueva) => {
    database.ref(`estudios_solicitados/${uid}/${folioId}/${tipo}`).set(infoNueva)
      .catch(e => {
        console.log(`No se pudo subir la info para ${tipo}`, e);
      });
  };
};

/**
 * Subir cualquier documento
 * @param {string} uid
 * @param {string} folioId
 * @param {file} arResutlado - Archivo a subir
 * @param {string} tipo - const string reflejando la etapa --> ESTUDIO_PAGO
 */
const subirDocumentosDB = dispatch => {
  return (uid, folioId, arResutlado, tipo) => {
    // database.ref(`estudios_solicitados/${uid}/${folioId}/resultado_emitido`).update({ file: arResutlado.name });
    const refArchivo = storageRef.ref(`${uid}/${folioId}/${arResutlado.name}`);
    refArchivo.put(arResutlado)
      .catch(e => console.log('Error subiendo el archivo desde Detalle', e));
    dispatch({ type: tipo, payload: arResutlado });
  };
};

const bajarDocumentos = dispatch => {
  return (uid, folioId, nombreArchivo, tipo) => {
    const refArchivo = storageRef.ref(`${uid}/${folioId}/${nombreArchivo}`);
    refArchivo.getDownloadURL()
      .then(url => {
        dispatch({ type: tipo, payload: url });
      }).catch(e => {
        console.log('Error bajando resultado emitido', e);
      });
  };
};

const regresarYLimpiar = dispatch => {
  return () => {
    dispatch({ type: LIMPIAR_ARCHIVOS });
  };
};

const vizualisarAllEstudios = dispatch => {
  return uid => {
    database.ref(`estudios_solicitados/${uid}`)
      .once('value')
      .then(snapshot => {
        if (snapshot.val()) {
          const estudios = Object.values(snapshot.val());
          dispatch({ type: DOCTOR_ESTUDIOS, payload: estudios });
        }
      })
      .catch(e => {
        console.log('Error obteniendo estudios de este doctor', e);
      });
  };
};

const getAllLabs = dispatch => {
  return async () => {
    await database.ref('laboratorios')
      .once('value')
      .then(snapshot => {
        const laboratorios = Object.values(snapshot.val());
        dispatch({ type: ALL_LABS, payload: laboratorios });
      }).catch(e => console.log('Error al bajar los laboratorios', e));
  };
};

const getListadoDiags = dispatch => {
  return async () => {
    await database.ref('diagnosticos')
      .once('value')
      .then(snapshot => {
        const diagnosticos = Object.values(snapshot.val());
        dispatch({ type: ALL_DIAGS, payload: diagnosticos });
      }).catch(e => console.log('Error al bajar los diagnosticos', e));
  };
}

export const { Contexto, Proveedor } = crearContextoDatos(
  reducerDisplayEstudio,
  {
    getAllEstudios, getAllEstatus, displayProgramas,
    envioGuardarCotizacion, cotizacionASolicitud, subirEstudio,
    subirApCiEstudio, bajarApCiEstudio, updateEtapaEstudio,
    updateInfoEstudio, subirDocumentosDB, bajarDocumentos,
    regresarYLimpiar, vizualisarAllEstudios, getAllLabs,
    getListadoDiags
  },
  { listaEstudios: [], estudio: {}, programas: [], cotizacion: [], listaEstudiosDoc: [], listadoBorradoresDoc: [], listadoEstatus: [], docApCi: null, docPago: null, resEmitidoBD: null, resEmitidoDosBD: null, laboratorios: [], reporteHistoDoc: null, listadoDiagnosticos: [] }
);