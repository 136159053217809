import React, { useContext } from 'react';
import { Contexto as DoctoresContext } from '../../../context/DoctoresContext';

const BusquedaMedico = ({ children, setCedula, cedulaDoc, emailDoc, emailDoc2, emailElegido, setEmailElegido }) => {
  const { state: { doctores } } = useContext(DoctoresContext);

  return (
    <div className="form-row">

      <div className="col-12 mb-3">
        <label htmlFor="medicoCedula">Nombre/Cédula Profesional</label>
        <select className="form-control mr-sm-2" name="medicoCedula"
          onChange={e => setCedula(e.target.value)}
          value={cedulaDoc}
        >
          <option value=''>Seleccione un Doctor</option>
          {
            doctores.map(doctor => {
              if (doctor.skip) {
                return null;
              }
              if (doctor.cedula) {
                return <option key={doctor.cedula} value={doctor.cedula}>{doctor.nom} - cédula {doctor.cedula}</option>;
              }
              return null;
            })
          }
          <option value='No se encontró'>¿No encuentras al doctor?</option>
        </select>
      </div>

      {/* SI PARA DICHO MÉDICO SE REGISTRARON DOS CORREOS */}
      {
        cedulaDoc && emailDoc2 && (
          <div className="col-12 mb-3">
            <label htmlFor="medicoEmailDestino">Elegir el correo a dónde llegará la solicitud</label>
            <select className="form-control mr-sm-2" name="medicoEmailDestino"
              onChange={e => setEmailElegido(e.target.value)}
              value={emailElegido}
            >
              <option value=''>Seleccione un Doctor</option>
              <option value={emailDoc}>{emailDoc}</option>
              <option value={emailDoc2}>{emailDoc2}</option>
            </select>
          </div>
        )
      }


      {children}


    </div>
  );
};

export default BusquedaMedico;