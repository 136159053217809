import React from 'react';
import { Link } from 'react-router-dom';

import PromocionContainer from './PromocionContainer';
import { Estudio } from '../../screens/screenNames';

import LogoBayer from '../../../img/Logo-Bayer.svg';
import LogoBayer2 from '../../../img/Logo-Bayer2.png';

const PromocionesHeader = ({ programas, limit }) => {
  if (programas.length === 0) {
    return (
      <PromocionContainer>
        <h5 className="card-title">Por el momento no hay programas de Bayer.</h5>
      </PromocionContainer>
    );
  }

  let programasFiltradosBayern = programas.filter(programa => programa[1].empresaPharma === 'Bayer');

  return (
    <>
      {/* Listado de programas SÓLO BAYERN */}
      {
        programasFiltradosBayern.map((promocion, index) => {
          if (limit && index > 3) {
            return null;
          }

          const estudioElegido = [promocion[1].estudio, { 'Categoría': promocion[1]['Categoría'], Costo: promocion[1].costo, 'Metodología': promocion[1]['Metodología'], 'Clave del estudio': 'N/A' }];
          return (
            <div className="col-12 col-lg-4" key={promocion[0]}>
              <div className="card mb-3">
                <div className="card-header">
                  <h4>{promocion[0]}</h4>
                </div>
                <div className="row imgProgramBayer mx-0">
                  <div className="col-6 text-center">
                    <img src={LogoBayer2} className="img-fluid" alt="Logo Programa Bayer" />
                  </div>
                  <div className="col-6 d-flex justify-content-end align-items-end">
                    <img src={LogoBayer} className="img-fluid" alt="Logo Bayer" style={{ width: '2.5rem', paddingBottom: '0.5rem' }} />
                  </div>
                </div>
                <div className="card-body row mx-0 cardBHeader">
                  <div className="col-12 text-justify">
                    <p>{promocion[1].desc_general}</p>
                  </div>
                  <div className="col-12">
                    <Link className="btn btn-primary btn-block mt-3"
                      to={{
                        pathname: `/${Estudio}`,
                        state: { estudioElegido, empresaPharma: 'Bayer', saltarElegirEspecialidad: true, promocion, logo1: LogoBayer2, logo2: LogoBayer }
                      }}
                    >
                      Solicitar Estudio <i className="fas fa-arrow-circle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          );
        })
      }
      {/* {children} */}

    </>
  );
};

export default PromocionesHeader;
