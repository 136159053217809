import React from 'react';

import FirmaDigital from '../FirmaDigital';
import ModalApCi from '../ModalApCi';
import ModalApCiBayer from '../ModalApCiBayer';

const FormApCi = ({ enviarFirmas, empresaPharma, firmaMed, firmaMedico, firmaPaciente }) => {
  return (
    <>
      <h5 className="mb-3 card-h5">Aviso de Privacidad y Consentimiento Informado</h5>
      {/* <p className="mb-3">Es necesario firmar el Consentimiento Informado y Aviso de Privacidad para proceder con su estudio:</p> */}
      <p className="mb-3">Al firmar el formato de solicitud de estudios certifico la información asentada y acepto los término del presente Consentimiento Informado y Aviso de Privacidad.</p>
      
      <div className="form-row mb-3">
        <div style={{ justifyContent: 'center' }} className="col mb-3 d-flex align-items-center">
          <button type="button" className="btn btn-APCi" data-toggle="modal" data-target="#ModalApCi">Ver AP & CI de la solicitud</button>
        </div>
        <div style={{ justifyContent: 'center' }} className="col mb-3 d-flex align-items-center">
          <button type="button" className="btn btn-APCi" data-toggle="modal" data-target="#ModalApCiBayer">Ver Aviso de Priv. de Bayer</button>
        </div>
      </div>
      <div className="form-row">
        {
          !empresaPharma && !!firmaPaciente && (
            <div className="col-6 mb-3 align-items-center">
              <button type="button" className="btn btn-info btn-block" data-toggle="modal" data-target="#ModalFirmaPaciente">Firmar digitalmente paciente <i className="fas fa-file-signature"></i></button>
            </div>
          )
        }

        {
          !!firmaMed || !!firmaMedico ?
            <h5 className="text-center">Ya se ha subido la firma del médico para este estudio.</h5>
            :
            <div className="col-12 mb-3 align-items-center">
              <button type="button" className="btn btn-primary btn-lg" data-toggle="modal" data-target="#ModalFirmaMedico">Firmar digitalmente médico <i className="fas fa-file-signature"></i></button>
            </div>
        }
      </div>

      {/* FIRMA */}
      <FirmaDigital
        idModal='ModalFirmaPaciente'
        del='Paciente'
        enviarFirmas={enviarFirmas}
        skipFirmaPaciente={true}
      />
      <FirmaDigital
        idModal='ModalFirmaMedico'
        del='Médico'
        enviarFirmas={enviarFirmas}
        skipFirmaPaciente={true}
      />

      <ModalApCi />
      <ModalApCiBayer />
    </>
  );
};

export default FormApCi;