import React from 'react';

const ElegirEspecialidad = ({ children, state, metodologiaEstudio, claveEstudio, detectarCategoriaElegida, intereses, setMetoClavePrecio, categoriaElegida, estudioSolNombre, filtroEstudioCategoria, costoEstudio }) => {

  return (
    <div className="form-row">
      <div className="col-6 mb-3">
        <label htmlFor="especialidad">Seleccione Categoría</label>
        <select className="form-control mr-sm-2" name="especialidad"
          onChange={detectarCategoriaElegida}
          value={categoriaElegida}
        >
          {state ? null : <option value=''>Seleccione Categoría</option>}
          {
            state ?
              <>
                <option value={state.estudioElegido[1]['Categoría']}>{state.estudioElegido[1]['Categoría']}</option>
              </>
              :
              intereses.map(interes => {
                return <option key={interes} value={interes}>{interes}</option>;
              })
          }
        </select>
      </div>
      <div className="col-6 mb-3">
        <label htmlFor="estudio">Estudio a solicitar</label>
        <select className="form-control mr-sm-2" name="estudio"
          onChange={setMetoClavePrecio}
          value={estudioSolNombre}
        >
          {
            state ?
              <>
                <option value={state.estudioElegido[0]}>{state.estudioElegido[0]}</option>
              </>
              : categoriaElegida ?
                <>
                  <option value=''>Seleccione Estudio</option>
                  {
                    filtroEstudioCategoria.map(estudio => {
                      return <option key={estudio[0]} value={estudio[0]}>{estudio[0]}</option>;
                    })
                  }
                </>
                :
                <option>No se ha elegido una categoría</option>
          }
        </select>
      </div>
      <div className="col-6 mb-3">
        <label htmlFor="metodologia">Metodología</label>
        <div className="form-control mr-sm-2" type="input" placeholder="Metodología" name="metodologia">
          {
            metodologiaEstudio
              ? metodologiaEstudio
              : <>No se ha elegido el estudio</>
          }
        </div>
      </div>
      <div className="col-6 mb-3">
        <label htmlFor="claveEstudio">Clave de estudio</label>
        <div className="form-control mr-sm-2" type="input" placeholder="Clave de Estudio" name="claveEstudio">
          {
            claveEstudio
              ? claveEstudio
              : <>No se ha elegido el estudio</>
          }
        </div>
      </div>
      <div className="col-12 mb-3 text-center">
        <span style={{ color: 'green', fontWeight: 'bold' }}>Costo de estudio: ${costoEstudio}.00 MXN</span>
      </div>
      {children}
    </div>
  );
};

export default ElegirEspecialidad;