import database from '../firebase/Firebase';
import crearContextoDatos from './crearContextoDatos';
import {
  ALL_SOLICITUDES_DAT, ALL_TITULOS_CATEGORIAS, ALL_TITULOS_ESPECIALIDADES,
  ALL_TITULOS_ESTUDIOS, APPEND_ESTUDIO,
  INFO_ESTUDIO, ESTUDIO_DUPLCIADO, ALL_TITULOS_PROGRAS, INFO_PROGRA,
  APPEND_PROGRA, PROGRA_DUPLCIADO, ALL_PHARMAS
} from '../actions/actionNames';

const reducerAdmin = (state, action) => {
  switch (action.type) {
    case ALL_SOLICITUDES_DAT:
      return { ...state, allEstudiosDAT: action.payload }
    case ALL_TITULOS_CATEGORIAS:
      return { ...state, titulosCategorias: action.payload };
    case ALL_TITULOS_ESPECIALIDADES:
      return { ...state, titulosEspecialidades: action.payload };
    case ALL_TITULOS_ESTUDIOS:
      return { ...state, titulosEstudios: action.payload };
    case ALL_PHARMAS:
      return { ...state, pharmas: action.payload };
    case APPEND_ESTUDIO:
      if (!state.titulosEstudios.includes(action.payload)) {
        return { ...state, titulosEstudios: [...state.titulosEstudios, action.payload].sort() };
      }
      return state;
    case ESTUDIO_DUPLCIADO:
      return {
        ...state, titulosEstudios: state.titulosEstudios.filter(estudio => {
          return estudio !== action.payload;
        })
      };
    case INFO_ESTUDIO:
      return { ...state, infoEstudioPart: action.payload };
    case ALL_TITULOS_PROGRAS:
      return { ...state, titulosProgs: action.payload };
    case APPEND_PROGRA:
      if (!state.titulosProgs.includes(action.payload)) {
        return { ...state, titulosProgs: [...state.titulosProgs, action.payload].sort() };
      }
      return state;
    case INFO_PROGRA:
      return { ...state, infoPrograPart: action.payload };
    case PROGRA_DUPLCIADO:
      return {
        ...state, titulosProgs: state.titulosProgs.filter(prog => {
          return prog !== action.payload;
        })
      };
    default:
      return state;
  };
};

const getAllSolicitudesDAT = dispatch => {
  return async () => {
    await database.ref('estudios_solicitados')
      .once('value')
      .then(snapshot => {
        if (snapshot.val()) {
          const listadoGeneral = Object.values(snapshot.val());
          const listadoSolicitudesEstudios = listadoGeneral.map(item => {
            return Object.values(item);
          });

          dispatch({ type: ALL_SOLICITUDES_DAT, payload: [].concat.apply([], listadoSolicitudesEstudios) });
        }
      }).catch(e => {
        console.log('Error visualizando estudios', e);
      });
  };
};

const getAllCategorias = dispatch => {
  return async () => {
    await database.ref('categorias')
      .once('value')
      .then(snapshot => {
        const listadoCategorias = Object.keys(snapshot.val());
        dispatch({ type: ALL_TITULOS_CATEGORIAS, payload: listadoCategorias })
      }).catch(e => {
        console.log('Error visualizando especialidades', e);
      });
  };
};

const getAllEspecialidades = dispatch => {
  return async () => {
    await database.ref('especialidades')
      .once('value')
      .then(snapshot => {
        const listadoEspecialidades = Object.keys(snapshot.val());
        dispatch({ type: ALL_TITULOS_ESPECIALIDADES, payload: listadoEspecialidades })
      }).catch(e => {
        console.log('Error visualizando especialidades', e);
      });
  };
};

const getAllEstudios = dispatch => {
  return async () => {
    await database.ref('estudios')
      .once('value')
      .then(snapshot => {
        const listadoEstudios = Object.keys(snapshot.val());
        dispatch({ type: ALL_TITULOS_ESTUDIOS, payload: listadoEstudios })
      }).catch(e => {
        console.log('Error visualizando estudios', e);
      });
  };
};

const getAllNomsPharmas = dispatch => {
  return async () => {
    await database.ref('pharmas')
      .once('value')
      .then(snapshot => {
        const listadoPharmas = Object.values(snapshot.val());
        dispatch({ type: ALL_PHARMAS, payload: listadoPharmas });
      }).catch(e => {
        console.log('Error obteniendo pharmas', e);
      });
  };
};

const getDetalleEstudio = dispatch => {
  return async tituloEstudio => {
    if (!tituloEstudio) {
      dispatch({ type: INFO_ESTUDIO, payload: null });
    }

    await database.ref(`estudios/${tituloEstudio}`)
      .once('value')
      .then(snapshot => {
        const estudioInfo = snapshot.val();
        dispatch({ type: INFO_ESTUDIO, payload: estudioInfo });
      })
      .catch(e => {
        console.log('Error obteniendo detalle estudio', e);
      });
  };
};

const eliminarEstudioDuplicado = dispatch => {
  return async tituloOriginal => {
    await database.ref(`estudios/${tituloOriginal}`)
      .remove()
      .then(() => dispatch({ type: ESTUDIO_DUPLCIADO, payload: tituloOriginal }))
      .catch(e => {
        console.log('Error eliminando estudio duplicado', e);
      });
  };
};

const enviarEstudio = dispatch => {
  return async (titulo, datos) => {
    await database.ref('estudios').update({ [titulo]: datos })
      .then(
        dispatch({ type: APPEND_ESTUDIO, payload: titulo })
      ).catch(e => {
        console.log('Error subiendo estudio', e);
      });
  };
};

const getAllProgs = dispatch => {
  return async () => {
    await database.ref('programas')
      .once('value')
      .then(snapshot => {
        const listadoProgs = Object.keys(snapshot.val());
        dispatch({ type: ALL_TITULOS_PROGRAS, payload: listadoProgs })
      }).catch(e => {
        console.log('Error visualizando programas', e);
      });
  };
};

const getDetalleProgra = dispatch => {
  return async tituloProgra => {
    if (!tituloProgra) {
      dispatch({ type: INFO_PROGRA, payload: null });
    }

    await database.ref(`programas/${tituloProgra}`)
      .once('value')
      .then(snapshot => {
        const prograInfo = snapshot.val();
        dispatch({ type: INFO_PROGRA, payload: prograInfo });
      })
      .catch(e => {
        console.log('Error obteniendo detalle programa', e);
      });
  };
};

const eliminarPrograDuplicado = dispatch => {
  return async tituloOriginal => {
    await database.ref(`programas/${tituloOriginal}`)
      .remove()
      .then(() => dispatch({ type: PROGRA_DUPLCIADO, payload: tituloOriginal }))
      .catch(e => {
        console.log('Error eliminando programa duplicado', e);
      });
  };
};

const enviarPrograma = dispatch => {
  return async (titulo, datos) => {
    await database.ref('programas').update({ [titulo]: datos })
      .then(
        dispatch({ type: APPEND_PROGRA, payload: titulo })
      ).catch(e => {
        console.log('Error subiendo programa', e);
      });
  };
};

export const { Contexto, Proveedor } = crearContextoDatos(
  reducerAdmin,
  {
    getAllSolicitudesDAT,
    getAllCategorias, getAllEspecialidades, getAllEstudios,
    enviarEstudio, getDetalleEstudio, eliminarEstudioDuplicado,
    getAllProgs, getDetalleProgra, enviarPrograma,
    eliminarPrograDuplicado, getAllNomsPharmas
  },
  {
    titulosCategorias: [], titulosEspecialidades: [],
    titulosEstudios: [], infoEstudioPart: null,
    titulosProgs: [], infoPrograPart: null,
    pharmas: [], allEstudiosDAT: null
  }
)