import React, { useRef, useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import EstudiosActivos from './EstudiosActivos';
import { Contexto as EstudiosContext } from '../context/EstudiosContext';
import { Registros } from '../screens/screenNames';
import { compararFechas } from '../utils/Fecha';
import { exportCSVFile, headers, formatItems } from '../utils/descargaCSV';
import { exportXMLFile } from '../utils/descargaXML';

const EstudiosActivosContainer = ({ listaEstudiosDoc, limit }) => {
  const { state: { listadoEstatus } } = useContext(EstudiosContext);
  const Fechas = useRef();

  const [listaEstudiosDocFiltrada, setListaEstudiosDocFiltrada] = useState([]);
  const [folioNombre, setFolioNombre] = useState('');
  const [filtroSelect, setFiltroSelect] = useState('');
  const [fechaUno, setFechaUno] = useState('');
  const [fechaDos, setFechaDos] = useState('');

  // Filtro de no leídos activa o no
  const [soloNoVistos, setSoloNoVistos] = useState(true);

  const FiltrarEstatus = value => {
    setFiltroSelect(value);
    if (value === 'Fecha') {
      Fechas.current.classList.remove('cajaCerrada');
      Fechas.current.classList.add('cajaAbierta');
    } else {
      Fechas.current.classList.remove('cajaAbierta');
      Fechas.current.classList.add('cajaCerrada');
    }
  };

  const compareArrOfEstudios = (a, b) => {
    if (a.fecha_solcitada > b.fecha_solcitada) return -1;
    if (a.fecha_solcitada < b.fecha_solcitada) return 1;
    return 0;
  };

  /**
   * Filtrar dependiendo el folio o el nombre
  */
  useEffect(() => {
    if (listaEstudiosDoc && listadoEstatus.length) {
      // MÁS RECIENTES PRIMERO
      const listaOrdenada = listaEstudiosDoc.sort(compareArrOfEstudios);

      // FILTRO POR BÚSQUEDA
      const nuevaLista = listaOrdenada.filter(estudio => {
        const busqueda = folioNombre.toUpperCase();
        return estudio.nombrePaciente.toUpperCase().includes(busqueda) || estudio.apellidoPaciente.toUpperCase().includes(busqueda) || estudio.folio.toUpperCase().includes(busqueda);
      });

      // Estudios con el estatos de: AP_CI
      if (soloNoVistos) {
        const listaSoloNoVistos = nuevaLista.filter(estudio => estudio.etapa === listadoEstatus[0][0]);
        setListaEstudiosDocFiltrada(listaSoloNoVistos);
      } else {
        setListaEstudiosDocFiltrada(nuevaLista);
      }
    }
  }, [listaEstudiosDoc, folioNombre, soloNoVistos, listadoEstatus]);

  /**
   * Filtrar dependiendo del select
   */
  useEffect(() => {
    if (!!filtroSelect && filtroSelect !== 'Fecha' && listaEstudiosDoc) {
      const nuevaLista = listaEstudiosDoc.filter(estudio => {
        return estudio.etapa.includes(filtroSelect);
      });
      setListaEstudiosDocFiltrada(nuevaLista);
    }
  }, [listaEstudiosDoc, filtroSelect]);

  /**
   * Filtrar por rango de fechas
   */
  useEffect(() => {
    if (fechaUno && fechaDos) {
      const nuevaLista = listaEstudiosDoc.filter(estudio => {
        return compararFechas(fechaUno, estudio.fecha_solcitada, fechaDos);
      });
      setListaEstudiosDocFiltrada(nuevaLista);
    }
  }, [listaEstudiosDoc, fechaUno, fechaDos]);

  const buttonVerMas = () => {
    if (limit && listaEstudiosDoc && listaEstudiosDoc.length > 5) {
      return <Link to={`${Registros}`} className="btn btn-primary btn-block mt-3">Ver más <i className="fas fa-search-plus"></i></Link>;
    }
    return null;
  };

  return (
    <div className="card mt-3">
      <div className="card-header">
        <h4>Busque su caso aquí</h4>
      </div>
      <div className="card-body">
        <ul className="list-group">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item">
              <a
                className="nav-link active"
                id="noReadMessages-tab"
                data-toggle="tab"
                href="#noReadMessages"
                role="tab"
                aria-controls="noReadMessages"
                aria-selected="true"
                onClick={() => setSoloNoVistos(true)}
              >
                <i className="fas fa-envelope"></i> No leídos
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                id="home-tab"
                data-toggle="tab"
                href="#home"
                role="tab"
                aria-controls="home"
                aria-selected="false"
                onClick={() => setSoloNoVistos(false)}
              >
                <i className="fas fa-search"></i> Buscar
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                id="profile-tab"
                data-toggle="tab"
                href="#profile"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
                onClick={() => setSoloNoVistos(false)}
              >
                <i className="fas fa-filter"></i> Filtrar avanzado
              </a>
            </li>
            <li>
              <button
                className="btn btn-secondary btn-block"
                type="button"
                onClick={() => exportCSVFile(headers, listaEstudiosDoc)}
              >
                <i className="fas fa-file-download"></i> Exportar
              </button>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="noReadMessages" role="tabpanel" aria-labelledby="noReadMessages-tab">
              {/* FALTARÍA PONER ALGO AQUÍ */}
            </div>
            <div className="tab-pane fade" id="home" role="tabpanel" aria-labelledby="home-tab">
              <form className="my-3" onSubmit={e => e.preventDefault()}>
                <div className="form-row">
                  <div className="col-12">
                    <input className="form-control mr-sm-2" type="search" placeholder="Buscar por folio o nombre" aria-label="Buscar"
                      value={folioNombre}
                      onChange={e => setFolioNombre(e.target.value)}
                    />
                  </div>
                </div>
              </form>
            </div>
            <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
              <form className="my-3" onSubmit={e => e.preventDefault()}>
                <div className="form-row">
                  <div className="col-12">
                    <select
                      className="form-control mr-sm-2"
                      name="tipoFiltro"
                      id="tipoFiltro"
                      onChange={e => FiltrarEstatus(e.target.value)}
                    >
                      <option value="">Seleccione una Etapa</option>
                      {
                        listadoEstatus.map(estatus => <option key={estatus[0]} value={`${estatus[0]}`}>{estatus[1]}</option>)
                      }
                      <option value='Fecha'>Por Fecha</option>
                    </select>
                  </div>
                  <div id="Fechas" ref={Fechas} className="row cajaCerrada">
                    <div className="col-xs-12 col-sm-6 col-md-6 mt-3">
                      <label htmlFor="">Fecha de inicio</label>
                      <input className="form-control mr-sm-2" type="date" onChange={e => setFechaUno(e.target.value)} />
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 mt-3">
                      <label htmlFor="">Fecha de término</label>
                      <input className="form-control mr-sm-2" type="date" onChange={e => setFechaDos(e.target.value)} />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div className="tableWarp">
            <table className="table table-hover d-md-table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Folio</th>
                  <th scope="col">Nombre</th>
                  <th scope="col">Estudio</th>
                  <th scope="col">Etapa</th>
                  <th scope="col">Resultado</th>
                  <th scope="col">Leído/no leído</th>
                </tr>
              </thead>
              <tbody>
                <EstudiosActivos
                  listaEstudiosDoc={listaEstudiosDocFiltrada}
                  limit={limit}
                />
              </tbody>
            </table>
          </div>
          {buttonVerMas()}
        </ul>
      </div>
    </div>
  );
};

export default EstudiosActivosContainer;
