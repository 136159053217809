import React from 'react';

const Input = props => {
  return (
    <div className="col-6 mb-3">
      <label htmlFor={props.id}>{props.label ? props.label : props.placeholder}</label>
      <input className={`form-control mr-sm-2 deshabilitado3 ${props.claseExtra}${props.value}`} type="text"
        name={props.id} id={props.id}
        disabled
        {...props}
        value={props.value}
        onChange={e => props.onChange(e.target.value)}
      />
    </div>
  );
};

export default Input;