import React, { useContext, useState } from 'react';
import { Contexto as UsersContext } from '../../context/UsersContext';
import Input from '../../modules/LoginPath/Input';
import mostrarSaludo from '../../utils/Saludo';

const LoginCard = ({ setIsLogin }) => {
  const { loginUsuario } = useContext(UsersContext);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const { texto } = mostrarSaludo();
  const signIn = async () => {
    const response = await loginUsuario(username, password);
    if (response) {
      window.Swal.fire({
        title: '¡Revise el usuario o la contraseña!',
        text: 'Si no recuerda su contraseña, puede solicitar la recuperación más abajo. Si aún no se ha registrado, haga click en "Regístrese".',
        icon: 'warning',
        confirmButtonText: 'OK!'
      });
    }
  };

  return (
    <>
      <div className="form-group">
        <p>
          <b>{texto}<span id="saludo"></span> doctor@,</b>
          <br />
          inicie sesión o registrese para acceder.
        </p>
      </div>
      <Input autoFocus type="email" id="inputEmail" placeholder="Dirección de Email" required
        value={username}
        onChange={setUsername}
      />
      <Input type="password" id="inputPassword" placeholder="Contraseña" required
        value={password}
        onChange={setPassword}
      />
      <p className="mb-3">
        <button
          className="btn btnTypeAnchor"
          onClick={() => setIsLogin(false)}
        >
          Regístrese
        </button>
      </p>
      <button
        style={{ color: 'white' }} className="btn btn-lg btn-primary btn-block text-uppercase"
        type="submit"
        onClick={() => {
          window.hideModal("#ModalLogin");
          signIn();
        }}
      >
        Entrar
      </button>
      <div className="my-3">
        <button style={{ padding: 0, fontSize: '90%' }} type="button" className="btn btnTypeAnchor" data-toggle="modal" data-target="#OlvidoContrasenia">¿Olvidó su contraseña?</button>
        {/* <input type="checkbox" className="custom-control-input" id="customCheck1" />
        <label className="custom-control-label" htmlFor="customCheck1">Recordar contraseña</label> */}
      </div>
    </>
  )
};

export default LoginCard;