import React from 'react';
import Promocion from '../../../modules/PromocionesPath/Promocion';
import ButtonNavSolicitud from '../../../modules/SolicitudPath/ButtonNavSolicitud';

const BannerPharma = ({ state, seccionesRef, isAdmin, metodologiaEstudio, claveEstudio, cambioApartadoSolicitud, setTituloGeneral }) => {
  return (
    <div className="card mb-3">
      <div className="card-header card-promos-header">
        <h4>{state.promocion[0]}</h4>
      </div>
      <div className="row imgProgramBayer mx-0">
        <div className="col-6 text-center">
          <img src={state.logo1} className="img-fluid" alt="Logo Programa Bayer" />
        </div>
        <div className="col-6 d-flex justify-content-end align-items-end">
          <img src={state.logo2} className="img-fluid" alt="Logo Bayer" style={{ width: '3rem', paddingBottom: '0.5rem' }} />
        </div>
      </div>
      <div className="card-body row mx-0 p-1">
        <div className="col-12 mb-3 text-justify">
          <p className="mb-1">{state.promocion[1].desc_general}</p>
        </div>
        <Promocion promocion={state.promocion} origen="bayer" />
        <div className="col-12 mb-3 text-center">
          {
            isAdmin && metodologiaEstudio && claveEstudio ?
              <>
                <ButtonNavSolicitud
                  navegacion="Atrás" destino={0} tituloGeneral='Buscar Médico'
                  cambioApartadoSolicitud={cambioApartadoSolicitud} seccionesRef={seccionesRef} setTituloGeneral={setTituloGeneral}
                />
                <ButtonNavSolicitud
                  navegacion="Solicitar Estudio" destino={2} tituloGeneral='Información del paciente'
                  cambioApartadoSolicitud={cambioApartadoSolicitud} seccionesRef={seccionesRef} setTituloGeneral={setTituloGeneral}
                />
              </>
              :
              null
          }
          {
            !isAdmin && metodologiaEstudio && claveEstudio ?
              <ButtonNavSolicitud
                navegacion="Solicitar Estudio" destino={1} tituloGeneral='Información del paciente'
                cambioApartadoSolicitud={cambioApartadoSolicitud} seccionesRef={seccionesRef} setTituloGeneral={setTituloGeneral}
              />
              : null
          }
        </div>
      </div>
    </div>
  )
};

export default BannerPharma;