import React from 'react';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <>
      <footer className="row fixed-bottom">
        <div className="col-sm-12 text-center py-3">
          <h6 className="mb-3">
            ¿Necesita asistencia? Contáctenos vía <a href="mailto:contacto@diagnostiqueatiempo.com"><i className="fas fa-envelope"></i> Correo a contacto@diagnostiqueatiempo.com</a> o bien vía <a target="blank" href="https://wa.me/525588807871"><i className="fab fa-whatsapp"></i> Whatsapp® haciendo click aquí</a> o al teléfono: <a href="tel:+525588807871"><i className="fas fa-mobile-alt"></i> +52 55 8880 7871</a>.
          </h6>
          <p className="legales">Diagnóstique a tiempo® <span id="year">{currentYear}</span> </p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
