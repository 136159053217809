import React from 'react';
import AvisosContainer from '../../modules/AvisosPath/AvisosContainer';

const PoliticaCookies = () => {
  return (
    <AvisosContainer>
      <h3 className="textoFormal mb-2">Política de Cookies</h3>
      <p className="text-justify mb-2">Este sitio web utiliza cookies. Si continúa navegando consideramos que acepta su uso.</p>
      <p className="text-justify mb-2">
        Una cookie o galleta informática es un pequeña información enviada por un sitio web y almacenada en el navegador del usuario, de manera que el sitio web puede reconocer el dispositivo y recordarlo. Cuando visita nuestros sitios, podemos colectar información sobre usted automáticamente a través de cookies o tecnologías similares.
      </p>

      <h4 className="textoFormal text-justify mb-2">¿Cómo usamos las cookies?</h4>
      <p className="text-justify mb-2">Las usamos para mejorar su experiencia ene l sitio, como pueden ser:</p>
      <ul className="text-justify mb-2 dashed">
        <li>Mantenerlo ingresado en la Plataforma DAT</li>
        <li>Entender como usa el sitio</li>
      </ul>
      <p className="text-justify mb-2">
        Usando cookies y otras tecnologías ayuda a nuestra organización a analizar como se usa el sitio, el número de visitantes en el sitio y desplegar publicidad si aplica. Nuestro sitio web no accesa a cookies de terceros ni permite a otras organizaciones acceder a las cookies usadas por nuestro sitio.
      </p>

      <h4 className="textoFormal text-justify mb-2">¿Qué tipo de cookies usamos?</h4>
      <ul className="text-justify mb-2 dashed">
        <li>Esenciales. Necesarias para proveer los servicios solicitados. Por ejemplo, esto incluye cookies que hacen posible que sea recordado y permanezca ingresado en su cuenta, hacer solicitudes, seguimientos, etc. Si ha establecido que su navegador bloquee estas cookies, las funciones y servicios podrían no funcionar.</li>
        <li>Rendimiento. Son cookies que miden la frecuencia de su visita a nuestros sitios y como los usa. Usamos esta información para tener una mejor idea de como nuestros usuarios ingresan a la plataforma y son usadas para proporcionar una mejor experiencia. Por ejemplo, colectamos información sobre cual página es visitada con mayor frecuencia, por qué tipos de usuarios, desde que tipo de dispositivos, etc.</li>
        <li>Funcionalidad.  Cookies usadas para reconocer y recordar sus preferencias cuando regresa a nuestro sitio, de esa manera podemos proveer una experiencia más personalizada.</li>
        <li>Publicidad. Cookies usadas para colectar información acerca de su visita a nuestro sitio, incluyendo el contenido que ha visto, los enlaces seguidos, información sobre el navegador que usa, su dispositivo y dirección IP.</li>
      </ul>

      <h4 className="textoFormal text-justify mb-2">¿Cómo usamos las cookies para publicidad?</h4>
      <p className="text-justify mb-2">
        Estas cookies nos permiten mostrarle publicidad e información más relevante basados en sus patrones de búsqueda y la manera en la que interactúa con nuestros sitios y enlaces. El contenido no está influenciado por estos patrones pero nos motiva a desarrollar contenido que sea de mayor interés para usted.
      </p>
      <p className="text-justify mb-2">
        Las técnicas de publicidad que usamos no recolectan información personal como nombre, correo electrónico, dirección o número personal.
      </p>
      <p className="text-justify mb-2">
        Usted puede desactivar la aceptación de cookies al cambiar la configuración de su navegador en los menús de  opciones o preferencias.
      </p>
      <p className="text-justify mb-2">
        Si desea contactarnos para preguntar acerca de cookies por favor escriba a contacto@diagnostiqueatiempo.com
      </p>
    </AvisosContainer>
  );
};

export default PoliticaCookies;