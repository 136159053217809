import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { firebase } from '../../firebase/Firebase';
import LoginContainer from '../../modules/LoginPath/LoginContainer';

const Verificacion = () => {
  const [sentText, setSendText] = useState('');
  const [enviado, setEnviado] = useState(false);

  useEffect(() => {
    const user = firebase.auth().currentUser;
    const email = user.email;
    user.sendEmailVerification()
      .then(response => {
        console.log(response);
        setSendText(`Un link fue enviado al correo ${email}. Revise bandeja de entrada y correo no deseado.`);
        setEnviado(true);
      }).catch(e => {
        console.log('Error mandando link de verificación', e);
        setSendText('Hubo un error mandando el enlace, inténtelo nuevamente o contáctenos.');
        setEnviado(false);
      });
  }, []);

  return (
    <LoginContainer>
      <div className="form-group mb-3">
        <h4 className="mb-2">Su perfil de usuario ha sido creado su con éxito.</h4>
        <p>La seguridad de su información es muy importante para nosotros, por favor verifique su dirección de correo electrónico.</p>
      </div>
      <div className="form-group mb-3">
        <h5>
          {sentText}
        </h5>
      </div>
      <div className="form-group">
        {
          enviado ?
            <div>
              <p className="mb-3">Una vez que haya hecho click enlace del correo y realizado la verificación, de click aquí para completar el proceso:</p>
              <Link to='/' className="btn btn-lg btn-primary btn-block text-uppercase">Entrar a DAT</Link>
            </div>
            : null
        }
      </div>
    </LoginContainer>
  );
};

export default Verificacion;