import React from 'react';

const InfoColecta = ({ handleDirElegida, listadoDirs, dirElegida, setInputCalleNumero, inputCalleNumero, setInputCodigoPostal, inputCodigoPostal, setInputColinia, inputColinia, setInputAlcaldiaMun, inputAlcaldiaMun, setInputEstado, inputEstado, setInputNombreHospital, inputNombreHospital, setInputReferencias, inputReferencias, referenciasPlaceHolder, horarioRecoger, setHorarioRecoger }) => {
  return (
    <>
      <h5 className="mb-3">Lugar de la colecta</h5>
      <div className="form-row">
        <div className="col-12 mb-3">
          <label htmlFor="dirMedico">Seleccione la dirección donde será recolectada la muestra o ingrese una nueva dirección:</label>
          <select className="form-control mr-sm-2" name="dirMedico"
            onChange={e => handleDirElegida(e.target.value)}
            value={dirElegida}
          >
            <option value=''>Seleccione un punto de colecta</option>
            {
              listadoDirs.map((dir, index) => {
                return <option key={dir.nombreHospital} value={index}>{dir.nombreHospital} - calle {dir.calle}</option>;
              })
            }
            <option value={-1}>Ingrese una nueva dirección</option>
          </select>
          <hr />
        </div>
        {
          dirElegida === -1 ?
            <>
              <div className="col-6 mb-3">
                <label className="alert-warning"><b>*</b> Calle y número:</label>
                <input className="form-control mr-sm-2" type="text" placeholder="Calle y número"
                  onChange={e => setInputCalleNumero(e.target.value)}
                  value={inputCalleNumero}
                />
              </div>
              <div className="col-6 mb-3">
                <label className="alert-warning"><b>*</b> Código Postal:</label>
                <input className="form-control mr-sm-2" type="email" placeholder="Código Postal"
                  onChange={e => setInputCodigoPostal(e.target.value)}
                  value={inputCodigoPostal}
                />
              </div>
              <div className="col-6 mb-3">
                <label className="alert-warning"><b>*</b> Colonia:</label>
                <input className="form-control mr-sm-2" type="text" placeholder="Colonia"
                  onChange={e => setInputColinia(e.target.value)}
                  value={inputColinia}
                />
              </div>
              <div className="col-6 mb-3">
                <label className="alert-warning"><b>*</b> Alcaldia/Municipio:</label>
                <input className="form-control mr-sm-2" type="text" placeholder="Alcaldia/Municipio"
                  value={inputAlcaldiaMun}
                  onChange={e => setInputAlcaldiaMun(e.target.value)}
                />
              </div>
              <div className="col-6 mb-3">
                <label className="alert-warning"><b>*</b> Estado:</label>
                <select id="inputEstado" className="form-control" name="estado"
                  onChange={e => setInputEstado(e.target.value)}
                  value={inputEstado}
                >
                  <option value=''>Seleccione su estado</option>
                  <option value='Aguascalientes'>Aguascalientes</option>
                  <option value='Baja California'>Baja California</option>
                  <option value='Baja California Sur'>Baja California Sur</option>
                  <option value='Campeche'>Campeche</option>
                  <option value='CDMX'>CDMX</option>
                  <option value='Chiapas'>Chiapas</option>
                  <option value='Chihuahua'>Chihuahua</option>
                  <option value='Coahuila'>Coahuila</option>
                  <option value='Colima'>Colima</option>
                  <option value='Durango'>Durango</option>
                  <option value='Estado de México'>Estado de México</option>
                  <option value='Guanajuato'>Guanajuato</option>
                  <option value='Guerrero'>Guerrero</option>
                  <option value='Hidalgo'>Hidalgo</option>
                  <option value='Jalisco'>Jalisco</option>
                  <option value='Michoacán'>Michoacán</option>
                  <option value='Morelos'>Morelos</option>
                  <option value='Nayarit'>Nayarit</option>
                  <option value='Nuevo León'>Nuevo León</option>
                  <option value='Oaxaca'>Oaxaca</option>
                  <option value='Puebla'>Puebla</option>
                  <option value='Querétaro'>Querétaro</option>
                  <option value='Quintana Roo'>Quintana Roo</option>
                  <option value='San Luis Potosí'>San Luis Potosí</option>
                  <option value='Sinaloa'>Sinaloa</option>
                  <option value='Sonora'>Sonora</option>
                  <option value='Tabasco'>Tabasco</option>
                  <option value='Tamaulipas'>Tamaulipas</option>
                  <option value='Tlaxcala'>Tlaxcala</option>
                  <option value='Veracruz'>Veracruz</option>
                  <option value='Yucatán'>Yucatán</option>
                  <option value='Zacatecas'>Zacatecas</option>
                </select>
              </div>
              <div className="col-6 mb-3">
                <label className="alert-warning"><b>*</b> Nombre del hospital:</label>
                <input className="form-control mr-sm-2" type="text" placeholder="Nombre del hospital"
                  value={inputNombreHospital}
                  onChange={e => setInputNombreHospital(e.target.value)}
                />
              </div>
              <div className="col-12 mb-3">
                <label className="alert-warning"><b>*</b> Referencias:</label>
                <textarea id="inputReferencias" className="form-control"
                  placeholder={referenciasPlaceHolder}
                  value={inputReferencias}
                  onChange={e => setInputReferencias(e.target.value)}
                />
                {
                  inputReferencias ?
                    <small>{referenciasPlaceHolder}</small>
                    : null
                }
              </div>
              {/* SELECCION HORARIO */}
              <div className="col-12 mb-3 px-3">
                <label className="alert-warning"><b>*</b> Seleccione el horario de colecta:</label>
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="horarioRecoger" id="horarioRecoger1" value="9 a 12 hrs"
                    checked={horarioRecoger === '9 a 12 hrs'}
                    onChange={e => setHorarioRecoger(e.target.value)}
                  />
                  <label className="form-check-label" htmlFor="horarioRecoger1">
                    9 a 12 hrs
                            </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="horarioRecoger" id="horarioRecoger2" value="11 a 14 hrs"
                    checked={horarioRecoger === '11 a 14 hrs'}
                    onChange={e => setHorarioRecoger(e.target.value)}
                  />
                  <label className="form-check-label" htmlFor="horarioRecoger2">
                    11 a 14 hrs
                            </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="horarioRecoger" id="horarioRecoger3" value="15 a 18 hrs"
                    checked={horarioRecoger === '15 a 18 hrs'}
                    onChange={e => setHorarioRecoger(e.target.value)}
                  />
                  <label className="form-check-label" htmlFor="horarioRecoger3">
                    15 a 18 hrs
                            </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="horarioRecoger" id="horarioRecoger4" value="9 a 18 hrs"
                    checked={horarioRecoger === '9 a 18 hrs'}
                    onChange={e => setHorarioRecoger(e.target.value)}
                  />
                  <label className="form-check-label" htmlFor="horarioRecoger4">
                    9 a 18 hrs
                            </label>
                </div>
                <hr />
              </div>
            </>
            : null
        }
        {
          dirElegida >= 0 ?
            <>
              <div className="col-12 mb-3">
                <label className="alert-warning"><b>*</b> Referencias:</label>
                <textarea id="inputReferencias" className="form-control"
                  placeholder={referenciasPlaceHolder}
                  value={inputReferencias}
                  onChange={e => setInputReferencias(e.target.value)}
                />
                {
                  inputReferencias ?
                    <small>{referenciasPlaceHolder}</small>
                    : null
                }
              </div>
              {/* SELECCION HORARIO */}
              <div className="col-12 mb-3 px-3">
                <label className="alert-warning"><b>*</b> Seleccione el horario de colecta:</label>
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="horarioRecoger" id="horarioRecoger1" value="9 a 12 hrs"
                    checked={horarioRecoger === '9 a 12 hrs'}
                    onChange={e => setHorarioRecoger(e.target.value)}
                  />
                  <label className="form-check-label" htmlFor="horarioRecoger1">
                    9 a 12 hrs
                            </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="horarioRecoger" id="horarioRecoger2" value="11 a 14 hrs"
                    checked={horarioRecoger === '11 a 14 hrs'}
                    onChange={e => setHorarioRecoger(e.target.value)}
                  />
                  <label className="form-check-label" htmlFor="horarioRecoger2">
                    11 a 14 hrs
                            </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="horarioRecoger" id="horarioRecoger3" value="15 a 18 hrs"
                    checked={horarioRecoger === '15 a 18 hrs'}
                    onChange={e => setHorarioRecoger(e.target.value)}
                  />
                  <label className="form-check-label" htmlFor="horarioRecoger3">
                    15 a 18 hrs
                            </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="horarioRecoger" id="horarioRecoger4" value="9 a 18 hrs"
                    checked={horarioRecoger === '9 a 18 hrs'}
                    onChange={e => setHorarioRecoger(e.target.value)}
                  />
                  <label className="form-check-label" htmlFor="horarioRecoger4">
                    9 a 18 hrs
                            </label>
                </div>
                <hr />
              </div>
            </>
            : null
        }
      </div>
    </>
  );
};

export default InfoColecta;