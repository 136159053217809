import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { home, Registros, Cotizacion, Estudio, Programas, Doctores } from '../screens/screenNames';
import { Contexto as UsersContext } from '../context/UsersContext';

const MenuColapsable = () => {
  const { state: { isAdmin } } = useContext(UsersContext);

  const location = window.location.pathname;

  return (
    <nav className="navbar navbar-expand-lg navbar-light">
      <Link className="navbar-brand" to={`/${home}`}>
        <h3>Menú</h3>
      </Link>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#MenuMovil" aria-controls="MenuMovil" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse justify-content-end" id="MenuMovil">
        <ul className="navbar-nav pt-3">
          {
            location !== `/${Registros}` ?
              <li className="nav-item mb-3">
                <Link to={`/${Registros}`} className="btn btn-primary btn-block btn-lg"><i className="fas fa-notes-medical"></i> Consultar mis registros</Link>
              </li>
              : null
          }
          {
            location !== `/${Cotizacion}` ?
              <li className="nav-item mb-3">
                <Link to={`/${Cotizacion}`} className="btn btn-primary btn-block btn-lg"><i className="fas fa-dollar-sign"></i> Solicitar cotización</Link>
              </li>
              : null
          }
          {
            location !== `/${Estudio}` ?
              <li className="nav-item mb-3">
                <Link to={`/${Estudio}`} className="btn btn-primary btn-block btn-lg"><i className="fas fa-certificate"></i> Solicitar estudio</Link>
              </li>
              : null
          }
          {
            location !== `/${Programas}` ?
              <li className="nav-item mb-3">
                <Link to={`/${Programas}`} className="btn btn-primary btn-block btn-lg"><i className="fas fa-certificate"></i> Programas</Link>
              </li>
              : null
          }
          {
            isAdmin && location !== `/${Doctores}` ?
              <li className="nav-item mb-3">
                <Link to={`/${Doctores}`} className="btn btn-primary btn-block btn-lg"><i className="fas fa-search" aria-hidden="true"></i> Buscar médicos en el sistema</Link>
              </li>
              : null
          }
          {/* <li className="nav-item mb-3">
                  <Link to="/registrosTotales" className="btn btn-primary btn-block btn-lg"><i className="fas fa-search" aria-hidden="true"></i> Consultar registros</Link>
                </li> */}
        </ul>
      </div>
    </nav>
  );
};

export default MenuColapsable;
