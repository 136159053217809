import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { FindtrackPDF } from '../../../assets';
import { DATLogo, LogoBayColor, MiniLogoBlanco } from '../../../img';
import ModalLogin from '../ModalLogin';

import './Login.css';
import Footer from '../Footer';

const LoginContainer = ({ children }) => {
  const formDoctor = useRef(null);

  return (
    <>
      <header>
        <div className="bg-nav">
          <nav className="navbar navbar-dark">
            <div className="navbar-brand">
              <img src={DATLogo} className="img-fluid" width="94" alt="logo DAT" />
            </div>
            <div className="navbar-expand-lg" id="navbarSupportedContent">
              <button
                style={{ color: 'white' }}
                className="btn btn-secondary mr-2 text-uppercase btnModalLogin"
                data-toggle="modal" data-target="#ModalLogin"
              >
                Registrarme
              </button>
              <button
                style={{ color: 'white' }}
                className="btn btn-primary text-uppercase btnModalLogin"
                data-toggle="modal" data-target="#ModalLogin"
              >
                Iniciar sesión
              </button>

              {/* Display para los botones */}
              {/* <div className="form-group">
                  <p>
                    <span id="PacienteMedico" ref={PacienteMedico}>¿Es paciente?</span> clic <button className="btn btnTypeAnchor" onClick={ToggleForm}>aquí</button>
                  </p>
                </div> */}
            </div>
          </nav>
        </div>
      </header>

      <div id='login-container' className="container">
        <div className="row py-3">
          <div className='col-12 d-flex justify-content-center align-items-center'>
            <img src={LogoBayColor} className='img-fluid' alt="Findtrack" width='100%' />
          </div>
        </div>
        <div className='row d-flex justify-content-center'>
          <div className='col-6'>
            <div className="card card-signin my-3 p-3 text-center">
              <h2>Conoce más acerca del programa FINDTRACK:</h2>
              <br />
              <p>
                Descargue el PDF con toda la información acerca del programa:
                <br />
                <a className='btn btn-primary mt-2' href={FindtrackPDF} target='_blank' rel='noopener noreferrer'>
                  Descargar <i className="fas fa-download"></i>
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className='row my-5'>
          <div className='col-12 text-center'>
            <h3 className='patrocinado'>
              Patrocinado por
            </h3>
            <img className='img-fluid' src={MiniLogoBlanco} alt='Bayer' width='15%'></img>
          </div>
        </div>

        <div className="card card-signin text-center">
          <Footer />
        </div>

        <div className="row numSeries">
          <div className='col-12'>
            <p>PP-VIT-MX-0022-1</p>
            <p>Exp. Marzo 2024</p>
          </div>
        </div>
      </div >

      <ModalLogin>
        <form onSubmit={e => e.preventDefault()} id="formDoctor" ref={formDoctor} className="form-signin needs-validation">
          {children}
        </form>
      </ModalLogin>
    </>
  );
};

export default LoginContainer;
