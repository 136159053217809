import React from 'react';
import { Link } from 'react-router-dom';

import DATLogo from '../../../img/DAT-logo.svg';
import { AvisoPrivacidad, TerminosCondiciones, Cookies } from '../../screens/screenNames';

const AvisosContainer = ({ children }) => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 mx-auto">
          <div className="card card-signin my-3">
            <div className="card-body text-center">
              <div className="row">
                <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
                  <Link to="/">
                    <img src={DATLogo} className="img-fluid my-5" width="75%" alt="Diagnostique a tiempo Logo" />
                  </Link>
                </div>
              </div>
              {children}
              <hr className="my-4" />
              <div className="form-group">
                <p className="legales">
                  <span><Link to={`/${AvisoPrivacidad}`}>Aviso de privacidad</Link> | <Link to={`${Cookies}`}>Política de cookies</Link> | <Link to={`${TerminosCondiciones}`}>Términos y Condiciones</Link> - </span>
                  <b>DAT® {new Date().getFullYear()}</b> by <b><a href="https://www.meetccs.com">Creative Consulting Systems</a></b>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AvisosContainer;