import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { Contexto as EstudiosContext } from '../../context/EstudiosContext';
import { Contexto as UsersContext } from '../../context/UsersContext';
import { Contexto as DoctoresContext } from '../../context/DoctoresContext';
import { Contexto as AdminContext } from '../../context/AdminContext';

import { Programas } from '../screenNames';

import { RestContainer } from '../../modules/RestContainer';
import { formatoFechaBDD } from '../../utils/Fecha';
import Promociones from '../../modules/PromocionesPath/Promociones';
import EstudiosActivosContainer from '../../modules/EstudiosActivosContainer';

const Home = () => {
  const { state: { programas, listaEstudiosDoc, listadoEstatus }, getAllEstudios, displayProgramas, vizualisarAllEstudios, getAllEstatus, getAllLabs } = useContext(EstudiosContext);
  const { state: { intereses, userUid, isAdmin } } = useContext(UsersContext);
  const { getAllDoctores } = useContext(DoctoresContext);
  const { state: { allEstudiosDAT }, getAllSolicitudesDAT } = useContext(AdminContext);

  useEffect(() => {
    // Cambiar fondo body
    window.document.body.classList.remove('bodyLogin');
  }, []);


  useEffect(() => {
    getAllEstudios(intereses);
    // eslint-disable-next-line
  }, [intereses]);

  useEffect(() => {
    const fecha = formatoFechaBDD(new Date());
    displayProgramas(fecha);
    getAllEstatus();
    getAllLabs();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    vizualisarAllEstudios(userUid);
    // eslint-disable-next-line
  }, [userUid]);

  // Listado de médicos en el sistema
  useEffect(() => {
    if (isAdmin) {
      getAllDoctores();
      getAllSolicitudesDAT();
    }
    // eslint-disable-next-line
  }, [isAdmin]);

  return (
    <RestContainer
      isHome={true}
      programas={programas}
    >
      <div id="Seleccion" className="col-sm-12 col-md-12 col-lg-8 pt-3">
        {/* LISTADO DE 5 ESTUDIOS */}
        {
          isAdmin ?
            <EstudiosActivosContainer
              listaEstudiosDoc={allEstudiosDAT}
              limit={true}
              listadoEstatus={listadoEstatus}
              idDocBuscado={userUid}
            />
            :
            <EstudiosActivosContainer
              listaEstudiosDoc={listaEstudiosDoc}
              limit={true}
              listadoEstatus={listadoEstatus}
              idDocBuscado={userUid}
            />
        }
        {/* LISTADO DE PROMOCIONES */}
        <Promociones
          programas={programas}
          limit={true}
        >
          {
            programas.length > 2 ?
              <Link to={`${Programas}`} className="btn btn-primary btn-block mt-3">Ver más <i className="fas fa-search-plus"></i></Link>
              : null
          }
        </Promociones>
      </div>
    </RestContainer>
  );
};

export default Home;
