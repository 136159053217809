import React, { useContext, useEffect } from 'react';

import { Contexto as AdminContext } from '../../context/AdminContext';

import { RestContainer } from '../../modules/RestContainer';

// import { ToggleCampos } from '../../utils/displayFields';
import AgregarEstudio from './AgregarEstudio';
import EditarEstudio from './EditarEstudio';
import AgregarPrograma from './AgregarProg';
import EditarPrograma from './EditarProg';

import history from '../../Navigation';

const Administrcion = () => {
  const { state: {
    titulosCategorias, titulosEspecialidades, titulosEstudios, infoEstudioPart, titulosProgs, infoPrograPart, pharmas
  },
    getAllCategorias, getAllEstudios, getAllEspecialidades,
    enviarEstudio, getDetalleEstudio, eliminarEstudioDuplicado,
    enviarPrograma, getAllProgs, getDetalleProgra,
    eliminarPrograDuplicado, getAllNomsPharmas
  } = useContext(AdminContext);

  useEffect(() => {
    getAllCategorias();
    getAllEspecialidades();
    getAllEstudios();
    getAllProgs();
    getAllNomsPharmas();
    // eslint-disable-next-line
  }, []);

  return (
    <RestContainer>

      <div id="Seleccion" className="col-sm-12 col-md-12 col-lg-8">
        <div className="row">
          <div className="col-6">
            <h3 onClick={() => history.push('/home')}><i className="fas fa-arrow-circle-left"></i> Administración</h3>
          </div>
        </div>
        <div className="division2 my-3"></div>
        <div className="card mt-3 tarjetas">
          <div className="card-header">
            {/* EMPTY */}
          </div>
          <div className="card-body">
            <ul className="list-group">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item">
                  <a className="nav-link active" id="agregarEstudio-tab" data-toggle="tab" href="#agregarEstudio" role="tab" aria-controls="agregarEstudio" aria-selected="true"><i className="fi-cwsuxl-plus-solid"></i> Agregar Estudio</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" id="editarEstudio-tab" data-toggle="tab" href="#editarEstudio" role="tab" aria-controls="editarEstudio" aria-selected="false"><i className="fi-xnsuxl-edit-solid"></i> Editar Estudio</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" id="agregarPrograma-tab" data-toggle="tab" href="#agregarPrograma" role="tab" aria-controls="agregarPrograma" aria-selected="false"><i className="fi-cwsuxl-plus-solid"></i> Agregar Programa</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" id="editarPrograma-tab" data-toggle="tab" href="#editarPrograma" role="tab" aria-controls="editarPrograma" aria-selected="false"><i className="fi-xnsuxl-edit-solid"></i> Editar Programa</a>
                </li>
                {/* <li className="nav-item">
                  <a className="nav-link" id="agregarMedico-tab" data-toggle="tab" href="#agregarMedico" role="tab" aria-controls="agregarMedico" aria-selected="false"><i className="fi-cwsuxl-plus-solid"></i> Agregar Médico</a>
                </li> */}
              </ul>
              <div className="tab-content" id="myTabContent">

                {/* AGREGAR ESTUDIO */}
                <AgregarEstudio
                  titulo='Agregar Estudio'
                  titulosCategorias={titulosCategorias}
                  titulosEspecialidades={titulosEspecialidades}
                  enviarEstudio={enviarEstudio}
                />

                {/* EDITAR ESTUDIO */}
                <EditarEstudio
                  titulo='Editar Estudio'
                  titulosCategorias={titulosCategorias}
                  titulosEspecialidades={titulosEspecialidades}
                  enviarEstudio={enviarEstudio}

                  titulosEstudios={titulosEstudios}
                  infoEstudioPart={infoEstudioPart}
                  getDetalleEstudio={getDetalleEstudio}
                  eliminarEstudioDuplicado={eliminarEstudioDuplicado}
                />

                {/* AGREGAR PROGRAMA */}
                <AgregarPrograma
                  titulo='Agregar Programa'
                  titulosEstudios={titulosEstudios}
                  pharmas={pharmas}
                  getDetalleEstudio={getDetalleEstudio}
                  infoEstudioPart={infoEstudioPart}
                  enviarPrograma={enviarPrograma}
                />

                {/* EDITAR PROGRAMA */}
                <EditarPrograma
                  titulo='Editar Programa'
                  titulosEstudios={titulosEstudios}
                  pharmas={pharmas}
                  getDetalleEstudio={getDetalleEstudio}
                  infoEstudioPart={infoEstudioPart}
                  enviarPrograma={enviarPrograma}

                  titulosProgs={titulosProgs}
                  infoPrograPart={infoPrograPart}
                  getDetalleProgra={getDetalleProgra}
                  eliminarPrograDuplicado={eliminarPrograDuplicado}
                />

                {/* AGREGAR MÉDICO */}
                <div className="tab-pane fade" id="agregarMedico" role="tabpanel" aria-labelledby="agregarMedico-tab">
                  <div className="card mt-3">
                    <div className="card-header">
                      <h5><i className="fi-cwsuxl-check"></i> Agregar Médico</h5>
                    </div>
                    <div className="card-body">
                      <form id="AgregarMedico">
                        <div className="form-row">
                          <div className="col-6 mb-3">
                            <label htmlFor="PrimerNombreMed">Primer Nombre</label>
                            <input className="form-control mr-sm-2" type="text" placeholder="Primer Nombre" name="PrimerNombreMed" id="PrimerNombreMed" />
                          </div>
                          <div className="col-6 mb-3">
                            <label htmlFor="SegundoNombreMed">Segundo Nombre</label>
                            <input className="form-control mr-sm-2" type="text" placeholder="Segundo Nombre" name="SegundoNombreMed" id="SegundoNombreMed" />
                          </div>
                          <div className="col-6 mb-3">
                            <label htmlFor="PrimerApellidoMed">Primer Apellido</label>
                            <input className="form-control mr-sm-2" type="text" placeholder="Primer Apellido" name="PrimerApellidoMed" id="PrimerApellidoMed" />
                          </div>
                          <div className="col-6 mb-3">
                            <label htmlFor="SegundoApellidoMed">Segundo Apellido</label>
                            <input className="form-control mr-sm-2" type="text" placeholder="Segundo Apellido" name="SegundoApellidoMed" id="SegundoApellidoMed" />
                          </div>

                          <div className="col-6 mb-3">
                            <label htmlFor="especialidad">Seleccione Especialidad</label>
                            <select className="form-control mr-sm-2" name="especialidad">
                              <option value="">Seleccione</option>
                              <option value="Estudio 1">Estudio 1</option>
                              <option value="Estudio 2">Estudio 2</option>
                              <option value="Estudio 3">Estudio 3</option>
                              <option value="Estudio 4">Estudio 4</option>
                              <option value="Estudio 5">Estudio 5</option>
                              <option value="Estudio 6">Estudio 6</option>
                              <option value="Otro">Otro</option>
                            </select>
                          </div>
                          <div className="col-6 mb-3">
                            <label htmlFor="CedulaProfMed">Cédula Profesional</label>
                            <input className="form-control mr-sm-2" type="text" placeholder="Cédula Profesional" name="CedulaProfMed" id="CedulaProfMed" />
                          </div>

                          <div className="col-6 mb-3">
                            <label htmlFor="TelefonoMed">Teléfono</label>
                            <input className="form-control mr-sm-2" type="text" placeholder="Teléfono" name="TelefonoMed" id="TelefonoMed" />
                          </div>
                          <div className="col-6 mb-3">
                            <label htmlFor="ConfTelefonoMed">Confirmar Teléfono</label>
                            <input className="form-control mr-sm-2" type="text" placeholder="Teléfono" name="ConfTelefonoMed" id="ConfTelefonoMed" />
                          </div>

                          <div className="col-6 mb-3">
                            <label htmlFor="EmailMed">Email</label>
                            <input className="form-control mr-sm-2" type="text" placeholder="Email" name="EmailMed" id="EmailMed" />
                          </div>
                          <div className="col-6 mb-3">
                            <label htmlFor="ConfEmailMed">Confirmar Email</label>
                            <input className="form-control mr-sm-2" type="text" placeholder="Email" name="ConfEmailMed" id="ConfEmailMed" />
                          </div>
                          <div className="col-12 mb-3">
                            <h4>Dirección:</h4>
                          </div>
                          <div className="col-12 col-md-6 mb-3">
                            <label htmlFor="DirMedCalle">Calle</label>
                            <input className="form-control mr-sm-2" type="text" placeholder="Calle" name="DirMedCalle" id="DirMedCalle" />
                          </div>
                          <div className="col-12 col-md-3 mb-3">
                            <label htmlFor="DirMedNumero">Número</label>
                            <input className="form-control mr-sm-2" type="text" placeholder="Número" name="DirMedNumero" id="DirMedNumero" />
                          </div>
                          <div className="col-12 col-md-3 mb-3">
                            <label htmlFor="DirMedAlcaldia">Alcaldía</label>
                            <select className="form-control mr-sm-2" type="text" name="DirMedAlcaldia" id="DirMedAlcaldia">
                              <option value="">Alcaldía</option>
                              <option value="Alcaldía1">Alcaldía 1</option>
                              <option value="Alcaldía2">Alcaldía 2</option>
                              <option value="Alcaldía3">Alcaldía 3</option>
                              <option value="Alcaldía4">Alcaldía 4</option>
                            </select>
                          </div>
                          <div className="col-12 col-md-4 mb-3">
                            <label htmlFor="DirMedCiudad">Ciudad</label>
                            <input className="form-control mr-sm-2" type="text" placeholder="Ciudad" name="DirMedCiudad" id="DirMedCiudad" />
                          </div>
                          <div className="col-12 col-md-4 mb-3">
                            <label htmlFor="DirMedEstado">Estado</label>
                            <input className="form-control mr-sm-2" type="text" placeholder="Estado" name="DirMedEstado" id="DirMedEstado" />
                          </div>
                          <div className="col-12 col-md-4 mb-3">
                            <label htmlFor="DirMedCodPostal">Código Postal</label>
                            <input className="form-control mr-sm-2" type="text" placeholder="Código Postal" name="DirMedCodPostal" id="DirMedCodPostal" />
                          </div>
                          <div className="col-12 mb-3 text-center">
                            <button className="btn btn-primary btn-md" type="button">Agregar <i className="fi-cwsuxl-plus-solid"></i></button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </ul>
          </div>
        </div>
      </div>

    </RestContainer>
  );
};

export default Administrcion;