import React from 'react';

const PromocionContainer = props => {
  return (
    <div className="card mt-3">
      <div className="card-header">
        <h4>Programas</h4>
      </div>
      <div className="card-body">
        {props.children}
      </div>
    </div>
  );
};

export default PromocionContainer;
