import React from 'react';

const FormPaciente = ({ children, folio, setNombrePaciente, nombrePaciente, setApellidoPaciente, apellidoPaciente, fechaNacHumano, handleDateSafari, nacDia, nacMes, nacAnio, currentYear, genero, setGenero, setBothEmails, emailPaciente, ecog, setEcog, telPaciente, setTelPaciente, celular, setCelular }) => {
  return (
    <>
      <h4 className="alert-warning"><b>*</b> Los campos en amarillo son obligatorios</h4>
      <br />
      <div className="form-row">
        <div className="col-12 mb-3">
          <div className="form-control mr-sm-2">No. Folio: {folio}</div>
        </div>
        <div className="col-6 mb-3">
          <label className="alert-warning"><b>*</b> Nombre(s) del paciente:</label>
          <input className={`form-control mr-sm-2 alert-warning${nombrePaciente}`} type="input" placeholder="Nombre(s)" name="nombres"
            onChange={e => setNombrePaciente(e.target.value)}
            value={nombrePaciente}
          />
        </div>
        <div className="col-6 mb-3">
          <label className="alert-warning"><b>*</b> Apellido(s) del paciente:</label>
          <input className={`form-control mr-sm-2 alert-warning${apellidoPaciente}`} type="input" placeholder="Apellido(s)" name="apellidos"
            onChange={e => setApellidoPaciente(e.target.value)}
            value={apellidoPaciente}
          />
        </div>
        <div className="col-12 mb-3">
          <label className="alert-warning">Fecha de nacimiento: {fechaNacHumano}</label>
          <div className="row">
            <div className="col-4">
              <label className="alert-warning"><b>*</b> Día</label>
              <input className={`form-control mr-sm-2 alert-warning${fechaNacHumano}`} type="number" placeholder="día" name="nacDia"
                onChange={e => handleDateSafari(e.target.value, 31, 'dia')}
                value={nacDia}
                max="31"
                min="1"
                maxLength="2"
              />
            </div>
            <div className="col-4">
              <label className="alert-warning"><b>*</b> Mes</label>
              <input className={`form-control mr-sm-2 alert-warning${fechaNacHumano}`} type="number" placeholder="mes" name="nacMes"
                onChange={e => handleDateSafari(e.target.value, 12, 'mes')}
                value={nacMes}
                max="12"
                min="1"
                maxLength="2"
              />
            </div>
            <div className="col-4">
              <label className="alert-warning"><b>*</b> Año</label>
              <input className={`form-control mr-sm-2 alert-warning${fechaNacHumano}`} type="number" placeholder="año" name="nacAnio"
                onChange={e => handleDateSafari(e.target.value, currentYear, 'anio')}
                value={nacAnio}
                max={`${currentYear}`}
                min="1930"
                maxLength="4"
              />
            </div>
          </div>
        </div>
        <div className="col-6 mb-3">
          <label className="alert-warning"><b>*</b> ECOG (0-4):</label>
          <input className={ecog ? 'form-control mr-sm-2' : 'form-control mr-sm-2 alert-warning'} type="number"
            onChange={e => setEcog(e.target.value)}
            value={ecog}
            max={4}
            min={0}
            disabled={ecog === 'Ecog no disponible'}
          />
          <label htmlFor="checkEcog">¿O no está disponible el ECOG?</label> <input type="checkbox"
            value="Ecog no disponible"
            id="checkEcog"
            onChange={e => {
              e.target.checked ?
                setEcog('Ecog no disponible')
                : setEcog()
            }}
            checked={ecog === 'Ecog no disponible'}
          />
        </div>
        <div className="col-6 mb-3">
          <label className="alert-warning"><b>*</b> Género del paciente:</label>
          <select className={`form-control mr-sm-2 alert-warning${genero}`} name="genero"
            onChange={e => setGenero(e.target.value)}
            value={genero}
          >
            <option value="">Seleccione el género</option>
            <option value="Masculino">Masculino</option>
            <option value="Femenino">Femenino</option>
            <option value="No determinado">No determinado</option>
          </select>
        </div>
        <div className="col-6 mb-3">
          <label>Email del paciente:</label>
          <input className="form-control mr-sm-2" type="email" placeholder="Email" name="emailPaciente"
            onChange={e => setBothEmails(e.target.value)}
            value={emailPaciente}
          />
        </div>
        <div className="col-6 mb-3">
          <label>Teléfono de casa:</label>
          <input className="form-control mr-sm-2" type="tel" placeholder="Teléfono a 10 dígitos"
            maxLength={10} minLength={10}
            value={telPaciente}
            onChange={e => setTelPaciente(e.target.value)}
          />
        </div>
        <div className="col-6 mb-3">
          <label>Teléfono celular:</label>
          <input className="form-control mr-sm-2" type="tel" placeholder="Teléfono móvil a 10 dígitos"
            maxLength={10} minLength={10}
            value={celular}
            onChange={e => setCelular(e.target.value)}
          />
        </div>
        {children}
      </div>
    </>
  );
};

export default FormPaciente;