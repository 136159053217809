export const copyClipboard = idInput => {
  /* Get the text field */
  const copyText = document.getElementById(idInput);

  /* Select the text field */
  copyText.select();
  copyText.setSelectionRange(0, 99999); /*For mobile devices*/

  /* Copy the text inside the text field */
  document.execCommand("copy");
};