import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import EstudioURL from './EstudioURL';
import { Contexto as EstudiosContext } from '../context/EstudiosContext';
import { DetalleRegistro } from '../screens/screenNames';

const EstudiosActivos = ({ listaEstudiosDoc, limit }) => {
  const { state: { listadoEstatus } } = useContext(EstudiosContext);

  if (listaEstudiosDoc.length === 0) {
    return (
      <tr>
        <td><h5 className="card-title">Todavía no se ha realizado ninguna solicitud de estudio.</h5></td>
      </tr>
    );
  }

  return (
    <>
      {
        listaEstudiosDoc.map((solicitudActiva, index) => {
          if (limit && index > 4) {
            return null;
          }

          return (
            <tr key={solicitudActiva.folio}>
              <th scope="row">{index + 1}</th>
              <td>
                <Link
                  to={{
                    pathname: `/${DetalleRegistro}/${solicitudActiva.folio}`,
                    state: { solicitudActiva }
                  }}
                >
                  {solicitudActiva.folio}
                </Link>
              </td>
              <td>{solicitudActiva.nombrePaciente} {solicitudActiva.apellidoPaciente}</td>
              <td>{solicitudActiva.estudio}</td>
              <td>{solicitudActiva.etapa}</td>
              <EstudioURL
                solicitudActiva={solicitudActiva}
              />
              <td align="center">
                {solicitudActiva.etapa === listadoEstatus[0][0] ?
                  <i className="messageColor fas fa-envelope messageNoRead"></i>
                  : <i className="messageColor fas fa-envelope-open messageRead"></i>
                }
              </td>
            </tr>
          );
        })
      }
    </>
  );
}

export default EstudiosActivos;