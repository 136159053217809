import React from 'react';

const ApCi = () => {
  return (
    <div className="row printApCI" id="printSection">
      {/* CONSENTIMIENTO INFORMADO */}
      <h3 className="textoFormal">CONSENTIMIENTO INFORMADO</h3>
      <ol className="text-justify">
        <li>
          Que mi médico tratante me ha informado sobre la naturaleza del estudio, su utilidad, alcances y limitaciones y he solicitado voluntariamente que se me realice el estudio indicado.
        </li>
        <li>
          Que mi médico solicitante me ha informado y entiendo que el estudio indicado tiene por finalidad la realización de una prueba de diagnóstico y me han explicado los siguientes puntos:
          <ul>
            <li>
              El análisis de DNA requiere una muestra de tejido que puede ser de sangre, cepillado oral, biopsia u otros métodos de obtención de la muestra.
            </li>
            <li>
              Existe la posibilidad que se requieran muestras adicionales por considerarse insuficiente o no adecuada para reportar un resultado.
            </li>
            <li>
              El análisis de DNA es específico para ciertas condiciones. La exactitud de los análisis puede verse limitada por la técnica empleada, el
              diagnóstico clínico y la naturaleza de la condición o condiciones para la que se ha solicitado la prueba. Estas limitaciones pueden estar sujetas al conocimiento o tecnología existente al momento de realizar e interpretar el análisis.
            </li>
            <li>
              Siempre existe la posibilidad de falla o error en el análisis o interpretación de estos estudios, la posibilidad es cercana a 1 de cada 1000 casos.
            </li>
            Este error existe para cualquier laboratorio.
            <li>
              Me han explicado la especificidad, utilidad y limitación de las pruebas solicitadas.
            </li>
            <li>
              La correcta interpretación de los resultados depende en gran medida del diagnóstico clínico e historia médica.
            </li>
            <li>
              Los resultados serán enviados únicamente al médico tratante.
            </li>
            <li>
              Los resultados del paciente serán confidenciales.
            </li>
            <li>
              Solicitudes para estudios adicionales pueden generar cobros adicionales.
            </li>
            <li>
              El estudio requiere la entrega de una muestra de tejido la cual será entregada a un laboratorio cualificado con quién Diagnostique A Tiempo
              (de ahora en adelante DAT) tenga un convenio previo.
            </li>
            <li>
              He sido informado(a) por mi médico tratante sobre el objetivo de esta prueba, la metodología empleada, los eventuales beneficios, los posibles riesgos y eventos adversos que se pueden presentar.
            </li>
          </ul>
        </li>
        <li>
          Autorizo el envío y uso de mi material biológico al laboratorio en convenio con DAT con el fin de realizar las pruebas pertinentes de diagnóstico. Si al finalizar el estudio existe material restante, éste quedará bajo responsabilidad del laboratorio.
        </li>
        <li>
          Que por el presente documento otorgo mi consentimiento expreso y acepto libre y voluntariamente se realicen las pruebas indicadas por el médico solicitante.
        </li>
        <li>
          He sido informado que los resultados emitidos le serán entregados directamente a mi médico tratante, según la información contenida en la solicitud de estudios para la realización de la prueba.
        </li>
        <li>
          Entiendo y acepto que las recomendaciones, solicitudes de estudios y tratamientos son responsabilidad de mi médico tratante/solicitante y axcepto que DAT no es responsable por mi salud y no busca reemplazar las recomendaciones y/o las órdenes de mi médico tratante relacionadas con mi salud.
        </li>
        <li>
          Entiendo, reconozco y acepto que DAT no es responsable de los resultados del laboratorio, siendo éstos responsabilidad del laboratorio de diagnóstico. Adicionalmente, reconozco que durante el tránsito de la muestra hacia el laboratorio o en su procesamiento o estudio, existe un riesgo que la muestra sufra daño, robo, desgaste, degradación u otro tipo de perjuicios y que tales eventualidades se encuentran fuera del control de mi médico tratante y de DAT.
        </li>
        <li>
          Que entiendo que estoy en total libertad de retirar mi consentimiento en cualquier momento.
        </li>
        <li>
          Se me ha explicado y entiendo que los resultados serán entregados única y exclusivamente al médico responsable/solicitante quién me informará personalmente acerca de los mismos. Asimismo, se me indica que estos resultados no serán comunicados a otros miembros de mi familia o a terceras partes sin mi consentimiento expreso por escrito con mi firma en señal de conformidad.
        </li>
        <li>
          Se me ha explicado y acepto que la muestra no será procesada o transferida al laboratorio para su proceso hasta realizar el pago en su totalidad.
        </li>
        <li>
          En caso de requerir reembolso de los costos del estudio, DAT o sus representantes podrán compartir información confidencial que identifique al paciente y otro tipo de información, incluyendo el resultado.
          He leído y acepto las condiciones aquí indicadas, se me ha explicado y comprendo su contenido y lo ratifico al incluir mi firma en el presente documento. He tenido oportunidad de formular preguntas y todas las preguntas expresadas han sido respondidas o explicadas a mi entera satisfacción.
        </li>
      </ol>

      {/* AVISO DE PRIVACIDAD */}
      <h3 className="textoFormal">AVISO DE PRIVACIDAD</h3>
      <p className="text-justify">
        Comprendo y solicito libre, expresa y voluntariamente me sea realizado el estudio indicado por mi médico solicitante. Acepto también y consiento el uso de mis datos personales y confidenciales para los fines antes descritos y la información sea registrada, conservada y protegida bajo medidas de estricta confidencialidad en cumplimiento con la Ley Federal de Protección de Datos Personales en Posesión de Particulares, al mismo tiempo, autorizo expresa- mente a DAT para guardar, transferir y/o compartir mis datos personales a terceros, como son empresas que le proporcionen servicios a DAT, para que dichos terceros puedan tener accesos y hacer uso de mis datos personales para el efecto de la administración, resguardo, investigación, academia y fines legítimos en relación con la obtención del resultado o del trámite de reembolso en caso que aplique. Entiendo que la información protegida no será utilizada para ningún otro propósito que los mencionados anteriormente y que si deseo ejercer mis derechos ARCO para: (i) Tener acceso a mis datos personales; (ii) Rectificar los mismos; (iii) Cancelarlos; (iv) Oponerse a un uso distinto al autorizado y/o (v) Revocar mi consentimiento al tratamiento de mis Datos Perso- nales, podré solicitarlo por escrito dirigido a DX a Tiempo S.A.S. de C.V. y a la siguiente dirección de correo electrónico: contacto@diagnostiqueatiempo.- com, contando con un plazo de 90 días naturales para recibir respuesta y acreditando adecuadamente identidad o representación legal
      </p>

    </div>
  );
};

export default ApCi;