import dias from '../../img/dias.svg';
import tardes from '../../img/tardes.svg';
import noches from '../../img/noches.svg';

const mostrarSaludo = () => {
  const hora = new Date().getHours();
  let texto = 'Buenas noches';
  let imagen = noches;
  const fondo = 'noches';

  if (hora >= 6 && hora < 15) {
    texto = "Buenos días";
    imagen = dias;
  }

  if (hora >= 15 && hora < 20) {
    texto = "Buenas tardes";
    imagen = tardes;
  }

  return { imagen, texto, fondo };
}

export default mostrarSaludo;
