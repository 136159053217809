import axios from '../axios/axios';
import { mesHumanoANumero } from './Fecha';

export const handlePDF = async (folio, nombrePaciente, apellidoPaciente, nacDia, nacMes, nacAnio, genero,
  categoriaElegida, estudioElegido, metodologiaEstudio, claveEstudio,
  nomDoc, cedula, email, telefono,
  primarioSelect, metastasicoSelect, muestraBloque, numeroMuestraBloque, muestraLaminillas, numeroMuestraLaminillas, muestraMes, muestraAnio, diagnostico, tratamientosPrevios, labRadio,
  inputNombreHospital, inputCalleNumero, inputCodigoPostal, inputColinia, inputAlcaldiaMun, inputEstado, inputReferencias, horarioRecoger,
  costoEstudio, firmaPaciente, firmaMedico) => {

  firmaPaciente = firmaPaciente ? String(firmaPaciente) : '';
  firmaMedico = firmaMedico ? String(firmaMedico) : '';
  const response = await axios.post('/pdfgen',
    {
      folio,
      nombrePaciente,
      apellidoPaciente,
      nacDia: Number(nacDia),
      nacMes: Number(nacMes),
      nacAnio: Number(nacAnio),
      genero,
      categoriaElegida,
      estudioElegido: estudioElegido[0],
      metodologiaEstudio,
      claveEstudio,
      nomDoc,
      cedula,
      email,
      telefono,
      primarioSelect,
      metastasicoSelect,
      muestraBloque,
      numeroMuestraBloque,
      muestraLaminillas,
      numeroMuestraLaminillas: (numeroMuestraLaminillas || ''),
      muestraMes: Number(muestraMes),
      muestraAnio: Number(muestraAnio),
      diagnostico,
      tratamientosPrevios,
      labRadio,
      inputNombreHospital,
      inputCalleNumero,
      inputCodigoPostal,
      inputColinia,
      inputAlcaldiaMun,
      inputEstado,
      inputReferencias,
      horarioRecoger,
      costoEstudio: Number(costoEstudio),
      firmaPaciente,
      firmaMedico
    }
  );

  // 1. Create blob link to download
  const file = new Blob([response.data]);
  const url = window.URL.createObjectURL(file);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `FormatoFindTRACK-${folio}.pdf`);

  // 2. Append to html page
  document.body.appendChild(link);
  // 3. Force download
  link.click();
  // 4. Clean up and remove the link
  link.parentNode.removeChild(link);
};

export const handleGeneratedPDF = async solicitudActiva => {
  // const response = await axios.get(`/pdf/${folio}`);

  const {
    folio,
    nombrePaciente,
    apellidoPaciente,
    fechaNac,
    genero,
    categoriaElegida,
    estudio,
    metodologiaEstudio,
    claveEstudio,
    nomDoc,
    cedulaDoc,
    emailDoc,
    telefono,
    primarioSelect,
    metastasicoSelect,
    muestraBloque,
    numeroMuestraBloque,
    muestraLaminillas,
    numeroMuestraLaminillas,
    fechaMuestra,
    diagnostico,
    tratamientosPrevios,
    laboratorioSeleccionado,

    direccion,

    horarioRecoger,
    costo,
    firmaMedico,
    firmaPaciente
  } = solicitudActiva;

  const nacSeparado = fechaNac.split('-');
  const nacDia = nacSeparado[0];
  const nacMes = mesHumanoANumero(nacSeparado[1]);
  const nacAnio = nacSeparado[2];

  const fechaMuestraSeparado = fechaMuestra.split('/');
  const muestraMes = mesHumanoANumero(fechaMuestraSeparado[1]);
  const muestraAnio = fechaMuestraSeparado[1];

  const inputNombreHospital = direccion.nombreHospital;
  const inputCalleNumero = direccion.calle;
  const inputCodigoPostal = direccion.cp;
  const inputColinia = direccion.colonia;
  const inputAlcaldiaMun = direccion.municipio;
  const inputEstado = direccion.estado;
  const inputReferencias = direccion.referencias;

  const response = await axios.post('/pdfgen',
    {
      folio,
      nombrePaciente,
      apellidoPaciente,
      nacDia: Number(nacDia),
      nacMes: Number(nacMes),
      nacAnio: Number(nacAnio),
      genero,
      categoriaElegida,
      estudioElegido: estudio,
      metodologiaEstudio,
      claveEstudio,
      nomDoc,
      cedula: cedulaDoc,
      email: emailDoc,
      telefono,
      primarioSelect,
      metastasicoSelect,
      muestraBloque,
      numeroMuestraBloque,
      muestraLaminillas,
      numeroMuestraLaminillas: (numeroMuestraLaminillas || ''),
      muestraMes: Number(muestraMes),
      muestraAnio: Number(muestraAnio),
      diagnostico,
      tratamientosPrevios,
      labRadio: laboratorioSeleccionado,
      inputNombreHospital,
      inputCalleNumero,
      inputCodigoPostal,
      inputColinia,
      inputAlcaldiaMun,
      inputEstado,
      inputReferencias,
      horarioRecoger,
      costoEstudio: Number(costo),
      firmaMedico,
      firmaPaciente
    }
  );

  // 1. Create blob link to download
  const file = new Blob([response.data]);
  const url = window.URL.createObjectURL(file);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `FormatoFindTRACK-${folio}.pdf`);

  // 2. Append to html page
  document.body.appendChild(link);
  // 3. Force download
  link.click();
  // 4. Clean up and remove the link
  link.parentNode.removeChild(link);
};

export const handleGeneratedPDFBayer = async solicitudActiva => {
  const { folio, nomDoc, firmaMedico } = solicitudActiva;

  const response = await axios.post('/pdfbayer',
    {
      folio,
      nomDoc,
      firmaMedico
    }
  );

  // 1. Create blob link to download
  const file = new Blob([response.data]);
  const url = window.URL.createObjectURL(file);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `FormatoBayer-${folio}.pdf`);

  // 2. Append to html page
  document.body.appendChild(link);
  // 3. Force download
  link.click();
  // 4. Clean up and remove the link
  link.parentNode.removeChild(link);
};