import React, { useEffect } from 'react';

import Header from '../Header';
import Footer from '../Footer';
import MenuLateral from '../MenuLateral';
import MenuColapsable from '../MenuColapsable';
import PromocionesHeader from '../PromocionesPath/PromocionesHeader';

import './RestContainer.css';

const RestContainer = ({ children, isHome, programas }) => {

  return (
    <div className="container-fluid container-rest">
      <Header />
      <section className="row p-2 mt-2">
        {/* <div className="col-sm-12 col-md-10 mb-4">
          <h1>Bienvenid@ <span className="d-none d-md-inline">{`${nombre} ${apellidoPat}`}</span></h1>
        </div> */}
        {
          isHome ?
            <PromocionesHeader
              programas={programas}
              limit={true}
            ></PromocionesHeader>
            : null
        }
        <div className="col-sm-12 d-sm-block d-md-block d-lg-none">
          <MenuColapsable />
        </div>
        <div className="col-sm-12">
          <div className="division my-3"></div>
        </div>
        {children}
        <MenuLateral />
      </section>
      <Footer />
    </div>
  );
};

export default RestContainer;
