import React, { useEffect, useState, useRef, useContext } from 'react';
import LoginContainer from '../../modules/LoginPath/LoginContainer';

import { Contexto as PacientesContext } from '../../context/PacientesContext';
import { Contexto as EstudiosContext } from '../../context/EstudiosContext';

import FormPago from '../../modules/SolicitudPath/FormPago';
import FormApCi from '../../modules/SolicitudPath/FormApCi';

const VisorFirma = () => {
  const subUrl = window.location.href.split('/')

  const [folioSolicitud, setFolioSolicitud] = useState(subUrl[subUrl.length - 1]);
  const folioRef = useRef(null);

  const [facturar, setFacturar] = useState(false);
  const [fisicaMoral, setFisicaMoral] = useState('');
  const [RFC, setRFC] = useState('');
  const [emailFactura, setEmailFactura] = useState('');
  const [pagoPayPal, setPagadoPayPal] = useState(false);
  const [errorPayPal, setErrorPayPal] = useState(null);
  const payPalRef = useRef();

  const { state: { folioEncontrado, idEmplear, estudioElegido, costoEstudio, empresaPharma, firmaMed, pago }, buscarSolicitudFolio } = useContext(PacientesContext);
  const { updateEtapaEstudio, updateInfoEstudio } = useContext(EstudiosContext);
  const [firmaPaciente, setFirmaPaciente] = useState(null);
  const [firmaMedico, setFirmaMedico] = useState(null);

  useEffect(() => {
    if (!folioSolicitud) {
      return;
    }
    const timer = setTimeout(() => {
      if (folioSolicitud === folioRef.current.value) {
        buscarSolicitudFolio(folioSolicitud);
      }
    }, 750);
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line
  }, [folioSolicitud, folioRef]);

  /**
   * Cargar script de PayPal
   */
  useEffect(() => {
    if (payPalRef.current) {
      window.paypal
        .Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  description: estudioElegido[0],
                  amount: {
                    currency_code: 'MXN',
                    value: costoEstudio,
                  },
                },
              ],
            });
          },
          onApprove: async (data, actions) => {
            const order = await actions.order.capture();
            setPagadoPayPal(true);
            console.log(order);
          },
          onError: err => {
            setErrorPayPal(err);
            console.error(err);
          },
        })
        .render(payPalRef.current);
    }
  }, [estudioElegido, costoEstudio]);

  /**
   * Envío de las firmas del doctor y el paciente
   */
  const enviarFirmas = (firma, fuente) => {
    if (fuente === 'Paciente') {
      if (pagoPayPal || pago) {
        guardarDatosSeccion('Pago realizado', 'firmaPaciente', firma);
      } else {
        guardarDatosSeccion('Pago Pendiente', 'firmaPaciente', firma);
      }
      setFirmaPaciente(firma);
    } else {
      if (pagoPayPal || pago) {
        guardarDatosSeccion('Pago realizado', 'firmaMedico', firma);
      } else {
        guardarDatosSeccion('Pago Pendiente', 'firmaMedico', firma);
      }
      setFirmaMedico(firma);
    }

    window.Swal.fire({
      title: '¡Firma exitosa!',
      text: 'La firma se ha guardado correctamente.',
      icon: 'success',
      confirmButtonText: 'OK!'
    });
  };

  const guardarDatosSeccion = (etapaUpdate, tipo, datosUpdate) => {
    updateEtapaEstudio(idEmplear, folioSolicitud, etapaUpdate);
    updateInfoEstudio(idEmplear, folioSolicitud, tipo, datosUpdate);
  };

  return (
    <LoginContainer>
      <div className="form-signin">
        <div className="form-group">
          <p>
            Usted puede realizar la firma del aviso de privacidad y el consentimiento informado o en caso de que aplique, realizar el pago aquí:
          </p>
        </div>
        <div className="form-label-group">
          <input type="text" id="folio" className="form-control text-center" placeholder="# Folio" required
            ref={folioRef}
            value={folioSolicitud}
            onChange={e => setFolioSolicitud(e.target.value.toUpperCase())}
          />
          <label htmlFor="folio"># Folio</label>
        </div>
        {
          folioSolicitud ?
            <div className="form-group">
              <div className="row">
                <div className="col-12 mb-3">
                  {
                    folioEncontrado ?
                      <>
                        <div className="row mb-3">
                          <div className="col">
                            <h4 className=" card-h5">Bienvenid@, por favor proporcione la siguiente información:</h4>
                          </div>
                        </div>
                        <FormApCi
                          enviarFirmas={enviarFirmas}
                          empresaPharma={empresaPharma}
                          firmaMed={firmaMed}
                          firmaMedico={firmaMedico}
                          firmaPaciente={firmaPaciente}
                        />

                        {
                          costoEstudio > 0 && (
                            <FormPago
                              payPalRef={payPalRef}
                              costoEstudio={costoEstudio}
                              pago={pagoPayPal}
                              facturar={facturar}
                              fisicaMoral={fisicaMoral}
                              RFC={RFC}
                              errorPayPal={errorPayPal}
                              setFacturar={setFacturar}
                              setFisicaMoral={setFisicaMoral}
                              setRFC={setRFC}
                              emailFactura={emailFactura}
                              setEmailFactura={setEmailFactura}
                            />
                          )
                        }
                      </>
                      :
                      <div className="row">
                        <div className="col">
                          <h3 className=" card-h5">No se encontró ningúna solicitud con ese folio ingresado.</h3>
                        </div>
                      </div>
                  }
                </div>
              </div>
            </div>
            : null
        }
      </div>
    </LoginContainer>
  );
};

export default VisorFirma;