import React, { useRef, useState, useEffect } from 'react';
import SignaturePad from 'signature_pad';

import './FirmaDigital';

const FirmaDigital = ({ idModal, del, enviarFirmas, skipFirmaPaciente }) => {
  const canvasFirma = useRef(null);
  const signaturePad = useRef(null);
  const [mensaje, setMensaje] = useState('');
  const [mensajePos, setMensajePos] = useState(false);

  useEffect(() => {
    if (canvasFirma.current) {
      signaturePad.current = new SignaturePad(canvasFirma.current);
    }
  }, [canvasFirma]);

  const limpiarFirma = () => {
    signaturePad.current.clear();
  };

  const guardarFirma = () => {
    if (!signaturePad.current.isEmpty()) {
      // setFirma(signaturePad.toDataURL("image/jpeg"));
      enviarFirmas(signaturePad.current.toDataURL(), del);

      setMensaje('¡Firma guardada con éxito!');
      setMensajePos(true);
      window.hideModal(`#${idModal}`);
    } else {
      setMensajePos(false);
      setMensaje('Todavía no ingresas una firma');
    }
  };

  const cerrarModal = () => {
    window.hideModal(`#${idModal}`);
  };

  return (
    <div id={idModal} className="modal fade">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Firma del {del}</h5>
            <button type="button" className="close" onClick={cerrarModal} aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {
              skipFirmaPaciente ?
                <p className="text-justify mb-2">Al firmar esta solicitud, estoy de acuerdo con los términos y condiciones del programa de NTRK de Bayer y certifico que he obtenido el consentimiento informado del paciente para realizar esta prueba diagnóstica.</p>
                :
                <p className="text-justify mb-2">Al firmar el presente certifico que se me ha informado del aviso de privacidad y del consentimiento informado, y estoy de acuerdo con los términos y condiciones descritos en el mismo.</p>
            }
            <div id="containerCanvas"></div>
            <canvas ref={canvasFirma} id="signature-pad" className="canvas signature-pad" width={400} height={200}></canvas>
            <div className="row">
              <div className="col">
                <button className="btn btn-primary" type="button" onClick={limpiarFirma}>
                  Limpiar firma
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col">
                {
                  mensajePos ?
                    <h5 className='anuncioPositivo'>{mensaje}</h5>
                    : <h6 className='anuncioNegativo'>{mensaje}</h6>
                }
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-danger" onClick={cerrarModal}><i className="fas fa-trash" aria-hidden="true"></i> Cancelar</button>
            <button
              onClick={guardarFirma}
              type="button" className="btn btn-primary">
              <i className="fas fa-save" aria-hidden="true"></i> Guardar
            </button>
          </div>
        </div>
      </div>
    </div >
  );
};

export default FirmaDigital;
