import React, { useState, useEffect } from 'react';
import AdminEstudioForm from '../../modules/AdminPath/AdminEstudioForm';

const EditarEstudio = ({ titulo, titulosCategorias, titulosEspecialidades, enviarEstudio,
  titulosEstudios, infoEstudioPart, getDetalleEstudio, eliminarEstudioDuplicado }) => {
  const [estudioSelect, setEstudioSelect] = useState('');

  useEffect(() => {
    if (estudioSelect) {
      getDetalleEstudio(estudioSelect);
    }
    // eslint-disable-next-line
  }, [estudioSelect]);

  return (
    <div className="tab-pane fade" id="editarEstudio" role="tabpanel" aria-labelledby="editarEstudio-tab">
      <div className="card mt-3">
        <div className="card-header">
          <h5><i className="fi-cwsuxl-check"></i> Selecione el estudio que desea editar</h5>
        </div>
        <div className="card-body">
          <div className="form-row">
            <div className="col-12 mb-3">
              <label htmlFor="especialidad">Seleccione Especialidad</label>
              <select className="form-control mr-sm-2" name="especialidad"
                onChange={e => setEstudioSelect(e.target.value)}
              >
                <option value={''}>Seleccione un estudio a editar</option>
                {
                  titulosEstudios.map(titulo => {
                    return <option key={titulo} value={titulo}>{titulo}</option>;
                  })
                }
              </select>
            </div>
          </div>
        </div>
      </div>
      <AdminEstudioForm
        titulo={titulo}
        titulosCategorias={titulosCategorias}
        titulosEspecialidades={titulosEspecialidades}
        enviarEstudio={enviarEstudio}
        infoEstudioPart={infoEstudioPart}
        estudioSelect={estudioSelect}
        eliminarEstudioDuplicado={eliminarEstudioDuplicado}
      />
    </div>
  );
};

export default EditarEstudio;