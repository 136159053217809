import React, { useState, useContext, useEffect } from 'react';

import { Contexto as UsersContext } from '../../context/UsersContext';
import { Contexto as AdminContext } from '../../context/AdminContext';
import history from '../../Navigation';
import Input from '../../modules/LoginPath/Input';
import mostrarSaludo from '../../utils/Saludo';

const Register = ({ setIsLogin }) => {
  const { state, registrarNuevoUsuario, registrarInfoNuevoUsuario } = useContext(UsersContext);
  const { state: { titulosEspecialidades }, getAllEspecialidades } = useContext(AdminContext);

  const [inputName, setInputName] = useState('');
  const [inputSecondName, setInputSecondName] = useState('');
  const [inputApellido, setInputApellido] = useState('');
  const [inputSecondApellido, setInputSecondApellido] = useState('');
  const [email, setEmail] = useState('');
  const [email2, setEmail2] = useState('');
  const [errorEmail, setErrorEmail] = useState(null);
  const [inputTelefono, setInputTelefono] = useState('');
  const [inputCedula, setInputCedula] = useState('');
  const [inputCedulaEspecialidad, setInputCedulaEspecialidad] = useState('');
  const [inputEspecialidad, setInputEspecialidad] = useState('');
  const [password, setPassword] = useState('');

  const [inputCalleNumero, setInputCalleNumero] = useState('');
  const [inputCodigoPostal, setInputCodigoPostal] = useState('');
  const [inputColinia, setInputColinia] = useState('');
  const [inputAlcaldiaMun, setInputAlcaldiaMun] = useState('');
  const [inputEstado, setInputEstado] = useState('');
  const [inputNombreHospital, setInputNombreHospital] = useState('');
  const [inputReferencias, setInputReferencias] = useState('');

  const { texto } = mostrarSaludo();

  const errorDominio = 'Ingrese un email que pertenezca a una organización';

  useEffect(() => {
    getAllEspecialidades();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (state.userUid) {
      registrarInfoUsuario();
    }
    // eslint-disable-next-line
  }, [state.userUid]);

  const registrarUsuario = async () => {
    if (inputName && inputApellido && email && inputTelefono && inputCedula && password) {
      // if (email.includes('gmail') || email.includes('hotmail') || email.includes('live') || email.includes('yahoo') || email.includes('aol') || email.includes('icloud')) {
      //   setErrorEmail(errorDominio)
      //   return;
      // } else {
      //   const emailEnMinus = email.toLocaleLowerCase();
      //   const response = await registrarNuevoUsuario(emailEnMinus, password);
      //   setErrorEmail(response);
      // }
      const emailEnMinus = email.toLocaleLowerCase();
      const response = await registrarNuevoUsuario(emailEnMinus, password);
      setErrorEmail(response);
    }
  }

  const registrarInfoUsuario = () => {
    const infoUsuario = {
      primerNombre: inputName,
      segundoNombre: inputSecondName,
      apellidoPat: inputApellido,
      apellidoMat: inputSecondApellido,
      telefono: inputTelefono,
      cedula: inputCedula,
      cedulaEspecialidad: inputCedulaEspecialidad,
      especialidad: inputEspecialidad,
      isAdmin: false,
      operador: false,
      email,
      email2: email2.toLocaleLowerCase(),
      direccion: {
        0: {
          calle: inputCalleNumero,
          cp: inputCodigoPostal,
          colonia: inputColinia,
          municipio: inputAlcaldiaMun,
          estado: inputEstado,
          nombreHospital: inputNombreHospital,
          referencias: inputReferencias
        }
      }
    };

    registrarInfoNuevoUsuario(state.userUid, infoUsuario);
    history.push('/completarregistro');
  };

  return (
    <>
      <div className="form-group">
        <p>
          <b>{texto}<span id="saludo"></span> doctor@,</b>
          <br />
          inicie sesión o registrese para acceder.
        </p>
      </div>

      <h5><i>Los campos marcados con * son obligatorios</i></h5>
      <br />

      <Input autoFocus id="inputName" placeholder="Primer Nombre *" required
        value={inputName}
        onChange={setInputName}
        claseExtra="alert-warning"
      />

      <Input id="inputSecondName" placeholder="Segundo Nombre"
        value={inputSecondName}
        onChange={setInputSecondName}
      />

      <Input id="inputApellido" placeholder="Apellido Paterno *" required
        value={inputApellido}
        onChange={setInputApellido}
        claseExtra="alert-warning"
      />

      <Input id="inputSecondApellido" placeholder="Apellido Materno"
        value={inputSecondApellido}
        onChange={setInputSecondApellido}
      />

      <Input type="email" id="inputEmail" placeholder="Dirección de Email *" required
        value={email}
        onChange={setEmail}
        claseExtra="alert-warning"
      />
      {
        errorEmail === 'auth/email-already-in-use' ?
          <h5 className="anuncioNegativo mb-3">Este correo ya está en uso</h5>
          : null
      }
      {
        errorEmail === errorDominio && <h5 className="anuncioNegativo mb-3">{errorDominio}</h5>
      }

      <Input type="email" id="inputEmail2" placeholder="Correo Alternativo Para Soporte"
        value={email2}
        onChange={setEmail2}
      />

      <Input type="tel" id="inputTelefono" placeholder="Número de teléfono *" required
        value={inputTelefono}
        onChange={setInputTelefono}
        claseExtra="alert-warning"
      />

      <Input id="inputCedula" placeholder="Cédula Profesional *" required
        value={inputCedula}
        onChange={setInputCedula}
        claseExtra="alert-warning"
      />
      <Input id="inputCedulaEspecialidad" placeholder="Cédula profesional de especialidad"
        value={inputCedulaEspecialidad}
        onChange={setInputCedulaEspecialidad}
      />

      <div className="form-label-group">
        <select id="inputEspecialidad" className="form-control" name="especialidad"
          onChange={e => setInputEspecialidad(e.target.value)}
          value={inputEspecialidad}
        >
          <option value=''>Seleccione su especialidad</option>
          {
            titulosEspecialidades.map(titulo => {
              return <option key={titulo} value={titulo}>{titulo}</option>;
            })
          }
        </select>
      </div>

      <Input type="password" id="inputPassword" placeholder="Contraseña *" required
        value={password}
        onChange={setPassword}
        claseExtra="alert-warning"
      />
      {
        errorEmail === 'auth/weak-password' ?
          <h5 className="anuncioNegativo mb-3">Contraseña débil, al menos 6 caracteres</h5>
          : null
      }


      <div className="form-label-group">
        <p className="mb-3"><b>Dirección</b></p>
      </div>

      <Input id="inputCalleNumero" placeholder="Calle y número"
        value={inputCalleNumero}
        onChange={setInputCalleNumero}
      />
      <Input id="inputCodigoPostal" placeholder="Código Postal"
        value={inputCodigoPostal}
        onChange={setInputCodigoPostal}
      />
      <Input id="inputColinia" placeholder="Colonia"
        value={inputColinia}
        onChange={setInputColinia}
      />
      <Input id="inputAlcaldiaMun" placeholder="Alcaldia/Municipio"
        value={inputAlcaldiaMun}
        onChange={setInputAlcaldiaMun}
      />

      <div className="form-label-group">
        <select id="inputEstado" className="form-control" name="estado"
          onChange={e => setInputEstado(e.target.value)}
        >
          <option value=''>Seleccione su estado</option>
          <option value='Aguascalientes'>Aguascalientes</option>
          <option value='Baja California'>Baja California</option>
          <option value='Baja California Sur'>Baja California Sur</option>
          <option value='Campeche'>Campeche</option>
          <option value='CDMX'>CDMX</option>
          <option value='Chiapas'>Chiapas</option>
          <option value='Chihuahua'>Chihuahua</option>
          <option value='Coahuila'>Coahuila</option>
          <option value='Colima'>Colima</option>
          <option value='Durango'>Durango</option>
          <option value='Estado de México'>Estado de México</option>
          <option value='Guanajuato'>Guanajuato</option>
          <option value='Guerrero'>Guerrero</option>
          <option value='Hidalgo'>Hidalgo</option>
          <option value='Jalisco'>Jalisco</option>
          <option value='Michoacán'>Michoacán</option>
          <option value='Morelos'>Morelos</option>
          <option value='Nayarit'>Nayarit</option>
          <option value='Nuevo León'>Nuevo León</option>
          <option value='Oaxaca'>Oaxaca</option>
          <option value='Puebla'>Puebla</option>
          <option value='Querétaro'>Querétaro</option>
          <option value='Quintana Roo'>Quintana Roo</option>
          <option value='San Luis Potosí'>San Luis Potosí</option>
          <option value='Sinaloa'>Sinaloa</option>
          <option value='Sonora'>Sonora</option>
          <option value='Tabasco'>Tabasco</option>
          <option value='Tamaulipas'>Tamaulipas</option>
          <option value='Tlaxcala'>Tlaxcala</option>
          <option value='Veracruz'>Veracruz</option>
          <option value='Yucatán'>Yucatán</option>
          <option value='Zacatecas'>Zacatecas</option>
        </select>
      </div>

      <Input id="inputNombreHospital" placeholder="Nombre del hospital"
        value={inputNombreHospital}
        onChange={setInputNombreHospital}
      />
      <div>
        <label htmlFor='inputReferencias'>Referencias</label>
        <textarea id="inputReferencias" className="form-control text-center"
          placeholder="Indica el piso, a quién contactar, horarios y cómo llegar para colecta o entrega."
          value={inputReferencias}
          onChange={e => setInputReferencias(e.target.value)}
          style={{ borderRadius: '2rem' }}
        />
      </div>

      <p className="mb-3">
        <button
          className="btn btnTypeAnchor"
          onClick={() => setIsLogin(true)}
        >
          ¿Ya tienes cuenta?
        </button>
      </p>

      <button
        style={{ color: 'white' }}
        className="btn btn-lg btn-primary btn-block text-uppercase"
        type="submit"
        onClick={() => {
          window.hideModal("#ModalLogin");
          registrarUsuario();
        }}
      >
        Registrarse
      </button>
    </>
  );
}

export default Register;