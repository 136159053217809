import React, { useState } from 'react';

import { copyClipboard } from '../../utils/copiarPortapapeles';

const CopiarFirma = ({ asunto, folio, desdeDetalle }) => {
  const [textoCopiado, setTextoCopiado] = useState(false);
  const idInput = `portapapeles${asunto}`;
  const claseDetalleONo = desdeDetalle ? 'form-inline' : 'form-inline mb-5';

  const mensaje = `Si se va a ${asunto} después, ya sea digitalmente o subiendo un documento, enviar el siguiente mensaje:`;

  return (
    <div className="col-12 px-3 ml-auto mb-3">
      <hr />
      {
        desdeDetalle ?
        <p className="mb-3">{mensaje}</p>
        : <h5 className="mb-3">{mensaje}</h5>
      }
      <div className={claseDetalleONo}>
        <div className="form-group text-center">
          <label htmlFor={idInput} style={{ marginRight: '0.5rem' }}>Copiar a portapapeles enviar por mensaje o WhatsApp:</label>
          <input className="form-control mr-sm-2" type="text" id={idInput} readOnly
            value={`Firmar en https://diagnostiqueatiempo.com/firmamedico/${folio.toUpperCase()}, con el siguiente folio: ${folio.toUpperCase()}`}
          />
          <button className="btn btn-info" type="button" onClick={() => {
            copyClipboard(idInput);
            setTextoCopiado(true);
          }}>
            <i className="far fa-copy"></i> {textoCopiado ? 'Texto copiado' : 'Copiar texto'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CopiarFirma;