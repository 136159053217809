import React from 'react';
import AvisosContainer from '../../modules/AvisosPath/AvisosContainer';

const Privacidad = () => {
  return (
    <AvisosContainer>
      <h3 className="textoFormal mb-2">Aviso de privacidad</h3>
      <p className="text-justify mb-2">
        DX a Tiempo SAS de CV (Diagnostique A Tiempo, de ahora en adelante “DAT”), con domicilio en Zapata 360 Colonia Santa Cruz Atoyac, Alcaldía Benito Juárez, Ciudad de México, Código Postal 03310, declara que al recopilar, recibir,  almacenar y utilizar los datos personales y/o sensibles de sus clientes, médicos solicitantes y sus pacientes usa estos datos para cumplir las obligaciones que requieren la prestación de servicios para los fines legítimos que su operación requiere. Entre los datos recopilados se encuentran:
      </p>
      <ul className="text-justify mb-2 dashed">
        <li>Nombre</li>
        <li>Género</li>
        <li>Edad</li>
        <li>Fecha de nacimiento</li>
        <li>Diagnóstico</li>
        <li>Teléfono</li>
        <li>Correo electrónico</li>
        <li>Dirección </li>
        <li>Información relacionada al estado de salud del paciente</li>
        <li>Información de su tarjeta de crédito o débit</li>
        <li>Información sobre su dispositivo, como pueden ser, dirección IP</li>
        <li>Información relacionada a como usa el sitio o los servicios del sitio</li>
      </ul>
      <p className="text-justify mb-2">
        DAT transferirá esos datos, en territorio nacional o en el extranjero, a sus socios comerciales, proveedores de servicios y/o laboratorios para apoyar en el traslado, proceso de las pruebas diagnósticas, procesos administrativos relacionados con la obtención de tratamientos, procedimientos médicos o diagnósticos usando estos datos para cumplir con la solicitud de prestación de servicios mediada por un médico solicitante, facultado para ejercer en el país y para solicitar, analizar e interpretar dichas solicitudes.
        Solo utilizaremos su información personal o la de su paciente cuando exista un propósito acorde a los servicios ofrecidos por DAT.
      </p>
      <p className="text-justify mb-2">
        Hemos implementado controles técnicos y organizacionales para proteger su información personal contra accesos no autorizados, daño o pérdida accidental. Usted es responsable de elegir una contraseña segura cuando así lo solicitemos para acceder a ciertas partes del sitio, usted debe mantener ésta contraseña confidencial y debería elegir una contraseña que no use en otro sitio. No debe compartir su contraseña con otras personas, incluyendo a los empleados de DAT.
      </p>
      <p className="text-justify mb-2">
        La información es conservada de manera indefinida, pero no menor a un período de 5 años.
      </p>
      <p className="text-justify mb-2">
        Si tiene dudas respecto a este aviso o la información que DAT recopila, utiliza o comparte sobre sus datos, desea ejercer sus derechos de Transparencia, Acceso, Rectificación, Supresión, Limitación, Portabilidad, Cancelación u Oposición en términos del capítulo IV de la Ley Federal de Protección de Datos Personales en Posesión de Particulares (LFPDPPP) o excluirse de comunicaciones o programas conforme a la ley aplicable, puede contactarnos. El tiempo de respuesta es de 30 días hábiles.
      </p>
      <p className="text-justify mb-2">
        Por correo electrónico: <a href="mailto:contacto@diagnostiqueatiempo.com">contacto@diagnostiqueatiempo.com</a>
      </p>
    </AvisosContainer>
  );
};

export default Privacidad;