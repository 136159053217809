import React from 'react';
import AdminProgForm from '../../modules/AdminPath/AdminProgForm';

const AgregarPrograma = ({ titulo, titulosEstudios, getDetalleEstudio, infoEstudioPart, enviarPrograma, pharmas }) => {
  return (
    <div className="tab-pane fade" id="agregarPrograma" role="tabpanel" aria-labelledby="agregarPrograma-tab">
      <AdminProgForm
        titulo={titulo}
        titulosEstudios={titulosEstudios}
        getDetalleEstudio={getDetalleEstudio}
        infoEstudioPart={infoEstudioPart}
        enviarPrograma={enviarPrograma}
        pharmas={pharmas}
      />
    </div>
  );
};

export default AgregarPrograma;