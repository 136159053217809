import React from 'react';

const FormFacturar = ({ fisicaMoral, setFisicaMoral, RFC, setRFC, correoFactura, setEmailFactura, metodoPago }) => {
  return (
    <>
      <div className="col-6 mb-3">
        <label>Ingrese la persona física o moral:</label>
        <input className="form-control mr-sm-2" type="input" placeholder="Nombre completo o denominación social"
          onChange={e => setFisicaMoral(e.target.value)}
          value={fisicaMoral}
        />
      </div>
      <div className="col-6 mb-3">
        <label>Ingrese el RFC:</label>
        <input className="form-control mr-sm-2" type="input" placeholder="Ingrese RFC" name="RFC"
          onChange={e => setRFC(e.target.value)}
          value={RFC}
        />
      </div>
      <div className="col-6 mb-3">
        <label>Ingrese su correo para facturar:</label>
        <input className="form-control mr-sm-2" type="email" placeholder="Email empleado en facturas" name="emailFactura"
          onChange={e => setEmailFactura(e.target.value)}
          value={correoFactura}
        />
      </div>
      <div className="col-6 mb-3">
        <label>Método elegido de pago:</label>
        <div className="form-control mr-sm-2" type="input" placeholder="Método de pago" name="metodoPago">
          {
            metodoPago
              ? metodoPago
              : <>No se ha elegido un método de pago</>
          }
        </div>
      </div>
    </>
  );
};

export default FormFacturar;