import React from 'react';

const MenuEstudio = ({ cambioApartadoSolicitud, secciones, btnCrumbRef }) => {
  
  return (
    <div className="row mt-3 d-flex justify-content-center">
      {
        secciones.map((seccion, index) => {
          if (index === 0) {
            return (
              <div className="col-12 col-md-2" key={index}>
                <button id={`btn-crumb-${index}`}
                  ref={ref => {
                    if (ref && !btnCrumbRef.current.includes(ref)) {
                      btnCrumbRef.current.push(ref)
                    }
                  }}
                  onClick={() => cambioApartadoSolicitud(`btn-crumb-${index}`, seccion)} className="btn btn-crumb text-center breadcrumb-item2"
                >
                  <p>{`${index+1}. ${seccion.id}`}</p>
                </button>
              </div>
            );
          }
          return (
            <div className="col-12 col-md-2 d-none d-md-block" key={index}>
              <button id={`btn-crumb-${index}`}
                ref={ref => {
                  if (ref && !btnCrumbRef.current.includes(ref)) {
                    btnCrumbRef.current.push(ref)
                  }
                }}
                onClick={() => cambioApartadoSolicitud(`btn-crumb-${index}`, seccion, true)} className="btn btn-crumb text-center breadcrumb-item1"
              >
                <p>{`${index+1}. ${seccion.id}`}</p>
              </button>
            </div>
          );
        })
      }

    </div>
  );
};

export default MenuEstudio;
